import { useLongTermGoals } from "../../hooks/custom-goals/useLongTermGoals";
import { NoteProgressForm } from "../note-progress";
import { LongTermGoal, SmartGoal, SmartGoalCategory } from "./custom-goals";

export enum CustomGoalsModalMode {
	READ = "READ",
	CREATE = "CREATE",
	DELETE = "DELETE",
	COMPLETE = "COMPLETE",
}

export enum CustomGoalsFormMode {
	CREATE = "CREATE",
	UPDATE_SMART_GOAL = "UPDATE_SMART_GOAL",
	UPDATE_LONG_TERM_GOAL = "UPDATE_LONG_TERM_GOAL",
	DUPLICATE_SMART_GOAL = "DUPLICATE_SMART_GOAL",
	DUPLICATE_LONG_TERM_GOAL = "DUPLICATE_LONG_TERM_GOAL",
	DUPLICATE_MAINTENANCE_GOAL = "DUPLICATE_MAINTENANCE_GOAL",
}

export interface CustomGoalsFormProps {
	participantId: number;
	mode: CustomGoalsFormMode;
	entity: SmartGoal | LongTermGoal | null;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onDone: () => void;
}

export interface CustomGoalsFormData {
	type: "SMART_GOAL" | "LONG_TERM_GOAL" | null;
}

export type SmartGoalsFormProps = {
	entity: SmartGoal | null;
} & CustomGoalsFormProps;

export interface SmartGoalFormData {
	name: string | null;
	category: SmartGoalCategory[] | null;
	startDate: Date | null;
	endDate: Date | null;
	details: string | null;
	support: string[];
}

export type LongTermGoalsFormProps = {
	entity: LongTermGoal | null;
} & Omit<CustomGoalsFormProps, "longTermGoalsQuery">;

export interface LongTermGoalFormData {
	name: string | null;
	startDate: Date | null;
	endDate: Date | null;
	motivations: string | null;
	barriers: string | null;
}

export enum CustomGoalType {
	SMART_GOAL = "SMART_GOAL",
	LONG_TERM_GOAL = "LONG_TERM_GOAL",
	MAINTENANCE_GOAL = "MAINTENANCE_GOAL",
}

export interface CustomGoalsDeleteFormProps {
	participantId: number;
	mode: CustomGoalType;
	entity: SmartGoal | LongTermGoal;
	onDone: () => void;
}

export type CustomGoalsCompleteFormProps = CustomGoalsDeleteFormProps;

export interface CustomGoalsActiveForm {
	selected: boolean;
	progress: NoteProgressForm;
}
