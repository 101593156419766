import { UiTableProps } from "./table.model";

export enum TableFilterType {
	STRING = "STRING",
	STRING_RANGE = "STRING_RANGE",
	NUMBER = "NUMBER",
	NUMBER_RANGE = "NUMBER_RANGE",
	DATE = "DATE",
	DATE_NAVIGATOR = "DATE_NAVIGATOR",
	DATE_RANGE = "DATE_RANGE",
	SELECT = "SELECT",
	SELECT_MULTIPLE = "SELECT_MULTIPLE",
	BOOLEAN = "BOOLEAN",
}

export interface TableFilterOptions {
	columnClass?: string; // Bootstrap column class (ex: 'col-12 col-lg-4')
	props?: any; // props?: UiSelectMultipleProps | UiSelectProps | UiInputTextProps | UiInputDateProps;
	submitOnChange?: boolean;
}

// Filter
export interface ITableFilter {
	field: string;
	type: TableFilterType;
	order?: boolean;
	value?: any;
	options?: TableFilterOptions;
}

export class TableFilter implements ITableFilter {
	field: string;
	type: TableFilterType;
	order: boolean;
	value?: any;
	options?: any;

	constructor(params: ITableFilter) {
		this.field = params.field;
		this.type = params.type;
		this.order = params.order || params.order === false ? params.order : true;
		this.value = params.value;
		this.options = params.options;
	}
}

/**
 * Filters
 */

export interface ITableHeaderFilter {
	title?: string;
	tooltip?: string;
	filters: ITableFilter[];
}

export class TableHeaderFilter implements ITableHeaderFilter {
	title: string;
	tooltip?: string;
	filters: ITableFilter[];
	submitFn?: (value: TableFilterFormData) => void;

	constructor(params: UiTableProps) {
		this.title = params.filter?.title ?? "UI_COMPONENTS.TABLE.ACTIONS.FILTER";
		this.tooltip = params.filter?.tooltip;
		this.filters = params.filter?.filters.map((filter) => new TableFilter(filter)) ?? [];
		this.submitFn = params.filterFn;
	}
}

/**
 * Filter component
 */

export interface UiTableHeaderFilterProps {
	title: string;
	filters: ITableFilter[];
	fn: (value: any) => void;
}

export type TableFilterFormData = {
	[x: string]: any;
};
