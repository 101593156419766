import { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { AppointmentRow } from "../../../../models/pages/appointments/appointments.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import {
	Appointment,
	AppointmentCoach,
	AppointmentParticipant,
	AppointmentTypes,
} from "../../../../models/entities/appointments.model";
import AppointmentDetails from "../../../../components/appointments/AppointmentDetails";
import AppointmentModal from "../../../../components/appointments/AppointmentModal";
import AppointmentModalCancel from "../../../../components/appointments/AppointmentModalCancel";
import {
	AppointmentModalCoach,
	AppointmentModalMode,
} from "../../../../models/components/appointment-modal.model";
import { AppointmentsByParticipantRequest } from "../../../../models/endpoints/appointments-endpoint.model";
import { UpcomingAppointmentRow } from "../../../../models/pages/appointments/upcoming-appointment.model";

const ParticipantUpcomingAppointments = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const [appointmentTypes, setAppointmentTypes] = useState<AppointmentTypes[]>([]);
	const [appointmentCoaches, setAppointmentCoaches] = useState<AppointmentCoach[]>([]);

	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "type",
			title: "APPOINTMENTS.TABLE.COLUMNS.appointmentType",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.APPOINTMENTS_TYPE" },
			sortable: true,
		},
		{
			field: "datetime",
			title: "APPOINTMENTS.TABLE.COLUMNS.dateOn",
			sortable: true,
		},
		{
			field: "duration",
			title: "APPOINTMENTS.TABLE.COLUMNS.duration",
			sortable: true,
		},
		{
			field: "expandCollapse",
			template: TableColumnTemplate.EXPANDER,
			style: {
				minWidth: "120px",
			},
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			style: {
				minWidth: "220px",
			},
		},
	];

	// Rows
	const [rows, setRows] = useState<UpcomingAppointmentRow[]>([]);

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "datetime",
		sortOrder: 1,
		search: undefined,
		filters: undefined,
	});

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		// Get appointment coaches
		getAppointmentCoaches();
	}, []);

	// Get appointment coaches
	const getAppointmentCoaches = async () => {
		const response = await EndpointsService.appointments.getCoaches();
		setAppointmentCoaches(response);
	};

	// Get data: upcoming appointments
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		// Get appointments types
		let types: AppointmentTypes[] = appointmentTypes || [];
		if (appointmentTypes.length === 0) {
			types = await EndpointsService.appointments.getTypes();
			setAppointmentTypes(types);
		}

		// Request
		const request: AppointmentsByParticipantRequest = {
			config: {
				params: {
					minDate: null,
					maxDate: null,
					kannactId: participantContext.participant!.kannactId,
				},
			},
		};
		const ptEmail =
			participantContext.participant!.emails?.filter((x) => x.isPrimary)[0]?.emailAddress ||
			undefined;
		if (ptEmail) {
			request.config.params.email = ptEmail;
		}

		// Call endpoint
		await EndpointsService.appointments
			.getAppointmentsByParticipant(request)
			.then((response: Appointment[]) => {
				setMessage({
					severity: FieldMessageSeverity.INFO,
					label: messages.empty,
				});
				if (response?.length > 0) {
					const rows: UpcomingAppointmentRow[] = response
						.filter((x) => new Date(x.datetime).getTime() >= new Date().getTime())
						.map(
							(item) =>
								new UpcomingAppointmentRow(
									item,
									types,
									rescheduleModalOpen.bind(this, item),
									cancelModalOpen.bind(this, item)
								)
						);
					setRows(rows);
				}
			})
			.catch(() =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	/**
	 * ACTION: Reschedule appointment
	 */

	const [rescheduleModalShow, setRescheduleModalShow] = useState<boolean>(false);
	const [rescheduleModalAppointment, setRescheduleModalAppointment] =
		useState<Appointment | null>(null);
	const [rescheduleModalCoach, setRescheduleModalCoach] = useState<AppointmentModalCoach | null>(
		null
	);
	const [rescheduleModalParticipant, setRescheduleModalParticipant] =
		useState<AppointmentParticipant | null>(null);
	const rescheduleModalOpen = (e: Appointment) => {
		setRescheduleModalShow(true);
		setRescheduleModalAppointment(e);
		setRescheduleModalCoach({
			calendarId: e.coachId,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		});
		setRescheduleModalParticipant(e.participant);
	};

	/**
	 * ACTION: Cancel appointment
	 */

	const [cancelModalShow, setCancelModalShow] = useState<boolean>(false);
	const [cancelModalAppointment, setCancelModalAppointment] = useState<Appointment | null>(null);
	const cancelModalOpen = (e: Appointment) => {
		setCancelModalShow(true);
		setCancelModalAppointment(e);
	};

	return (
		<>
			<UiTable
				useAsCard={false}
				dataKey="id"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
				paginationFn={onPagination}
				paginator={false}
				expandedTemplate={(row: AppointmentRow) => <AppointmentDetails row={row} />}
			/>

			{/* Modal: Cancel appointment */}
			{cancelModalShow && cancelModalAppointment && (
				<AppointmentModalCancel
					isVisible={cancelModalShow}
					appointment={cancelModalAppointment}
					onCancel={() => setCancelModalShow(false)}
					onConfirm={() => {
						setCancelModalShow(false);
						getData(pagination);
					}}
				/>
			)}

			{/* Modal: reschedule appointment */}
			{rescheduleModalShow &&
				rescheduleModalAppointment &&
				rescheduleModalCoach &&
				rescheduleModalParticipant && (
					<AppointmentModal
						mode={AppointmentModalMode.RESCHEDULE}
						isVisible={rescheduleModalShow}
						participant={rescheduleModalParticipant}
						coach={rescheduleModalCoach}
						appointmentCoaches={appointmentCoaches}
						appointmentTypes={appointmentTypes}
						appointment={rescheduleModalAppointment}
						onCancel={() => setRescheduleModalShow(false)}
						onConfirm={() => {
							setRescheduleModalShow(false);
							getData(pagination);
						}}
					/>
				)}
		</>
	);
};
export default ParticipantUpcomingAppointments;
