import { Formatter } from "../../../services/formatter/formatter.service";
import { CONDITIONS_DEFAULT, Condition } from "../../entities/condition.model";

export class ConditionsSelfReportedRow {
	entity: Condition;
	conditionNameFormatted: string;
	isExternalFormatted = "ENUMS.SOURCE.COACH";
	isActiveFormatted: string;
	notesFormatted: string;

	constructor(params: Condition) {
		this.entity = params;
		this.conditionNameFormatted =
			CONDITIONS_DEFAULT.indexOf(params.conditionName) !== -1
				? `ENUMS.CONDITIONS.${params.conditionName}`
				: params.conditionName;
		this.isActiveFormatted = params.isActive
			? "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status_true"
			: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status_false";
		this.notesFormatted = params.notes ?? Formatter.NO_DATA;
	}
}
