import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useProvidersActive = (event: { search: string | undefined; filters: string }) => {
	const queryClient = useQueryClient();
	const queryKey = ["providers-active", event];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getProvidersActive({
			config: {
				params: {
					search: event.search || null,
					filters: event.filters,
				},
			},
		});
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
