import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import UiButton from "../../../../components/button/Button";
import {
	MedicationsModalMode,
	MedicationsModalProps,
} from "../../../../models/pages/medications/medications-modal";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import MedicationsCreate from "./MedicationsCreate";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import {
	MedicationRow,
	MedicationsListProps,
} from "../../../../models/pages/medications/medications-list.model";
import { AxiosError } from "axios";
import MedicationsList from "./MedicationsList";
import { MedicationLatestResponse } from "../../../../models/endpoints/medications-endpoint.model";
import { TabPanel, TabView } from "primereact/tabview";
import MedicationAdherenceView from "./MedicationsAdherenceView";
import MedicationsDelete from "./MedicationsDelete";

const MedicationsModal = (props: MedicationsModalProps) => {
	const { t } = useTranslation("common");

	const [activeTab, setActiveTab] = useState<number>(0);
	const [mode, setMode] = useState<MedicationsModalMode>(props.mode);
	const [toDelete, setToDelete] = useState<MedicationRow>();

	// Message: loading / error
	const messages = {
		loading: "PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.LOADING",
		error: "PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.ERROR",
	};

	/**
	 * GET MEDICATIONS
	 */

	// First time load
	useEffect(() => {
		getData();
	}, []);

	// Endpoint: GET latest biometric
	const getData = async (): Promise<void> => {
		// Message: loading
		setListProps({
			...listProps,
			medications: [],
			message: {
				severity: FieldMessageSeverity.LOADING,
				label: messages.loading,
			},
		});

		// Get the data
		await EndpointsService.dataRetriever
			.getMedicationsLatest({
				config: {
					params: {
						participantId: props.participantId,
						onlyActive: false,
					},
				},
			})
			.then((response: MedicationLatestResponse[]) => {
				setListProps({ ...listProps, medications: response });
			})
			.catch((error: AxiosError) => {
				// Message: error
				setListProps({
					...listProps,
					message: {
						severity: FieldMessageSeverity.DANGER,
						label: messages.error,
					},
				});
			});
	};

	// Component props: LIST
	const [listProps, setListProps] = useState<MedicationsListProps>({
		mode: props.mode,
		participantId: props.participantId,
		submitting: false,
		message: null,
		goals: props.goals,
		medications: [],
		changeFn: getData.bind(this),
	});

	return (
		<Dialog
			header={
				<div className="dialog-custom-header">
					{/* Title */}
					<span className="dialog-custom-header__title">
						{t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.TITLE")}
					</span>

					{/* Actions */}
					{mode === MedicationsModalMode.READ && (
						<div className="dialog-custom-header__actions">
							<UiButton
								label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.ACTION_CREATE"
								type="button"
								className="p-button-sm p-button-rounded"
								icon="pi pi-plus"
								onClick={() => setMode(MedicationsModalMode.CREATE)}
							/>
						</div>
					)}
				</div>
			}
			visible={props.isVisible}
			className="medications-modal"
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: mode === MedicationsModalMode.DELETE ? "50vw" : "80vw" }}
			modal={true}
			focusOnShow={false}
			draggable={false}
			onHide={props.closeModal}
		>
			{mode === MedicationsModalMode.CREATE && (
				<MedicationsCreate
					participantId={props.participantId}
					onCancel={() => setMode(MedicationsModalMode.READ)}
					onComplete={() => {
						getData();
						setMode(MedicationsModalMode.READ);
					}}
				/>
			)}

			{mode === MedicationsModalMode.READ && (
				<TabView
					activeIndex={activeTab}
					onTabChange={(e) => setActiveTab(e.index)}
				>
					<TabPanel
						header={t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.HEADERS.LIST_VIEW")}
					>
						<MedicationsList
							{...listProps}
							onClickDeactivate={(e) => {
								setToDelete(e);
								setMode(MedicationsModalMode.DELETE);
							}}
						/>
					</TabPanel>
					<TabPanel
						header={t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.HEADERS.ADHERENCE_VIEW")}
					>
						<MedicationAdherenceView {...listProps} />
					</TabPanel>
				</TabView>
			)}

			{mode === MedicationsModalMode.DELETE && toDelete && (
				<MedicationsDelete
					medication={toDelete}
					onCancel={() => {
						setToDelete(undefined);
						setMode(MedicationsModalMode.READ);
					}}
					onComplete={() => {
						setToDelete(undefined);
						setMode(MedicationsModalMode.READ);
						getData();
					}}
				/>
			)}
		</Dialog>
	);
};

export default MedicationsModal;
