import { useTranslation } from "react-i18next";

const BiometricsChartsLinks = ({ kannactId }: { kannactId: string }) => {
	const { t } = useTranslation("common");

	const link = `https://healthmonitoring.kannact.com/charts/predictions?kannactId=${kannactId}`;

	return (
		<div className="d-flex align-items-baseline mt-3">
			<span className="text-bold fs-5 me-3">
				{t("PARTICIPANT.BIOMETRICS.CHARTS.GLUCOSE_LINK_LABEL")}
			</span>

			<a
				href={link}
				target="_blank"
				rel="noreferrer noopener"
				className="k-link"
			>
				<span className="k-link-label">{link}</span>
			</a>
		</div>
	);
};
export default BiometricsChartsLinks;
