import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabPanel, TabView } from "primereact/tabview";
import { PageProps } from "../../../models/routing.model";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import ParticipantSms from "./sms/Sms";
import ParticipantCareMessagesClient from "./care-messages/CareMessagesClient";
import { Badge } from "primereact/badge";
import TwilioConversationService from "../../../services/twilio/twilio-conversations.service";
import { useUnreadMessagesCounts } from "./care-messages/useUnreadMessagesCounts";
import UiModalEngagement from "../../../components/modal-engagement/ModalEngagement.component";
import UiButton from "../../../components/button/Button";
import { ModalEngagementMode } from "../../../models/components/modal-engagement.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import UiCard from "../../../components/card/Card";

const ParticipantMessages = (props: PageProps) => {
	const { t } = useTranslation("common");
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	const unreadMessagesCounts = useUnreadMessagesCounts({
		participantId: participantContext.participant!.id,
	});

	const numUnreadCareMessages = unreadMessagesCounts.data?.careMessageUnreadCount ?? 0;

	const numUnreadSMS = unreadMessagesCounts.data?.smsUnreadCount ?? 0;

	const type = new URLSearchParams(window.location.search).get("type");

	const activeIndex = type === "SMS" ? 1 : 0;

	return (
		<UiCard
			title="PARTICIPANT.MESSAGES.TITLE"
			titleUnderline={false}
			customHeader={<NonCallEngagement patientId={participantContext.participant?.id!} />}
		>
			<TabView activeIndex={activeIndex}>
				{/* Care messages */}
				<TabPanel
					header={
						<>
							{t("PARTICIPANT.MESSAGES.CARE_MESSAGES.TAB_TITLE")}
							{numUnreadCareMessages > 0 && (
								<Badge
									value={TwilioConversationService.beautifyUnreadCount(
										numUnreadCareMessages
									)}
									severity="danger"
									className="messages__unread-badge"
								/>
							)}
						</>
					}
				>
					{participantContext.participant && (
						<ParticipantCareMessagesClient
							participant={participantContext.participant}
						/>
					)}
				</TabPanel>

				{/* SMS */}
				<TabPanel
					header={
						<>
							{t("PARTICIPANT.MESSAGES.SMS.TAB_TITLE")}
							{numUnreadSMS > 0 && (
								<Badge
									value={TwilioConversationService.beautifyUnreadCount(
										numUnreadSMS
									)}
									severity="danger"
									className="messages__unread-badge"
								/>
							)}
						</>
					}
				>
					{participantContext.participant && (
						<ParticipantSms participant={participantContext.participant} />
					)}
				</TabPanel>

				{/* Emails */}
				{/* <TabPanel header={t("PARTICIPANT.MESSAGES.CARE_MESSAGES.TAB_TITLE")} disabled /> */}
			</TabView>
		</UiCard>
	);
};

const NonCallEngagement = ({ patientId }: { patientId: number }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	return (
		<div className="action">
			<UiButton
				id="btnModalNonCallEngagement"
				label="PARTICIPANT.PROFILE.FIELDS.NON_CALL_ENGAGEMENT"
				type="button"
				icon="pi pi-plus"
				className="p-button-rounded p-button-sm"
				onClick={() => setIsModalVisible(true)}
			/>

			{isModalVisible && (
				<UiModalEngagement
					edit={false}
					mode={ModalEngagementMode.NON_CALL}
					patientId={patientId}
					participantList={null}
					entity={{
						callSid: null,
						engagementDate: new Date(),
						outcome: true,
						coachId: getSessionStorageUser().id!,
					}}
					isVisible={isModalVisible}
					closeModal={(shouldRefresh) => setIsModalVisible(false)}
				/>
			)}
		</div>
	);
};

export default ParticipantMessages;
