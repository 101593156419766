import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useBillingProviders = () => {
	const queryClient = useQueryClient();
	const queryKey = ["billing-providers"];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getProvidersInfo({
			config: {
				params: {
					filters: `type==BILLING_PROVIDER`,
				},
			},
		});
		return response.map((provider) => ({
			...provider,
			name: `${provider.firstName} ${provider.lastName}`,
		}));
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
