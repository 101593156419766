import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { classNames } from "primereact/utils";
import { ConfirmDialog } from "primereact/confirmdialog";
import Header from "../../components/header/Header";
import SidebarDesktop from "../../components/sidebar/SidebarDesktop";
import UiCallBar from "../../components/call-bar/CallBar.component";
import { getFunctionalModules } from "../../services/header/header.service";
import { getSidebarMenu } from "../../services/sidebar/sidebar.service";
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { getSessionStorageUser } from "../../services/session/auth.service";

const Main = () => {
	const matomoInstance = createInstance({
		urlBase: process.env.REACT_APP_MATOMO_URL_BASE!,
		siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID!),
		userId: getSessionStorageUser().id!.toString(),
		disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
		heartBeat: {
			active: true,
			seconds: 10, // Requires a page to be actively viewed for 10 seconds for any heart beat request to be sent.
		},
		linkTracking: false,
	});

	const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

	const location = useLocation();

	const [headerMenu] = useState(getFunctionalModules());
	const [sidebarMenu, setSidebarMenu] = useState(getSidebarMenu(location.pathname));
	const [mainClass, setMainClass] = useState<string>("main-container");

	// Main content css class
	const updateMainClass = (): void => {
		let css: string = "main-container ";
		if (!sidebarMenu || sidebarMenu?.menu?.length === 0) css += "no-sidebar ";
		setMainClass(css);
	};

	useEffect(() => {
		// Update sidemenu
		setSidebarMenu(getSidebarMenu(location.pathname));
		updateMainClass();
	}, [location]);

	return (
		<div
			className={
				!sidebarMenu || sidebarMenu?.menu?.length === 0
					? "content-wrapper no-sidebar"
					: "content-wrapper"
			}
		>
			{/* Header */}
			{headerMenu?.length > 0 && <Header props={headerMenu} />}

			{/* Left sidebar */}
			{sidebarMenu?.menu?.length > 0 && isDesktop && <SidebarDesktop props={sidebarMenu} />}

			<main className={classNames(mainClass, isMobile && "responsive")}>
				<MatomoProvider value={matomoInstance}>
					{/* Pages are loaded here */}
					<div className="main-content">
						<Outlet />
					</div>

					{/* Confirmation dialog */}
					<ConfirmDialog />

					{/* Call management bar */}
					<UiCallBar />
				</MatomoProvider>
			</main>
		</div>
	);
};

export default Main;
