import { ColumnSortOrderType } from "primereact/column";
import {
	TableColumnTemplate,
	TableColumn,
	TableDateMode,
} from "../../models/components/table/table-column.model";
import { TableFilterType, ITableFilter } from "../../models/components/table/table-filter.model";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
} from "../../models/components/table/table-template.model";
import {
	AppointmentBlocksTypes,
	AppointmentBlockType,
	AppointmentCoach,
	AppointmentType,
	AppointmentTypes,
} from "../../models/entities/appointments.model";
import { List } from "../../models/misc.model";
import { AppointmentRow } from "../../models/pages/appointments/appointments.model";
import { Validations } from "../form/validations.service";
import { Formatter } from "../formatter/formatter.service";
import { HelperService } from "../helpers/helper.service";

// Date: today's date
const getTodayDate = () => {
	const today: Date = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
};

// Table: columns
const getColumns = () => {
	const columns: TableColumn[] = [
		{
			field: "kannactIdTemplate",
			title: "APPOINTMENTS.TABLE.COLUMNS.kannactId",
			template: TableColumnTemplate.KANNACT_ID,
		},
		{
			field: "name",
			sortingField: "firstName,lastName",
			title: "APPOINTMENTS.TABLE.COLUMNS.name",
			sortable: true,
		},
		{
			field: "typeTemplate",
			title: "APPOINTMENTS.TABLE.COLUMNS.appointmentType",
			template: TableColumnTemplate.TRANSLATE,
			sortable: true,
		},
		{
			field: "datetime",
			title: "APPOINTMENTS.TABLE.COLUMNS.dateOn",
			sortable: true,
			onSort: (e) => sort(e.order, e.rowData as AppointmentRow[]),
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
					timeZoneName: "shortGeneric",
				},
				dateMode: TableDateMode.LOCAL,
			},
		},
		{
			field: "duration",
			title: "APPOINTMENTS.TABLE.COLUMNS.duration",
		},
		{
			field: "phoneTemplate",
			title: "APPOINTMENTS.TABLE.COLUMNS.phoneNumber",
			template: TableColumnTemplate.PHONE_CALL,
			style: {
				minWidth: "200px",
			},
		},
		{
			field: "expandCollapse",
			template: TableColumnTemplate.EXPANDER,
			style: {
				minWidth: "120px",
			},
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			style: {
				minWidth: "220px",
			},
		},
	];

	if (HelperService.isAdminViewMode()) {
		const column = {
			field: "coachId",
			template: TableColumnTemplate.COACH,
			title: "APPOINTMENTS.TABLE.COLUMNS.coachId",
			sortable: true,
		};
		columns.splice(3, 0, column);
	}

	return columns;
};

// Table: filters
const getFilters = (
	typesList: List,
	coachesList: List
): {
	datetime: ITableFilter;
	type: ITableFilter;
	coachId: ITableFilter;
} => {
	const result = {
		datetime: {
			field: "datetime",
			type: TableFilterType.DATE_NAVIGATOR,
			options: {
				columnClass: "col-12 col-sm-6 col-md-6 col-lg-3",
				submitOnChange: true,
				props: {
					label: "APPOINTMENTS.TABLE.FILTER_TITLE_DATEON",
					validations: [Validations.required],
				},
			},
			value: getTodayDate(),
		},
		type: {
			field: "type",
			type: TableFilterType.SELECT_MULTIPLE,
			options: {
				columnClass: "col-12 col-md-4 col-lg-3",
				props: {
					label: "APPOINTMENTS.TABLE.FILTER_TITLE_APPOINTMENT_TYPE",
					options: typesList,
				},
			},
			value: [],
		},
		coachId: {
			field: "coachId",
			type: TableFilterType.SELECT_MULTIPLE,
			options: {
				columnClass: "col-12 col-md-3 col-lg-3",
				props: {
					label: "APPOINTMENTS.TABLE.FILTER_TITLE_COACH",
					options: coachesList,
					filter: true,
				},
			},
			value: [],
		},
	};

	return result;
};

const removeFilterDatetime = (filters: ITableFilter[]): ITableFilter[] =>
	filters.filter((x) => x.field !== "datetime");

const filterFormattedDatetime = (filters: ITableFilter[]) => {
	const date: Date | undefined = filters?.filter((x) => x.field === "datetime")[0].value;
	let result: string | null = null;
	if (date) {
		const year = `${date.getFullYear()}`;
		const month =
			date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
		const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
		result = `${year}-${month}-${day}`;
	}
	return result;
};

// Table: sort by datetime
const sort = (order: ColumnSortOrderType, rows: AppointmentRow[]) =>
	rows.sort((a, b) =>
		order === 1
			? new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
			: new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
	);

// Table template: datetime
const templateDatetime = (dateOn: string) =>
	Formatter.dateISOToString(dateOn, {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		timeZoneName: "shortGeneric",
	});

// Table template: duration
const templateDuration = (type: AppointmentType, appointmentTypes: AppointmentTypes[]) => {
	const appointment = appointmentTypes.find((x) => x.type === type);
	return `${appointment?.durationInMinutes ?? Formatter.NO_DATA} min + ${
		appointment?.totalPaddingInMinutes ?? Formatter.NO_DATA
	} buffer`;
};

// Table template: actions
const templateActions = (
	rescheduleFn: (e: TableTemplateButtonsEvent) => any,
	cancelFn: (e: TableTemplateButtonsEvent) => any
): TableTemplateButtons => [
	{
		id: "rescheduleBtn",
		label: "APPOINTMENTS.TABLE.ACTION_RESCHEDULE",
		className: "p-button-sm p-button-rounded",
		disabled: false,
		onClick: rescheduleFn,
	},
	{
		id: "cancelBtn",
		label: "APPOINTMENTS.TABLE.ACTION_CANCEL",
		className: "p-button-sm p-button-outlined p-button-rounded",
		disabled: false,
		onClick: cancelFn,
	},
];

const formatTypeDuration = (type: string) => {
	let result = "";
	switch (type) {
		case AppointmentType.COACHING_CALL_10:
			result = " (10 min)";
			break;
		case AppointmentType.COACHING_CALL_15:
			result = " (15 min)";
			break;
		case AppointmentType.COACHING_CALL_30:
			result = " (30 min)";
			break;
		case AppointmentType.COACHING_CALL_30_15:
			result = " (30 min)";
			break;
		case AppointmentType.COACHING_CALL_45:
			result = " (45 min)";
			break;
		case AppointmentType.KANNACT_INTRO_CALL_10:
			result = " (10 min)";
			break;
		case AppointmentType.FOLLOWUP_VISIT_20_10:
			result = " (20 min)";
			break;
		default:
			break;
	}
	return result;
};

const isPast = (
	datetime: string,
	type: AppointmentType | AppointmentBlockType,
	appointmentTypes: AppointmentTypes[],
	appointmentBlocksTypes: AppointmentBlocksTypes[]
) => {
	// Duration + Buffer
	const appt = type.includes("_BLOCK")
		? appointmentBlocksTypes.find((x) => x.type === type)
		: appointmentTypes.find((x) => x.type === type);
	const total = (appt?.durationInMinutes || 0) + (appt?.totalPaddingInMinutes || 0);

	const now = new Date();

	const apptDate = new Date(datetime);
	apptDate.setMinutes(apptDate.getMinutes() + total);

	return now.getTime() > apptDate.getTime();
};

const getAppointmentCoach = (list: AppointmentCoach[], calendarId: string) =>
	list.find((x) => x.id === calendarId);

export const AppointmentsService = {
	getColumns,
	getTodayDate,
	getFilters,
	filterFormattedDatetime,
	formatTypeDuration,
	removeFilterDatetime,
	templateDatetime,
	templateDuration,
	templateActions,
	sort,
	isPast,
	getAppointmentCoach,
};
