import { useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../../components/button/Button";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { List, ListOption } from "../../../../models/misc.model";
import UiSelect from "../../../../components/select/Select.component";
import { BsPlus } from "react-icons/bs";
import { TbTrash } from "react-icons/tb";
import UiInputTextarea from "../../../../components/input-textarea/InputTextarea.component";
import { useConditions } from "./useConditions";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { CreateConditionBody } from "../../../../models/endpoints/conditions.model";
import { useConditionsOptions } from "./useConditionsOptions";
import { useTranslation } from "react-i18next";
import { Validations } from "../../../../services/form/validations.service";
import { FieldError } from "../../../../models/form.model";
import FormSection from "../../../../components/form-section/FormSection.component";

type ADD_FORM = {
	list: List;
	condition: string | null;
	notes: string | null;
};

const ConditionsCreate = ({
	conditionsQuery,
	participantId,
	onCancel,
	onComplete,
}: {
	conditionsQuery: ReturnType<typeof useConditions>;
	participantId: number;
	onCancel: () => void;
	onComplete: () => void;
}) => {
	const { t } = useTranslation("common");

	const conditionsOptionsQuery = useConditionsOptions();

	// Loading
	if (conditionsQuery.isLoading || conditionsOptionsQuery.isLoading) {
		return (
			<div className="condition-create-form">
				<div className="message-content">
					<UiFieldMessage
						severity={FieldMessageSeverity.LOADING}
						label="PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.LOADING2"
					/>
				</div>
			</div>
		);
	}

	// Error
	if (conditionsQuery.isError || conditionsOptionsQuery.isError) {
		return (
			<div className="condition-create-form">
				<div className="message-content">
					<UiFieldMessage
						severity={FieldMessageSeverity.DANGER}
						label="PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.ERROR"
					/>
				</div>
			</div>
		);
	}

	/* There is data, or the data is empty */

	// Update initial conditions (options list)
	const existingConditions: string[] = conditionsQuery.data.map((item) => item.conditionName);
	const initialOptions: List = conditionsOptionsQuery.data
		.map((item) => new ListOption({ id: item, label: t(`ENUMS.CONDITIONS.${item}`) }))
		.filter((item) => !existingConditions.includes(item.id.toString()));

	return (
		<ConditionsCreateForm
			optionsList={initialOptions}
			participantId={participantId}
			onCancel={onCancel}
			onComplete={onComplete}
		/>
	);
};
export default ConditionsCreate;

const ConditionsCreateForm = ({
	optionsList,
	participantId,
	onCancel,
	onComplete,
}: {
	optionsList: List;
	participantId: number;
	onCancel: () => void;
	onComplete: () => void;
}) => {
	const [formError, setFormError] = useState<string | null>(null);

	const [formData, setFormData] = useState<{ data: ADD_FORM[] }>({
		data: [
			{
				list: optionsList,
				condition: null,
				notes: null,
			},
		],
	});

	const filterOptions = (): List => {
		let result: List = [];

		const existingList = formData.data.map((item) => item.condition);
		result = optionsList.filter((item) => !existingList.includes(item.id.toString()));

		return result;
	};

	const addCondition = () => {
		const tmpData = { ...formData };
		tmpData.data.push({
			condition: null,
			notes: null,
			list: filterOptions(),
		});
		setFormData(tmpData);
	};

	const removeCondition = (index: number) => {
		const tmpData = { ...formData };
		tmpData.data.splice(index, 1);
		setFormData(tmpData);
	};

	const handleSubmit = async () => {
		const body: CreateConditionBody[] = formData.data
			.filter((item) => item.condition)
			.map((item) => {
				const data: CreateConditionBody = {
					participantId,
					isActive: true,
					isExternal: false,
					notes: item.notes,
					conditionName: item.condition as string,
				};
				return data;
			});

		await EndpointsService.dataRetriever
			.createConditions({ body })
			.then(() => onComplete())
			.catch((error) =>
				setFormError("PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.ERROR_CREATE")
			);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			validate={(values: Record<string, any>) => {
				const result: { data: ({ condition: FieldError } | null)[] } = {
					data: [],
				};

				formData.data.forEach((item, index) => {
					const error = Validations.required(item.condition);
					if (error) {
						result.data.push({ condition: error });
					} else {
						result.data.push(null);
					}
				});

				return result;
			}}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					{/* Add form list */}
					{formData.data.map((item, index) => (
						<div
							key={index}
							className="condition-create-form"
						>
							<FormSection>
								<div className="row">
									<div className="col-12 col-md-8 col-lg-4 col-xxl-3">
										<UiSelect
											id={`data[${index}].condition`}
											label="PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.FORM_FIELDS.condition"
											name={`data[${index}].condition`}
											onChange={(e) => {
												const updatedForm = { ...formData };
												updatedForm.data[index].condition = e.target.value;
												setFormData(updatedForm);
											}}
											editable={true}
											options={item.list}
											disabled={submitting}
										/>
									</div>
									<div className="col-12 col-md-12 col-lg-8 col-xxl-6">
										<UiInputTextarea
											id={`data[${index}].notes`}
											label="PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.FORM_FIELDS.notes"
											name={`data[${index}].notes`}
											onChange={(e) => {
												const updatedForm = { ...formData };
												updatedForm.data[index].notes = e.target.value;
												setFormData(updatedForm);
											}}
											disabled={submitting}
										/>
									</div>
								</div>
							</FormSection>

							{/* Remove */}
							<div className="condition-create-form__remove">
								{index !== 0 && (
									<UiButton
										type="button"
										icon={<TbTrash style={{ fontSize: "1.5rem" }} />}
										className="p-button-text neutral"
										onClick={() => removeCondition(index)}
									/>
								)}
							</div>
						</div>
					))}

					{/* Add new */}
					<div className="mt-3 text-center">
						<UiButton
							label="PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.ACTION_ADD_CONDITION"
							className="p-button-sm p-button-rounded"
							type="button"
							icon={<BsPlus />}
							onClick={addCondition}
							disabled={submitting || formData.data.length === 5}
						/>
					</div>

					{formError && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label={formError}
							/>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							type="button"
							className="p-button-outlined p-button-rounded"
							onClick={onCancel}
							disabled={submitting}
						/>
						<UiButton
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							type="submit"
							className="p-button-rounded"
							loading={submitting}
							disabled={submitting}
						/>
					</div>
				</form>
			)}
		/>
	);
};
