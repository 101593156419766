import { BiCopy } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import ParticipantDetailsField from "./ParticipantDetailsField";
import UiButton from "../../../components/button/Button";

const ParticipantDetailExternalLink = (props: { title: string; href: string }) => {
	const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
	const handleCopyContentToClipboard = () => {
		navigator.clipboard.writeText(props.href);
		setHasCopiedToClipboard(true);

		setTimeout(() => {
			setHasCopiedToClipboard(false);
		}, 3000);
	};

	return (
		<div className="participant-details-external-link">
			<ParticipantDetailsField
				title={props.title}
				value={
					<>
						<a
							href={props.href}
							target="_blank"
							rel="noreferrer noopener"
							className="k-link"
						>
							<span className="k-link-label">{props.href}</span>
						</a>
						{!hasCopiedToClipboard ? (
							<UiButton
								className="p-button-outlined p-button-xs"
								label="UI_COMPONENTS.BUTTONS.COPY"
								icon={<BiCopy />}
								onClick={handleCopyContentToClipboard}
							/>
						) : (
							<UiButton
								className="p-button-outlined p-button-xs"
								label="UI_COMPONENTS.BUTTONS.COPIED"
								style={{ pointerEvents: "none" }}
								icon={<FaCheck />}
							/>
						)}
					</>
				}
			/>
		</div>
	);
};

export default ParticipantDetailExternalLink;
