import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Call } from "@twilio/voice-sdk";
import { OverlayPanel } from "primereact/overlaypanel";
import {
	CallPresenterEngagementForm,
	CallPresenterProps,
} from "../../models/components/call-presenter.model";
import UiButton from "../button/Button";
import { Formatter } from "../../services/formatter/formatter.service";
import UiExtensionPad from "../extension-pad/ExtensionPad.component";
import { Form } from "react-final-form";
import { FormApi } from "final-form";
import UiSelect from "../select/Select.component";
import {
	eEngagementDetails,
	engagementType,
	IEngagement,
} from "../../models/entities/engagement.model";
import { getSessionStorageUser } from "../../services/session/auth.service";
import { List, ListOption } from "../../models/misc.model";
import UiInputText from "../input-text/InputText.component";
import { DropdownChangeParams } from "primereact/dropdown";
import { Validations } from "../../services/form/validations.service";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { mappedAxiosError } from "../../services/http/http.service";
import UiFieldMessage from "../field-message/FieldMessage.component";
import UiLink from "../link/Link.component";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const CallPresenter = (props: CallPresenterProps) => {
	const { t } = useTranslation("common");
	const matomo = useMatomo();

	// Keypad popup
	const op = useRef<OverlayPanel>(null);

	/* Engagement form */

	// Participants (when multiple particiants have the same phone number)
	const [participantList, setParticipantList] = useState<List>([]);

	const outcomeList: List = [
		{ id: true, label: "UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.outcomeList.true" },
		{ id: false, label: "UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.outcomeList.false" },
	];
	// Details: The coach talked to the participant
	const detailsList1: List = [
		{ id: "COACHING_SESSION", label: "ENUMS.ENGAGEMENT_DETAILS.COACHING_SESSION" },
		{ id: "TECH_SUPPORT", label: "ENUMS.ENGAGEMENT_DETAILS.TECH_SUPPORT" },
		{ id: "RESCHEDULED", label: "ENUMS.ENGAGEMENT_DETAILS.RESCHEDULED" },
		{ id: "PT_UNENROLLED", label: "ENUMS.ENGAGEMENT_DETAILS.PT_UNENROLLED" },
		{ id: "CONNECTION_ISSUES", label: "ENUMS.ENGAGEMENT_DETAILS.CONNECTION_ISSUES" },
		{ id: "INTRO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INTRO_CALL" },
		{ id: "INFO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INFO_CALL" },
		{ id: "OTHER", label: "ENUMS.ENGAGEMENT_DETAILS.OTHER" },
	];
	// Details: The coach didn't talk to tthe participant
	const detailsList2: List = [
		{ id: "LEFT_VOICEMAIL", label: "ENUMS.ENGAGEMENT_DETAILS.LEFT_VOICEMAIL" },
		{ id: "VOICEMAIL_PROBLEM", label: "ENUMS.ENGAGEMENT_DETAILS.VOICEMAIL_PROBLEM" },
		{ id: "INCORRECT_PHONE", label: "ENUMS.ENGAGEMENT_DETAILS.INCORRECT_PHONE" },
		{ id: "OTHER", label: "ENUMS.ENGAGEMENT_DETAILS.OTHER" },
	];

	const [engagementForm, setEngagementForm] = useState<CallPresenterEngagementForm>({
		participant: null,
		outcome: null,
		details: null,
		other: null,
		formMessage: undefined,
	});

	// Listen the participant information changes
	useEffect(() => {
		updateParticipantInfo();
	}, [props.callPresenter.state.callerInfo]);

	/**
	 * Actions
	 */

	const done = (): void => props.callPresenter.done();
	const extension = (digits: string): void => {
		if (props.callPresenter.state.call && digits)
			props.callPresenter.extension(props.callPresenter.state.call, digits);
	};

	/**
	 * Template: Actions
	 */

	const setActionsActiveTemplate = (): React.ReactNode => {
		return (
			<>
				<div className="call-actions">
					<UiButton
						type="button"
						label="UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.KEYPAD"
						className="p-button-white p-button-sm"
						onClick={(e) => op.current?.toggle(e)}
					></UiButton>
					<UiButton
						type="button"
						label={
							props.callPresenter.state.call?.isMuted()
								? "UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.UNMUTE"
								: "UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.MUTE"
						}
						className="p-button-white p-button-sm"
						icon={
							props.callPresenter.state.call?.isMuted()
								? "pi pi-volume-up"
								: "pi pi-volume-off"
						}
						onClick={props.callPresenter.mute}
					></UiButton>
					<UiButton
						type="button"
						label="UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.HANGUP"
						className="p-button-danger p-button-sm"
						onClick={() => {
							props.callPresenter.hangUp();
							matomo.trackEvent({ category: "CALL_ACTION", action: "HANG_UP" });
						}}
					></UiButton>
				</div>

				{/* Extensionpad */}
				<OverlayPanel
					ref={op}
					dismissable
				>
					{/* {data?.call &&  */}
					<UiExtensionPad
						call={props.callPresenter.state.call || undefined}
						sendExtension={extension}
					></UiExtensionPad>
					{/* } */}
				</OverlayPanel>
			</>
		);
	};

	const setActionsIncomingTemplate = (): React.ReactNode => {
		return (
			<div className="call-actions">
				<UiButton
					type="button"
					label="UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.VOICEMAIL"
					className="p-button-white p-button-sm"
					onClick={props.callPresenter.reject}
				></UiButton>
				<UiButton
					type="button"
					label="UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.ANSWER"
					className="p-button-success p-button-sm"
					onClick={() => {
						props.callPresenter.answer();
						matomo.trackEvent({ category: "CALL_ACTION", action: "ANSWER" });
					}}
				></UiButton>
			</div>
		);
	};

	const setActionsInactiveTemplate = (): React.ReactNode => {
		return (
			<div className="call-actions">
				<UiButton
					type="button"
					label="UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.HANGUP"
					className="p-button-danger p-button-sm"
					onClick={props.callPresenter.hangUp}
				></UiButton>
			</div>
		);
	};

	const setActionsTemplate = (): React.ReactNode => {
		let template: React.ReactNode = <></>;
		switch (props.callPresenter.state.call?.status()) {
			case Call.State.Pending:
				if (props.callPresenter.state.call?.direction === Call.CallDirection.Incoming)
					template = setActionsIncomingTemplate();
				break;
			case Call.State.Open:
				template = setActionsActiveTemplate();
				break;
			case Call.State.Connecting:
			case Call.State.Reconnecting:
			case Call.State.Ringing:
				template = setActionsInactiveTemplate();
				break;
			default:
				break;
		}
		return template;
	};

	/**
	 * Template: Engagement
	 */

	// Participant information
	const updateParticipantInfo = () => {
		let list: List = [];

		if (props.callPresenter.state.callerInfo?.length) {
			/* We could find the phone number in the participants DB */

			// Update the participants list
			const participants: List = props.callPresenter.state.callerInfo.map((pt) => {
				const item: ListOption = {
					id: pt.id,
					label: `${Formatter.fullName(pt.firstName, pt.lastName)} / ${pt.kannactId}`,
				};
				return item;
			});
			list = participants;
		} else {
			// We couldn't find the phone number in the participants DB
			const phoneNumber: string =
				props.callPresenter.state.call?.direction === Call.CallDirection.Incoming
					? props.callPresenter.state.fromNumber
					: props.callPresenter.state.toNumber;

			list = [{ id: phoneNumber, label: phoneNumber }];
		}

		// Set select options list
		setParticipantList(list);
	};

	// Engagement form: outcome changed
	const outcomeChanged = (event: DropdownChangeParams): void => {
		// Update field value, and update the fields that depend on this one
		setEngagementForm({
			...engagementForm,
			outcome: event.target.value,
			details: null,
			other: null,
		});
	};

	// Engagement form: details changed
	const detailsChanged = (event: DropdownChangeParams): void => {
		// Update field value, and update the fields that depend on this one
		setEngagementForm({
			...engagementForm,
			details: event.target.value,
			other: null,
		});
	};

	// Submit: create engagement
	const submitEngagement = async (
		data: CallPresenterEngagementForm,
		form: FormApi<CallPresenterEngagementForm, CallPresenterEngagementForm>
	): Promise<void> => {
		const participant = Number(data.participant)
			? data.participant
			: data.participant?.toString().substring(3);
		await EndpointsService.dataRetriever
			.createEngagement({
				body: {
					callSid: props.callPresenter.state.call?.parameters["CallSid"] as string, // CallSid is the parentCallSid at the moment of the call
					callType: engagementType.PHONE,
					coachId: getSessionStorageUser().id!,
					details: data.details,
					patientId: participant as number,
					other: data.other,
					outcome: data.outcome as boolean,
					engagementDate: new Date(),
				},
			})
			.then((response: IEngagement) => {
				// Close the call bar
				done();
			})
			.catch((error: AxiosError) => {
				// Error message
				setEngagementForm({
					...engagementForm,
					formMessage: mappedAxiosError(
						undefined,
						"UI_COMPONENTS.CALL_MANAGEMENT.MESSAGES.SUBMITTING_ERROR"
					),
				});
			});
	};

	// Template: engagement
	const setEngagementTemplate = (): React.ReactNode => {
		return (
			<>
				{props.callPresenter.state.call?.status() === Call.State.Closed && (
					<Form
						onSubmit={submitEngagement}
						initialValues={engagementForm}
						render={({ handleSubmit, form, submitting }) => (
							<form onSubmit={handleSubmit}>
								<div className="call-engagement">
									<div className="row">
										{/* Participant */}
										<div className="col-12 col-sm-6 col-md-6 col-lg-3">
											<UiSelect
												id="participant"
												label="UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.participant"
												name="participant"
												onChange={(e) =>
													setEngagementForm({
														...engagementForm,
														participant: e.target.value,
													})
												}
												disabled={submitting}
												editable={true}
												validations={[Validations.required]}
												options={participantList}
												appendTo="self"
											/>
										</div>

										{/* Call outcome: Did the coach talk to the participant? */}
										<div className="col-12 col-sm-6 col-md-6 col-lg-2">
											<UiSelect
												id="outcome"
												label="UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.outcome"
												name="outcome"
												onChange={outcomeChanged}
												disabled={submitting}
												validations={[Validations.required]}
												options={outcomeList}
												appendTo="self"
											/>
										</div>

										{/* Details */}
										<div className="col-12 col-sm-6 col-md-6 col-lg-2">
											<UiSelect
												id="details"
												label="UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.details"
												name="details"
												onChange={detailsChanged}
												disabled={submitting}
												validations={[Validations.required]}
												scrollHeight="350px"
												options={
													engagementForm.outcome === true
														? detailsList1
														: detailsList2
												}
												appendTo="self"
											/>
										</div>

										{/* Other */}
										{engagementForm.details === eEngagementDetails.OTHER && (
											<div className="col-12 col-sm-6 col-md-6 col-lg-3">
												<UiInputText
													id="other"
													label="UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.other"
													name="other"
													onChange={(e) =>
														setEngagementForm({
															...engagementForm,
															other: e.target.value,
														})
													}
													disabled={submitting}
												/>
											</div>
										)}
									</div>

									<div className="complete-engagement">
										<UiButton
											type="submit"
											label={
												submitting
													? "UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.DONE_SUBMITTING"
													: "UI_COMPONENTS.CALL_MANAGEMENT.ACTIONS.DONE"
											}
											className="p-button-white inline-form"
											loading={submitting}
											disabled={!form.getState().valid}
										></UiButton>
									</div>
								</div>

								{/* Login endpoint messages */}
								{engagementForm?.formMessage && (
									<div className="pt-2 pb-2">
										<UiFieldMessage
											{...engagementForm?.formMessage}
										></UiFieldMessage>
									</div>
								)}
							</form>
						)}
					/>
				)}
			</>
		);
	};

	/**
	 * Template: Information
	 */
	const setInfoTemplate = (): React.ReactNode => {
		// Call status title (CALL ENDED, INCOMING CALL, ACTIVE CALL, etc.)
		const setTitle = (): string => {
			let title: string = "";
			switch (props.callPresenter.state.call?.status()) {
				case Call.State.Closed:
					title = t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.ENDED`);
					break;
				case Call.State.Open:
					title = t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.ACTIVE`);
					break;
				case Call.State.Pending:
					// Used only with incoming calls
					if (props.callPresenter.state.call?.direction === Call.CallDirection.Incoming)
						title = t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.INCOMING`);
					break;
				case Call.State.Ringing:
					// Used only with outgoing calls
					if (props.callPresenter.state.call?.direction === Call.CallDirection.Outgoing)
						title = t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.CALLING`);
					break;
				case Call.State.Connecting:
					title =
						props.callPresenter.state.call?.direction === Call.CallDirection.Incoming
							? t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.CONNECTING_INCOMING`)
							: t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.CONNECTING_OUTBOUND`);
					break;
				case Call.State.Reconnecting:
					title =
						props.callPresenter.state.call?.direction === Call.CallDirection.Incoming
							? t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.RECONNECTING_INCOMING`)
							: t(`UI_COMPONENTS.CALL_MANAGEMENT.TITLES.RECONNECTING_OUTBOUND`);
					break;
				default:
					break;
			}
			return title;
		};

		// Call duration timer
		const setTime = (): string | undefined =>
			Formatter.duration(props.callPresenter.state.timer);

		// Phone number (formatted)
		const setPhone = (): string | undefined =>
			Formatter.phoneNumber(
				props.callPresenter.state.call?.direction === Call.CallDirection.Incoming
					? props.callPresenter.state.fromNumber
					: props.callPresenter.state.toNumber
			);

		return (
			<div className={`call-info`}>
				<span className="title">{setTitle()}</span>
				<span className="duration">{setTime()}</span>
				<span className="phone">{setPhone()}</span>
				{props.callPresenter.state.call?.direction === Call.CallDirection.Incoming &&
					props.callPresenter.state.call?.status() !== Call.State.Closed &&
					props.callPresenter.state.callerInfo?.length && (
						<span className="pt-info">
							{/* Brand logo */}
							{props.callPresenter.state.callerInfo[0].brandLogo && (
								<img
									alt="brand logo"
									className="brand-logo"
									src={props.callPresenter.state.callerInfo[0].brandLogo}
								/>
							)}

							{/* KannactID */}
							{props.callPresenter.state.callerInfo[0].kannactId && (
								<UiLink
									className="kannact-id"
									to={`/participant/${props.callPresenter.state.callerInfo[0].kannactId.slice(3, props.callPresenter.state.callerInfo[0].kannactId.length)}/dashboard`}
									label={props.callPresenter.state.callerInfo[0].kannactId}
								/>
							)}
						</span>
					)}
			</div>
		);
	};

	return (
		<>
			{props.callPresenter.state.call?.status() && (
				<div
					className={`k-call-mngt-container direction-${props.callPresenter.state.call?.direction} status-${props.callPresenter.state.call?.status()}`}
				>
					<section className="top-section">
						{/* Information: call status + timer (only shown when the call is active) + phone number */}
						{setInfoTemplate()}

						{/* Call actions */}
						{setActionsTemplate()}
					</section>

					<section className="bottom-section">
						{/* Engagement: only shown when the call ends */}
						{setEngagementTemplate()}
					</section>
				</div>
			)}
		</>
	);
};

export default CallPresenter;
