import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UiCard from "../../components/card/Card";
import { PageProps } from "../../models/routing.model";
import { getCurrentRouteProps } from "../../services/routing/routing.service";

const Showcases = (props: PageProps) => {
	const navigate = useNavigate();
	const [pageTitle, setPageTitle] = useState<string>("");

	// Listen to the route changes, to update the page title dynamically
	useEffect(() => {
		const title: string | undefined = getCurrentRouteProps(window.location.pathname)?.title;
		if (title) setPageTitle(title);
	}, [navigate]);

	return (
		<div className="showcases-container">
			<UiCard
				title={pageTitle}
				theme="secondary"
			>
				<Outlet />
			</UiCard>
		</div>
	);
};

export default Showcases;
