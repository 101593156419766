import { useTranslation } from "react-i18next";
import { Formatter } from "../../../../../../services/formatter/formatter.service";

const NotesHistoryPanelItem = ({ label, value }: { label: string; value?: string }) => {
	const { t } = useTranslation("common");

	return (
		<p className="participant-notes-history__panel-item">
			<span className="participant-notes-history__panel-item-label">{t(label)}: </span>
			<span>{value || Formatter.NO_DATA}</span>
		</p>
	);
};

export default NotesHistoryPanelItem;
