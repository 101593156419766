import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { UiTableHeaderSearchProps } from "../../../models/components/table/table-search.model";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const UiTableHeaderSearch = (props: UiTableHeaderSearchProps) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState<{ search: string | null }>({
		search: props.value ?? null,
	});

	const handleChange = (value: string | null): void => {
		setFormData({ ...formData, search: value });
		if (!value) props.searchFn(value);
	};

	const handleSubmit = (data: { search: string }, form: any) => props.searchFn(data.search);

	return (
		<div className="k-searchbar">
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="p-inputgroup">
							<InputText
								id="search"
								placeholder={t(props.placeholder)}
								name="search"
								value={formData.search || undefined}
								className="search-input"
								onChange={(e) => handleChange(e.target.value)}
							/>
							<Button
								icon="pi pi-search"
								className=""
								onClick={handleSubmit}
							/>
						</div>
					</form>
				)}
			/>
		</div>
	);
};

export default UiTableHeaderSearch;
