import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { Sms } from "../../../../models/entities/sms.model";

export const useParticipantSms = (participantId: string) => {
	const queryClient = useQueryClient();
	const queryKey = getParticipantSmsQueryKey(participantId);

	const query = useQuery(
		queryKey,
		async () => {
			const smsFromTwilio = await EndpointsService.twilio.getSmsByParticipant(participantId);
			const smsFromCache = queryClient.getQueryData<Sms[] | undefined>(queryKey);

			/**
			 * Twilio has a race condition. Sometimes after queuing a new SMS successfully
			 *  with them, it's not returned in the request to list all the SMS.
			 *
			 * We're refetching the SMS list every 3 seconds to simulate a real-time experience
			 *  for the coach. To avoid encountering the race conditions described, we do the following:
			 *
			 *  1) After queuing a new SMS successfully with Twilio, we update the cache with the new SMS (check useCreateParticipantSms mutation code)
			 *  2) Every time we fetch the SMS list from Twilio we also get the SMS list from the cache, we merge them together and we sort them by creation date
			 */
			const smsFromCacheThatAreNotInTwilioYet =
				smsFromCache?.filter(
					(smsCache) => !smsFromTwilio.find((smsTwilio) => smsTwilio.sid === smsCache.sid)
				) ?? [];

			const smsSortedByCreationDate = [
				...smsFromTwilio,
				...smsFromCacheThatAreNotInTwilioYet,
			].sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());

			return smsSortedByCreationDate;
		},
		{
			refetchInterval: 3000,
		}
	);

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};

export const getParticipantSmsQueryKey = (participantId: string) => [
	"participant-sms",
	{ participantId },
];
