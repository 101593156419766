import React from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldInputProps } from "react-final-form";

// Primereact
import { InputText } from "primereact/inputtext";
import { ObjectUtils, classNames } from "primereact/utils";

// Own "library"
import UiFieldLabel from "../field-label/FieldLabel.component";
import { UiInputTextProps } from "../../models/components/input-text.model";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { Validations } from "../../services/form/validations.service";

const UiInputText = (props: UiInputTextProps) => {
	const { t } = useTranslation("common");

	/**
	 * Remove from the props the ones that need special treatment
	 * (like the placholder that needs to be translated, etc.).
	 */
	const otherProps = ObjectUtils.findDiffKeys(props, {
		leftIcon: props.leftIcon,
		rightIcon: props.rightIcon,
		placeholder: props?.placeholder,
		onChange: props.onChange,
		onBlur: props.onBlur,
		name: props.name,
		value: props.value,
		leftIconFn: props.leftIconFn,
		rightIconFn: props.rightIconFn,
		className: props.className,
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop
	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		input: FieldInputProps<any, HTMLElement>
	): void => {
		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		if (input) input.onChange(event);
		input.onBlur();
		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
	};

	const handleBlur = (
		event: React.FocusEvent<HTMLInputElement, Element>,
		input: FieldInputProps<any, HTMLElement>
	): void => {
		if (input) input.onBlur();
		// Execute onBlur callback function
		if (props.onBlur) props.onBlur(event);
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div className="k-inputtext-container">
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							prefix={props.prefix}
							leftIcon={props.leftIcon}
							rightIcon={props.rightIcon}
							meta={meta}
							validations={props.validations}
						>
							{/* Left icon */}
							{(props.leftIcon || props.prefix) && (
								<i
									className={classNames(
										"input-icon",
										props.leftIcon,
										props.leftIconFn ? "is-clickable" : "",
										props.prefix && "input-prefix"
									)}
									onClick={props.leftIconFn}
									role="button"
									tabIndex={0}
								>
									{props.prefix}
								</i>
							)}

							{/* Right icon */}
							{props.rightIcon && (
								<i
									className={classNames(
										"input-icon",
										props.rightIcon,
										props.rightIconFn ? "is-clickable" : ""
									)}
									onClick={props.rightIconFn}
									role="button"
									tabIndex={0}
								/>
							)}

							{/* Input */}
							<InputText
								placeholder={props?.placeholder ? t(props.placeholder) : undefined}
								className={classNames(
									{ "p-invalid": !!(meta.touched && meta.error) },
									props.className
								)}
								name={input.name}
								value={input.value}
								onChange={(event) => handleChange(event, input)}
								onBlur={(event) => handleBlur(event, input)}
								data-testid={props.testId}
								{...otherProps}
							/>
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiInputText;
