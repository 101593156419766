import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Form } from "react-final-form";
import { Trans, useTranslation } from "react-i18next";
import UiInputText from "../input-text/InputText.component";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../models/components/field-message.model";
import UiButton from "../button/Button";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { Appointment } from "../../models/entities/appointments.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { CancelAppointmentRequest } from "../../models/endpoints/appointments-endpoint.model";
import FormSection from "../form-section/FormSection.component";

const AppointmentModalCancel = ({
	isVisible,
	appointment,
	onCancel,
	onConfirm,
}: {
	isVisible: boolean;
	appointment: Appointment;
	onCancel: () => void;
	onConfirm: (e: Appointment) => void;
}) => {
	const { t } = useTranslation("common");

	const [reason, setReason] = useState<string | null>(null);
	const [message, setMessage] = useState<string | null>(null);

	const handleSubmit = async () => {
		// Request
		const request: CancelAppointmentRequest = {
			body: {
				appointmentId: appointment.id,
			},
		};
		if (reason) request.body.reason = reason;

		// Cancel
		await EndpointsService.appointments
			.cancelAppointment(request)
			.then((response) => {
				onConfirm(response);
			})
			.catch((error: AxiosError) => {
				switch (error.response?.status) {
					case 400:
						setMessage("UI_COMPONENTS.FIELD_MESSAGE.HTTP.400");
						break;
					case 404:
						setMessage("APPOINTMENTS.HTTP_MESSAGES.CANCEL_404");
						break;
					default:
						setMessage("APPOINTMENTS.HTTP_MESSAGES.CANCEL_500");
						break;
				}
			});
	};

	return (
		<Dialog
			header={t("APPOINTMENTS.MODAL_CANCEL.TITLE")}
			visible={isVisible}
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: "80vw" }}
			modal={true}
			focusOnShow={false}
			draggable={false}
			closable={true}
			onHide={onCancel}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={{ reason: null }}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						{/* Message */}
						<div className="mb-4">
							<Trans
								i18nKey="APPOINTMENTS.MODAL_CANCEL.MSG"
								defaults={t("APPOINTMENTS.MODAL_CANCEL.MSG")}
								values={{
									appointmentType: t(
										`ENUMS.APPOINTMENTS_TYPE.${appointment.type}`
									),
									name: Formatter.fullName(
										appointment.participant.firstName,
										appointment.participant.lastName
									),
									date: Formatter.date(appointment.datetime, {
										day: "2-digit",
										month: "long",
										year: "numeric",
										weekday: "long",
									}),
									ptTime: Formatter.dateISOToString(appointment.datetime, {
										hour: "2-digit",
										minute: "2-digit",
										hour12: true,
										timeZoneName: "shortGeneric",
										timeZone: appointment.participant.timezone,
									}),
									coachTime: Formatter.dateISOToString(appointment.datetime, {
										hour: "2-digit",
										minute: "2-digit",
										hour12: true,
										timeZoneName: "shortGeneric",
									}),
								}}
								components={{ bold: <span className="text-bold" /> }}
							/>
						</div>

						<FormSection>
							{/* Reason */}
							<UiInputText
								id="reason"
								placeholder="APPOINTMENTS.MODAL_CANCEL.NOTE"
								label="APPOINTMENTS.MODAL_CANCEL.NOTE"
								name="reason"
								onChange={(e) => setReason(e.target.value)}
								disabled={submitting}
							/>
						</FormSection>

						{/* FORM MESSAGE */}
						{message && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label={message}
								/>
							</div>
						)}

						{/* Actions: cancel | confirm */}
						<div className="action-buttons">
							<UiButton
								label="UI_COMPONENTS.BUTTONS.CANCEL"
								className="p-button-outlined p-button-rounded"
								type="button"
								onClick={onCancel}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "APPOINTMENTS.MODAL_CANCEL.BUTTON_SUBMITTING"
										: "APPOINTMENTS.MODAL_CANCEL.BUTTON_SUBMIT"
								}
								className="p-button-rounded"
								type="submit"
								disabled={submitting}
							/>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};
export default AppointmentModalCancel;
