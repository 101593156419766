import { useProvidersInfo } from "../../hooks/useProvidersInfo";

const getCoachName = (cellData: any): string => {
	const providers = useProvidersInfo();
	const foundProvider = providers.data?.find((provider) => provider.coachId === cellData);
	if (!foundProvider) return cellData;
	return `${foundProvider?.firstName} ${foundProvider?.lastName}`;
};

export const CoachFormatter = { getCoachName };
