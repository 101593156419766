import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { ParticipantProfile } from "../../../../models/entities/participant.model";

export const useParticipantSmsMembers = ({ participant }: { participant: ParticipantProfile }) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-sms-members"];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getProvidersInfo();

		const participantSmsMmebers: {
			[x: string]: { phone: string; fullName: string };
		} = {};

		// Map coaches names
		if (response?.length > 0) {
			response.forEach((item) => {
				participantSmsMmebers[`${item.countryCode}${item.phone}`] = {
					fullName: Formatter.fullName(item.firstName, item.lastName),
					phone: `${item.countryCode}${item.phone}`,
				};
			});
		}

		// Add the participant
		const phoneNumber = participant.phones?.length
			? participant.phones.find((e) => e.isPrimary)!
			: null;
		participantSmsMmebers[`${phoneNumber?.countryCode}${phoneNumber?.phoneNumber}`] = {
			fullName: Formatter.fullName(participant.firstName, participant.lastName),
			phone: `$${phoneNumber?.countryCode}${phoneNumber?.phoneNumber}`,
		};

		return participantSmsMmebers;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
