import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiButton from "../../../../../components/button/Button";
import { useEffect, useState } from "react";
import UiInputText from "../../../../../components/input-text/InputText.component";
import UiFieldMessage from "../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../models/components/field-message.model";
import { Validations } from "../../../../../services/form/validations.service";
import { EndpointsService } from "../../../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import {
	CustomGoalType,
	CustomGoalsCompleteFormProps,
} from "../../../../../models/custom-goals/custom-goals-misc";
import UiInputCheckbox from "../../../../../components/input-checkbox/InputCheckbox.component";

const CustomGoalsComplete = (props: CustomGoalsCompleteFormProps) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<boolean>(false);
	const [formData, setFormData] = useState<{
		reason: string | null;
		maintenance: boolean | null;
	}>({
		reason: null,
		maintenance: false,
	});

	useEffect(() => {
		console.log("Form data changed! ", formData);
	}, [formData]);

	const handleSubmit = async (data: { reason: string }) => {
		if (props.mode === CustomGoalType.SMART_GOAL) {
			await completeSmartGoal();
		} else {
			await completeLongTermGoal();
		}
	};

	const completeSmartGoal = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.completeSmartGoal({
				body: {
					id: props.entity!.id,
					reason: formData.reason!,
					maintenance: formData.maintenance!,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	const completeLongTermGoal = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.completeLongTermGoal({
				body: {
					id: props.entity!.id,
					reason: formData.reason!,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					{/* Message */}
					<div className="mb-4">
						<span>
							{t(
								props.mode === CustomGoalType.SMART_GOAL
									? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.MSG_SMART_GOAL"
									: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.MSG_LONG_TERM_GOAL"
							)}
						</span>
						<span className="mx-2 text-bold">{props.entity.name}</span>
					</div>

					{/* Reason */}
					<UiInputText
						id="reason"
						placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.reasonPlaceholder"
						label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.reason"
						name="reason"
						onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
						validations={[Validations.required]}
						disabled={submitting}
					/>

					{/* Maintenance */}
					{props.mode === CustomGoalType.SMART_GOAL && (
						<div className="row">
							<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
								<UiInputCheckbox
									id="maintenance"
									name="maintenance"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.maintenance"
									onChange={(e) =>
										setFormData({ ...formData, maintenance: e.target.value })
									}
									disabled={submitting}
									binary={true}
								/>
							</div>
						</div>
					)}

					{/* FORM MESSAGE */}
					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.SUBMIT_ERROR"
							/>
						</div>
					)}

					{/* Actions: cancel | confirm */}
					<div className="action-buttons">
						<UiButton
							label={"UI_COMPONENTS.BUTTONS.NEVERMIND"}
							className="p-button-text"
							type="button"
							onClick={props.onDone}
							disabled={submitting}
						/>
						<UiButton
							label={
								submitting
									? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.COMPLETING"
									: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COMPLETE_FORM.COMPLETE"
							}
							loading={submitting}
							type="submit"
							disabled={submitting}
						/>
					</div>
				</form>
			)}
		/>
	);
};
export default CustomGoalsComplete;
