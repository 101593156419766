import { UiFieldMessageProps } from "../components/field-message.model";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateTag,
} from "../components/table/table-template.model";
import { IEngagement, EngagementType, engagementType } from "../entities/engagement.model";

export type EngagementInfoData = {
	contactMethod: EngagementType | null;
	contactMethodSubmitting: boolean;
	engagementDate: Date | Date[] | null | undefined;
	engagementDateSubmitting: boolean;
	formMessage?: UiFieldMessageProps;
};

export interface IEngagementHistoryRow {
	id: number;
	engagementDate: Date;
	outcome: boolean;
	outcomeTemplate: TableTemplateTag | undefined;
	callType: EngagementType;
	callTypeTemplate: TableTemplateTag;
	details: string | null;
	other: string | null;
	coachId: number;
	actionsTemplate: TableTemplateButtons;
	entity: IEngagement;
}

export class EngagementHistoryRow implements IEngagementHistoryRow {
	id: number;
	engagementDate: Date;
	outcome: boolean;
	outcomeTemplate: TableTemplateTag | undefined;
	callType: EngagementType;
	callTypeTemplate: TableTemplateTag;
	details: string | null;
	other: string | null;
	coachId: number;
	actionsTemplate: TableTemplateButtons;
	entity: IEngagement;

	constructor(params: IEngagement, editFn: (e: TableTemplateButtonsEvent) => any) {
		this.id = params.id;
		this.engagementDate = params.engagementDate;
		this.outcome = params.outcome;
		this.outcomeTemplate = this.setOutcomeTemplate(params.outcome);
		this.callType = params.callType;
		this.callTypeTemplate = this.setTypeTemplate(params.callType);
		this.details = params.details;
		this.other = params.other;
		this.coachId = params.coachId;
		this.actionsTemplate = this.setActionsTemplate(params, editFn);
		this.entity = params;
	}

	private setOutcomeTemplate = (paramValue: boolean): TableTemplateTag | undefined => {
		const value: TableTemplateTag = {
			value: `PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.OUTCOME.${paramValue}`,
			uppercase: true,
			rounded: true,
			severity: paramValue ? "success" : "danger",
		};
		return paramValue || paramValue === false ? value : undefined;
	};

	private setTypeTemplate = (paramValue: EngagementType): TableTemplateTag => {
		const value: TableTemplateTag = {
			value: `ENUMS.ENGAGEMENT_TYPE.${paramValue}`,
			uppercase: true,
			rounded: true,
			severity: "danger",
		};

		switch (paramValue) {
			case engagementType.PHONE:
				value.severity = "success";
				break;
			case engagementType.CARE_MESSAGE:
				value.severity = "info";
				break;
			case engagementType.EMAIL:
				value.severity = "warning";
				break;
			default:
				break;
		}

		return value;
	};

	private setActionsTemplate = (
		params: IEngagement,
		editFn: (e: TableTemplateButtonsEvent) => any
	): TableTemplateButtons => {
		return [
			{
				id: "editBtn",
				className: "p-button-text neutral",
				icon: "pi pi-pencil",
				disabled: false,
				onClick: editFn,
			},
		];
	};
}
