import { useEffect, useState } from "react";
import UiTable from "../../../../components/table/Table.component";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { TableColumn } from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { SimpleTableRow } from "../../../../models/pages/showcases.model";
import { PageProps } from "../../../../models/routing.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableHeaderFilter,
} from "../../../../models/components/table/table-filter.model";
import { ITableHeaderRefresh } from "../../../../models/components/table/table-refresh.model";
import { ITableHeaderSearch } from "../../../../models/components/table/table-search.model";
import { ITableHeaderCrud } from "../../../../models/components/table/table-crud.model";
import { FilterService } from "../../../../services/filter/filter.service";

const FullTableShowcase = (props: PageProps) => {
	// Table: columns
	const columns: TableColumn[] = [
		{
			field: "key1",
			title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key1",
			sortable: true,
		},
		{
			field: "key2",
			title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key2",
			sortable: true,
		},
		{
			field: "key3",
			title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key3",
		},
		{
			field: "key4",
			title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key4",
		},
	];

	// Table: rows
	const [rows, setRows] = useState<SimpleTableRow[]>();

	// Table: pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
		search: undefined,
		filters: undefined,
	});

	// Table: message
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.LOADING,
		label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
	});

	// First time load: get the table data
	useEffect(() => {
		getData();
	}, []);

	// Simulate a backend call
	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	// Get data
	const getData = async (): Promise<void> => {
		// Message: loading
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
		});

		// Simulate a backend call
		await sleep(2000);

		// Set rows
		setRows([
			{
				key1: "Row 1 - Col 1",
				key2: 1,
				key3: "Row 1 - Col 3",
				key4: "",
			},
			{
				key1: "Row 2 - Col 1",
				key2: 5,
				key3: "Row 2 - Col 3",
				key4: "",
			},
			{
				key1: "Row 3 - Col 1",
				key2: 22,
				key3: "Row 3 - Col 3",
				key4: "Row 3 - Col 4",
			},
			{
				key1: "Row 4 - Col 1",
				key2: 87,
				key3: "Row 4 - Col 3",
				key4: "Row 4 - Col 4",
			},
		]);
	};

	/**
	 * REFRESH
	 */

	const tableRefresh: ITableHeaderRefresh = {
		fn() {
			console.log("Refreshing rows...");
			// Set rows
			setRows([
				{
					key1: "Row 1 - Col 1",
					key2: 1,
					key3: "Row 1 - Col 3",
					key4: "Row 1 - Col 4",
				},
				{
					key1: "Row 2 - Col 1",
					key2: 5,
					key3: "Row 2 - Col 3",
					key4: "Row 2 - Col 4",
				},
				{
					key1: "Row 3 - Col 1",
					key2: 22,
					key3: "Row 3 - Col 3",
					key4: "Row 3 - Col 4",
				},
				{
					key1: "Row 4 - Col 1",
					key2: 87,
					key3: "Row 4 - Col 3",
					key4: "Row 4 - Col 4",
				},
			]);
		},
	};

	/**
	 * SEARCH
	 */

	const tableSearch: ITableHeaderSearch = {
		title: "Search by phone...",
		fn(value: string | null | undefined) {
			console.log("Searching...", value);
			setPagination({ ...pagination, search: value });
		},
	};

	/**
	 * CRUD
	 */

	const tableCrud: ITableHeaderCrud = {
		create: {
			title: "Create",
			icon: "pi pi-plus",
			fn() {
				console.log("Crud create");
			},
		},
		update: {
			title: "Edit",
			icon: "pi pi-pencil",
			fn() {
				console.log("Crud edit");
			},
		},
		delete: {
			fn() {
				console.log("Crud delete");
			},
		},
	};

	/**
	 * FILTERS
	 */

	const [tableFilters, setTableFilters] = useState<ITableHeaderFilter>({
		filters: [
			{
				field: "key1",
				type: TableFilterType.STRING,
				value: "filterValue",
				options: {
					props: {
						label: "Filter key1",
						placeholder: "Filter by key1",
					},
				},
			},
			{
				field: "key2",
				type: TableFilterType.SELECT_MULTIPLE,
				options: {
					props: {
						options: [
							{ id: "ACTIVE", label: "ENUMS.PARTICIPANT_STATUS.ACTIVE" },
							{ id: "DNC", label: "ENUMS.PARTICIPANT_STATUS.DNC" },
							{ id: "INACTIVE", label: "ENUMS.PARTICIPANT_STATUS.INACTIVE" },
							{ id: "PROSPECTIVE", label: "ENUMS.PARTICIPANT_STATUS.PROSPECTIVE" },
						],
					},
				},
				value: ["ACTIVE"],
			},
		],
	});
	const submitFilters = (value: TableFilterFormData) => {
		console.log("filtering: ", value);
		// TODO: update filters values
		setTableFilters({
			...tableFilters,
			filters: FilterService.updateFiltersValues(tableFilters.filters, value),
		});

		// TODO: update pagination
		// setPagination({ ...pagination, filters: value });
	};

	return (
		<>
			<UiTable
				title="SHOWCASES.TABLE_SHOWCASES.FULL_TABLE_SHOWCASE.SUBTITLE"
				dataKey="key0"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
				refresh={tableRefresh}
				search={tableSearch}
				crud={tableCrud}
				filter={tableFilters}
				filterFn={submitFilters}
			/>
		</>
	);
};

export default FullTableShowcase;
