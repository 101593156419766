import { useTranslation } from "react-i18next";
import { AppointmentRow } from "../../models/pages/appointments/appointments.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { PastAppointmentRow } from "../../models/pages/appointments/past-appointment.model";
import { UpcomingAppointmentRow } from "../../models/pages/appointments/upcoming-appointment.model";
import { Appointment } from "../../models/entities/appointments.model";

const AppointmentDetails = ({
	row,
}: {
	row: AppointmentRow | PastAppointmentRow | UpcomingAppointmentRow;
}) => {
	const { t } = useTranslation("common");

	const participant = (row.entity as Appointment).participant;

	return (
		<div className="row">
			{/* Kannact ID */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.kannactId")}</label>
				<span>{participant.kannactId ?? t("APPOINTMENTS.DETAILS.noProfile")}</span>
			</div>

			{/* Institution */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.institution")}</label>
				<span>{participant.institution ?? Formatter.NO_DATA}</span>
			</div>

			{/* Phone (Acuity) */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.phoneAcuity")}</label>
				<span>
					{participant.phone
						? Formatter.phoneNumber(participant.phone)
						: Formatter.NO_DATA}
				</span>
			</div>

			{/* Spanish speaker */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.isSpanish")}</label>
				<span>{t(Formatter.beautifyBoolean(participant.isSpanish))}</span>
			</div>

			{/* Timezone (participant's) */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.timezone")}</label>
				<span>
					{participant.timezone && row.entity.datetime
						? Formatter.dateISOToString(row.entity.datetime, {
								hour: "2-digit",
								minute: "2-digit",
								hour12: true,
								timeZoneName: "shortGeneric",
								timeZone: participant.timezone,
							})
						: Formatter.NO_DATA}
				</span>
			</div>

			{/* Date of Birth (DOB) */}
			<div className="col-12 col-md-6 col-lg-3 col-xl-3">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.dob")}</label>
				<span>
					{participant.dateOfBirth
						? Formatter.dateISOToString(participant.dateOfBirth, {
								day: "numeric",
								month: "short",
								year: "numeric",
								timeZone: "UTC",
							}) ?? Formatter.NO_DATA
						: Formatter.NO_DATA}
				</span>
			</div>

			{/* Email (Acuity) */}
			<div className="col-12 col-md-6 col-lg-6 col-xl-6">
				<label className="text-bold me-2">{t("APPOINTMENTS.DETAILS.emailAcuity")}</label>
				<span>{participant.email ?? Formatter.NO_DATA}</span>
			</div>
		</div>
	);
};
export default AppointmentDetails;
