import { useState } from "react";
import { useTranslation } from "react-i18next";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { Condition } from "../../../../models/entities/condition.model";
import { ConditionsSelfReportedRow } from "../../../../models/pages/conditions/conditions-self-reported.model";
import { useConditions } from "./useConditions";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { InputTextarea } from "primereact/inputtextarea";
import UiButton from "../../../../components/button/Button";
import { TbTrashOff } from "react-icons/tb";
import { TableStyle } from "../../../../models/components/table/table.model";

const ConditionsSelfReported = ({
	conditionsQuery,
}: {
	conditionsQuery: ReturnType<typeof useConditions>;
}) => {
	// Columns
	const columns: TableColumn[] = [
		{
			field: "conditionNameFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.conditionName",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isExternalFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.source",
			sortable: false,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isActiveFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "notesFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.notes",
			sortable: false,
		},
		{
			field: "actionsTemplate",
			sortable: false,
			template: TableColumnTemplate.BUTTONS,
		},
	];

	// Pagination
	const pagination: TablePagination = {
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
		search: undefined,
		filters: undefined,
	};

	// Messages
	const messages = new TableMessages();

	// Loading
	if (conditionsQuery.isLoading) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.LOADING,
					label: messages.loading,
				}}
				pagination={pagination}
			/>
		);
	}

	// Error
	if (conditionsQuery.isError) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				}}
				pagination={pagination}
			/>
		);
	}

	// Empty
	if (!conditionsQuery.data.length) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.INFO,
					label: messages.empty,
				}}
				pagination={pagination}
			/>
		);
	}

	/* There is data */

	return (
		<ConditionsSelfReportedTable
			conditionsQueryData={conditionsQuery.data}
			onRefresh={conditionsQuery.invalidate}
		/>
	);
};

export default ConditionsSelfReported;

const ConditionsSelfReportedTable = ({
	conditionsQueryData,
	onRefresh,
}: {
	conditionsQueryData: Condition[];
	onRefresh: () => Promise<void>;
}) => {
	const { t } = useTranslation("common");

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
		search: undefined,
		filters: undefined,
	});

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	const messages = new TableMessages();

	// Editing row
	const [editingRow, setEditingRow] = useState<ConditionsSelfReportedRow | null>(null);

	// Update endpoint is being called
	const [submitting, setSubmitting] = useState<boolean>(false);

	const rows = conditionsQueryData
		.filter((item) => !item.isExternal)
		.map((item) => new ConditionsSelfReportedRow(item))
		.sort((a, b) => {
			const nameA = t(a.conditionNameFormatted).toLowerCase();
			const nameB = t(b.conditionNameFormatted).toLowerCase();
			// Sort by active/inactive
			return (
				(a.entity.isActive === b.entity.isActive ? 0 : a.entity.isActive ? -1 : 1) ||
				// Then sort by the condition name
				(nameA > nameB ? 1 : nameB > nameA ? -1 : 0)
			);
		});

	// Action: update notes/active/inactive
	const update = async (
		row: ConditionsSelfReportedRow,
		body: { [x: string]: boolean | string | null }
	) => {
		// Submitting
		setSubmitting(true);

		await EndpointsService.dataRetriever.updateCondition({
			participantConditionId: row.entity.participantConditionId,
			body: {
				...body,
				participantId: row.entity.participantId,
			},
		});

		await onRefresh();

		setEditingRow(null);

		setSubmitting(false);
	};

	// Columns
	const columns: TableColumn[] = [
		{
			field: "conditionNameFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.conditionName",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isExternalFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.source",
			sortable: false,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isActiveFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "notesFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.notes",
			sortable: !editingRow,
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (row: ConditionsSelfReportedRow) => (
					<>
						{row.entity.participantConditionId ===
						editingRow?.entity.participantConditionId ? (
							<InputTextarea
								value={editingRow.entity.notes || undefined}
								name="notes"
								onChange={(e) => {
									setEditingRow({
										...editingRow,
										entity: {
											...editingRow.entity,
											notes: e.target.value,
										},
									});
								}}
								disabled={submitting}
							/>
						) : (
							t(row.notesFormatted)
						)}
					</>
				),
			},
		},
		{
			field: "actionsTemplate",
			sortable: false,
			alignment: "right",
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (row: ConditionsSelfReportedRow) => (
					<>
						{row.entity.participantConditionId ===
						editingRow?.entity.participantConditionId ? (
							<>
								{/* Editing: cancel edition (read mode) */}
								<UiButton
									label="UI_COMPONENTS.BUTTONS.CANCEL"
									className="p-button-sm p-button-outlined p-button-rounded"
									onClick={() => setEditingRow(null)}
									disabled={submitting}
								/>
								{/* Editing: submit notes changes */}
								<UiButton
									label={
										submitting
											? "UI_COMPONENTS.BUTTONS.SAVING"
											: "UI_COMPONENTS.BUTTONS.SAVE"
									}
									className="p-button-sm p-button-rounded"
									loading={submitting}
									onClick={() =>
										update(row, { notes: editingRow?.entity.notes || null })
									}
									disabled={submitting}
								/>
							</>
						) : row.entity.isActive ? (
							<>
								{/* Edit notes */}
								<UiButton
									icon={"pi pi-pencil"}
									className="p-button-text neutral"
									onClick={() => setEditingRow(row)}
									disabled={submitting}
								/>
								{/* Set condition as inactive */}
								<UiButton
									icon={"pi pi-trash"}
									className="p-button-text neutral"
									onClick={() => update(row, { isActive: false })}
									disabled={submitting}
								/>
							</>
						) : (
							<>
								{/* Edit notes */}
								<UiButton
									icon={"pi pi-pencil"}
									className="p-button-text neutral"
									onClick={() => setEditingRow(row)}
									disabled={submitting}
								/>
								{/* Set condition as active */}
								<UiButton
									icon={<TbTrashOff style={{ fontSize: "1.25rem" }} />}
									className="p-button-text neutral"
									onClick={() => update(row, { isActive: true })}
									disabled={submitting}
								/>
							</>
						)}
					</>
				),
			},
		},
	];

	return (
		<UiTable
			dataKey="entity.participantConditionId"
			type={TableStyle.DEFAULT}
			columns={columns}
			value={rows}
			message={{ severity: FieldMessageSeverity.INFO, label: messages.empty }}
			pagination={pagination}
			paginationFn={onPagination}
			rowClassName={(data: ConditionsSelfReportedRow) => {
				let css = "";
				if (!data.entity.isActive) css += " row-inactive";
				if (editingRow) css += " inline-form";
				return css;
			}}
		/>
	);
};
