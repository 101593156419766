import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	ParticipantSUGData,
	ParticipantSUGService,
} from "../../../models/pages/service-utilization-goals/participant-sug.model";
import {
	SuCategories,
	SuExam,
	SuServices,
	SuStatus,
} from "../../../models/entities/service-utilization-goals.model";
import { SU_Service } from "../../../services/service-utilization-goals/service-utilization-goals.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { ServiceUtilizationGoalsResponse } from "../../../models/endpoints/service-utilization-goals-endpoint.model";

export const useCategoriesGoals = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["categories-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		const { goals } = await EndpointsService.dataRetriever.getServiceUtilizationGoals({
			participantId,
		});

		const sortedGoals = sortGoalsByStatus(goals);

		const categories = getCategories();

		const goalsExams = getGoalsExams(sortedGoals);

		for (const goal of sortedGoals) {
			const goalCategory = getGoalCategory(goal);

			const service = new ParticipantSUGService(goal, goalsExams);
			categories[goalCategory].services.push(service);

			const warningCount = SU_Service.updateCount(categories[goalCategory].services);
			categories[goalCategory].warningsCount = warningCount;
		}

		return categories;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};

const getCategories = () =>
	({
		[SuCategories.SCREENINGS]: {
			id: SuCategories.SCREENINGS,
			disabled: false,
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.SCREENINGS.TITLE",
			warningsCount: 0,
			services: [],
		},
		[SuCategories.VACCINES]: {
			id: SuCategories.VACCINES,
			disabled: false,
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.VACCINES.TITLE",
			warningsCount: 0,
			services: [],
		},
		[SuCategories.MENTAL_HEALTH]: {
			id: SuCategories.MENTAL_HEALTH,
			disabled: false,
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.MENTAL_HEALTH.TITLE",
			warningsCount: 0,
			services: [],
		},
		[SuCategories.NON_APPLICABLE]: {
			id: SuCategories.NON_APPLICABLE,
			disabled: false,
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.NON_APPLICABLE.TITLE",
			warningsCount: 0,
			services: [],
		},
	}) as { [x: string]: ParticipantSUGData };

const sortGoalsByStatus = (goals: ServiceUtilizationGoalsResponse["goals"]) => {
	const goalStatuses = Object.values(SuStatus);

	return [...goals].sort((a, b) => goalStatuses.indexOf(a.state) - goalStatuses.indexOf(b.state));
};

const getGoalsExams = (goals: ServiceUtilizationGoalsResponse["goals"]) =>
	goals.map((goal) => ({
		name: goal.goalName,
		events: goal.examTypes,
	}));

const getGoalCategory = (goal: ServiceUtilizationGoalsResponse["goals"][number]) => {
	return !goal.eligible || !goal.applicable ? SuCategories.NON_APPLICABLE : goal.category;
};
