import { useTranslation } from "react-i18next";
import UiFieldMessage from "../../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../../models/components/field-message.model";
import { Formatter } from "../../../../../../services/formatter/formatter.service";
import { TableMessages } from "../../../../../../models/components/table/table-message.model";
import { useNextAppointment } from "../../../../../../hooks/useNextAppointment";

const NoteNextAppointment = (props: {
	nextAppointmentQuery: ReturnType<typeof useNextAppointment>;
}) => {
	const { t } = useTranslation("common");
	const messages = new TableMessages();

	const appointment = props.nextAppointmentQuery.data
		?.filter((x) => new Date(x.datetime).getTime() >= new Date().getTime())
		.sort((a, b) =>
			new Date(a.datetime) > new Date(b.datetime)
				? 1
				: new Date(b.datetime) > new Date(a.datetime)
					? -1
					: 0
		)[0];

	return (
		<div className="mb-3">
			<h5 className="text-bold">{t("PARTICIPANT.DASHBOARD.NOTES.NEXT_CALL")}</h5>
			{props.nextAppointmentQuery.isLoading && (
				<UiFieldMessage
					severity={FieldMessageSeverity.LOADING}
					label={messages.loading}
				/>
			)}
			{props.nextAppointmentQuery.isError && (
				<UiFieldMessage
					severity={FieldMessageSeverity.DANGER}
					label={messages.error}
				/>
			)}
			{props.nextAppointmentQuery.data && appointment && (
				<span className="mx-2">
					{Formatter.dateISOToString(appointment.datetime, {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						hour12: true,
						timeZoneName: "shortGeneric",
					}) ?? Formatter.NO_DATA}
				</span>
			)}
		</div>
	);
};

export default NoteNextAppointment;
