import { FormApi } from "final-form";
import { MouseEvent, useEffect, useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../components/button/Button";
import UiFormInfo from "../../../components/form-info/FormInfo.component";
import UiInputText from "../../../components/input-text/InputText.component";
import { TextFormData } from "../../../models/pages/showcases.model";
import { PageProps } from "../../../models/routing.model";
import { Validations } from "../../../services/form/validations.service";

const InputTextShowcase = (props: PageProps) => {
	const [formData, setFormData] = useState<TextFormData>({
		default: null,
		default_value: "Value",
		disabled: null,
		disabled_value: "Value",
		left_icon: "w/ a left icon",
		left_icon_clickable: "icon is clickable",
		right_icon: "w/ a right icon",
		right_icon_clickable: "icon is clickable",
		required: null,
		multiple_validations: null,
	});
	useEffect(() => {
		console.log("Input text showcase > Form changed! ", formData);
	}, [formData]);

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	const dummyFunction = (event: MouseEvent<any, MouseEvent>): void => {
		console.log("dummyFunction: ", event);
	};

	// Submit
	const handleSubmit = async (data: TextFormData, form: FormApi<TextFormData, TextFormData>) => {
		console.log("Submitting... ", data, form, formData);
		await sleep(3000);
		// Update state onSubmit
		setFormData({ ...data });
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="default"
									placeholder="SHOWCASES.COMMON_FIELDS.default"
									label="SHOWCASES.COMMON_FIELDS.default"
									name="default"
									onChange={(e) =>
										setFormData({ ...formData, default: e.target.value })
									}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="default_value"
									placeholder="SHOWCASES.COMMON_FIELDS.default_value"
									label="SHOWCASES.COMMON_FIELDS.default_value"
									name="default_value"
									onChange={(e) =>
										setFormData({ ...formData, default_value: e.target.value })
									}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="disabled"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled"
									label="SHOWCASES.COMMON_FIELDS.disabled"
									name="disabled"
									onChange={(e) =>
										setFormData({ ...formData, disabled: e.target.value })
									}
									disabled={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="disabled_value"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled_value"
									label="SHOWCASES.COMMON_FIELDS.disabled_value"
									name="disabled_value"
									onChange={(e) =>
										setFormData({ ...formData, disabled_value: e.target.value })
									}
									disabled={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="left_icon"
									placeholder="SHOWCASES.COMMON_FIELDS.left_icon"
									label="SHOWCASES.COMMON_FIELDS.left_icon"
									name="left_icon"
									onChange={(e) =>
										setFormData({ ...formData, left_icon: e.target.value })
									}
									leftIcon="pi pi-search"
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="left_icon_clickable"
									placeholder="SHOWCASES.COMMON_FIELDS.left_icon_clickable"
									label="SHOWCASES.COMMON_FIELDS.left_icon_clickable"
									name="left_icon_clickable"
									onChange={(e) =>
										setFormData({
											...formData,
											left_icon_clickable: e.target.value,
										})
									}
									leftIcon="pi pi-search"
									leftIconFn={dummyFunction}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="right_icon"
									placeholder="SHOWCASES.COMMON_FIELDS.right_icon"
									label="SHOWCASES.COMMON_FIELDS.right_icon"
									name="right_icon"
									onChange={(e) =>
										setFormData({ ...formData, right_icon: e.target.value })
									}
									rightIcon="pi pi-home"
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="right_icon_clickable"
									placeholder="SHOWCASES.COMMON_FIELDS.right_icon_clickable"
									label="SHOWCASES.COMMON_FIELDS.right_icon_clickable"
									name="right_icon_clickable"
									onChange={(e) =>
										setFormData({
											...formData,
											right_icon_clickable: e.target.value,
										})
									}
									rightIcon="pi pi-search"
									rightIconFn={dummyFunction}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="required"
									placeholder="SHOWCASES.COMMON_FIELDS.required"
									label="SHOWCASES.COMMON_FIELDS.required"
									name="required"
									onChange={(e) =>
										setFormData({ ...formData, required: e.target.value })
									}
									validations={[Validations.required]}
									leftIcon="pi pi-search"
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputText
									id="multiple_validations"
									placeholder="SHOWCASES.COMMON_FIELDS.multiple_validations"
									label="SHOWCASES.COMMON_FIELDS.multiple_validations"
									name="multiple_validations"
									onChange={(e) =>
										setFormData({
											...formData,
											multiple_validations: e.target.value,
										})
									}
									validations={[
										Validations.required,
										Validations.minLength(3),
										Validations.email,
									]}
									disabled={submitting}
								/>
							</div>
						</div>

						{/* SUBMIT */}
						<div className="text-end">
							<UiButton
								id="btnSubmit"
								type="submit"
								label={
									submitting
										? "SHOWCASES.COMMON_ACTIONS.BTN_SUBMITTING"
										: "SHOWCASES.COMMON_ACTIONS.BTN_SUBMIT"
								}
								disabled={submitting}
								loading={submitting}
							/>
						</div>

						{/* FORM INFORMATION */}
						<UiFormInfo {...(form as any)}></UiFormInfo>
					</form>
				)}
			/>
		</>
	);
};

export default InputTextShowcase;
