import { confirmDialog } from "primereact/confirmdialog";
import { TFunction } from "react-i18next";
import TwilioVoiceService from "../twilio/twilio-voice.service";
import { Formatter } from "../formatter/formatter.service";
import { TableHeaderFilter } from "../../models/components/table/table-filter.model";
import { UiTableProps } from "../../models/components/table/table.model";

const phoneCall = (t: TFunction<"common", undefined>, phone: string): void => {
	confirmDialog({
		message: t("UI_COMPONENTS.TABLE.TEMPLATES.PHONE_CALL.MESSAGE", {
			phone: Formatter.phoneNumber(phone),
		}),
		header: t("UI_COMPONENTS.TABLE.TEMPLATES.PHONE_CALL.TITLE"),
		acceptClassName: "p-button-rounded",
		accept: () => TwilioVoiceService.outboundCall(phone),
		rejectClassName: "p-button-rounded p-button-outlined",
		draggable: false,
		focusOnShow: false,
	});
};

const hasSelectedFilters = (filters?: TableHeaderFilter): boolean => {
	let result = false;
	filters?.filters.forEach((filter) => {
		if (filter.value) result = true;
	});
	return result;
};

const showHeader = (props: UiTableProps): boolean => {
	let result: boolean = false;
	if (props.title || props.refresh || props.search || props.crud || props.filter) result = true;
	return result;
};

export const TableService = {
	phoneCall,
	hasSelectedFilters,
	showHeader,
};
