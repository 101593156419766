import { ParticipantSurvey } from "../entities/survey.model";
import { TableTemplateButtons } from "../components/table/table-template.model";

export interface ModalSurveyProps {
	isVisible: boolean;
	surveyName: string;
	surveyResult: any;
	closeModal: () => void;
}

export class ParticipantSurveyRow {
	surveyResultId: number;
	surveyName: string;
	createdOn: string; // Date
	actionsTemplate: TableTemplateButtons;

	constructor(params: ParticipantSurvey, openModalFn: () => void) {
		this.surveyResultId = params.surveyResultId;
		this.surveyName = params.surveyName;
		this.createdOn = params.createdOn;
		this.actionsTemplate = [
			{
				id: "btnViewResults",
				label: "PARTICIPANT.SURVEYS.TABLE.BUTTON_VIEW_RESULTS",
				className: "p-button-sm p-button-rounded",
				disabled: false,
				onClick: openModalFn,
			},
		];
	}
}
