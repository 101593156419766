import { EngagementDetails, EngagementType, IEngagement } from "../entities/engagement.model";
import { List } from "../misc.model";
import { UiFieldMessageProps } from "./field-message.model";

export enum ModalEngagementMode {
	NON_CALL = "NON_CALL",
	CALL = "CALL",
}

export interface UiModalEngagementProps {
	edit: boolean;
	mode: ModalEngagementMode;
	patientId: number | null;
	participantList: List | null;
	entity?: Partial<IEngagement>;
	isVisible: boolean;
	closeModal: (refresh: boolean, engagement: IEngagement | null) => void;
	updateState?: (props: UiModalEngagementProps) => void;
}

export type ModalEngagementData = {
	// Control variables
	formMessage?: UiFieldMessageProps;
	confirmNoEngagement: boolean;
	// Form fields
	participant: number | null;
	coachId: number;
	type: EngagementType | null;
	callSid: string | null;
	outcome: boolean | null;
	details: EngagementDetails | null;
	other: string | null;
	engagementDate: Date;
	declineReason?: string | null;
};
