import { RouteObject } from "react-router-dom";
import { Route } from "../../models/routing.model";
import { getRoute } from "../routing/protected-routes.service";
import { getRoutes } from "../routing/routing.service";

const iterateRoutes = (route: RouteObject): Route[] => {
	const functionalModules: Route[] = [];

	// Get route and it's props
	const tmpRoute: Route | undefined = getRoute(route);
	if (tmpRoute?.routeProps?.isModule) functionalModules.push(tmpRoute);

	// If the route has children -> iterate recursively
	if (route.children) {
		route.children.forEach((childRoute) => {
			functionalModules.push(...iterateRoutes(childRoute));
		});
	}

	return functionalModules;
};

/**
 * Routes: functional modules
 * @description Get a list of the main functional modules (show them in the Header)
 */
export const getFunctionalModules = (): Route[] => {
	const routes = getRoutes();
	const functionalModules: Route[] = [];
	routes.forEach((route) => {
		functionalModules.push(...iterateRoutes(route));
	});
	return functionalModules;
};
