import React from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldInputProps } from "react-final-form";

// Primereact
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { ObjectUtils, classNames } from "primereact/utils";

// Own "library"
import UiFieldLabel from "../field-label/FieldLabel.component";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { UiInputDateProps } from "../../models/components/input-date.model";
import { Validations } from "../../services/form/validations.service";

const UiInputDate = (props: UiInputDateProps) => {
	const { t } = useTranslation("common");

	// Props that need special treatment (like the placholder that needs to be translated)
	const otherProps = ObjectUtils.findDiffKeys(props, {
		placeholder: props?.placeholder,
		showIcon: props.showIcon,
		onChange: props.onChange,
		onBlur: props.onBlur,
		name: props.name,
		value: props.value,
		className: props.className,
		readOnlyInput: props.readOnlyInput,
		selectionMode: props.selectionMode,
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop, and integrate it with
	const handleChange = (
		event: CalendarChangeParams,
		input: FieldInputProps<any, HTMLElement>
	) => {
		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		if (input) {
			input.onChange(event);
			input.onBlur();
		}

		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
	};

	const handleBlur = (
		event: React.FocusEvent<HTMLInputElement, Element>,
		input: FieldInputProps<any, HTMLElement>
	): void => {
		if (input) input.onBlur();
		// Execute onBlur callback function
		if (props.onBlur) props.onBlur(event);
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div
						className={classNames("k-inputdate-container", props.inline && "is-inline")}
					>
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							meta={meta}
							validations={props.validations}
						>
							{/* Input */}
							<Calendar
								placeholder={props?.placeholder ? t(props.placeholder) : undefined}
								className={classNames(
									{ "p-invalid": !!(meta.touched && meta.error) },
									props.className
								)}
								name={input.name}
								value={input.value}
								hourFormat="12"
								onChange={(event) => handleChange(event, input)}
								onBlur={(event) => handleBlur(event, input)}
								showIcon={props.inline ?? true}
								readOnlyInput={true}
								showButtonBar={props.showButtonBar ?? true}
								todayButtonClassName="p-button-today"
								clearButtonClassName="p-button-clear"
								selectionMode={props.selectionMode ?? "single"}
								{...otherProps}
							/>
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiInputDate;
