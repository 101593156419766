import { useState } from "react";
import ParticipantDetailsCard from "./ParticipantDetailsCard";
import { ParticipantProfile } from "../../../models/entities/participant.model";
import { Form } from "react-final-form";
import ParticipantDetailsField from "./ParticipantDetailsField";
import UiInputSwitch from "../../../components/input-switch/InputSwitch.component";
import { FormApi } from "final-form";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import ParticipantDetailExternalLink from "./ParticipantDetailExternalLink";
import { PtDetailsMode } from "../../../models/pages/participant-details/participant-details.model";
import { PtSubscriptionsForm } from "../../../models/pages/participant-details/subscriptions-info.model";
import { Roles } from "../../../models/roles.model";

const ParticipantDetailsSubscriptions = ({
	participant,
	onUpdate,
}: {
	participant: ParticipantProfile;
	onUpdate: (updatedParticipant: ParticipantProfile) => void;
}) => {
	const initialFormData = new PtSubscriptionsForm(participant);
	const [mode, setMode] = useState<PtDetailsMode>(PtDetailsMode.READ);
	const [showError, setShowError] = useState<boolean>(false);
	const [formData, setFormData] = useState<PtSubscriptionsForm>({
		...initialFormData,
	});

	const setRequest = () => {
		const result: { [x: string]: any } = {};

		for (const key in formData) {
			if (Object.hasOwn(formData, key)) {
				if (key === "consentedSms" && formData.consentedSms !== participant.consentedSms) {
					result.consentedSms = formData.consentedSms;
				} else if (
					key !== "consentedSms" &&
					(formData[key as keyof PtSubscriptionsForm] !==
						new PtSubscriptionsForm(participant)[key as keyof PtSubscriptionsForm] ||
						!formData[key as keyof PtSubscriptionsForm])
				) {
					result[key as keyof PtSubscriptionsForm] =
						formData[key as keyof PtSubscriptionsForm];
				}
			}
		}

		return result;
	};

	const hasChanges = () => {
		return (
			formData.consentedSms != initialFormData.consentedSms ||
			formData.programUpdatesAndNewsletters != initialFormData.programUpdatesAndNewsletters ||
			formData.deviceOutOfRangeAlerts != initialFormData.deviceOutOfRangeAlerts ||
			formData.remindersMedicalDevices != initialFormData.remindersMedicalDevices ||
			formData.remindersScheduleCoachingCalls !=
				initialFormData.remindersScheduleCoachingCalls ||
			formData.programChanges != initialFormData.programChanges
		);
	};

	const handleSubmit = async (
		data: PtSubscriptionsForm,
		form: FormApi<PtSubscriptionsForm, PtSubscriptionsForm>
	) => {
		setShowError(false);
		await EndpointsService.dataRetriever
			.updateProfileSubscriptions({
				body: {
					participantId: participant.id,
					...setRequest(),
				},
			})
			.then((response) => {
				// Update form state
				form.reset();

				// Update participant state
				onUpdate(response);

				// Update mode
				setMode(PtDetailsMode.READ);
			})
			.catch((error) => setShowError(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<ParticipantDetailsCard
						title="PARTICIPANT.DETAILS.SUBSCRIPTIONS.TITLE"
						submitting={submitting}
						mode={mode}
						enableEditing={!Roles.isProvider()}
						showError={showError}
						enableSubmit={hasChanges()}
						onCancel={() => {
							form.reset();
							setShowError(false);
							setFormData(new PtSubscriptionsForm(participant));
							setMode(PtDetailsMode.READ);
						}}
						onModeChange={() => setMode(PtDetailsMode.UPDATE)}
					>
						{/* Subscription preferences */}
						<div className="">
							<ParticipantDetailsField
								title="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.subscription"
								value={
									<>
										<UiInputSwitch
											id="programUpdatesAndNewsletters"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.updatesNewsletters"
											name="programUpdatesAndNewsletters"
											onChange={(e) =>
												setFormData({
													...formData,
													programUpdatesAndNewsletters: e.value,
												})
											}
											disabled={mode === PtDetailsMode.READ}
										/>
										<UiInputSwitch
											id="deviceOutOfRangeAlerts"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.deviceOutRangeAlerts"
											name="deviceOutOfRangeAlerts"
											onChange={(e) =>
												setFormData({
													...formData,
													deviceOutOfRangeAlerts: e.value,
												})
											}
											disabled={mode === PtDetailsMode.READ}
										/>
										<UiInputSwitch
											id="remindersMedicalDevices"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.medicalDevicesReminders"
											name="remindersMedicalDevices"
											onChange={(e) =>
												setFormData({
													...formData,
													remindersMedicalDevices: e.value,
												})
											}
											disabled={mode === PtDetailsMode.READ}
										/>
										<UiInputSwitch
											id="remindersScheduleCoachingCalls"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.coachCallsReminders"
											name="remindersScheduleCoachingCalls"
											onChange={(e) =>
												setFormData({
													...formData,
													remindersScheduleCoachingCalls: e.value,
												})
											}
											disabled={mode === PtDetailsMode.READ}
										/>
										<UiInputSwitch
											id="programChanges"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.programChanges"
											name="programChanges"
											onChange={(e) =>
												setFormData({
													...formData,
													programChanges: e.value,
												})
											}
											disabled={mode === PtDetailsMode.READ}
										/>
									</>
								}
							/>
						</div>

						{/* Consent */}
						<div className="">
							<ParticipantDetailsField
								title="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.consent"
								value={
									<>
										<UiInputSwitch
											id="consentedSms"
											label="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.consentSms"
											name="consentedSms"
											onChange={(e) =>
												setFormData({ ...formData, consentedSms: e.value })
											}
											disabled={mode === PtDetailsMode.READ}
										/>
									</>
								}
							/>
						</div>

						{/* Subscription center link */}
						<ParticipantDetailExternalLink
							href={participant.subscriptionCenterLink}
							title="PARTICIPANT.DETAILS.SUBSCRIPTIONS.FORM_FIELDS.subscriptionCenterLink"
						/>
					</ParticipantDetailsCard>
				</form>
			)}
		/>
	);
};

export default ParticipantDetailsSubscriptions;
