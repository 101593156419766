import { PanelProps } from "primereact/panel";
import { Goal } from "../entities/goal.model";

export enum PanelListMessageSeverity {
	LOADING = "LOADING",
	ERROR = "ERROR",
	INFO = "INFO",
}

export type PanelListMessage = {
	severity: PanelListMessageSeverity;
	message: string;
};

export interface PanelListProps extends PanelProps {
	title: string;
	collapsible?: boolean;
	hasAction?: boolean;
	actionLabel?: string;
	actionFn?: () => void;
	messages?: {
		noData?: string;
		loading?: string;
		error?: string;
	};
	message?: PanelListMessage;
	list: PanelList[];
	testId?: string;
}

export class UiPanelListProps implements PanelListProps {
	title: string;
	collapsible: boolean;
	hasAction: boolean;
	actionLabel?: string;
	actionFn?: () => void;
	messages: {
		noData: string;
		loading: string;
		error: string;
	};
	message: PanelListMessage;
	list: PanelList[];
	testId?: string;

	constructor(params: PanelListProps) {
		this.title = params.title;
		this.collapsible =
			params.collapsible || params.collapsible === false ? params.collapsible : true;
		this.hasAction = params.collapsible ?? false;
		this.actionLabel = params.actionLabel ?? "UI_COMPONENTS.PANEL_LIST.ACTION_LABEL";
		this.actionFn = params.actionFn;
		this.messages = {
			noData: params.messages?.noData ?? "UI_COMPONENTS.PANEL_LIST.MESSAGES.NO_DATA",
			loading: params.messages?.loading ?? "UI_COMPONENTS.PANEL_LIST.MESSAGES.LOADING",
			error: params.messages?.error ?? "UI_COMPONENTS.PANEL_LIST.MESSAGES.ERROR",
		};
		this.message = params.message ?? {
			severity: PanelListMessageSeverity.INFO,
			message: this.messages.noData,
		};
		this.list = params.list ?? [];
		this.testId = params.testId;
	}
}

export interface PanelList {
	label: string;
	value: string;
	goal?: Goal;
}
