import HttpService from "../http/http.service";
import {
	TwilioConversationMessagesRequest,
	TwilioSmsPostRequest,
	TwilioTokenRequest,
} from "../../models/endpoints/twilio-endpoint.model";
import { Sms } from "../../models/entities/sms.model";
import { EndpointRequest } from "../../models/endpoints/endpoints.model";
import { UnreadConversation } from "../../models/entities/conversations.model";

const httpService = new HttpService(process.env.REACT_APP_API_TWILIO!);

// Twilio endpoints
export const TwilioEndpointsService = {
	getVoiceAccessToken: async (request: TwilioTokenRequest): Promise<{ token: string }> =>
		httpService.get("/twilio/access-token/voice", request),
	getConversationsAccessToken: async (request: TwilioTokenRequest): Promise<{ token: string }> =>
		httpService.get("/twilio/access-token/conversations", request),
	getRecording: async (callId: string): Promise<string> =>
		httpService.get(`/twilio/recording/${callId}`),
	sendCareMessageToParticipant: async (
		request: TwilioConversationMessagesRequest
	): Promise<void> => httpService.post(`/twilio/communications/message/coach`, request),
	getConversation: async (participantId: string): Promise<string> =>
		httpService.get(`/twilio/communications/${participantId}`),
	getSmsByParticipant: async (participantId: string): Promise<Sms[]> =>
		httpService.get(`/twilio/communications/sms/${participantId}`),
	sendSmsToParticipant: async (request: TwilioSmsPostRequest): Promise<Sms> =>
		httpService.post(`/twilio/communications/sms/coach`, request),
	getUnreadMessagesCounts: async (
		participantId: string
	): Promise<{ smsUnreadCount: number; careMessageUnreadCount: number }> =>
		httpService.get(`/twilio/communications/${participantId}/unresolved/count`),
	markConversationAsRead: async (request: {
		participantId: string;
		type: "message" | "sms";
	}): Promise<void> =>
		httpService.put(
			`/twilio/communications/${request.participantId}/${request.type}/resolve`,
			{}
		),
	getNumUnreadConversations: async (request: EndpointRequest): Promise<number> =>
		httpService.get("/twilio/communications/count", request),

	getUnreadConversations: async (request: EndpointRequest): Promise<UnreadConversation[]> =>
		httpService.get("/twilio/communications/", request),
};
