import { Route } from "../../models/routing.model";
import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const Header = ({ props }: { props: Route[] }) => {
	const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

	return <>{isDesktop ? <HeaderDesktop props={props} /> : <HeaderMobile props={props} />}</>;
};

export default Header;
