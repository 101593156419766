import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useRoutes } from "react-router-dom";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getRoutes } from "./services/routing/routing.service";
import { setCookieToken } from "./services/session/auth.service";
import { setAuth0 } from "./services/auth0/auth0.service";

const queryClient = new QueryClient();

/**
 * TODO: use env variable to enable/disable react-query-devtools
 */
const reactQueryDevtoolsEnabled = false;

const App = () => {
	const element = useRoutes(getRoutes());

	return (
		<QueryClientProvider client={queryClient}>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN!}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
				authorizationParams={{
					redirect_uri: window.location.origin + "/callback",
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				}}
				useRefreshTokens
			>
				<SetupAuth0Singleton>
					<RefreshAccessTokenPeriodically>
						{element}
						{reactQueryDevtoolsEnabled && (
							<ReactQueryDevtools
								initialIsOpen={false}
								position="bottom-right"
							/>
						)}
					</RefreshAccessTokenPeriodically>
				</SetupAuth0Singleton>
			</Auth0Provider>
		</QueryClientProvider>
	);
};

// The auth0 react sdk only exposes the auth0 object through a react hook
// In order to avoid a major refactor we are using a singleton to make the auth0 object available to the whole app.
// This way we can use the logout method in the http.service when the session expires or the token is invalid.
const SetupAuth0Singleton = (props: { children: React.ReactNode }) => {
	const auth0 = useAuth0();

	useEffect(() => {
		console.log(
			`Auth0 loading: ${auth0.isLoading}, authenticated: ${auth0.isAuthenticated}, error: ${auth0.error}`
		);
		setAuth0(auth0);
	}, [auth0.isLoading, auth0.isAuthenticated, auth0.error]);

	return <>{props.children}</>;
};

const RefreshAccessTokenPeriodically = (props: { children: React.ReactNode }) => {
	const { getAccessTokenSilently, isAuthenticated } = useAuth0();
	const refreshInterval = parseInt(process.env.REACT_APP_ACCESS_TOKEN_REFRESH_INTERVAL_MS!);
	console.log(
		`Refresh access token interval: ${refreshInterval}, is authenticated: ${isAuthenticated}`
	);
	useQuery(
		["access-token"],
		async () => {
			if (isAuthenticated) {
				const token = await getAccessTokenSilently();
				setCookieToken(token);

				return token;
			}

			return null;
		},
		{
			refetchInterval: refreshInterval,
		}
	);

	return <>{props.children}</>;
};

export default App;
