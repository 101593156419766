export enum engagementType {
	PHONE = "PHONE",
	EMAIL = "EMAIL",
	CARE_MESSAGE = "CARE_MESSAGE",
	NONE = "NONE",
}
export type EngagementType = (typeof engagementType)[keyof typeof engagementType];

export enum eEngagementDetails {
	OTHER = "OTHER",

	// If the coach talked to the participant
	COACHING_SESSION = "COACHING_SESSION",
	TECH_SUPPORT = "TECH_SUPPORT",
	RESCHEDULED = "RESCHEDULED",
	PT_UNENROLLED = "PT_UNENROLLED",
	CONNECTION_ISSUES = "CONNECTION_ISSUES",
	INTRO_CALL = "INTRO_CALL",
	INFO_CALL = "INFO_CALL",

	// If the coach didn't talk to the participant
	LEFT_VOICEMAIL = "LEFT_VOICEMAIL",
	VOICEMAIL_PROBLEM = "VOICEMAIL_PROBLEM",
	INCORRECT_PHONE = "INCORRECT_PHONE",
}
export type EngagementDetails = (typeof eEngagementDetails)[keyof typeof eEngagementDetails];

export interface IEngagement {
	id: number;
	callSid: string | null;
	patientId: number;
	outcome: boolean;
	createdOn: Date;
	callType: EngagementType;
	details: EngagementDetails | null;
	other: string | null;
	coachId: number;
	engagementDate: Date;
}

export class Engagement implements IEngagement {
	id: number;
	callSid: string | null;
	patientId: number;
	outcome: boolean;
	createdOn: Date;
	callType: EngagementType;
	details: EngagementDetails | null;
	other: string | null;
	coachId: number;
	engagementDate: Date;

	constructor(params: IEngagement) {
		this.id = params.id;
		this.callSid = params.callSid;
		this.patientId = params.patientId;
		this.outcome = params.outcome;
		this.createdOn = params.createdOn;
		this.callType = params.callType;
		this.details = params.details;
		this.other = params.other;
		this.coachId = params.coachId;
		this.engagementDate = params.engagementDate;
	}
}
