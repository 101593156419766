import { useTranslation } from "react-i18next";
import { TemplateOptions } from "../../../models/components/table/table-column.model";
import { TableTemplateText } from "../../../models/components/table/table-template.model";
import { Formatter } from "../../../services/formatter/formatter.service";

const UiTableTextTemplate = (
	props: TableTemplateText & { templateOptions?: TemplateOptions }
): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<div className="text-template">
			{props.value ? (
				<span className={props.className}>{t(props.value)}</span>
			) : props.templateOptions?.empty ? (
				Formatter.NO_DATA
			) : (
				<></>
			)}
		</div>
	);
};
export default UiTableTextTemplate;
