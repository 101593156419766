import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";

export const useHedisGoals = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["hedis-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		const hedisGoals = await EndpointsService.dataRetriever.getHedisGoals({
			participantId,
		});

		return hedisGoals;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
