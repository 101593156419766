import NotesHistoryPanel from "./NotesHistoryPanel";
import NotesHistoryPanelEdit from "./NotesHistoryPanelEdit";
import NotesHistoryPanelItem from "./NotesHistoryPanelItem";

const NotesHistoryOtherNote = (props: {
	date: string;
	callerName: string;
	editing: boolean;
	subjective?: string;
	topics?: string;
	lastEditionDate?: string;
	onClickEdit: () => void;
}) => {
	return (
		<NotesHistoryPanel
			date={props.date}
			type="PARTICIPANT.DASHBOARD.NOTES.OTHER"
			callerName={props.callerName}
			editing={props.editing}
		>
			<>
				<NotesHistoryPanelItem
					label="PARTICIPANT.DASHBOARD.NOTES.NOTES"
					value={props.subjective}
				/>
				<NotesHistoryPanelItem
					label="PARTICIPANT.DASHBOARD.NOTES.TOPICS"
					value={props.topics}
				/>

				<NotesHistoryPanelEdit
					lastEditionDate={props.lastEditionDate}
					onClickEdit={props.onClickEdit}
				/>
			</>
		</NotesHistoryPanel>
	);
};

export default NotesHistoryOtherNote;
