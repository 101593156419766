import { NoteProgress } from "../note-progress";

export interface SmartGoal {
	id: number;
	participantId: number;
	name: string;
	category: SmartGoalCategory[];
	startDate: string; // Date
	endDate: string; // Date
	details: string | null;
	support: number[] | null;
	completed: boolean;
	maintenance: boolean;
	reason: string | null;
	deleted: boolean;
	createdOn: string; // Date
	updatedOn: string; // Date
	deletedOn: string | null; // Date
	noteProgress: NoteProgress[] | null;
}

export interface LongTermGoal {
	id: number;
	participantId: number;
	name: string;
	startDate: string; // Date
	endDate: string; // Date
	motivations: string | null;
	barriers: string | null;
	completed: boolean;
	reason: string | null;
	deleted: boolean;
	createdOn: string; // Date
	updatedOn: string; // Date
	deletedOn: string | null; // Date
}

export enum SmartGoalCategory {
	NUTRITION = "NUTRITION",
	EXERCISE = "EXERCISE",
	CHRONIC_CARE_MANAGEMENT = "CHRONIC_CARE_MANAGEMENT",
	STRESS_MANAGEMENT = "STRESS_MANAGEMENT",
	SLEEP = "SLEEP",
	MEDICATION_ADHERENCE = "MEDICATION_ADHERENCE",
	TESTING_ADHERENCE = "TESTING_ADHERENCE",
	MENTAL_HEALTH = "MENTAL_HEALTH",
}
