import React from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldInputProps } from "react-final-form";

// Primereact
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import { ObjectUtils, classNames } from "primereact/utils";

// Own "library"
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { Validations } from "../../services/form/validations.service";
import { UiInputSwitchProps } from "../../models/components/input-switch.model";

const UiInputSwitch = (props: UiInputSwitchProps) => {
	const { t } = useTranslation("common");

	/**
	 * Remove from the props the ones that need special treatment
	 * (like the placholder that needs to be translated, etc.).
	 */
	const otherProps = ObjectUtils.findDiffKeys(props, {
		placeholder: props?.placeholder,
		onChange: props.onChange,
		onBlur: props.onBlur,
		name: props.name,
		value: props.value,
		className: props.className,
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop
	const handleChange = (
		event: InputSwitchChangeParams,
		input: FieldInputProps<any, HTMLElement>
	): void => {
		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		if (input) input.onChange(event);
		input.onBlur();
		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
	};

	const handleBlur = (
		event: React.FocusEvent<HTMLInputElement, Element>,
		input: FieldInputProps<any, HTMLElement>
	): void => {
		if (input) input.onBlur();
		// Execute onBlur callback function
		if (props.onBlur) props.onBlur(event);
	};

	const isRequired = (): boolean => !!props.validations?.find((val) => val.name === "required");

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div className="k-inputswitch-container">
						{/* Input */}
						<InputSwitch
							placeholder={props?.placeholder ? t(props.placeholder) : undefined}
							className={classNames(
								{ "p-invalid": !!(meta.touched && meta.error) },
								props.className
							)}
							name={input.name}
							checked={input.value}
							value={input.value}
							onChange={(event) => handleChange(event, input)}
							onBlur={(event) => handleBlur(event, input)}
							{...otherProps}
						/>

						{props.label && (
							<label
								htmlFor={props.id}
								className={classNames(
									"k-inputswitch-label",
									isRequired() && "is-required"
								)}
							>
								{t(props.label)}
							</label>
						)}
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiInputSwitch;
