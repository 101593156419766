import { Panel } from "primereact/panel";
import { Formatter } from "../../../../../../services/formatter/formatter.service";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";

const NotesHistoryPanel = ({
	date,
	type,
	callerName,
	editing,
	children,
}: {
	date: string; // Date
	type: string;
	callerName: string;
	editing: boolean;
	children: React.ReactNode;
}) => {
	const { t } = useTranslation("common");

	return (
		<Panel
			className="participant-notes-history__panel"
			header={
				<div className="participant-notes-history__panel-header">
					{/* Date */}
					<span className="">
						{Formatter.dateISOToString(date, {
							day: "2-digit",
							month: "2-digit",
							year: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
						})}
					</span>

					{/* Note type */}
					<span>{t(type)}</span>

					{/* Created by */}
					<span>{callerName}</span>
				</div>
			}
		>
			<div
				className={classNames(
					"participant-notes-history__panel-content",
					editing && "participant-notes-history__panel-content--editing"
				)}
			>
				{children}
			</div>
		</Panel>
	);
};

export default NotesHistoryPanel;
