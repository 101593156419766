import { FormApi } from "final-form";
import { MouseEvent, useEffect, useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../components/button/Button";
import UiFormInfo from "../../../components/form-info/FormInfo.component";
import UiSelect from "../../../components/select/Select.component";
import { List } from "../../../models/misc.model";
import { SelectFormData } from "../../../models/pages/showcases.model";
import { PageProps } from "../../../models/routing.model";
import { Validations } from "../../../services/form/validations.service";

const SelectShowcase = (props: PageProps) => {
	const optionsList: List = [
		{ id: "1", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.1" },
		{ id: "2", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.2" },
		{ id: "3", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.3", disabled: true },
		{ id: "4", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.4" },
	];
	const [formData, setFormData] = useState<SelectFormData>({
		default: null,
		default_value: "1",
		disabled: null,
		disabled_value: "2",
		left_icon: "1",
		left_icon_clickable: "2",
		required: null,
		multiple_validations: null,
		filter: null,
	});
	useEffect(() => {
		console.log("Select showcase > Form changed! ", formData);
	}, [formData]);

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	const dummyFunction = (event: MouseEvent<any, MouseEvent>): void => {
		console.log("dummyFunction: ", event);
	};

	// Submit
	const handleSubmit = async (
		data: SelectFormData,
		form: FormApi<SelectFormData, SelectFormData>
	) => {
		console.log("Submitting... ", data, form, formData);
		await sleep(3000);
		// Update state onSubmit
		setFormData({ ...data });
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="default"
									label="SHOWCASES.COMMON_FIELDS.default"
									name="default"
									onChange={(e) =>
										setFormData({ ...formData, default: e.target.value })
									}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="default_value"
									placeholder="SHOWCASES.COMMON_FIELDS.default_value"
									label="SHOWCASES.COMMON_FIELDS.default_value"
									name="default_value"
									onChange={(e) =>
										setFormData({ ...formData, default_value: e.target.value })
									}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="disabled"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled"
									label="SHOWCASES.COMMON_FIELDS.disabled"
									name="disabled"
									onChange={(e) =>
										setFormData({ ...formData, disabled: e.target.value })
									}
									disabled={true}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="disabled_value"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled_value"
									label="SHOWCASES.COMMON_FIELDS.disabled_value"
									name="disabled_value"
									onChange={(e) =>
										setFormData({ ...formData, disabled_value: e.target.value })
									}
									disabled={true}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="left_icon"
									placeholder="SHOWCASES.COMMON_FIELDS.left_icon"
									label="SHOWCASES.COMMON_FIELDS.left_icon"
									name="left_icon"
									onChange={(e) =>
										setFormData({ ...formData, left_icon: e.target.value })
									}
									leftIcon="pi pi-search"
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="left_icon_clickable"
									placeholder="SHOWCASES.COMMON_FIELDS.left_icon_clickable"
									label="SHOWCASES.COMMON_FIELDS.left_icon_clickable"
									name="left_icon_clickable"
									onChange={(e) =>
										setFormData({
											...formData,
											left_icon_clickable: e.target.value,
										})
									}
									leftIcon="pi pi-search"
									leftIconFn={dummyFunction}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="required"
									placeholder="SHOWCASES.COMMON_FIELDS.required"
									label="SHOWCASES.COMMON_FIELDS.required"
									name="required"
									onChange={(e) =>
										setFormData({ ...formData, required: e.target.value })
									}
									validations={[Validations.required]}
									leftIcon="pi pi-search"
									disabled={submitting}
									options={optionsList}
									showClear={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="multiple_validations"
									placeholder="SHOWCASES.COMMON_FIELDS.multiple_validations"
									label="SHOWCASES.COMMON_FIELDS.multiple_validations"
									name="multiple_validations"
									onChange={(e) =>
										setFormData({
											...formData,
											multiple_validations: e.target.value,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
									options={optionsList}
									showClear={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiSelect
									id="filter"
									placeholder="SHOWCASES.SELECT_SHOWCASE.filter"
									label="SHOWCASES.SELECT_SHOWCASE.filter"
									name="filter"
									onChange={(e) =>
										setFormData({ ...formData, filter: e.target.value })
									}
									disabled={submitting}
									options={optionsList}
									showClear={true}
									filter={true}
									showFilterClear={true}
								/>
							</div>
						</div>

						{/* SUBMIT */}
						<div className="text-end">
							<UiButton
								id="btnSubmit"
								type="submit"
								label={
									submitting
										? "SHOWCASES.COMMON_ACTIONS.BTN_SUBMITTING"
										: "SHOWCASES.COMMON_ACTIONS.BTN_SUBMIT"
								}
								disabled={submitting}
								loading={submitting}
							/>
						</div>

						{/* FORM INFORMATION */}
						<UiFormInfo {...(form as any)}></UiFormInfo>
					</form>
				)}
			/>
		</>
	);
};

export default SelectShowcase;
