import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../services/endpoints/endpoints.service";

export const useSmartGoals = (participantId: number) => {
	const queryClient = useQueryClient();
	const queryKey = ["smart-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		return await EndpointsService.dataRetriever.getSmartGoals({
			config: {
				params: { participantId },
			},
		});
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
