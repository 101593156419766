import { TableTemplateTag, TableTemplateTranslate } from "../components/table/table-template.model";
import { RiskUser, LastContactOutcome } from "../entities/risk-users.model";
import { HelperService } from "../../services/helpers/helper.service";
import { Formatter } from "../../services/formatter/formatter.service";

export interface IRiskUserRow {
	kannactId: string;
	lastContactOutcome: TableTemplateTag;
	fullName: string;
	phoneNumber: string;
	coachId: number;
	dateToBecomeInactive: string; // Date
	lastSuccessfulCall: string; // Date
	lastContact: string; // Date
	lastContactPurpose?: TableTemplateTranslate;
	daysPreference: string;
	timePreference: string;
	timeZone: string;
}

export class RiskUserRow implements IRiskUserRow {
	kannactId: string;
	lastContactOutcome: TableTemplateTag;
	fullName: string;
	phoneNumber: string;
	coachId: number;
	dateToBecomeInactive: string; // Date
	lastSuccessfulCall: string; // Date
	lastContact: string; // Date
	lastContactPurpose?: TableTemplateTranslate;
	daysPreference: string;
	timePreference: string;
	timeZone: string;

	constructor(params: RiskUser) {
		this.kannactId = params.id;
		this.lastContactOutcome = this.setOutcome(params.lastContactOutcome);
		this.fullName = Formatter.fullName(params.firstName, params.lastName);
		this.phoneNumber = params.phoneNumber;
		this.coachId = params.coachId;
		this.dateToBecomeInactive = params.dateToBecomeInactive;
		this.lastSuccessfulCall = params.lastSuccessfulCall;
		this.lastContact = params.lastContact;
		this.lastContactPurpose = HelperService.formatLastContactPurpose(params.lastContactPurpose);
		this.daysPreference = params.daysPreference;
		this.timePreference = params.timePreference;
		this.timeZone = params.timeZone;
	}

	private setOutcome = (paramValue: LastContactOutcome): TableTemplateTag => {
		const value: TableTemplateTag = {
			value: `RISK_USERS.TABLE.OUTCOME.${paramValue}`,
			uppercase: true,
			rounded: true,
			severity: "warning",
		};

		switch (paramValue) {
			case LastContactOutcome.SUCCESSFUL:
				value.severity = "success";
				break;
			case LastContactOutcome.UNSUCCESSFUL:
				value.severity = "danger";
				break;
			default:
				break;
		}

		return value;
	};
}
