import { Client, Conversation, Message, Paginator } from "@twilio/conversations";
import { EndpointsService } from "../endpoints/endpoints.service";
import { getSessionStorageUser } from "../session/auth.service";
import { BehaviorSubject } from "rxjs";

class TwilioConversationService {
	// SDK Client
	client: Client | null = null;
	clientSubject: BehaviorSubject<Client | null> = new BehaviorSubject<Client | null>(null);

	// Conversation
	conversation: Conversation | null = null;
	conversationSubject: BehaviorSubject<Conversation | null> =
		new BehaviorSubject<Conversation | null>(null);

	// Messages of a conversation
	messages: Message[] = [];
	messagesSubject: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
	messagesPaginator: Paginator<Message> | null = null;

	// Unread messages
	unreadCountSubject: BehaviorSubject<true> = new BehaviorSubject<true>(true);

	// Listeners
	messageAddedSubject: BehaviorSubject<Message | undefined> = new BehaviorSubject<
		Message | undefined
	>(undefined);

	/**
	 * Client: initialize the SDK client
	 * @description Initialize the SDK client:
	 * 		- Get access token
	 * 		- Instantiate the SDK client
	 */
	initializeClient = async (): Promise<Client> => {
		// Reset previous values
		this.client = null;
		this.clientSubject.next(this.client);
		this.conversation = null;
		this.conversationSubject.next(this.conversation);
		this.messages = [];
		this.messagesSubject.next(this.messages);

		// Initialize client
		const { token } = await EndpointsService.twilio.getConversationsAccessToken({
			config: {
				params: {
					ttl: 86400, //60 * 60 * 24 Seconds in a day (max ttl)
				},
			},
		});

		this.client = new Client(token);
		this.clientSubject.next(this.client);

		// Set client listeners
		if (this.client && this.client.listenerCount("initialized") <= 2) {
			this.client.on("initialized", () => this.clientSubject.next(this.client));

			// To catch client initialization errors, subscribe to the `'initFailed'` event.
			this.client.on("initFailed", ({ error }) => this.clientSubject.next(this.client));
		}

		return this.client;
	};

	/**
	 * Conversation: get a conversation if it exists
	 * @description Get a voncersation:
	 * 		- Get the conversation SID based on the participantId
	 * 		- Get the conversation based on the SID
	 */
	getConversation = async (participantId: number): Promise<Conversation | null> => {
		const conversationSid = await EndpointsService.twilio.getConversation(
			participantId.toString()
		);
		const response = await this.client?.peekConversationBySid(conversationSid);

		this.conversation = response || null;
		this.conversationSubject.next(this.conversation);

		// Set messages listeners
		if (this.conversation && this.conversation.listenerCount("messageAdded") <= 5) {
			this.conversation.on("messageAdded", (message: Message) => {
				this.unreadCountSubject.next(true);
				this.messageAddedSubject.next(message);
			});
		}

		return this.conversation;
	};

	/**
	 * Unread: beautify count
	 * @description Beatifies the unread messages counter. Shows "99+" when there are >= 100 unread messages.
	 */
	beautifyUnreadCount = (count: number): string => (count >= 100 ? `99+` : `${count}`);

	/**
	 * Messages: check message author
	 * @description Check if a message was sent by the logged user, or by someone else
	 */
	isOwnMessage = (message: Message): boolean =>
		message.author === getSessionStorageUser()?.id?.toString();

	/**
	 * Messages: get messages
	 * @description Get the conversation messages (client-side paginated)
	 */
	getMessages = async (): Promise<Message[]> => {
		const response = await this.conversation?.getMessages(100);
		this.messages = response?.items || [];
		this.messagesPaginator = response || null;

		this.messagesSubject.next(this.messages);

		return this.messages;
	};

	/**
	 * Messages: get previous messages
	 * @description Get the conversation previous messages
	 */
	getPreviousMessages = async (): Promise<Message[]> => {
		const response = await this.messagesPaginator?.prevPage();
		this.messages = [...(response?.items || []), ...this.messages];
		this.messagesPaginator = response || null;

		this.messagesSubject.next(this.messages);

		return this.messages;
	};

	/**
	 * Messages: send new message
	 * @description
	 * If the conversation already exists:
	 * 		- The message is sent
	 *
	 * If the conversation doesn't exist:
	 * 		- Creates the conversation
	 * 		- The logged coach joins the conversation
	 * 		- The participant is added to the conversation
	 * 		- The message is sent
	 */
	sendMessage = async (message: string, participantId: number): Promise<void> => {
		await EndpointsService.twilio
			.sendCareMessageToParticipant({
				body: {
					content: message,
					participantId,
				},
			})
			.finally(() => {
				if (!this.conversation) {
					// A new conversation has been created
					this.getConversation(participantId);
				} else {
					// The conversation already existed, the logged coach just sent a new message
					this.getMessages();
				}
			});
	};
}

const instance = new TwilioConversationService();
export default instance;
