import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useParticipantProfile = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-profile", { participantId }];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getParticipantById({
			participantId: participantId,
		});
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
