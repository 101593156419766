import {
	Gender,
	ParticipantProfile,
	PreferredLanguage,
	PreferredPronouns,
} from "../../entities/participant.model";

export class PtGeneralInfoForm {
	firstName: string;
	lastName: string;
	preferredName: string | null;
	gender: Gender | null;
	pronouns: PreferredPronouns | null;
	dob: Date;
	language: PreferredLanguage | null;

	constructor(params: ParticipantProfile) {
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.preferredName = params.preferredName;
		this.gender = params.gender;
		this.pronouns = params.pronouns;
		this.dob = new Date(params.dob);
		this.language = params.language;
	}
}
