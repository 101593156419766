import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import UiFieldMessage from "../../field-message/FieldMessage.component";

const TableEmptyMessage = (props?: UiFieldMessageProps) => {
	let template: React.ReactNode;
	if (props) {
		// Loading, Error, etc.
		template = (
			<div
				className={
					props.severity === FieldMessageSeverity.LOADING
						? "k-empty-message loading-message"
						: "k-empty-message"
				}
			>
				<UiFieldMessage
					severity={props.severity}
					label={props.label}
				></UiFieldMessage>
			</div>
		);
	} else {
		// No data
		template = (
			<div className="k-empty-message">
				<UiFieldMessage
					severity={FieldMessageSeverity.INFO}
					label="UI_COMPONENTS.TABLE.EMPTY_MESSAGE"
				></UiFieldMessage>
			</div>
		);
	}

	return template;
};

export default TableEmptyMessage;
