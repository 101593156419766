import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/img/kannact-logo-detailed.svg";
import UiButton from "../../components/button/Button";
import { PageProps } from "../../models/routing.model";
import { deleteSession } from "../../services/session/auth.service";
import { StoredCriteriaService } from "../../services/session/stored-criteria.service";
import { useState } from "react";
import { FieldMessageSeverity } from "../../models/components/field-message.model";
import UiFieldMessage from "../../components/field-message/FieldMessage.component";

const Login = (props: PageProps) => {
	const { t } = useTranslation("common");
	const { loginWithRedirect } = useAuth0();
	const [isRedirectingToAuth0, setIsRedirectingToAuth0] = useState(false);

	const error = getLoginError();

	// When the login page loads, reset the old session
	StoredCriteriaService.deleteAll();
	deleteSession();

	return (
		<div className="login__page-layout">
			<div className="login__form-layout">
				<div className="login__welcome-container">
					<img
						className="login__welcome-image"
						src={logo}
						alt={"logo"}
					/>
					<h4 className="login__wecome-text">{t("LOGIN.LOGIN")}</h4>
				</div>

				{error && (
					<div className="login__error">
						<UiFieldMessage
							severity={FieldMessageSeverity.DANGER}
							label={error}
						/>
					</div>
				)}

				<UiButton
					id="signinButton"
					testId="signinButton"
					label={isRedirectingToAuth0 ? "LOGIN.BUTTON_SIGNING_IN" : "LOGIN.BUTTON_SIGNIN"}
					className="p-button-lg"
					onClick={() => {
						setIsRedirectingToAuth0(true);
						loginWithRedirect();
					}}
					loading={isRedirectingToAuth0}
					disabled={isRedirectingToAuth0}
				/>
			</div>
		</div>
	);
};

const getLoginError = () => {
	const errorType = new URLSearchParams(window.location.search).get("errorType");

	const errors: { [type: string]: string } = {
		unauthorized_to_signin: "LOGIN.ERROR.UNAUTHORIZED_TO_SIGNIN",
		session_expired: "LOGIN.ERROR.SESSION_EXPIRED",
	};

	if (!errorType) {
		return null;
	}

	return errors[errorType] ?? "UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR";
};

export default Login;
