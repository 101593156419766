import { ParticipantProfile, PreferredCoachingMethod } from "../../entities/participant.model";

export class PtContactForm {
	phone1: number | null;
	phone2: number | null;
	phone3: number | null;
	primaryPhone: number | null;
	email1: string | null;
	email2: string | null;
	primaryEmail: string | null;
	preferredCoachingMethod: PreferredCoachingMethod | null;
	timezone: string | null;
	timeOfDay: string | null;
	daysOfWeek: string[];
	shippingAddressAddressLine1: string | null;
	shippingAddressAddressLine2: string | null;
	shippingAddressCity: string | null;
	shippingAddressCountry: string | null;
	shippingAddressState: string | null;
	shippingAddressPostalCode: string | null;

	constructor(params: ParticipantProfile) {
		this.phone1 = params.phones?.length ? Number(params.phones[0].phoneNumber) : null;
		this.phone2 = params.phones?.length > 1 ? Number(params.phones[1].phoneNumber) : null;
		this.phone3 = params.phones?.length > 2 ? Number(params.phones[2].phoneNumber) : null;
		this.primaryPhone = params.phones?.length
			? Number(params.phones.find((p) => p.isPrimary)!.phoneNumber)
			: null;
		this.email1 = params.emails?.length ? params.emails[0].emailAddress : null;
		this.email2 = params.emails?.length > 1 ? params.emails[1].emailAddress : null;
		this.primaryEmail = params.emails?.length
			? params.emails.find((e) => e.isPrimary)!.emailAddress
			: null;
		this.preferredCoachingMethod = params.preferredCoachingMethod;
		this.timezone = params.timezone;
		this.timeOfDay = params.timeOfDay;
		this.daysOfWeek = params.daysOfWeek ? params.daysOfWeek.split(",") : [];
		this.shippingAddressAddressLine1 = params.shippingAddressAddressLine1;
		this.shippingAddressAddressLine2 = params.shippingAddressAddressLine2;
		this.shippingAddressCity = params.shippingAddressCity;
		this.shippingAddressCountry = params.shippingAddressCountry;
		this.shippingAddressState = params.shippingAddressState;
		this.shippingAddressPostalCode = params.shippingAddressPostalCode;
	}
}
