export const CONDITIONS_DEFAULT = [
	"ARTHRITIS",
	"ASTHMA",
	"AUTOIMMUNE_DISEASE",
	"CANCER",
	"CARDIOVASCULAR_DISEASE",
	"COPD",
	"CHRONIC_KIDNEY_DISEASE",
	"CHRONIC_LUNG_DISEASE",
	"CHRONIC_PAIN",
	"GESTATIONAL_DIABETES",
	"HYPERLIPIDEMIA",
	"HYPERTENSION",
	"OBESITY",
	"DIABETES",
	"PRE-DIABETES",
	"PRE-HYPERTENSION",
	"T1_DIABETES",
	"T2_DIABETES",
	"THYROID_DISEASE",
	"OTHER",
];

export interface Condition {
	participantConditionId: number;
	participantId: number;
	conditionName: string;
	createdOn: string; // Date
	updatedOn: string; // Date
	isExternal: boolean;
	isActive: boolean;
	notes: string | null;
}
