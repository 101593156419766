import { useTranslation } from "react-i18next";
import { SelectButton } from "primereact/selectbutton";
import {
	CHART_FILTER,
	CHART_TYPE,
} from "../../../../models/pages/biometrics/biometrics-charts.model";

const BiometricsChartsFilters = ({
	filters,
	onFiltersChange,
}: {
	filters: { type: CHART_TYPE; range: CHART_FILTER };
	onFiltersChange: (e: { type: CHART_TYPE; range: CHART_FILTER }) => void;
}) => {
	const { t } = useTranslation("common");

	return (
		<div className="row chart-filters">
			{/* Chart type */}
			<div className="col-12 col-md-6 chart-filter-col">
				<span className="chart-filter-title">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.CHART_TYPE")}
				</span>
				<SelectButton
					className="chart-selector"
					value={filters.type}
					onChange={(e) => onFiltersChange({ type: e.value, range: filters.range })}
					options={[
						{
							id: CHART_TYPE.GLUCOSE,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.GLUCOSE"),
						},
						{
							id: CHART_TYPE.BLOOD_PRESSURE,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.BLOOD_PRESSURE"),
						},
						{
							id: CHART_TYPE.WEIGHT,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.WEIGHT"),
						},
					]}
					optionLabel="label"
					optionValue="id"
				/>
			</div>

			{/* Chart filter */}
			<div className="col-12 col-md-6 chart-filter-col">
				<span className="chart-filter-title">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.CHART_FILTER")}
				</span>
				<SelectButton
					className="chart-selector"
					value={filters.range}
					onChange={(e) => onFiltersChange({ type: filters.type, range: e.value })}
					options={[
						{
							id: CHART_FILTER.WEEK,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.WEEK"),
						},
						{
							id: CHART_FILTER.MONTH,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.MONTH"),
						},
						{
							id: CHART_FILTER.TRIMESTER,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.TRIMESTER"),
						},
						{
							id: CHART_FILTER.ALL,
							label: t("PARTICIPANT.BIOMETRICS.CHARTS.ALL"),
						},
					]}
					optionLabel="label"
					optionValue="id"
				/>
			</div>
		</div>
	);
};

export default BiometricsChartsFilters;
