import React from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { UiFieldLabelProps } from "../../models/components/field-container.model";

const UiFieldLabel = (props: UiFieldLabelProps) => {
	const { t } = useTranslation("common");

	const isRequired = (): boolean => !!props.validations?.find((val) => val.name === "required");

	return (
		<span
			className={classNames(
				"k-field-container",
				props.label && "p-float-label",
				props.disabled && "is-disabled",
				!!(props.meta?.touched && props.meta?.error) && "p-invalid",
				(props.leftIcon || props.prefix) && "p-input-icon-left",
				props.rightIcon && "p-input-icon-right",
				isRequired() && "is-required"
			)}
		>
			{/* Transclude content */}
			{props.children}

			{props.label && (
				<label
					htmlFor={props.id}
					className="k-field-label"
				>
					{t(props.label)}
				</label>
			)}
		</span>
	);
};

export default UiFieldLabel;
