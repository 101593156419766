import { UiTableProps } from "./table.model";

export interface ITableHeaderRefresh {
	title?: string;
	tooltip?: string;
	fn: () => void;
}

export class TableHeaderRefresh implements ITableHeaderRefresh {
	title: string;
	tooltip?: string;
	fn: () => void;

	constructor(params: UiTableProps) {
		this.title = params.refresh?.title ?? "UI_COMPONENTS.TABLE.ACTIONS.REFRESH";
		this.tooltip = params.refresh?.tooltip;
		this.fn = params.refresh?.fn as () => void;
	}
}
