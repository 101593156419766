import { useEffect, useState } from "react";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../models/components/table/table-column.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import { RecordingEntity } from "../../../models/entities/call-voicemail.model";
import { RecordingRow } from "../../../models/pages/call-voicemail-row.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { BackendPagination } from "../../../models/pagination.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { AxiosError } from "axios";
import UiTable from "../../../components/table/Table.component";
import { TableFilterType } from "../../../models/components/table/table-filter.model";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const DashboardVoicemail = () => {
	/* Table */

	const columns: TableColumn[] = [
		{
			field: "participant",
			title: "CALL_CENTER.DASHBOARD.VOICEMAIL.TABLE.COLUMNS.participant",
			sortable: true,
			template: TableColumnTemplate.KANNACT_ID,
		},
		{
			field: "startTime",
			title: "CALL_CENTER.DASHBOARD.VOICEMAIL.TABLE.COLUMNS.date",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "twilioAudioTemplate",
			title: "CALL_CENTER.DASHBOARD.VOICEMAIL.TABLE.COLUMNS.recording",
			template: TableColumnTemplate.TWILIO_AUDIO,
			style: {
				width: "100px",
			},
		},
		{
			field: "actionsTemplate",
			title: "CALL_CENTER.DASHBOARD.VOICEMAIL.TABLE.COLUMNS.actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
		},
	];

	const [rows, setRows] = useState<RecordingRow[]>([]);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["dashboard-voicemails"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "startTime",
			sortOrder: -1,
			search: undefined,
			filters: [
				{
					field: "coachId",
					type: TableFilterType.SELECT_MULTIPLE,
					value: [`${getSessionStorageUser()?.id}`],
				},
				{
					field: "alreadySeen",
					type: TableFilterType.SELECT_MULTIPLE,
					value: ["false"],
				},
			],
		}
	);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	/**
	 * DATA
	 */

	// First time load
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);
	}, [pagination]);

	// Get data: Voicemails
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		await EndpointsService.dataRetriever
			.getVoicemails({
				config: { params: new BackendPagination(event) },
			})
			.then((response: RecordingEntity[]) => {
				if (response?.length > 0) {
					setRows(
						response.map((item) => new RecordingRow(item, resolveVoicemail.bind(this)))
					);
				} else {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	// Get data: Voicemails count
	const getCount = async (event: TablePagination): Promise<void> => {
		await EndpointsService.dataRetriever
			.getVoicemailsCount({
				config: {
					params: new BackendPagination(event),
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Voicemails: Error getting the count");
			});
	};

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);

		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["dashboard-voicemails"],
			newPagination
		);
	};

	/**
	 * ACTIONS
	 */

	// Calls voicemails: mark as resolved
	const resolveVoicemail = async (event: TableTemplateButtonsEvent): Promise<void> => {
		// Delete row & Update rows state
		setRows(
			rows.filter(
				(row: RecordingRow) =>
					row.entity.callSid !== (event.row as RecordingRow).entity.callSid
			)
		);

		// Update data from the backend
		await EndpointsService.dataRetriever
			.voicemailResolve({
				config: {
					params: {
						recordingSid: (event.row as RecordingRow).entity.recordingSid,
						alreadySeen: true,
					},
				},
			})
			.finally(() => {
				getData(pagination);
				getCount(pagination);
			});
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="id"
			title="CALL_CENTER.DASHBOARD.VOICEMAIL.TITLE"
			columns={columns}
			value={rows}
			message={message}
			isServerPaginated={true}
			pagination={pagination}
			paginationFn={onPagination}
			totalRecords={count}
		/>
	);
};

export default DashboardVoicemail;
