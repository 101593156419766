import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { Panel } from "primereact/panel";
import { SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import UiInputTextarea from "../../../../../components/input-textarea/InputTextarea.component";
import UiInputCheckbox from "../../../../../components/input-checkbox/InputCheckbox.component";
import { CustomGoalsActiveForm } from "../../../../../models/custom-goals/custom-goals-misc";
import { NoteHelper } from "../../../../../services/helpers/note-helper";
import { Validations } from "../../../../../services/form/validations.service";

const CustomGoalsActive = ({
	noteId,
	smartGoals,
	noteProgress,
	onChange,
}: {
	noteId: number | null;
	smartGoals: SmartGoal[];
	noteProgress: CustomGoalsActiveForm[];
	onChange: (e: CustomGoalsActiveForm[]) => void;
}) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState<{ data: CustomGoalsActiveForm[] }>({
		data: [],
	});

	useEffect(() => {
		setFormData({ data: NoteHelper.setGoalsFormData(noteProgress, smartGoals, noteId) });
	}, []);

	useEffect(() => {
		setFormData({ data: NoteHelper.setGoalsFormData(noteProgress, smartGoals, noteId) });
	}, [noteProgress, smartGoals]);

	const handleSubmit = async () => {};

	return (
		<div className="custom-goals-active">
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form }) => (
					<form onSubmit={handleSubmit}>
						{formData.data?.length > 0 &&
							smartGoals
								.sort((a, b) =>
									new Date(a.endDate) > new Date(b.endDate)
										? 1
										: new Date(b.endDate) > new Date(a.endDate)
											? -1
											: 0
								)
								.map((x, index) => (
									<Panel
										toggleable={false}
										key={x.id}
										header={
											<div className="custom-goals-active_header">
												<UiInputCheckbox
													id={`selected_${index}`}
													name={`data[${index}].selected`}
													type="checkbox"
													onChange={(e) => {
														const updatedForm = { ...formData };
														updatedForm.data[index].selected =
															e.target.value;
														setFormData(updatedForm);

														// Fixes checkbox odd behaviour when being checked/un-checked
														form
															.getFieldState(
																`data[${index}].selected`
															)
															?.change(e.target.value);
														form
															.getFieldState(
																`data[${index}].selected`
															)
															?.blur();

														onChange(updatedForm.data);
													}}
													disabled={false}
													binary={true}
													validations={
														formData.data[index]?.progress
															? [Validations.required]
															: []
													}
												/>

												<div>
													<span className="custom-goals-active_header_title">
														{t(
															"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.PANEL_TITLE"
														)}
													</span>
													<span className="custom-goals-active_header_subtitle">
														{x.name}
													</span>
												</div>
											</div>
										}
									>
										<UiInputTextarea
											id={`progress_${index}`}
											label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.PROGRESS"
											name={`data[${index}].progress.progress`}
											onChange={(e) => {
												const updatedForm = { ...formData };
												updatedForm.data[index].progress.progress =
													e.target.value;
												setFormData(updatedForm);
												onChange(updatedForm.data);
											}}
											disabled={false}
										/>
									</Panel>
								))}
					</form>
				)}
			/>
		</div>
	);
};

export default CustomGoalsActive;
