import { useQueryClient } from "@tanstack/react-query";
import { TwilioEndpointsService } from "../../../../services/endpoints/twilio-endpoints.service";
import { Sms } from "../../../../models/entities/sms.model";
import { getParticipantSmsQueryKey } from "./useParticipantSms";

export const useCreateParticipantSms = () => {
	const queryClient = useQueryClient();

	return {
		mutateAsync: async ({
			participantId,
			content,
		}: {
			participantId: string;
			content: string;
		}) => {
			const newSMS = await TwilioEndpointsService.sendSmsToParticipant({
				body: {
					participantId,
					content,
				},
			});

			queryClient.setQueryData(getParticipantSmsQueryKey(participantId), (cache: unknown) => {
				const smsFromCache = cache as Sms[] | undefined;
				return smsFromCache ? [...smsFromCache, newSMS] : [newSMS];
			});

			return newSMS;
		},
	};
};
