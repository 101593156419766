import { Formatter } from "../../../services/formatter/formatter.service";
import { CONDITIONS_DEFAULT, Condition } from "./../../entities/condition.model";

export class ConditionsExternalRow {
	participantConditionId: number;
	participantId: number;
	conditionName: string;
	createdOn: string; // Date
	updatedOn: string; // Date
	isExternal: boolean;
	isExternalFormatted = "ENUMS.SOURCE.EXTERNAL";
	isActive: boolean;
	isActiveFormatted: string;
	notes: string | null;
	notesFormatted: string;

	constructor(params: Condition) {
		this.participantConditionId = params.participantConditionId;
		this.participantId = params.participantId;
		this.conditionName =
			CONDITIONS_DEFAULT.indexOf(params.conditionName) !== -1
				? `ENUMS.CONDITIONS.${params.conditionName}`
				: params.conditionName;
		this.createdOn = params.createdOn;
		this.updatedOn = params.updatedOn;
		this.isExternal = params.isExternal;
		this.isActive = params.isActive;
		this.isActiveFormatted = params.isActive
			? "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status_true"
			: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status_false";
		this.notes = params.notes;
		this.notesFormatted = params.notes ?? Formatter.NO_DATA;
	}
}
