import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TwilioEndpointsService } from "../../../../services/endpoints/twilio-endpoints.service";
import { getUnreadMessagesCountsQueryKey } from "./useUnreadMessagesCounts";

export const useMarkConversationAsRead = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			participantId,
			type,
		}: {
			participantId: number;
			type: "message" | "sms";
		}) => {
			await TwilioEndpointsService.markConversationAsRead({
				participantId: participantId.toString(),
				type,
			});

			await queryClient.invalidateQueries(getUnreadMessagesCountsQueryKey(participantId));
		},
	});
};
