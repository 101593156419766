import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { MedicationAdherenceViewRow } from "../../../../models/entities/medications.model";
import { MedicationsListProps } from "../../../../models/pages/medications/medications-list.model";
import { MedicationLatestResponse } from "../../../../models/endpoints/medications-endpoint.model";
import { MedicationsTemplate } from "../../../../services/medications/medications-templates.service";
import MedicationsHistory from "./MedicationsHistory";
import GoalIndicator from "../../../../components/goal-indicator/GoalIndicator";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { GoalsService } from "../../../../services/goals/goals.service";
import UiTable from "../../../../components/table/Table.component";
import { TableColumnTemplate } from "../../../../models/components/table/table-column.model";
import { TableStyle } from "../../../../models/components/table/table.model";

const categories = [
	"copd rescue drug",
	"asthma rescue inhaler",
	"copd maintance",
	"asthma maintenance",
	"statin",
];

const MedicationAdherenceView = (props: MedicationsListProps) => {
	const { t } = useTranslation("common");

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [rows, setRows] = useState<MedicationAdherenceViewRow[]>([]);

	useEffect(() => {
		if (props.medications) mapRows(props.medications);
		else setRows([]);
	}, []);

	const getCategoryNotes = async (category: string): Promise<string | null> => {
		setLoading(true);
		setError(false);

		return await EndpointsService.dataRetriever
			.getMedicationCategoryNotes({
				participantId: props.participantId,
				category: category,
			})
			.then((response: any) => {
				// TODO: type properly
				return response ? response.notes : null;
			})
			.catch(() => {
				setError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleUpsertNote = (info: { notes: string | null; categoryName: string }) => {
		const newRows = rows.map((row) => {
			if (row.categoryName === info.categoryName) {
				return {
					...row,
					drugCategoryNotes: info.notes,
				};
			}
			return row;
		});

		setRows(newRows);
	};

	const mapRows = async (data: MedicationLatestResponse[] | null): Promise<void> => {
		const internalRows: MedicationAdherenceViewRow[] = [];

		const affectedRows = data?.filter((drug) => {
			if (!drug.category || drug.category === null) return false;

			const drugCategories = drug.category.split(",");
			return categories.some((cat) => drugCategories.includes(cat));
		});

		if (affectedRows && affectedRows.length > 0) {
			const promises = categories.map(async (category) => {
				const medications = affectedRows!
					.filter((drug) => drug.category.split(",").includes(category))
					.sort((a, b) => {
						return new Date(b.filledDate).getTime() - new Date(a.filledDate).getTime();
					})
					.map((drug) => {
						return {
							medicationName: drug.medicationName,
							dateFilled: new Date(drug.filledDate),
							source: drug.source,
							active: drug.isActive,
						};
					});

				if (medications && medications.length > 0) {
					const row: MedicationAdherenceViewRow = {
						isExpanded: false,
						categoryName: category,
						goal: props.goals?.length
							? GoalsService.getMedicationGoalByCategory(props.goals, category)
							: undefined,
						medications: medications,
						drugCategoryNotes: await getCategoryNotes(category),
					};
					internalRows.push(row);
				}
			});

			await Promise.all(promises).then(() => {
				internalRows.sort((a, b) => {
					return (
						new Date(b.medications[0].dateFilled).getTime() -
						new Date(a.medications[0].dateFilled).getTime()
					);
				});

				setRows(internalRows);
			});
		}
	};

	return (
		<UiTable
			dataKey="categoryName"
			type={TableStyle.GREEN}
			useAsCard={false}
			columns={[
				{
					field: "categoryName",
					title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.DRUG_CATEGORY",
					sortable: false,
					style: {
						width: MedicationsTemplate.columnsWidth.drugCategory,
						fontFamily: "Roboto-Bold",
					},
					template: TableColumnTemplate.TRANSLATE_PREFIX,
				},
				{
					field: "pdcAmr",
					title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.VALUE",
					sortable: false,
					style: { width: MedicationsTemplate.columnsWidth.pdcAmr },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: MedicationAdherenceViewRow) => (
							<>{row.goal && GoalsService.getMedicationGoalValue(row.goal)}</>
						),
					},
				},
				{
					field: "goal",
					title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.GOALS",
					sortable: false,
					style: { width: MedicationsTemplate.columnsWidth.goal },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: MedicationAdherenceViewRow) => (
							<>
								{row.goal && (
									<GoalIndicator
										detailed={true}
										goal={row.goal}
									/>
								)}
							</>
						),
					},
				},
				{
					field: "dateFilled",
					title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.DATE_FILLED",
					sortable: false,
					style: { width: MedicationsTemplate.columnsWidth.dateFilled },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: MedicationAdherenceViewRow) => (
							<>
								{row.medications?.length && row.medications[0]?.dateFilled
									? Formatter.date(row.medications[0].dateFilled)
									: Formatter.NO_DATA}
							</>
						),
					},
				},
				{
					field: "source",
					title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.SOURCE",
					sortable: false,
					style: { width: MedicationsTemplate.columnsWidth.source },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: MedicationAdherenceViewRow) => (
							<>
								{row.medications?.length && row.medications[0]?.source
									? t(`ENUMS.SOURCE.${row.medications[0].source}`)
									: Formatter.NO_DATA}
							</>
						),
					},
				},
				{
					field: "expandCollapse",
					template: TableColumnTemplate.EXPANDER,
					style: {
						width: MedicationsTemplate.columnsWidth.expandCollapse,
					},
				},
			]}
			value={rows}
			message={{
				severity: loading
					? FieldMessageSeverity.LOADING
					: error
						? FieldMessageSeverity.DANGER
						: FieldMessageSeverity.INFO,
				label: loading
					? "PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.LOADING"
					: error
						? "PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.ERROR"
						: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE",
			}}
			paginator={false}
			pagination={{
				first: 0,
				rows: 10,
				page: 0,
				sortField: undefined,
				sortOrder: 1,
				search: undefined,
				filters: undefined,
			}}
			expandedTemplate={(e) => (
				<MedicationsHistory
					participantId={props.participantId}
					categoryName={e.categoryName}
					medications={e.medications}
					drugCategoryNotes={e.drugCategoryNotes}
					onSubmit={handleUpsertNote}
				/>
			)}
		/>
	);
};

export default MedicationAdherenceView;
