import { Client, Conversation } from "@twilio/conversations";
import { Participant } from "../entities/participant.model";

export enum CareMessageClientStatus {
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS",
}

export interface CareMessageProps {
	client: Client;
	participant: Participant;
	conversation: Conversation | null;
	members: { [x: string]: string };
	updateFn: (state?: CareMessageProps) => void;
}

export interface ConversationMessageForm {
	message: string | null;
	error: string | null;
}
