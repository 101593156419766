import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	MedicationsListProps,
	MedicationRow,
} from "../../../../models/pages/medications/medications-list.model";
import { MedicationsTemplate } from "../../../../services/medications/medications-templates.service";
import { MedicationLatestResponse } from "../../../../models/endpoints/medications-endpoint.model";
import { MedicationsFormatter } from "../../../../services/medications/medications-formatter.service";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { Form } from "react-final-form";
import { useQueryClient } from "@tanstack/react-query";
import { TableStyle } from "../../../../models/components/table/table.model";

const MedicationsList = (
	props: MedicationsListProps & {
		onClickDeactivate: (medication: MedicationRow) => void;
	}
) => {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();

	// Form: update/delete medications, create/update notes
	const [updateForm, setUpdateForm] = useState<MedicationRow>();

	const [rows, setRows] = useState<MedicationRow[]>([]);

	// Update medication: suggestions list
	const [suggestedMedication, setSuggestedMedication] = useState<string[]>([]);

	useEffect(() => {
		if (props.medications) mapRows(props.medications);
		else setRows([]);
	}, [props.medications]);

	const updateMedication = async (formData: MedicationRow): Promise<void> => {
		setSubmitting(true);

		try {
			await EndpointsService.dataRetriever.updateMedication({
				body: {
					id: formData.id,
					participantId: props.participantId,
					isActive: formData.active,
					filledDate: formData.dateFilled.toISOString(),
					dosage: formData.dosage!,
					dosageUnit: formData.dosageUnit!,
					frequency:
						formData.frequency && formData.period
							? formData.frequency.toString() + "_" + formData.period
							: null,
					medicationName: formData.name,
				},
			});

			await queryClient.invalidateQueries([
				"latest-active-medications",
				{ participantId: props.participantId },
			]);

			updateLocalMedicationValues(formData, rows);

			setRows([...rows]);
		} catch (_) {
			/* empty */
		}

		setSubmitting(false);
	};

	const invertEditionMode = (rows: MedicationRow[], medication: MedicationRow) => {
		return rows.map((r) => {
			if (r.id === medication.id) {
				r.editing = !r.editing;
			} else {
				r.editing = false;
			}
			return r;
		});
	};

	const changeEditModeFn = (medication: MedicationRow): void => {
		const updatedRows = invertEditionMode(rows, medication);
		setUpdateForm({ ...updateForm, ...medication });
		setRows(updatedRows);
	};

	const deleteFn = async (medication: MedicationRow): Promise<void> => {
		props.onClickDeactivate(medication);
	};

	const mapRows = (data: MedicationLatestResponse[] | null): void => {
		let internalRows: MedicationRow[] = [];
		if (data && data.length > 0) {
			// Create rows and subrows structure
			data.forEach((drug) => {
				// Create medication nested
				const medication: MedicationRow = new MedicationRow({
					id: drug.id,
					name: drug.medicationName,
					source: drug.source,
					sourceFormatted: drug.source,
					dateFilled: new Date(drug.filledDate),
					dosage: drug.dosage,
					dosageUnit: drug.dosageUnit,
					frequency: drug.frequency ? Number(drug.frequency.split("_")[0]) : undefined,
					period: drug.frequency?.split("_")[1],
					periodLabel: drug.frequency
						? t(
								"PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PERIODS." +
									drug.frequency.split("_")[1]
							)
						: undefined,
					participantId: drug.participantId,
					active: drug.isActive,
					activeFormatted: t(
						drug.isActive
							? "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.ACTIVE"
							: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.INACTIVE"
					),
					editing: false,
				});

				internalRows.push(medication);
			});

			// Sort drug categories and sort their medications
			internalRows = MedicationsFormatter.sortTableRows(internalRows);
		}

		setRows(internalRows);
	};

	const updateLocalMedicationValues = (formData: MedicationRow, rows: MedicationRow[]) => {
		const index = rows.findIndex((r) => r.id === formData.id);
		formData.editing = false;
		formData.periodLabel = formData.frequency
			? t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PERIODS." + formData.period)
			: undefined;
		rows[index] = { ...formData };
	};

	// Update medication: GET suggestions list
	const getSuggestedMedication = async (event: { query: string | null }): Promise<void> => {
		await EndpointsService.dataRetriever
			.getMedicationsList({
				config: {
					params: { search: event.query },
				},
			})
			.then((response: string[]) => {
				setSuggestedMedication(response.length > 0 ? response : []);
			});
	};

	const [submitting, setSubmitting] = useState<boolean>(false);

	// Columns
	const columns: TableColumn[] = [
		{
			field: "name",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.MEDICATION_NAME",
			sortable: true,
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (rowData: MedicationRow) => {
					return MedicationsTemplate.templateNameMedication(
						rowData,
						submitting,
						getSuggestedMedication.bind(this),
						suggestedMedication
					);
				},
			},
		},
		{
			field: "dosage",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.DOSAGE",
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (rowData: MedicationRow) => {
					return MedicationsTemplate.templateDosage(rowData, submitting);
				},
			},
		},
		{
			field: "frequency",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.FREQUENCY",
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (rowData: MedicationRow) => {
					return MedicationsTemplate.templateFrequency(rowData, submitting);
				},
			},
		},
		{
			field: "dateFilled",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.DATE_FILLED",
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (rowData: MedicationRow) => {
					return MedicationsTemplate.templateDateFilledMedication(rowData, submitting);
				},
			},
			sortable: true,
		},
		{
			field: "sourceFormatted",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.SOURCE",
			sortable: true,
		},
		{
			field: "activeFormatted",
			title: "PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.STATUS",
			sortable: true,
		},
		{
			field: "actions",
			style: { textAlign: "end" },
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: (rowData: MedicationRow) => {
					return MedicationsTemplate.templateActions(
						rowData,
						changeEditModeFn,
						updateMedication,
						changeEditModeFn,
						deleteFn,
						submitting
					);
				},
			},
		},
	];

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	const markRowAsInactive = (row: MedicationRow): { [x: string]: unknown } => {
		return { "row-inactive": !row.active, "inline-form": true };
	};

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "filledDate",
		sortOrder: -1,
		search: undefined,
		filters: undefined,
	});

	return (
		<Form
			onSubmit={updateMedication}
			initialValues={updateForm}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<UiTable
						dataKey="id"
						type={TableStyle.DEFAULT}
						tableClassName="medications-table"
						columns={columns}
						pagination={pagination}
						paginationFn={onPagination}
						isServerPaginated={false}
						value={rows}
						responsiveLayout="scroll"
						emptyMessage={MedicationsTemplate.templateEmptyMessage(props.message)}
						rowClassName={markRowAsInactive}
					/>
				</form>
			)}
		/>
	);
};

export default MedicationsList;
