import { RsqlFormatter } from "../services/rsql/rsql.formatter.service";
import { ITablePagination } from "./components/table/table-pagination.model";

export interface IBackendPagination {
	pageSize: number; // Rpp (Rows Per Page)
	pageNumber: number;
	search: string | null;
	sort: string | null; // RSQL
	filters: string | null; // RSQL
}

export class BackendPagination implements IBackendPagination {
	pageSize: number; // Rpp (Rows Per Page)
	pageNumber: number;
	search: string | null;
	sort: string | null; // RSQL
	filters: string | null; // RSQL

	constructor(params: ITablePagination) {
		this.pageSize = params.rows;
		this.pageNumber = params.page ?? 0;
		this.search = params.search ?? null;
		this.sort = RsqlFormatter.sort(params) ?? null;
		this.filters = RsqlFormatter.filters(params) ?? null;
	}
}
