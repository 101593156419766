import { useTranslation } from "react-i18next";
import { ObjectUtils, classNames } from "primereact/utils";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { Field } from "react-final-form";
import UiFieldLabel from "../field-label/FieldLabel.component";
import { Validations } from "../../services/form/validations.service";
import { List } from "../../models/misc.model";
import { UiInputRadioProps } from "../../models/components/input-radio.model";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";

const UiInputRadio = (props: UiInputRadioProps) => {
	const { t } = useTranslation("common");

	const optionsList: List =
		props.options?.map((item) => {
			item.label = t(item.label.toString());
			return item;
		}) || [];

	/**
	 * Remove from the props the ones that need special treatment
	 * (like the placholder that needs to be translated, etc.).
	 */
	const otherProps = ObjectUtils.findDiffKeys(props, {
		placeholder: props?.placeholder,
		onChange: props.onChange,
		onBlur: props.onBlur,
		name: props.name,
		value: props.value,
		className: props.className,
		display: props.display || "block",
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop
	const handleChange = (event: RadioButtonChangeParams): void => {
		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div
						className={classNames(
							"k-inputradio-container",
							props.label && "has-label",
							props.display
						)}
					>
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							meta={meta}
							validations={props.validations}
						>
							{optionsList.map((item) => (
								<div
									key={item.id.toString()}
									className="radio-item"
								>
									<RadioButton
										inputId={item.id.toString()}
										type="radio"
										placeholder={
											props?.placeholder ? t(props.placeholder) : undefined
										}
										className={classNames(
											{ "p-invalid": !!(meta.touched && meta.error) },
											props.className
										)}
										name={input.name}
										value={item.id}
										onChange={(event) => handleChange(event)}
										checked={input.value === item.id}
										disabled={props.disabled || item.disabled}
										{...otherProps}
									/>
									<label
										htmlFor={item.id.toString()}
										className="p-radiobutton-label"
									>
										{t(item.label.toString())}
									</label>
								</div>
							))}
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiInputRadio;
