import { useContext, useEffect, useState } from "react";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { ITableHeaderRefresh } from "../../../../models/components/table/table-refresh.model";
import { EngagementHistoryRow } from "../../../../models/pages/enagement-history.model";
import { IEngagement } from "../../../../models/entities/engagement.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { AxiosError } from "axios";
import UiModalEngagement from "../../../../components/modal-engagement/ModalEngagement.component";
import {
	ModalEngagementMode,
	UiModalEngagementProps,
} from "../../../../models/components/modal-engagement.model";
import { List, ListOption } from "../../../../models/misc.model";
import { CallerInfo } from "../../../../models/entities/call.model";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { TableTemplateButtonsEvent } from "../../../../models/components/table/table-template.model";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import { BackendPagination } from "../../../../models/pagination.model";
import {
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
} from "../../../../models/components/table/table-filter.model";

const ParticipantEngagementHistory = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "outcomeTemplate",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.outcome",
			template: TableColumnTemplate.TAG,
		},
		{
			field: "engagementDate",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.engagementDate",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "callTypeTemplate",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.callType",
			template: TableColumnTemplate.TAG,
		},
		{
			field: "details",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.details",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.ENGAGEMENT_DETAILS" },
			sortable: true,
		},
		{
			field: "other",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.other",
			sortable: true,
		},
		{
			field: "coachId",
			title: "PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TABLE.COLUMNS.createdBy",
			template: TableColumnTemplate.COACH,
			sortable: true,
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
		},
	];

	// Rows
	const [rows, setRows] = useState<EngagementHistoryRow[]>([]);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	// Refresh
	const refresh: ITableHeaderRefresh = {
		fn: () => {
			getData();
		},
	};

	/**
	 * MODAL: ENGAGEMENT
	 */

	/**
	 * Participant information
	 * @description When receiving an incoming call, map the FROM phone number with our participant's DB.
	 * When making an outbound call, map the TO phone number with our participant's DB.
	 */
	const getCallinfo = async (callSid: string): Promise<List> => {
		let participantsList: List = [];

		await EndpointsService.dataRetriever
			.getCallInfo({
				config: {
					params: { callSid },
				},
			})
			.then((response: CallerInfo[]) => {
				if (response?.length) {
					/* We found the phone number in the participants DB */

					// Update the participants list
					const participants: List = response.map((pt) => {
						const item: ListOption = {
							id: pt.id,
							label: `${Formatter.fullName(pt.firstName, pt.lastName)} / ${
								pt.kannactId
							}`,
						};
						return item;
					});

					participantsList = participants;
				}
			})
			.catch((error: AxiosError) => {
				console.log("Call: error getting the participants information");
			});

		return participantsList;
	};

	const editModal = async (event: TableTemplateButtonsEvent): Promise<void> => {
		const row: EngagementHistoryRow = event.row;

		let ptList: List = [];
		if (row.entity.callSid) ptList = await getCallinfo(row.entity.callSid);

		setModalProps({
			...modalProps,
			edit: true,
			mode: row.entity.callSid ? ModalEngagementMode.CALL : ModalEngagementMode.NON_CALL,
			patientId: row.entity.patientId,
			participantList: ptList,
			entity: row.entity,
			isVisible: true,
		});
	};

	const closeModalEngagement = (refresh: boolean, engagement: IEngagement | null): void => {
		setModalProps({ ...modalProps, isVisible: false });

		// Update row template
		if (refresh && engagement) {
			setRows(
				rows.map((row: EngagementHistoryRow) => {
					if (row.id === engagement.id) {
						// Update read value
						const originalEntity: IEngagement = {
							...row.entity,
							...engagement,
						};

						// Create new row
						row = new EngagementHistoryRow(originalEntity, editModal.bind(this));
					}
					return row;
				})
			);
		}

		// Refresh to get the updated data (also down the tree)
		if (refresh) {
			getData();
		}
	};

	// Modal reminder: state
	const [modalProps, setModalProps] = useState<UiModalEngagementProps>({
		edit: false,
		mode: ModalEngagementMode.CALL,
		patientId: null,
		participantList: null,
		isVisible: false,
		closeModal: closeModalEngagement.bind(this),
	});

	/**
	 * DATA
	 */

	// First time load
	useEffect(() => {
		// Get data
		getData();
	}, []);

	const participantFilter: ITableFilter = {
		field: "patientId",
		type: TableFilterType.NUMBER,
		value: [`${participantContext.participant?.id}`],
	};

	const [filters] = useState<ITableHeaderFilter | undefined>({
		filters: [participantFilter],
	});

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "engagementDate",
		sortOrder: -1,
		search: undefined,
		filters: filters?.filters,
	});

	// Get data: users at risk
	const getData = async (): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		await EndpointsService.dataRetriever
			.getEngagements({
				config: {
					params: new BackendPagination(pagination),
				},
			})
			.then((response: IEngagement[]) => {
				if (response?.length > 0)
					setRows(
						response.map((item) => new EngagementHistoryRow(item, editModal.bind(this)))
					);
				else
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
			})
			.catch((error: AxiosError) =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	return (
		<>
			<UiTable
				useAsCard={false}
				dataKey="id"
				title="PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TITLE"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
				paginationFn={onPagination}
				refresh={refresh}
			/>

			{modalProps.isVisible && <UiModalEngagement {...modalProps}></UiModalEngagement>}
		</>
	);
};
export default ParticipantEngagementHistory;
