import { useTranslation } from "react-i18next";
import { CONDITIONS_DEFAULT, Condition } from "../../../../models/entities/condition.model";
import QuickViewPanelWrapper from "../../../../components/quick-view-panel-wrapper/QuickViewPanelWrapper";
import Spacer from "../../../../components/spacer/Spacer";

const ConditionsQuickViewPanel = (props: {
	isLoading: boolean;
	isError: boolean;
	conditions: Condition[] | undefined;
	onClickAction: () => void;
}) => {
	const { t } = useTranslation("common");

	const activeConditions = (props.conditions ?? [])
		.filter((item) => item.isActive)
		.map((item) => ({
			isExternal: item.isExternal,
			value:
				CONDITIONS_DEFAULT.indexOf(item.conditionName) !== -1
					? t(`ENUMS.CONDITIONS.${item.conditionName}`)
					: item.conditionName,
		}))
		.sort((a, b) => {
			const nameA = t(a.value).toLowerCase();
			const nameB = t(b.value).toLowerCase();
			return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
		});

	const selfReportedConditions = activeConditions.filter((item) => !item.isExternal);

	const externalConditions = activeConditions.filter((item) => item.isExternal);

	if (props.isLoading) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data loading">
					<i className="pi pi-spin pi-spinner message-icon"></i>
					{t("PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.LOADING")}
				</p>
			</QuickViewPanelWrapper>
		);
	}

	if (props.isError) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data has-error">
					{t("PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.ERROR")}
				</p>
			</QuickViewPanelWrapper>
		);
	}

	if (!activeConditions.length) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data">
					{t("PARTICIPANT.DASHBOARD.CONDITIONS.MESSAGES.NO_DATA")}
				</p>
			</QuickViewPanelWrapper>
		);
	}
	return (
		<QuickViewPanelWrapper
			title="PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.PANEL_TITLE"
			onClickAction={props.onClickAction}
			isLoading={props.isLoading}
			isError={props.isError}
		>
			<>
				{selfReportedConditions.length > 0 && (
					<ConditionsList
						title={t("PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.SELF_REPORTED")}
						conditions={selfReportedConditions}
					/>
				)}
				{selfReportedConditions.length > 0 && externalConditions.length > 0 && (
					<Spacer y={12} />
				)}

				{externalConditions.length > 0 && (
					<ConditionsList
						title={t("PARTICIPANT.DASHBOARD.CONDITIONS.QUICK_VIEW.EXTERNAL")}
						conditions={externalConditions}
					/>
				)}
			</>
		</QuickViewPanelWrapper>
	);
};

const ConditionsList = (props: { title: string; conditions: { value: string }[] }) => (
	<>
		<div className="text-bold text-black">{props.title}</div>
		<ul className="panel-list">
			{props.conditions.map(({ value }, index) => (
				<li
					key={index}
					className="panel-list-item"
				>
					<span className="item-value">{value}</span>
				</li>
			))}
		</ul>
	</>
);

export default ConditionsQuickViewPanel;
