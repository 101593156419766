import React from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { UiButtonProps } from "../../models/components/button.model";

const UiButton = (props: UiButtonProps) => {
	const { t } = useTranslation("common");

	return (
		<Button
			id={props?.id}
			type={props?.type}
			label={props?.label ? t(`${props?.label}`) : undefined}
			icon={props?.icon}
			iconPos={props?.iconPos}
			tooltip={props?.tooltip ? t(`${props?.tooltip}`) : undefined}
			tooltipOptions={props?.tooltipOptions}
			loading={props?.loading}
			loadingIcon="pi pi-spin pi-spinner"
			className={props?.className}
			disabled={props?.disabled}
			onClick={props?.onClick}
			style={props?.style}
			data-testid={props.testId}
		/>
	);
};

export default UiButton;
