import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { CareMessageClientStatus } from "../../../models/pages/care-messages.model";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";

const MessagesClientState = ({ clientState }: { clientState: CareMessageClientStatus }) => {
	// Template: loading
	const loadingTemplate = (): React.ReactNode => (
		<div className="k-empty-message">
			<UiFieldMessage
				severity={FieldMessageSeverity.LOADING}
				label="PARTICIPANT.MESSAGES.CARE_MESSAGES.EMPTY_MESSAGE_LOADING"
			/>
		</div>
	);

	// Template: error
	const errorTemplate = (): React.ReactNode => (
		<div className="k-empty-message">
			<UiFieldMessage
				severity={FieldMessageSeverity.DANGER}
				label="PARTICIPANT.MESSAGES.CARE_MESSAGES.EMPTY_MESSAGE_ERROR"
			/>
		</div>
	);

	// Template: main
	const setTemplate = (): React.ReactNode => {
		let template: React.ReactNode = <></>;
		if (clientState === CareMessageClientStatus.LOADING) {
			template = loadingTemplate();
		} else if (clientState === CareMessageClientStatus.ERROR) {
			template = errorTemplate();
		}
		return template;
	};

	return <div className="client-state">{setTemplate()}</div>;
};
export default MessagesClientState;
