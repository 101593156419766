import { ValidationErrors } from "final-form";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { UiFormInfoProps } from "../../models/components/form-info.model";

const UiFormInfo = (props: UiFormInfoProps) => {
	const { t } = useTranslation("common");

	const mapFormErrors = (): React.ReactNode[] => {
		const errors: ValidationErrors = props.getState().errors;
		const mappedErrors: React.ReactNode[] = [];

		// Iterate form fields (that have at least an error)
		for (const key in errors) {
			if (Object.hasOwn(errors, key)) {
				// Iterate the errors of a form field (required, email, etc.)
				const fieldErrorList: string[] = [];
				for (const key2 in errors[String(key)]) {
					if (Object.hasOwn(errors[String(key)], key2)) {
						fieldErrorList.push(key2);
					}
				}
				mappedErrors.push(
					<div
						key={key}
						className="form-error"
					>
						<span>
							{key}: {fieldErrorList.join(" , ")}
						</span>
					</div>
				);
			}
		}

		return mappedErrors;
	};

	return (
		<div className="form-info mt-4">
			<h6>{t("SHOWCASES.COMMON_ACTIONS.FORM_INFO_TITLE")}</h6>

			{/* Touched ? */}
			<div>
				<span className="info-text">{t("SHOWCASES.COMMON_ACTIONS.FORM_TOUCHED")}</span>
				<i
					className={classNames(
						"info-icon",
						props.getState().touched
							? "pi pi-check-circle success"
							: "pi pi-times-circle error"
					)}
				></i>
			</div>

			{/* Valid ? */}
			<div>
				<span className="info-text">{t("SHOWCASES.COMMON_ACTIONS.FORM_VALID")}</span>
				<i
					className={classNames(
						"info-icon",
						props.getState().valid
							? "pi pi-check-circle success"
							: "pi pi-times-circle error"
					)}
				></i>
			</div>

			<div>
				<span className="info-text">{t("SHOWCASES.COMMON_ACTIONS.FORM_VALUES")}</span>
				{JSON.stringify(props.getState().values)}
			</div>

			{/* Form errors */}
			<div>
				<span className="info-text">{t("SHOWCASES.COMMON_ACTIONS.FORM_ERRORS")}</span>
				{mapFormErrors()}
			</div>
		</div>
	);
};
export default UiFormInfo;
