import { ParticipantProfile } from "../../../models/entities/participant.model";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import ParticipantDetailsCard from "./ParticipantDetailsCard";
import {
	PtPhysicianInfo,
	PtPhysiciansInfoForm,
} from "../../../models/pages/participant-details/physicians-info.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { FormApi } from "final-form";
import { useParticipantPhysicians } from "../../../hooks/useParticipantPhysicians";
import { PhysiciansUpdateBody } from "../../../models/endpoints/physicians-endpoint.model";
import { PtDetailsMode } from "../../../models/pages/participant-details/participant-details.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiButton from "../../../components/button/Button";
import UiInputText from "../../../components/input-text/InputText.component";
import { Validations } from "../../../services/form/validations.service";
import ParticipantDetailsField from "./ParticipantDetailsField";
import { TbTrash } from "react-icons/tb";
import { Formatter } from "../../../services/formatter/formatter.service";

const ParticipantDetailsPhysicians = ({
	participant,
	query,
}: {
	participant: ParticipantProfile;
	query: ReturnType<typeof useParticipantPhysicians>;
}) => {
	const [mode, setMode] = useState<PtDetailsMode>(PtDetailsMode.READ);
	const [showError, setShowError] = useState<boolean>(false);
	const [formData, setFormData] = useState<PtPhysiciansInfoForm>({
		data: [],
	});

	useEffect(() => {
		console.log("Query data changed!");
		setFormData({
			data: query.data ? query.data.map((x) => new PtPhysicianInfo(x)) : [],
		});
	}, [query.data]);

	const add = () => {
		const data = [...formData.data];
		data.push(
			new PtPhysicianInfo({
				participantId: participant.id,
				deleted: false,
			})
		);
		setFormData({ data: [...data] });
	};

	const remove = (id: number | null, index: number) => {
		if (id !== null) {
			setFormData({
				data: formData.data.map((x) => {
					if (id === x.id) {
						x.deleted = true;
					}
					return x;
				}),
			});
		} else {
			const data = [...formData.data];
			data.splice(index, 1);
			setFormData({
				data: [...data],
			});
		}
	};

	const setMessage = () => {
		let severity = FieldMessageSeverity.INFO;
		if (query.isLoading) {
			severity = FieldMessageSeverity.LOADING;
		} else if (query.isError) {
			severity = FieldMessageSeverity.DANGER;
		}

		let label = "PARTICIPANT.DETAILS.PHYSICIANS.MSG_EMPTY";
		if (query.isLoading) {
			label = "PARTICIPANT.DETAILS.PHYSICIANS.MSG_LOADING";
		} else if (query.isError) {
			label = "PARTICIPANT.DETAILS.PHYSICIANS.MSG_ERROR";
		}
		return {
			severity,
			label,
		};
	};

	const hasChanged = (formItem: PtPhysicianInfo) => {
		let result = false;

		// Find
		if (formItem.id && query.data) {
			const ph = query.data.find((x) => x.id === formItem.id);
			if (
				ph &&
				(ph.name !== formItem.name ||
					ph.email !== formItem.email ||
					ph.phone !== formItem.phone ||
					ph.fax !== formItem.fax ||
					ph.address !== formItem.address)
			) {
				result = true;
			}
		}

		return result;
	};

	const setBody = (): PhysiciansUpdateBody => {
		const result: PhysiciansUpdateBody = [];
		formData.data.forEach((x) => {
			if (x.deleted || !x.id || hasChanged(x)) {
				result.push(x);
			}
		});
		return result;
	};

	const handleSubmit = async (
		data: PtPhysiciansInfoForm,
		form: FormApi<PtPhysiciansInfoForm, PtPhysiciansInfoForm>
	) => {
		setShowError(false);
		await EndpointsService.dataRetriever
			.updatePhysicians({
				participantId: participant.id,
				body: setBody(),
			})
			.then(() => {
				form.reset();
				query.invalidate();

				// Update form mode
				setMode(PtDetailsMode.READ);
			})
			.catch(() => setShowError(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<ParticipantDetailsCard
						title="PARTICIPANT.DETAILS.PHYSICIANS.TITLE"
						submitting={submitting}
						mode={mode}
						enableEditing={true}
						showError={showError}
						// enableSubmit={!!Object.keys(setRequest())}
						enableSubmit={form.getState().valid}
						onCancel={() => {
							form.reset();
							setShowError(false);
							setFormData(new PtPhysiciansInfoForm(query.data || []));
							setMode(PtDetailsMode.READ);
						}}
						onModeChange={() => setMode(PtDetailsMode.UPDATE)}
					>
						{/* MESSAGE */}
						{query.isLoading ||
						query.isError ||
						!query.data ||
						query.data?.length === 0 ? (
							// Message
							<div className="form-message">
								<UiFieldMessage
									severity={setMessage().severity}
									label={setMessage().label}
								/>
							</div>
						) : (
							<></>
						)}

						{/* DATA */}
						{formData.data.length > 0 ? (
							<>
								{formData.data
									// .filter((x) => !x.deleted) // NOT WORKING (WHY??)
									.map((x, index) => {
										return !x.deleted ? (
											<div
												id={index.toString()}
												key={index.toString()}
											>
												<div className="row align-items-center">
													{/* Form fields */}
													<div className="col-10 col-sm-11 col-md-11 col-lg-11">
														<div className="row">
															{/* Name */}
															<div className="col-12 col-sm-6 col-md-4 col-lg-4">
																{mode === PtDetailsMode.READ ? (
																	<ParticipantDetailsField
																		title="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.name"
																		value={x.name}
																	/>
																) : (
																	<UiInputText
																		id={`data[${index}].name`}
																		name={`data[${index}].name`}
																		label="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.name"
																		onChange={(e) => {
																			const data = [
																				...formData.data,
																			];
																			data[index].name =
																				e.target.value;
																			setFormData({
																				data,
																			});
																		}}
																		validations={[
																			Validations.required,
																		]}
																		disabled={submitting}
																	/>
																)}
															</div>

															{/* Email */}
															<div className="col-12 col-sm-6 col-md-5 col-lg-5">
																{mode === PtDetailsMode.READ ? (
																	<ParticipantDetailsField
																		title="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.email"
																		value={x.email}
																	/>
																) : (
																	<UiInputText
																		id={`data[${index}].email`}
																		name={`data[${index}].email`}
																		label="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.email"
																		onChange={(e) => {
																			const data = [
																				...formData.data,
																			];
																			data[index].email =
																				e.target.value;
																			setFormData({
																				data,
																			});
																		}}
																		validations={
																			formData.data[index]
																				.email
																				? [
																						Validations.email,
																					]
																				: []
																		}
																		disabled={submitting}
																	/>
																)}
															</div>

															{/* Phone */}
															<div className="col-12 col-sm-6 col-md-3 col-lg-3">
																{mode === PtDetailsMode.READ ? (
																	<ParticipantDetailsField
																		title="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.phone"
																		value={
																			x.phone
																				? Formatter.phoneNumber(
																						x.phone
																					)
																				: Formatter.NO_DATA
																		}
																	/>
																) : (
																	<UiInputText
																		id={`data[${index}].phone`}
																		name={`data[${index}].phone`}
																		label="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.phone"
																		onChange={(e) => {
																			const data = [
																				...formData.data,
																			];
																			data[index].phone =
																				e.target.value;
																			setFormData({
																				data,
																			});
																		}}
																		validations={
																			formData.data[index]
																				.phone
																				? [
																						Validations.regex(
																							/^\d{10}$/,
																							"PARTICIPANT.DETAILS.PHYSICIANS.MSG_VALIDATION_PHONE"
																						),
																					]
																				: []
																		}
																		disabled={submitting}
																	/>
																)}
															</div>

															{/* Fax */}
															<div className="col-12 col-sm-6 col-md-3 col-lg-3">
																{mode === PtDetailsMode.READ ? (
																	<ParticipantDetailsField
																		title="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.fax"
																		value={
																			x.fax
																				? Formatter.phoneNumber(
																						x.fax
																					)
																				: Formatter.NO_DATA
																		}
																	/>
																) : (
																	<UiInputText
																		id={`data[${index}].fax`}
																		name={`data[${index}].fax`}
																		label="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.fax"
																		onChange={(e) => {
																			const data = [
																				...formData.data,
																			];
																			data[index].fax =
																				e.target.value;
																			setFormData({
																				data,
																			});
																		}}
																		// validations={[
																		// 	Validations.numbers,
																		// ]}
																		disabled={submitting}
																	/>
																)}
															</div>

															{/* Address */}
															<div className="col-12 col-sm-6 col-md-9 col-lg-9">
																{mode === PtDetailsMode.READ ? (
																	<ParticipantDetailsField
																		title="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.address"
																		value={x.address}
																	/>
																) : (
																	<UiInputText
																		id={`data[${index}].address`}
																		name={`data[${index}].address`}
																		label="PARTICIPANT.DETAILS.PHYSICIANS.FORM_FIELDS.address"
																		onChange={(e) => {
																			const data = [
																				...formData.data,
																			];
																			data[index].address =
																				e.target.value;
																			setFormData({
																				data,
																			});
																		}}
																		disabled={submitting}
																	/>
																)}
															</div>
														</div>
													</div>

													{/* Remove form */}
													<div className="col-2 col-sm-1 col-md-1 col-lg-1 text-end">
														<UiButton
															className="p-button-text neutral"
															type="button"
															icon={
																<TbTrash
																	style={{ fontSize: "1.5rem" }}
																/>
															}
															disabled={
																submitting ||
																mode === PtDetailsMode.READ
															}
															onClick={() => remove(x.id, index)}
														/>
													</div>
												</div>

												<hr />
											</div>
										) : (
											<></>
										);
									})}
							</>
						) : (
							<></>
						)}

						<div className="d-flex justify-content-center mt-2">
							<UiButton
								id="addPhysician"
								type="button"
								className="p-button-sm p-button-rounded"
								label="PARTICIPANT.DETAILS.PHYSICIANS.CREATE"
								icon={"pi pi-plus"}
								onClick={() => add()}
								disabled={submitting || mode === PtDetailsMode.READ}
							/>
						</div>
					</ParticipantDetailsCard>
				</form>
			)}
		/>
	);
};

export default ParticipantDetailsPhysicians;
