export enum CHART_TYPE {
	GLUCOSE = "GLUCOSE",
	BLOOD_PRESSURE = "BLOOD_PRESSURE",
	WEIGHT = "WEIGHT",
}

export enum CHART_FILTER {
	WEEK = "WEEK",
	MONTH = "MONTH",
	TRIMESTER = "TRIMESTER",
	ALL = "ALL",
}

export type CHART_DATA = {
	labels: (number | Date)[];
	datasets: CHART_DATASET[];
};

export type CHART_DATASET = {
	label: string;
	data: { x: Date | number; y: number }[];
};

export type CHART_INFO = {
	count: number | null;
	min: number | string | null;
	avg: number | string | null;
	max: number | string | null;
};
