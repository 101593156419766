import { MedicationLatestResponse } from "../../models/endpoints/medications-endpoint.model";
import { MedicationRow } from "../../models/pages/medications/medications-list.model";

// Sort medications by: drug category and then by dateFilled (more recent date on top)
const sortMedications = (medications: MedicationLatestResponse[]): MedicationLatestResponse[] => {
	return medications.sort(function (a, b) {
		const dateA = new Date(a.filledDate);
		const dateB = new Date(b.filledDate);
		return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
	});
};

const sortTableRows = (rows: MedicationRow[]): MedicationRow[] => {
	return rows.sort(function (a, b) {
		// Sort drug categories
		const dateA = new Date(a.dateFilled);
		const dateB = new Date(b.dateFilled);
		return (
			(a.active === b.active ? 0 : a.active ? -1 : 1) ||
			(dateA < dateB ? 1 : dateA > dateB ? -1 : 0)
		);
	});
};

export const MedicationsFormatter = {
	sortMedications,
	sortTableRows,
};
