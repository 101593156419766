// RSQL
import builder from "@rsql/builder";
import { ComparisonNode, ExpressionNode } from "@rsql/ast";
import { emit } from "@rsql/emitter";
// Others
import { FilterService } from "./../filter/filter.service";
import { ITablePagination } from "../../models/components/table/table-pagination.model";
import { TableFilterType } from "../../models/components/table/table-filter.model";

/**
 * RSQL: SORT
 */

const sort = (params: ITablePagination): string | undefined => {
	let rsqlSort: string | undefined;

	let sortBuilder: ExpressionNode | undefined;

	if (params.sortField && (params.sortOrder || params.sortOrder === 0)) {
		const sortComparisonNode: ComparisonNode[] = [];

		params.sortField.split(",").forEach((field) => {
			sortComparisonNode.push(builder.eq(field, params.sortOrder === -1 ? "DESC" : "ASC"));
		});

		sortBuilder = builder.and(...sortComparisonNode);
		rsqlSort = emit(sortBuilder);
	}

	return rsqlSort;
};

/**
 * RSQL: FILTERS
 */

//	TODO: Refactor and remove redundand code.
//	This is used everywhere, we'll need to do some regression testing to ensure that we don't break anything.
// 	It will also be a good idea to add unit tests for it.
const filters = (params: ITablePagination): string | undefined => {
	let rsqlFilters: string | undefined;

	let filtersBuilder: ExpressionNode | undefined;

	if (params.filters && FilterService.hasSelectedFilters(params.filters)) {
		// All the filters (AND)
		const filterAnds: ExpressionNode[] = [];

		params.filters.forEach((filter) => {
			if (filter.value instanceof Array && filter.value.length > 0) {
				/* The filter is an array */

				// Filter concatenated OR's
				let filterExpression: ExpressionNode;

				// Individual filter values: EQUALS, BETWEEN (range), GREATER THAN, etc
				const filterBuilder: (ComparisonNode | ExpressionNode)[] = [];

				if (filter.type === TableFilterType.DATE_RANGE) {
					const min = filter.value[0];
					const max = filter.value[1];

					if (min && max) {
						// Range: min-max (between min and max)
						const minComparison: ComparisonNode = builder.ge(filter.field, min);
						const maxComparison: ComparisonNode = builder.le(filter.field, max);
						const minMaxComparison: ExpressionNode = builder.and(
							...[minComparison, maxComparison]
						);
						filterBuilder.push(minMaxComparison);
					} else if (min && !max) {
						// Range: min- (greater than min)
						const minComparison: ComparisonNode = builder.ge(filter.field, min);
						filterBuilder.push(minComparison);
					} else if (!min && max) {
						// Range: -max (lesser than max)
						const maxComparison: ComparisonNode = builder.le(filter.field, max);
						filterBuilder.push(maxComparison);
					}
				} else {
					filter.value.forEach((selectedFilter) => {
						switch (filter.type) {
							/* BY VALUE */
							case TableFilterType.STRING:
							case TableFilterType.DATE:
							case TableFilterType.BOOLEAN:
							case TableFilterType.NUMBER:
							case TableFilterType.SELECT:
							case TableFilterType.SELECT_MULTIPLE:
							case TableFilterType.DATE_NAVIGATOR:
								filterBuilder.push(builder.eq(filter.field, selectedFilter));
								break;
							/* RANGE */
							case TableFilterType.STRING_RANGE:
							case TableFilterType.NUMBER_RANGE:
							default:
								break;
						}
					});
				}

				filterExpression = builder.or(...filterBuilder);

				filterAnds.push(filterExpression);
			} else if (
				!(filter.value instanceof Array) &&
				(filter.value || filter.value === 0 || filter.value === false)
			) {
				/* The filter is not an array (string, number, boolean, date) */

				// Filter concatenated OR's
				let filterExpression: ExpressionNode;

				// Individual filter values: EQUALS, BETWEEN (range), GREATER THAN, etc
				const filterBuilder: (ComparisonNode | ExpressionNode)[] = [];
				filterBuilder.push(builder.eq(filter.field, filter.value));
				filterExpression = builder.or(...filterBuilder);
				filterAnds.push(filterExpression);
			}
		});

		filtersBuilder = builder.and(...filterAnds);
		rsqlFilters = emit(filtersBuilder);
	}

	/* 
		Remove the extra double quotes when the value has spaces (it was breaking on the SQL side)
		Value without spaces: institution%3D%3DDirect
		Value with spaces: institution%3D%3D%22City+Of+Dallas%22
			--> institution%3D%3DCity+Of+Dallas
	*/
	if (rsqlFilters) {
		rsqlFilters = rsqlFilters.replaceAll('"', "");
	}
	return rsqlFilters;
};

export const RsqlFormatter = { sort, filters };
