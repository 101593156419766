import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import { useTranslation } from "react-i18next";
import UiButton from "../button/Button";

const QuickViewPanelWrapper = (props: {
	title: string;
	children: React.ReactNode;
	onClickAction: () => void;
	isLoading: boolean;
	isError: boolean;
}) => (
	<Panel
		headerTemplate={(options) => (
			<PanelHeader
				{...options}
				title={props.title}
				hasAction
				actionLabel="UI_COMPONENTS.QUICK_VIEW_PANEL_WRAPPER.ACTION_LABEL"
				actionFn={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			/>
		)}
		className="k-panel-list"
	>
		{props.children}
	</Panel>
);

const PanelHeader = (
	props: PanelHeaderTemplateOptions & {
		title: string;
		hasAction?: boolean;
		actionLabel?: string;
		actionFn?: () => void;
		isLoading: boolean;
		isError: boolean;
	}
) => {
	const { t } = useTranslation("common");

	return (
		<div className="panel-list-header">
			<span className="header-title">{t(props.title)}</span>

			{props.hasAction && (
				<UiButton
					type="button"
					className="p-button-xs p-button-rounded header-action"
					label={props.actionLabel}
					onClick={props.actionFn}
					disabled={props.isLoading || props.isError}
				/>
			)}
		</div>
	);
};

export default QuickViewPanelWrapper;
