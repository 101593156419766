import { useTranslation } from "react-i18next";
import { Field, FieldInputProps } from "react-final-form";

// Primereact
import { ObjectUtils, classNames } from "primereact/utils";
import { SelectButton, SelectButtonChangeParams } from "primereact/selectbutton";

// Own "library"
import UiFieldLabel from "../field-label/FieldLabel.component";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { List } from "../../models/misc.model";
import { Validations } from "../../services/form/validations.service";
import { UiSelectButtonProps } from "../../models/components/select-button.model";

const UiSelectButton = (props: UiSelectButtonProps) => {
	const { t } = useTranslation("common");

	const optionsList: List =
		props.options?.map((item) => {
			item.label = t(item.label.toString());
			return item;
		}) ?? [];

	// Props without the following specific keys (these specific attributes have to be treated differently)
	const otherProps = ObjectUtils.findDiffKeys(props, {
		name: props.name,
		value: props.value,
		className: props.className,
		options: props.options,
		onChange: props.onChange,
		optionValue: props.optionValue || "id",
		optionLabel: props.optionLabel || "label",
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop, and integrate it with
	const handleChange = (
		event: SelectButtonChangeParams,
		input: FieldInputProps<any, HTMLElement>
	) => {
		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		if (input) {
			input.onChange(event);
			input.onBlur();
		}
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div
						className={classNames(
							"k-select-button-container",
							props.label && "has-label"
						)}
					>
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							meta={meta}
							validations={props.validations}
						>
							<SelectButton
								id={props.id}
								className={classNames(
									{ "p-invalid": !!(meta.touched && meta.error) },
									props.className
								)}
								name={input.name}
								value={input.value}
								onChange={(event) => handleChange(event, input)}
								disabled={props.disabled}
								options={optionsList}
								optionValue={props.optionValue || "id"}
								optionLabel={props.optionLabel || "label"}
								{...otherProps}
							/>
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiSelectButton;
