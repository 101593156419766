import { Message } from "@twilio/conversations";
import { classNames } from "primereact/utils";
import { Formatter } from "../../../../services/formatter/formatter.service";
import TwilioConversationService from "../../../../services/twilio/twilio-conversations.service";

const ConversationMessage = ({
	message,
	members,
}: {
	message: Message;
	members: { [x: string]: string };
}) => (
	<div
		key={message.sid}
		className={classNames(
			"message-container",
			TwilioConversationService.isOwnMessage(message) ? "own-message" : "other-message"
		)}
	>
		<div className="message-wrapper">
			<div className="message-info">
				{/* Message: author */}
				{message.author && (
					<span className="message-author">
						{members[message.author] || message.author}
					</span>
				)}

				{/* Message: creation date */}
				{message.dateCreated && (
					<span className="message-date">
						{Formatter.date(message.dateCreated, {
							day: "numeric",
							month: "short",
							year: "numeric",
							hour: "2-digit",
							minute: "2-digit",
						})}
					</span>
				)}
			</div>

			{/* Message: content/body */}
			<div className="message-body">{message.body}</div>
		</div>
	</div>
);

export default ConversationMessage;
