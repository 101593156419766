import { useEffect, useState } from "react";
import UiTable from "../../../../components/table/Table.component";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { TableColumn } from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { SimpleTableRow } from "../../../../models/pages/showcases.model";
import { PageProps } from "../../../../models/routing.model";

const SimpleTableShowcase = (props: PageProps) => {
	// Table: columns
	const columns: TableColumn[] = [
		{ field: "key1", title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key1" },
		{ field: "key2", title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key2" },
		{ field: "key3", title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key3" },
		{ field: "key4", title: "SHOWCASES.TABLE_SHOWCASES.COLUMNS.key4" },
	];

	// Table: rows
	const [rows, setRows] = useState<SimpleTableRow[]>();

	// Table: pagination
	const pagination: TablePagination = {
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
	};

	// Table: message
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.LOADING,
		label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
	});

	// First time load: get the table data
	useEffect(() => {
		getData();
	}, []);

	// Simulate a backend call
	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	// Get data
	const getData = async (): Promise<void> => {
		// Message: loading
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
		});

		// Simulate a backend call
		await sleep(2000);

		// Set rows
		setRows([
			{
				key1: "Row 1 - Col 1",
				key2: 1,
				key3: "Row 1 - Col 3",
				key4: "",
			},
			{
				key1: "Row 2 - Col 1",
				key2: 5,
				key3: "Row 2 - Col 3",
				key4: "",
			},
			{
				key1: "Row 3 - Col 1",
				key2: 22,
				key3: "Row 3 - Col 3",
				key4: "Row 3 - Col 4",
			},
			{
				key1: "Row 4 - Col 1",
				key2: 87,
				key3: "Row 4 - Col 3",
				key4: "Row 4 - Col 4",
			},
		]);
	};

	return (
		<>
			<UiTable
				title="SHOWCASES.TABLE_SHOWCASES.SIMPLE_TABLE_SHOWCASE.SUBTITLE"
				dataKey="key0"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
			/>
		</>
	);
};

export default SimpleTableShowcase;
