import { useTranslation } from "react-i18next";
import { IParticipantSUGService } from "../../../models/pages/service-utilization-goals/participant-sug.model";
import { SuStatus } from "../../../models/entities/service-utilization-goals.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillQuestionCircle } from "react-icons/ai";
import { MdAccessTimeFilled } from "react-icons/md";
import { ImSpinner3 } from "react-icons/im";
import { SU_Service } from "../../../services/service-utilization-goals/service-utilization-goals.service";

const ServiceUtilizationGoalsStatus = ({ props }: { props: IParticipantSUGService }) => {
	const { t } = useTranslation("common");

	const iconTemplate = (): React.ReactNode => {
		let icon: React.ReactNode;

		switch (props.status) {
			case SuStatus.on_track:
				icon = <AiFillCheckCircle className="status-icon" />;
				break;
			case SuStatus.no_data:
				icon = <AiFillQuestionCircle className="status-icon" />;
				break;
			case SuStatus.off_track:
			case SuStatus.therapy_off_track:
			case SuStatus.survey_off_track:
			case SuStatus.never_received:
				icon = <AiFillCloseCircle className="status-icon" />;
				break;
			case SuStatus.due_soon:
			case SuStatus.therapy_due_soon:
			case SuStatus.survey_due_soon:
				icon = <BsFillExclamationCircleFill className="status-icon" />;
				break;
			case SuStatus.fallback:
				icon = <ImSpinner3 className="status-icon" />;
				break;
			case SuStatus.expired:
				icon = <MdAccessTimeFilled className="status-icon" />;
				break;
			default:
				icon = <></>;
				break;
		}

		return icon;
	};

	const statusTemplate = (): React.ReactNode => {
		const shouldDisplayDate =
			props.date && props.status && SU_Service.serviceNeedsAttention(props.status);

		return (
			<div className={`sug-status ${props.status}`}>
				{/* State description */}
				<span className="status-title">
					{t(`PARTICIPANT.SERVICE_UTILIZATION_GOALS.STATUS.${props.status}`)}
				</span>

				{/* Due date */}
				{shouldDisplayDate && (
					<span className="status-date">
						{Formatter.date(props.dueDate!, {
							day: "2-digit",
							month: "2-digit",
							year: "2-digit",
						})}
					</span>
				)}

				{/* Icon */}
				{iconTemplate()}
			</div>
		);
	};

	return (
		<div className="sug-status-container">
			{props.eligible && props.applicable && statusTemplate()}
		</div>
	);
};

export default ServiceUtilizationGoalsStatus;
