import { useTranslation } from "react-i18next";
import UiButton from "../../../components/button/Button";
import { PageProps } from "../../../models/routing.model";

const ButtonShowcase = (props: PageProps) => {
	const { t } = useTranslation("common");

	return (
		<div className="button-showcase row">
			<div className="col-12 col-lg-6">
				<h1 className="mt-3">{t("SHOWCASES.BUTTON.PRIMARY")}</h1>
				<div>
					<UiButton label="SHOWCASES.BUTTON.PRIMARY" />
					<UiButton
						label="SHOWCASES.BUTTON.PRIMARY"
						icon="pi pi-plus"
					/>
					<UiButton
						label="SHOWCASES.BUTTON.PRIMARY"
						disabled={true}
					/>
					<UiButton
						label="SHOWCASES.BUTTON.PRIMARY"
						loading={true}
					/>
					<UiButton icon="pi pi-plus" />
					<UiButton
						icon="pi pi-plus"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.ROUNDED")}</h1>
				<div>
					<UiButton
						className="p-button-rounded"
						label="SHOWCASES.BUTTON.ROUNDED"
					/>
					<UiButton
						className="p-button-rounded"
						label="SHOWCASES.BUTTON.ROUNDED"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-rounded"
						label="SHOWCASES.BUTTON.ROUNDED"
						disabled={true}
					/>
					<UiButton
						className="p-button-rounded"
						label="SHOWCASES.BUTTON.ROUNDED"
						loading={true}
					/>
					<UiButton
						className="p-button-rounded"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-rounded"
						icon="pi pi-plus"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.SUCCESS")}</h1>
				<div>
					<UiButton
						className="p-button-success"
						label="SHOWCASES.BUTTON.SUCCESS"
					/>
					<UiButton
						className="p-button-success"
						label="SHOWCASES.BUTTON.SUCCESS"
						icon="pi pi-check"
					/>
					<UiButton
						className="p-button-success"
						label="SHOWCASES.BUTTON.SUCCESS"
						disabled={true}
					/>
					<UiButton
						className="p-button-success"
						label="SHOWCASES.BUTTON.SUCCESS"
						loading={true}
					/>
					<UiButton
						className="p-button-success"
						icon="pi pi-check"
					/>
					<UiButton
						className="p-button-success"
						icon="pi pi-check"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.DANGER")}</h1>
				<div>
					<UiButton
						className="p-button-danger"
						label="SHOWCASES.BUTTON.DANGER"
					/>
					<UiButton
						className="p-button-danger"
						label="SHOWCASES.BUTTON.DANGER"
						icon="pi pi-trash"
					/>
					<UiButton
						className="p-button-danger"
						label="SHOWCASES.BUTTON.DANGER"
						disabled={true}
					/>
					<UiButton
						className="p-button-danger"
						label="SHOWCASES.BUTTON.DANGER"
						loading={true}
					/>
					<UiButton
						className="p-button-danger"
						icon="pi pi-trash"
					/>
					<UiButton
						className="p-button-danger"
						icon="pi pi-trash"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.NEUTRAL")}</h1>
				<div>
					<UiButton
						className="p-button-secondary"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
					/>
					<UiButton
						className="p-button-secondary"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
						icon="pi pi-copy"
					/>
					<UiButton
						className="p-button-secondary"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
						disabled={true}
					/>
					<UiButton
						className="p-button-secondary"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
						loading={true}
					/>
					<UiButton
						className="p-button-secondary"
						icon="pi pi-copy"
					/>
					<UiButton
						className="p-button-secondary"
						icon="pi pi-copy"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.INFO")}</h1>
				<div>
					<UiButton
						className="p-button-info"
						label="SHOWCASES.BUTTON.INFO"
					/>
					<UiButton
						className="p-button-info"
						label="SHOWCASES.BUTTON.INFO"
						icon="pi pi-info"
					/>
					<UiButton
						className="p-button-info"
						label="SHOWCASES.BUTTON.INFO"
						disabled={true}
					/>
					<UiButton
						className="p-button-info"
						label="SHOWCASES.BUTTON.INFO"
						loading={true}
					/>
					<UiButton
						className="p-button-info"
						icon="pi pi-info"
					/>
					<UiButton
						className="p-button-info"
						icon="pi pi-info"
						disabled={true}
					/>
				</div>
			</div>

			<div className="col-12 col-lg-6">
				<h1 className="mt-3">{t("SHOWCASES.BUTTON.OUTLINED")}</h1>
				<div>
					<UiButton
						className="p-button-outlined"
						label="SHOWCASES.BUTTON.OUTLINED"
					/>
					<UiButton
						className="p-button-outlined"
						label="SHOWCASES.BUTTON.OUTLINED"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-outlined"
						label="SHOWCASES.BUTTON.OUTLINED"
						disabled={true}
					/>
					<UiButton
						className="p-button-outlined"
						label="SHOWCASES.BUTTON.OUTLINED"
						loading={true}
					/>
					<UiButton
						className="p-button-outlined"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-outlined"
						icon="pi pi-plus"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.OUTLINED_ROUNDED")}</h1>
				<div>
					<UiButton
						className="p-button-outlined p-button-rounded"
						label="SHOWCASES.BUTTON.OUTLINED_ROUNDED"
					/>
					<UiButton
						className="p-button-outlined p-button-rounded"
						label="SHOWCASES.BUTTON.OUTLINED_ROUNDED"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-outlined p-button-rounded"
						label="SHOWCASES.BUTTON.OUTLINED_ROUNDED"
						disabled={true}
					/>
					<UiButton
						className="p-button-outlined p-button-rounded"
						label="SHOWCASES.BUTTON.OUTLINED_ROUNDED"
						loading={true}
					/>
					<UiButton
						className="p-button-outlined p-button-rounded"
						icon="pi pi-plus"
					/>
					<UiButton
						className="p-button-outlined p-button-rounded"
						icon="pi pi-plus"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.TEXT")}</h1>
				<div>
					<UiButton
						className="p-button-text"
						label="SHOWCASES.BUTTON.TEXT"
					/>
					<UiButton
						className="p-button-text"
						label="SHOWCASES.BUTTON.TEXT"
						icon="pi pi-search"
					/>
					<UiButton
						className="p-button-text"
						label="SHOWCASES.BUTTON.TEXT"
						disabled={true}
					/>
					<UiButton
						className="p-button-text"
						label="SHOWCASES.BUTTON.TEXT"
						loading={true}
					/>
					<UiButton
						className="p-button-text"
						icon="pi pi-search"
					/>
					<UiButton
						className="p-button-text"
						icon="pi pi-search"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.TEXT_NEUTRAL")}</h1>
				<div>
					<UiButton
						className="p-button-text neutral"
						label="SHOWCASES.BUTTON.TEXT_NEUTRAL"
					/>
					<UiButton
						className="p-button-text neutral"
						label="SHOWCASES.BUTTON.TEXT_NEUTRAL"
						icon="pi pi-copy"
					/>
					<UiButton
						className="p-button-text neutral"
						label="SHOWCASES.BUTTON.TEXT_NEUTRAL"
						disabled={true}
					/>
					<UiButton
						className="p-button-text neutral"
						label="SHOWCASES.BUTTON.TEXT_NEUTRAL"
						loading={true}
					/>
					<UiButton
						className="p-button-text neutral"
						icon="pi pi-copy"
					/>
					<UiButton
						className="p-button-text neutral"
						icon="pi pi-copy"
						disabled={true}
					/>
				</div>

				<h1 className="mt-3">{t("SHOWCASES.BUTTON.LINK")}</h1>
				<div>
					<UiButton
						className="p-button-text link"
						label="SHOWCASES.BUTTON.LINK"
					/>
					<UiButton
						className="p-button-text link"
						label="SHOWCASES.BUTTON.LINK"
						icon="pi pi-link"
					/>
					<UiButton
						className="p-button-text link"
						label="SHOWCASES.BUTTON.LINK"
						disabled={true}
					/>
					<UiButton
						className="p-button-text link"
						label="SHOWCASES.BUTTON.LINK"
						loading={true}
					/>
					<UiButton
						className="p-button-text link"
						icon="pi pi-link"
					/>
					<UiButton
						className="p-button-text link"
						icon="pi pi-link"
						disabled={true}
					/>
				</div>
			</div>
		</div>
	);
};
export default ButtonShowcase;
