import { useContext, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { BiometricRow } from "../../../../models/pages/biometrics/biometrics-modal.model";
import { useQueryClient } from "@tanstack/react-query";
import UiTable from "../../../../components/table/Table.component";
import { TableStyle } from "../../../../models/components/table/table.model";
import { BiometricsTemplate } from "../../../../services/biometrics/biometrics-template.service";
import { TableColumnTemplate } from "../../../../models/components/table/table-column.model";
import GoalIndicator from "../../../../components/goal-indicator/GoalIndicator";
import { GoalsService } from "../../../../services/goals/goals.service";
import { Validations } from "../../../../services/form/validations.service";
import UiInputDate from "../../../../components/input-date/InputDate.component";
import UiButton from "../../../../components/button/Button";
import BiometricsNestedList from "./BiometricsNestedList";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import { useBiometrics } from "../../../../hooks/useBiometrics";
import { useBiometricGoals } from "../../../../hooks/useBiometricGoals";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";
import { BiometricsInlineUpdateForm } from "../../../../models/pages/biometrics/biometrics-update.model";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import BiometricsDelete from "../BiometricsDelete";

const BiometricsList = () => {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	const biometrics = useBiometrics({
		participantId: participantContext.participant!.id,
	});
	const biometricGoals = useBiometricGoals({ participantId: participantContext.participant!.id });
	const latestBiometrics = BiometricsHelper.getLatestValues(biometrics.data ?? new Map());

	const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
	const [deletingRow, setDeletingRow] = useState<BiometricRow | null>(null);
	const [editingRow, setEditingRow] = useState<BiometricRow | null>(null);
	const [updateForm, setUpdateForm] = useState<BiometricsInlineUpdateForm>({
		value: null,
		dateOn: null,
	});
	const [expandedRow, setExpandedRow] = useState<BiometricRow | null>(null);

	const rows = BiometricsHelper.getListRows(
		latestBiometrics,
		biometricGoals.data || [],
		editingRow
	);

	const saveBiometricsUpdates = async (
		row: BiometricRow,
		updateForm: BiometricsInlineUpdateForm
	) => {
		if (!row.ids) {
			return;
		}

		const value = updateForm.value;
		const dateOn = updateForm.dateOn;
		const biometricsToUpdate = row.ids
			.map((id, index) => {
				return {
					id,
					body: BiometricsHelper.setUpdateBody(
						{
							...row,
							value,
							dateOn,
						} as any,
						row.subTypes[index]
					),
				};
			})
			.filter(({ body }) => body.value !== null);

		const biometricsToUpdatePromises = biometricsToUpdate.map(({ id, body }) =>
			EndpointsService.dataRetriever.updateBiometric({
				id,
				body,
			})
		);

		await Promise.all(biometricsToUpdatePromises);
	};

	const onSubmitEdit = async () => {
		// TODO: handle validation and server errors
		await saveBiometricsUpdates(editingRow!, updateForm);
		await queryClient.invalidateQueries([
			"biometrics",
			{ participantId: participantContext.participant!.id },
		]);

		setEditingRow(null);
		setUpdateForm({
			value: null,
			dateOn: null,
		});
	};

	const onDelete = (e: BiometricRow) => {
		setDeletingRow(e);
		setModalIsVisible(true);
	};

	return (
		<>
			{biometrics.isLoading || biometrics.isError ? (
				<UiFieldMessage
					severity={
						biometrics.isLoading
							? FieldMessageSeverity.LOADING
							: FieldMessageSeverity.DANGER
					}
					label={
						biometrics.isLoading
							? "PARTICIPANT.BIOMETRICS.MESSAGES.LOADING"
							: "PARTICIPANT.BIOMETRICS.MESSAGES.ERROR"
					}
				/>
			) : (
				<>
					<Form
						onSubmit={onSubmitEdit}
						initialValues={{ value: updateForm.value, dateOn: updateForm.dateOn }}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<UiTable
									dataKey="type"
									type={TableStyle.GREEN}
									tableClassName="nested-table"
									useAsCard={false}
									rowClassName={() => "inline-form biometrics-form"}
									columns={[
										{
											field: "measurement",
											title: "PARTICIPANT.BIOMETRICS.LIST.measurement",
											sortable: false,
											style: {
												width: BiometricsTemplate.columnsWidth.measurement,
												fontFamily: "Roboto-Bold",
											},
											template: TableColumnTemplate.TRANSLATE_PREFIX,
										},
										{
											field: "value",
											title: "PARTICIPANT.BIOMETRICS.LIST.value",
											sortable: false,
											style: { width: BiometricsTemplate.columnsWidth.value },
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) =>
													BiometricsTemplate.templateValue(
														row,
														(value) => {
															setUpdateForm({
																...updateForm,
																value:
																	updateForm.value &&
																	typeof updateForm.value ===
																		"object"
																		? {
																				...updateForm.value,
																				...value,
																			}
																		: value,
															});
														},
														submitting,
														updateForm.value
													),
											},
										},
										{
											field: "goal",
											title: "PARTICIPANT.BIOMETRICS.LIST.goal",
											sortable: false,
											style: { width: BiometricsTemplate.columnsWidth.goal },
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) =>
													GoalsService.showGoal(row.goal) && (
														<GoalIndicator
															goal={row.goal!}
															detailed={true}
														/>
													),
											},
										},
										{
											field: "dateOn",
											title: "PARTICIPANT.BIOMETRICS.LIST.dateOn",
											sortable: false,
											style: {
												width: BiometricsTemplate.columnsWidth.dateOn,
											},
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) => {
													if (!row.editing) {
														return (
															<span className="text">
																{row.dateOn.formatted}
																{row.estimated ? " (Est.)" : ""}
															</span>
														);
													}

													return (
														<UiInputDate
															id="dateOn"
															placeholder="ENUMS.BIOMETRICS.dateOn"
															name="dateOn"
															onChange={(e) =>
																setUpdateForm({
																	...updateForm,
																	dateOn: e.target.value as Date,
																})
															}
															validations={[Validations.required]}
															value={updateForm.dateOn ?? undefined}
															disabled={submitting}
															showTime
															showButtonBar={false}
															maxDate={new Date()}
														/>
													);
												},
											},
										},
										{
											field: "source",
											title: "PARTICIPANT.BIOMETRICS.LIST.source",
											sortable: false,
											style: {
												width: BiometricsTemplate.columnsWidth.source,
											},
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) =>
													BiometricsTemplate.templateSource(
														t(row.source.formatted)
													),
											},
										},
										{
											field: "notes",
											title: "PARTICIPANT.BIOMETRICS.LIST.notes",
											sortable: false,
											style: { width: BiometricsTemplate.columnsWidth.notes },
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) =>
													BiometricsTemplate.templateNotes(
														row.notes.formatted
													),
											},
										},
										{
											field: "expandCollapse",
											sortable: false,
											style: {
												width: BiometricsTemplate.columnsWidth
													.expandCollapse,
											},
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) => {
													const hasMoreThanOneBiometric =
														(biometrics.data?.get(row.type) ?? [])
															.length > 1;

													if (!hasMoreThanOneBiometric) {
														return null;
													}

													if (
														expandedRow &&
														expandedRow.type === row.type
													) {
														return (
															<UiButton
																label="PARTICIPANT.BIOMETRICS.LIST.collpase"
																type="button"
																className="p-button-text link p-0"
																onClick={() => setExpandedRow(null)}
															/>
														);
													}

													return (
														<UiButton
															label="PARTICIPANT.BIOMETRICS.LIST.expand"
															type="button"
															className="p-button-text link p-0"
															onClick={() => setExpandedRow(row)}
														/>
													);
												},
											},
										},
										{
											field: "edit",
											sortable: false,
											alignment: "right",
											style: { width: BiometricsTemplate.columnsWidth.edit },
											template: TableColumnTemplate.CUSTOM,
											templateOptions: {
												customTemplate: (row: BiometricRow) =>
													BiometricsTemplate.templateActions(
														row,
														(row) => {
															setEditingRow(row);
															setUpdateForm({
																value: row.value.value,
																dateOn: row.dateOn.value
																	? new Date(row.dateOn.value)
																	: null,
															});
														},
														() => {
															setEditingRow(null);
															setUpdateForm({
																value: null,
																dateOn: null,
															});
														},
														submitting,
														onDelete
													),
											},
										},
									]}
									value={rows}
									paginator={false}
									pagination={{
										first: 0,
										rows: 10,
										page: 0,
										sortField: "endDate",
										sortOrder: 1,
										search: undefined,
										filters: undefined,
									}}
									expandedRows={
										expandedRow ? { [expandedRow.type]: true } : undefined
									}
									expandedTemplate={(row: BiometricRow) => (
										<BiometricsNestedList
											goals={biometricGoals.data || []}
											type={row.type}
											biometrics={biometrics.data}
											editingRow={editingRow}
											setEditingRow={setEditingRow}
											updateForm={updateForm}
											setUpdateForm={setUpdateForm}
											submitting={submitting}
											onDeleteBiometric={onDelete}
										/>
									)}
								/>
							</form>
						)}
					/>

					{modalIsVisible && deletingRow && (
						<BiometricsDelete
							isVisible={modalIsVisible}
							row={deletingRow}
							onComplete={() => {
								setDeletingRow(null);
								setModalIsVisible(false);
								queryClient.invalidateQueries([
									"biometrics",
									{ participantId: participantContext.participant!.id },
								]);
								queryClient.invalidateQueries([
									"biometric-goals",
									{ participantId: participantContext.participant!.id },
								]);
							}}
							onClose={() => {
								setDeletingRow(null);
								setModalIsVisible(false);
							}}
						/>
					)}
				</>
			)}
		</>
	);
};
export default BiometricsList;
