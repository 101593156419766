import { UiTableProps } from "./table.model";

export enum TableHeaderCrudMode {
	CREATE = "CREATE",
	UPDATE = "UPDATE",
	DELETE = "DELETE",
}

export interface ITableHeaderCrudAction {
	title?: string;
	tooltip?: string;
	icon?: string;
	fn: () => void;
}

export class TableHeaderCrudAction implements ITableHeaderCrudAction {
	title?: string;
	tooltip?: string;
	icon?: string;
	fn: () => void;

	constructor(params: ITableHeaderCrudAction, action: TableHeaderCrudMode) {
		this.fn = params.fn;
		this.tooltip = params.tooltip;

		switch (action) {
			case TableHeaderCrudMode.CREATE:
				this.title = params.title ?? "UI_COMPONENTS.TABLE.ACTIONS.CRUD_CREATE";
				this.icon = params.icon ?? "pi pi-plus";
				break;
			case TableHeaderCrudMode.UPDATE:
				this.title = params.title ?? "UI_COMPONENTS.TABLE.ACTIONS.CRUD_UPDATE";
				this.icon = params.icon ?? "pi pi-pencil";
				break;
			case TableHeaderCrudMode.DELETE:
				this.title = params.title ?? "UI_COMPONENTS.TABLE.ACTIONS.CRUD_DELETE";
				this.icon = params.icon ?? "pi pi-trash";
				break;
			default:
				break;
		}
	}
}

export interface ITableHeaderCrud {
	create?: ITableHeaderCrudAction;
	update?: ITableHeaderCrudAction;
	delete?: ITableHeaderCrudAction;
}

export class TableHeaderCrud implements ITableHeaderCrud {
	create?: ITableHeaderCrudAction;
	update?: ITableHeaderCrudAction;
	delete?: ITableHeaderCrudAction;

	constructor(params: UiTableProps) {
		this.create =
			params.crud?.create &&
			new TableHeaderCrudAction(params.crud?.create, TableHeaderCrudMode.CREATE);
		this.update =
			params.crud?.update &&
			new TableHeaderCrudAction(params.crud?.update, TableHeaderCrudMode.UPDATE);
		this.delete =
			params.crud?.delete &&
			new TableHeaderCrudAction(params.crud?.delete, TableHeaderCrudMode.DELETE);
	}
}
