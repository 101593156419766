import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useGlucoseHistograms = ({
	participantId,
	startDate,
}: {
	participantId: number;
	startDate: string;
}) => {
	const queryClient = useQueryClient();
	const queryKey = ["glucose-histograms", { participantId, startDate }];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getGlucoseHistograms({
			config: {
				responseType: "blob",
				params: { participantId, startDate, endDate: new Date().toISOString() },
			},
		});
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
