import { useEffect, useState } from "react";
import UiTable from "../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableDateMode,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import {
	IParticipantSUGService,
	SuHistoryRow,
} from "../../../models/pages/service-utilization-goals/participant-sug.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import { useTranslation } from "react-i18next";
import { TableStyle } from "../../../models/components/table/table.model";

const ServiceUtilizationGoalsHistory = ({ props }: { props: IParticipantSUGService[] }) => {
	const { t } = useTranslation("common");

	// Table: columns
	const columns: TableColumn[] = [
		{
			field: "service",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.service",
			sortable: true,
		},
		{
			field: "examType",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.examType",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: {
				translationPrefix: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.EXAMS_TYPE",
			},
		},
		{
			field: "dateOn",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.dateOn",
			sortable: true,
			template: TableColumnTemplate.ESTIMATED_DATE,
			templateOptions: {
				dateMode: TableDateMode.UTC,
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					timeZone: "UTC",
				},
			},
		},
		{
			field: "source",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.source",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.SOURCE" },
		},
		{
			field: "notes",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.notes",
			sortable: true,
		},
	];

	// Table: rows
	const [rows, setRows] = useState<SuHistoryRow[]>([]);

	// Table: pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "dateOn",
		sortOrder: -1,
	});

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	// Table: messages
	const messages = new TableMessages();
	const [message] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// First time load
	useEffect(() => {
		mapData();
	}, [props]);

	// Rows: mapping
	const mapData = (): void => {
		const result: SuHistoryRow[] = [];

		if (props.length > 0) {
			props.forEach((item) => {
				item.history.forEach((event) => {
					const row: SuHistoryRow = {
						id: event.id,
						service: t(item.title),
						examType: event.examType,
						dateOn: event.dateOn,
						source: event.source,
						notes: event.notes ?? Formatter.NO_DATA,
						estimated: event.estimated,
					};
					result.push(row);
				});
			});
		}

		setRows(result);
	};

	return (
		<UiTable
			dataKey="id"
			type={TableStyle.GREEN}
			columns={columns}
			value={rows}
			message={message}
			pagination={pagination}
			paginationFn={onPagination}
		/>
	);
};

export default ServiceUtilizationGoalsHistory;
