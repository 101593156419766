import NotesHistoryPanel from "./NotesHistoryPanel";
import NotesHistoryPanelEdit from "./NotesHistoryPanelEdit";
import NotesHistoryPanelItem from "./NotesHistoryPanelItem";
import NotesHistoryGoals from "./NotesHistoryGoals";
import { NoteProgress } from "../../../../../../models/note-progress";
import { useSmartGoals } from "../../../../../../hooks/custom-goals/useSmartGoals";

const NotesHistoryCoachNote = (props: {
	date: string;
	callerName: string;
	editing: boolean;
	subjective?: string;
	objective?: string;
	assessment?: string;
	stateOfChange?: string;
	plan?: string;
	topics?: string;
	noteProgress: NoteProgress[] | null;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	lastEditionDate?: string;
	onClickEdit: () => void;
}) => {
	return (
		<NotesHistoryPanel
			date={props.date}
			type="PARTICIPANT.DASHBOARD.NOTES.COACHING_CALL"
			callerName={props.callerName}
			editing={props.editing}
		>
			<>
				<NotesHistoryPanelItem
					label="S"
					value={props.subjective}
				/>
				<NotesHistoryPanelItem
					label="O"
					value={props.objective}
				/>
				<NotesHistoryPanelItem
					label="PARTICIPANT.DASHBOARD.NOTES.STATE_OF_CHANGE"
					value={props.stateOfChange}
				/>
				<NotesHistoryPanelItem
					label="A"
					value={props.assessment}
				/>
				<NotesHistoryPanelItem
					label="P"
					value={props.plan}
				/>
				<NotesHistoryPanelItem
					label="PARTICIPANT.DASHBOARD.NOTES.TOPICS"
					value={props.topics}
				/>
				<NotesHistoryGoals
					noteProgress={props.noteProgress}
					smartGoalsQuery={props.smartGoalsQuery}
				/>

				<NotesHistoryPanelEdit
					lastEditionDate={props.lastEditionDate}
					onClickEdit={props.onClickEdit}
				/>
			</>
		</NotesHistoryPanel>
	);
};

export default NotesHistoryCoachNote;
