import { useState } from "react";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { useAppointmentsCoaches } from "../../../hooks/useAppointmentsCoaches";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { useAppointmentsTypes } from "../../../hooks/useAppointmentsTypes";
import UiButton from "../../../components/button/Button";
import AppointmentModal from "../../../components/appointments/AppointmentModal";
import { AppointmentModalMode } from "../../../models/components/appointment-modal.model";
import { PreferredLanguage, Timezone } from "../../../models/entities/participant.model";
import { Roles } from "../../../models/roles.model";

const ParticipantProfileAppointment = ({
	participantContext,
}: {
	participantContext: ParticipantContextProps;
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const phoneNumber = participantContext.participant?.phones?.find((p) => p.isPrimary);

	const appointmentCoaches = useAppointmentsCoaches();
	let coach = appointmentCoaches.data?.find(
		(x) => x.gladstoneId === participantContext.participant?.coachId?.toString()
	);
	// Fallback in case the participant's coach is not assigned
	if (!coach) {
		coach = appointmentCoaches.data?.find(
			(x) => x.gladstoneId === getSessionStorageUser()!.id?.toString()
		);
	}

	const appointmentTypes = useAppointmentsTypes();
	const participant = participantContext.participant!;

	return (
		<>
			{!Roles.isProvider() && (
				<UiButton
					id="modalAppointmentBtn"
					label="APPOINTMENTS.MODAL_APPOINTMENT.TITLE_SCHEDULE"
					type="button"
					className="p-button-rounded p-button-sm"
					onClick={() => setShowModal(true)}
					disabled={
						appointmentCoaches.isLoading ||
						appointmentCoaches.isError ||
						appointmentTypes.isLoading ||
						appointmentTypes.isError
					}
				/>
			)}

			<AppointmentModal
				mode={AppointmentModalMode.SCHEDULE}
				isVisible={showModal}
				participant={{
					id: participant.id,
					kannactId: participant.kannactId,
					firstName: participant.firstName,
					lastName: participant.lastName,
					institution: participant.institution,
					dateOfBirth: participant.dob,
					timezone: participant.timezone ?? Timezone.NA,
					email: participant.emails?.length
						? participant.emails.find((p) => p.isPrimary)!.emailAddress
						: "",
					phone: `${phoneNumber?.countryCode ?? ""}${phoneNumber?.phoneNumber ?? ""}`,
					isSpanish: participant.language === PreferredLanguage.SPANISH,
				}}
				coach={{
					calendarId: coach?.id ?? "",
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				}}
				appointmentTypes={appointmentTypes.data ?? []}
				appointmentCoaches={appointmentCoaches.data ?? []}
				appointment={null}
				onCancel={() => setShowModal(false)}
				onConfirm={(e) => {
					setShowModal(false);
					if (
						e.participant.timezone &&
						(participant.timezone === Timezone.NA || !participant.timezone)
					) {
						participantContext.onRefresh();
					}
				}}
			/>
		</>
	);
};
export default ParticipantProfileAppointment;
