import React from "react";
import { useTranslation } from "react-i18next";
import { UiLinkProps } from "../../models/components/link.model";
import { Link } from "react-router-dom";

const UiLink = (props: UiLinkProps) => {
	const { t } = useTranslation("common");

	const setClass = (): string => {
		let cssClass: string = "k-link ";

		if (props.icon && props.label) cssClass += "has-icon has-label ";
		if (props.disabled) cssClass += "p-disabled ";
		if (props.className) cssClass += `${props.className}`;

		return cssClass;
	};

	return (
		<>
			{/* Link as link or button */}
			{(props.to || props.onClick) && (
				<Link
					id={props.id}
					to={props.to}
					onClick={props.onClick}
					className={setClass()}
					aria-disabled={props.disabled}
				>
					{/* Icon */}
					{props.icon && <i className={`k-link-icon ${props.icon}`}></i>}

					{/* Label */}
					{props.label && <span className="k-link-label">{t(props.label)}</span>}
				</Link>
			)}
		</>
	);
};

export default UiLink;
