import { useTranslation } from "react-i18next";
import { GoalIndicatorProps } from "../../models/components/goal-indicator.model";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillQuestionCircle } from "react-icons/ai";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { MdAccessTimeFilled } from "react-icons/md";
import { ReactElement } from "react";
import { Tooltip } from "primereact/tooltip";
import { GoalState } from "../../models/entities/goal.model";
import { classNames } from "primereact/utils";
import { GoalsService } from "../../services/goals/goals.service";
import { ImSpinner3 } from "react-icons/im";

const GoalIndicator = (props: GoalIndicatorProps): ReactElement => {
	const { t } = useTranslation("common");

	const getIcon = () => {
		let icon = <></>;

		switch (props.goal.state) {
			case GoalState.on_track:
				icon = (
					<AiFillCheckCircle
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			case GoalState.off_track:
				icon = (
					<AiFillCloseCircle
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			case GoalState.no_data:
				icon = (
					<AiFillQuestionCircle
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			case GoalState.expired:
				icon = (
					<MdAccessTimeFilled
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			case GoalState.fallback:
				icon = (
					<ImSpinner3
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			case GoalState.due_soon:
				icon = (
					<BsFillExclamationCircleFill
						className={classNames("pb-1 ms-1", getSeverity())}
						fontSize={"22px"}
					/>
				);
				break;
			default:
				break;
		}

		return icon;
	};

	const getSeverity = () => {
		let severity = "";

		switch (props.goal.state) {
			case GoalState.on_track:
				severity = "text-success";
				break;
			case GoalState.off_track:
				severity = "text-danger";
				break;
			case GoalState.no_data:
				severity = "text-warning";
				break;
			case GoalState.expired: // No special colour applied for this state
				break;
			case GoalState.fallback:
				severity = "text-gray";
				break;
			case GoalState.due_soon:
				severity = "text-yellow";
				break;
			default:
				break;
		}

		return severity;
	};

	const mapData = () => {
		const result = {
			icon: getIcon(),
			description: (
				<span className={classNames("mx-1", getSeverity())}>
					{t(GoalsService.getDescription(props.goal))}
				</span>
			),
			tooltip: GoalsService.getTooltip(props.goal),
		};
		return result;
	};

	const data = mapData();

	return (
		<>
			<span
				id={props.goal.goalName}
				className="goal-indicator"
			>
				{props.detailed === true && data.description}
				{data.icon}
			</span>
			<Tooltip
				target={`#${props.goal.goalName}`}
				position="bottom"
				content={t(data.tooltip)}
			/>
		</>
	);
};

export default GoalIndicator;
