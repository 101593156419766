import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useNextAppointment = ({ kannactId, email }: { kannactId: string; email?: string }) => {
	const queryClient = useQueryClient();
	const queryKey = ["next-appointment", { kannactId, email }];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.appointments.getAppointmentsByParticipant({
			config: {
				params: {
					minDate: null,
					maxDate: null,
					email,
					kannactId,
				},
			},
		});
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
