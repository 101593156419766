import { ReactElement } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { Route } from "../../models/routing.model";
import { getSessionStorageUser, validCookieToken } from "../session/auth.service";
import { RolesService } from "../session/roles.service";

/**
 * Format route data
 * @description Formats the route data, taking into account if the route is nested by the ProtectedRoute
 */
export const getRoute = (route: RouteObject): Route | undefined => {
	const routeElement: React.ReactNode = route?.element as ReactElement;
	let item: Route | undefined;

	if (
		routeElement?.props?.children &&
		RolesService.hasValidRoles(
			routeElement?.props?.deniedRoles,
			routeElement?.props?.allowedRoles
		)
	) {
		// Has nested route (uses ProtectedRoute)
		item = {
			route: {
				path: route.path,
				element: routeElement.props.children,
			},
			routeProps: routeElement.props.children.props,
		};
	} else if (
		!routeElement?.props?.children &&
		RolesService.hasValidRoles(
			routeElement?.props?.deniedRoles,
			routeElement?.props?.allowedRoles
		)
	) {
		// Doesn't have nested routes
		item = { route: route, routeProps: routeElement?.props };
	}

	return item;
};

/**
 * Routes: protected
 * @description Protect routes so that they are not accessible when the user is not logged in
 */

export const ProtectedRoute = ({
	children,
	allowedRoles,
	deniedRoles,
}: {
	children: JSX.Element;
	allowedRoles?: number[];
	deniedRoles?: number[];
}) => {
	// Verify authentication
	if (!validCookieToken() || !getSessionStorageUser())
		return (
			<Navigate
				to="/login"
				replace
			/>
		);

	// Verify allowed and denied roles
	if (!RolesService.hasValidRoles(deniedRoles, allowedRoles)) return <Navigate to="/" />;

	return children;
};
