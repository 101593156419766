import { useTranslation } from "react-i18next";
import { Card } from "primereact/card";
import { IParticipantSUGService } from "../../../models/pages/service-utilization-goals/participant-sug.model";
import UiButton from "../../../components/button/Button";
import ServiceUtilizationGoalsStatus from "./ServiceUtilizationGoalsStatus";
import ServiceUtilizationGoalsRecentHistory from "./ServiceUtilizationGoalsRecentHistory";
import ServiceUtilizationGoalsDataEntry from "./ServiceUtilizationGoalsDataEntry";
import { CreateServiceUtilizationEntryRequest } from "../../../models/endpoints/service-utilization-goals-endpoint.model";

const ServiceUtilizationGoals = ({
	props,
}: {
	props: IParticipantSUGService & {
		onSubmitDataEntry: (
			createSUEntryRequest: CreateServiceUtilizationEntryRequest
		) => Promise<void>;
		onClickMarkApplicabilityButton: () => void;
		onClickMarkAsNeverReceived: () => void;
	};
}) => {
	const { t } = useTranslation("common");

	// Header
	const headerTemplate = (): React.ReactNode => {
		return (
			<>
				{/* Service name */}
				<span className="sug-title">{t(props.title)}</span>

				{/* Service date */}
				<ServiceUtilizationGoalsStatus props={props} />

				{props.eligible && (
					<UiButton
						label={
							props.applicable
								? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.BUTTON_NON_APPLICABLE"
								: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.BUTTON_APPLICABLE"
						}
						className="p-button-sm p-button-rounded"
						onClick={props.onClickMarkApplicabilityButton}
					/>
				)}
			</>
		);
	};

	return (
		<Card
			header={headerTemplate}
			className="sug-card"
		>
			{/* FORM */}
			<div className="sug-form">
				<div className="sug-content-header">
					{t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.FORM_TITLE")}
				</div>
				<ServiceUtilizationGoalsDataEntry props={props} />
			</div>

			{/* RECENT HISTORY */}
			<div className="sug-history">
				<div className="sug-content-header">
					{t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY_TITLE")}
				</div>
				<ServiceUtilizationGoalsRecentHistory props={props} />
			</div>
		</Card>
	);
};

export default ServiceUtilizationGoals;
