import { ConsentedHealthReport, ParticipantProfile } from "../../entities/participant.model";

export class PtSubscriptionsForm {
	programUpdatesAndNewsletters: boolean;
	deviceOutOfRangeAlerts: boolean;
	remindersMedicalDevices: boolean;
	remindersScheduleCoachingCalls: boolean;
	programChanges: boolean;
	consentedSms: boolean;
	consentedHealthReport: ConsentedHealthReport | null;

	constructor(params: ParticipantProfile) {
		this.programUpdatesAndNewsletters = true;
		this.deviceOutOfRangeAlerts = true;
		this.remindersMedicalDevices = true;
		this.remindersScheduleCoachingCalls = true;
		this.programChanges = true;
		this.consentedSms = params.consentedSms;
		this.consentedHealthReport = params.consentedHealthReport;

		const unsubscriptions = params.unsubscriptions || [];
		if (unsubscriptions.includes("UNSUBSCRIBE_CE_UNSUBSCRIBE_TO_ALL_EMAILS_ABOVE")) {
			this.programUpdatesAndNewsletters = false;
			this.deviceOutOfRangeAlerts = false;
			this.remindersMedicalDevices = false;
			this.remindersScheduleCoachingCalls = false;
			this.programChanges = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_REMINDERS_TO_SCHEDULE_COACH_CALLS")) {
			this.remindersScheduleCoachingCalls = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_DEVICE_OUT_OF_RANGE_ALERTS")) {
			this.deviceOutOfRangeAlerts = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_PROGRAM_UPDATES_NEWSLETTERS")) {
			this.programUpdatesAndNewsletters = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_REMINDERS_TO_USE_MEDICAL_DEVICES")) {
			this.remindersMedicalDevices = false;
		}

		if (unsubscriptions.includes("UNSUBSCRIBE_CE_ENROLLMENT_MARKETING")) {
			this.programChanges = false;
		}
	}
}
