import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import {
	CustomGoalsFormMode,
	CustomGoalType,
} from "../../../../../models/custom-goals/custom-goals-misc";
import CustomGoalsPastTable from "./CustomGoalsPastTable";

const CustomGoalsPast = ({
	smartGoalsQuery,
	longTermGoalsQuery,
	onUpdate,
}: {
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onUpdate: (e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => void;
}) => {
	return (
		<>
			<CustomGoalsPastTable
				type={CustomGoalType.SMART_GOAL}
				smartGoalsQuery={smartGoalsQuery}
				longTermGoalsQuery={longTermGoalsQuery}
				onUpdate={onUpdate}
			/>

			<CustomGoalsPastTable
				type={CustomGoalType.LONG_TERM_GOAL}
				smartGoalsQuery={smartGoalsQuery}
				longTermGoalsQuery={longTermGoalsQuery}
				onUpdate={onUpdate}
			/>
		</>
	);
};
export default CustomGoalsPast;
