import React from "react";
import { useTranslation } from "react-i18next";
import { FieldMetaState } from "react-final-form";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../models/components/field-message.model";

const UiFieldErrors = (props: FieldMetaState<any>) => {
	const { t } = useTranslation("common");

	const checkMessageStructure = (errorKey: string): React.ReactNode => {
		let template;
		if (typeof props.error[String(errorKey)] === "string") {
			template = (
				<UiFieldMessage
					key={errorKey}
					severity={FieldMessageSeverity.DANGER}
					label={t(props.error[String(errorKey)])}
				/>
			);
		} else {
			template = (
				<UiFieldMessage
					key={errorKey}
					severity={FieldMessageSeverity.DANGER}
					label={t(
						props.error[String(errorKey)].key,
						props.error[String(errorKey)].params
					)}
				/>
			);
		}
		return template;
	};

	return (
		<>
			{props.error &&
				!!(props.touched && props.error) &&
				Object.keys(props.error).map((key, index) => checkMessageStructure(key))}
		</>
	);
};

export default UiFieldErrors;
