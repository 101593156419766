import { Trans, useTranslation } from "react-i18next";
import UiButton from "../button/Button";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { useState } from "react";
import { FieldMessageSeverity } from "../../models/components/field-message.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { ModalReminderDeleteProps } from "../../models/components/modal-reminder.model";

const ModalReminderDelete = (props: ModalReminderDeleteProps) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const submit = async (): Promise<void> => {
		setMessage(false);
		setSubmitting(true);
		await EndpointsService.dataRetriever
			.deleteReminder({ id: props.entity!.id! })
			.then(() => props.onComplete())
			.catch(() => setMessage(true))
			.finally(() => setSubmitting(false));
	};

	return (
		<>
			<div className="mb-4">
				<Trans
					i18nKey="UI_COMPONENTS.MODAL_REMINDER.MSG_DELETE"
					defaults={t("UI_COMPONENTS.MODAL_REMINDER.MSG_DELETE")}
					values={{
						name: Formatter.fullName(props.entity!.firstName, props.entity!.lastName),
						kannactId: props.entity!.kannactId,
						dateOn: Formatter.date(props.entity!.dateOn, {
							day: "2-digit",
							month: "long",
							year: "numeric",
							weekday: "long",
						}),
					}}
					components={{ bold: <span className="text-bold" /> }}
				/>
			</div>

			{message && (
				<div className="form-message">
					<UiFieldMessage
						severity={FieldMessageSeverity.DANGER}
						label="UI_COMPONENTS.MODAL_REMINDER.MESSAGE_ERROR_DELETE"
					/>
				</div>
			)}

			<div className="action-buttons">
				<UiButton
					label="UI_COMPONENTS.BUTTONS.CANCEL"
					className="p-button-outlined p-button-rounded"
					type="button"
					onClick={props.onCancel}
					disabled={submitting}
				/>
				<UiButton
					label={
						submitting
							? "UI_COMPONENTS.BUTTONS.DELETING"
							: "UI_COMPONENTS.BUTTONS.CONFIRM"
					}
					className="p-button-rounded"
					type="button"
					onClick={submit}
					loading={submitting}
					disabled={submitting}
				/>
			</div>
		</>
	);
};
export default ModalReminderDelete;
