import { useState } from "react";
import UiTable from "../../../components/table/Table.component";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import {
	TableColumn,
	TableColumnTemplate,
	TableDateMode,
} from "../../../models/components/table/table-column.model";
import { useProcedures } from "../../../hooks/useProcedures";
import { TableStyle } from "../../../models/components/table/table.model";

const ProcedureHistory = ({ participantId }: { participantId: number }) => {
	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "dateOn",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.dateOn",
			sortable: true,
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateMode: TableDateMode.UTC,
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					timeZone: "UTC",
				},
			},
		},
		{
			field: "status",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.status",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.PROCEDURES_STATUS" },
		},
		{
			field: "type",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.type",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.PROCEDURES_TYPES" },
		},
		{
			field: "notes",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.notes",
			sortable: true,
		},
	];

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "dateOn",
		sortOrder: 1,
		search: undefined,
		filters: undefined,
	});

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	const proceduresQuery = useProcedures(participantId);

	return (
		<UiTable
			dataKey="id"
			useAsCard={false}
			type={TableStyle.GREEN}
			columns={columns}
			value={proceduresQuery.data?.map((x) => x) || []}
			message={message}
			pagination={pagination}
			paginationFn={(event: TablePagination) => {
				const newPagination: TablePagination = {
					...pagination,
					page: event.page,
					rows: event.rows,
					sortField: event.sortField,
					sortOrder: event.sortOrder,
					first: event.first,
				};
				setPagination(newPagination);
			}}
		/>
	);
};

export default ProcedureHistory;
