import HttpService from "../http/http.service";
import {
	AppointmentCreateRequest,
	AppointmentDatesRequest,
	AppointmentDatesResponse,
	AppointmentTimeSlotsRequest,
	AppointmentTimeSlotsResponse,
	AppointmentUpdateRequest,
	AppointmentsBlocksRequest,
	AppointmentsByParticipantRequest,
	AppointmentsRequest,
	CancelAppointmentRequest,
} from "../../models/endpoints/appointments-endpoint.model";
import {
	Appointment,
	AppointmentBlock,
	AppointmentBlocksTypes,
	AppointmentCoach,
	AppointmentTypes,
} from "../../models/entities/appointments.model";
import { v4 } from "uuid";
import { EndpointRequest } from "../../models/endpoints/endpoints.model";

const httpService = new HttpService(process.env.REACT_APP_API_APPOINTMENTS!);

// Add the header "request-id"
const addHeader = (request?: EndpointRequest) => {
	let result = request;
	if (!result) {
		result = {
			config: {
				headers: { "request-id": v4() },
			},
		};
	} else {
		result.config = {
			...result.config,
			headers: { "request-id": v4() },
		};
	}
	return result;
};

// Appointments (Acuity) endpoints
export const AppointmentsEndpointsService = {
	getAppointments: async (request: AppointmentsRequest): Promise<Appointment[]> =>
		httpService.get("/coach-appointments", addHeader(request)),
	getAppointmentsByParticipant: async (
		request: AppointmentsByParticipantRequest
	): Promise<Appointment[]> => httpService.get("/appointments", addHeader(request)),
	createAppointment: async (request: AppointmentCreateRequest): Promise<Appointment> =>
		httpService.post("/appointments", addHeader(request)),
	updateAppointment: async (request: AppointmentUpdateRequest): Promise<Appointment> =>
		httpService.post("/rescheduled-appointments", addHeader(request)),
	cancelAppointment: async (request: CancelAppointmentRequest): Promise<Appointment> =>
		httpService.post(`/cancelled-appointments`, addHeader(request)),
	getDates: async (request: AppointmentDatesRequest): Promise<AppointmentDatesResponse[]> =>
		httpService.get(`/available-dates`, addHeader(request)),
	getTimeSlots: async (
		request: AppointmentTimeSlotsRequest
	): Promise<AppointmentTimeSlotsResponse[]> =>
		httpService.get(`/available-time-slots`, addHeader(request)),
	getCoaches: async (): Promise<AppointmentCoach[]> =>
		httpService.get(`/gladstone-coaches`, addHeader()),
	getTypes: async (): Promise<AppointmentTypes[]> =>
		httpService.get(`/appointment-types`, addHeader()),
	getBlockTypes: async (): Promise<AppointmentBlocksTypes[]> =>
		httpService.get(`/call-block-types`, addHeader()),
	getBlocks: async (request: AppointmentsBlocksRequest): Promise<AppointmentBlock[]> =>
		httpService.get(`/coach-call-blocks`, addHeader(request)),
};
