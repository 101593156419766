import { MenuItem } from "primereact/menuitem";
import { UiButtonProps } from "../button.model";
import { UiLinkProps } from "../link.model";
import { UiTagProps } from "../tag.model";

// Template: Tag
export type TableTemplateTag = UiTagProps;

// Template: Link
export type TableTemplateLink = UiLinkProps;

// Template: Translate
export type TableTemplateTranslate = { key: string; params?: any };

// Template: Twilio audio
export type TableTemplateTwilioAudio = {
	callId: string | null;
	callDate: string | null;
	kannactId: string | null;
	mediaUrl: string | null;
};

// Template: Actions
export type TableTemplateActions = MenuItem[];

// Template: buttons
export type TableTemplateButtons = UiButtonProps[];
export type TableTemplateButtonsEvent = {
	columnId: string;
	row: any;
	buttonProps: UiButtonProps;
};

// Template: kannact id
export class TableTemplateKannactId {
	props: TableTemplateLink;

	constructor(kannactId: string) {
		this.props = this.setTemplate(kannactId);
	}

	private setTemplate = (kannactId: string): TableTemplateLink => {
		const value: TableTemplateLink = {
			id: kannactId,
			to: `/participant/${kannactId.slice(3, kannactId.length)}/dashboard`,
			label: kannactId,
		};
		return value;
	};
}

export type TableTemplateText = {
	className: string;
	value: string | null | undefined;
};
