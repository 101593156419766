import { ColumnAlignType, ColumnBodyOptions, ColumnSortParams } from "primereact/column";
import React from "react";

export type TableDateFormat = Intl.DateTimeFormatOptions;
export enum TableDateMode {
	LOCAL = "LOCAL",
	UTC = "UTC",
}

export enum TableColumnTemplate {
	DATE = "DATE",
	PHONE = "PHONE",
	COACH = "COACH",
	PHONE_CALL = "PHONE_CALL",
	TRANSLATE_PREFIX = "TRANSLATE_PREFIX",
	TRANSLATE = "TRANSLATE",
	TAG = "TAG",
	CURRENCY = "CURRENCY",
	LINK = "LINK",
	ACTIONS = "ACTIONS",
	DURATION = "DURATION",
	TWILIO_AUDIO = "TWILIO_AUDIO",
	AUDIO = "AUDIO",
	BUTTONS = "BUTTONS",
	KANNACT_ID = "KANNACT_ID",
	ESTIMATED_DATE = "ESTIMATED_DATE",
	CUSTOM_ACTIONS = "CUSTOM_ACTIONS",
	CUSTOM = "CUSTOM",
	EXPANDER = "EXPANDER",
	TEXT = "TEXT",
}

export interface TemplateOptions {
	dateFormat?: TableDateFormat; // Template: DATE
	dateMode?: TableDateMode; // Template: DATE

	translationPrefix?: string; // Template: TRANSLATE

	customTemplate?: (row: any, colBody: ColumnBodyOptions) => React.ReactNode; // Template: CUSTOM

	currency?: "USD" | "EUR"; // Template: CURRENCY

	empty?: boolean;

	expandedLabel?: string; // Template: EXPANDER
	collapsedLabel?: string; // Template: EXPANDER
	expanderTemplate?: (
		isExpanded: boolean,
		toggleExpand: (row: any, colBody: ColumnBodyOptions) => void
	) => React.ReactNode; // Template: EXPANDER
}

export interface TableColumn {
	field: string; // Column ID: maps the row[field] value
	title?: string; // Displayed column name
	template?: TableColumnTemplate;
	templateOptions?: TemplateOptions;
	sortable?: boolean;
	onSort?: (e: ColumnSortParams) => void;
	alignment?: ColumnAlignType;
	style?: React.CSSProperties;
	sortingField?: string;
}
