import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";
import ConditionsCreate from "./ConditionsCreate";
import UiButton from "../../../../components/button/Button";
import { ConditionsModalMode } from "../../../../models/pages/conditions/conditions-modal.model";
import ConditionsSelfReported from "./ConditionsSelfReported";
import ConditionsExternal from "./ConditionsExternal";
import { useConditions } from "./useConditions";

const ConditionsModal = ({
	modalMode,
	participantId,
	closeModal,
	isVisible,
}: {
	modalMode: ConditionsModalMode;
	participantId: number;
	closeModal: () => void;
	isVisible: boolean;
}) => {
	const { t } = useTranslation("common");

	const [mode, setMode] = useState<ConditionsModalMode>(modalMode);

	const conditions = useConditions({ participantId });

	return (
		<Dialog
			header={
				<div className="dialog-custom-header">
					{/* Title */}
					<span className="dialog-custom-header__title">
						{t("PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.TITLE")}
					</span>

					{/* Actions */}
					{mode === ConditionsModalMode.READ && (
						<div className="dialog-custom-header__actions">
							<UiButton
								label="PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.ACTION_CREATE"
								className="p-button-sm p-button-rounded"
								icon="pi pi-plus"
								type="button"
								onClick={() => setMode(ConditionsModalMode.CREATE)}
							/>
						</div>
					)}
				</div>
			}
			visible={isVisible}
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: "80vw" }}
			className="conditions-modal"
			modal={true}
			focusOnShow={false}
			onHide={closeModal}
		>
			{/* Create */}
			{mode === ConditionsModalMode.CREATE && (
				<ConditionsCreate
					conditionsQuery={conditions}
					participantId={participantId}
					onCancel={() => setMode(ConditionsModalMode.READ)}
					onComplete={() => {
						setMode(ConditionsModalMode.READ);
						conditions.invalidate();
					}}
				/>
			)}

			{/* Read/Update */}
			{mode === ConditionsModalMode.READ && (
				<TabView>
					{/* Conditions: Self-reported */}
					<TabPanel
						header={t("PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.TAB_TITLE_SELF_REPORTED")}
					>
						<ConditionsSelfReported conditionsQuery={conditions} />
					</TabPanel>

					{/* Conditions: External */}
					<TabPanel
						header={t("PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.TAB_TITLE_EXTERNAL")}
					>
						<ConditionsExternal conditionsQuery={conditions} />
					</TabPanel>
				</TabView>
			)}
		</Dialog>
	);
};
export default ConditionsModal;
