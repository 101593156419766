// Lists (select options, etc.)
export type List = ListOption[];

export type ListOptionValue = string | number | boolean;

export interface IListOption {
	id: ListOptionValue;
	label: string | number;
	className?: string;
	disabled?: boolean;
}

export class ListOption implements IListOption {
	id: ListOptionValue;
	label: string | number;
	className?: string;
	disabled?: boolean;

	constructor(params: IListOption) {
		this.id = params.id;
		this.label = params.label;
		this.className = params.className;
		this.disabled = params.disabled ?? false;
	}
}
