import { useLongTermGoals } from "./../../hooks/custom-goals/useLongTermGoals";
import { useSmartGoals } from "../../hooks/custom-goals/useSmartGoals";
import { TableMessages } from "../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";
import {
	TableColumn,
	TableColumnTemplate,
	TableDateMode,
} from "../../models/components/table/table-column.model";
import { CustomGoalType } from "../../models/custom-goals/custom-goals-misc";

const setColumnsPastGoals = (type: CustomGoalType) => {
	const columns: TableColumn[] = [
		colName,
		colStartDate,
		colEndDate,
		colStatus,
		colUpdatedOn,
		colExpandCollapse,
		colActions,
	];

	if (type === CustomGoalType.SMART_GOAL) {
		columns.splice(3, 0, colCategory);
	}

	return columns;
};

const setColumnsCurrentGoals = (type: CustomGoalType) => {
	const columns: TableColumn[] = [colName, colStartDate];

	switch (type) {
		case CustomGoalType.SMART_GOAL:
			columns.push(colEndDate, colCategory, colComplete, colExpandCollapse, colActions);
			break;
		case CustomGoalType.LONG_TERM_GOAL:
			columns.push(
				colEndDate,
				{
					field: "category",
					sortable: false,
					style: {
						minWidth: columnsWidthCurrentTable.category,
					},
				},
				colComplete,
				colExpandCollapse,
				colActions
			);
			break;
		case CustomGoalType.MAINTENANCE_GOAL:
			columns.push(
				{
					field: "endDate",
					style: {
						width: columnsWidthCurrentTable.endDate,
					},
				},
				colCategory,
				colUpdatedOn,
				colExpandCollapse,
				Object.assign({}, colActions, { alignment: "right" })
			);
			break;
	}

	return columns;
};

const setTableMessage = (
	query: ReturnType<typeof useSmartGoals | typeof useLongTermGoals>
): UiFieldMessageProps => {
	const messages = new TableMessages();
	let result: UiFieldMessageProps = {
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	};

	if (query.isLoading) {
		result = {
			severity: FieldMessageSeverity.INFO,
			label: messages.loading,
		};
	}

	if (query.isError) {
		result = {
			severity: FieldMessageSeverity.DANGER,
			label: messages.error,
		};
	}

	if ((!query.isLoading || !query.isError) && !query.data) {
		result = {
			severity: FieldMessageSeverity.DANGER,
			label: messages.error,
		};
	}

	return result;
};

const columnsWidthCurrentTable = {
	name: "150px",
	startDate: "150px",
	endDate: "150px",
	category: "200px",
	updatedOn: "200px",
	complete: "160px",
	status: "150px",
	expandCollapse: "120px",
	actions: "150px",
};

const colName: TableColumn = {
	field: "name",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.name",
	sortable: false,
	template: TableColumnTemplate.TEXT,
	style: {
		width: columnsWidthCurrentTable.name,
	},
};

const colStartDate: TableColumn = {
	field: "startDate",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.startDate",
	sortable: true,
	template: TableColumnTemplate.DATE,
	templateOptions: {
		dateMode: TableDateMode.UTC,
		dateFormat: {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
			timeZone: "UTC",
		},
	},
	style: {
		width: columnsWidthCurrentTable.startDate,
	},
};

const colEndDate: TableColumn = {
	field: "endDate",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.endDate",
	sortable: true,
	template: TableColumnTemplate.DATE,
	templateOptions: {
		dateMode: TableDateMode.UTC,
		dateFormat: {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
			timeZone: "UTC",
		},
	},
	style: {
		width: columnsWidthCurrentTable.endDate,
	},
};

const colCategory: TableColumn = {
	field: "category",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.category",
	sortable: true,
	style: {
		minWidth: columnsWidthCurrentTable.category,
	},
};

const colComplete: TableColumn = {
	field: "complete",
	template: TableColumnTemplate.BUTTONS,
	alignment: "center",
	style: {
		width: columnsWidthCurrentTable.complete,
	},
};

const colStatus: TableColumn = {
	field: "status",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.status",
	sortable: true,
	template: TableColumnTemplate.TEXT,
	style: {
		width: columnsWidthCurrentTable.status,
	},
};

const colUpdatedOn: TableColumn = {
	field: "updatedOn",
	title: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.updatedOn",
	sortable: true,
	template: TableColumnTemplate.DATE,
	templateOptions: {
		dateMode: TableDateMode.UTC,
		dateFormat: {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
			timeZone: "UTC",
		},
	},
	style: {
		width: columnsWidthCurrentTable.updatedOn,
	},
};

const colExpandCollapse: TableColumn = {
	field: "expandCollapse",
	template: TableColumnTemplate.EXPANDER,
	style: {
		width: columnsWidthCurrentTable.expandCollapse,
	},
};

const colActions: TableColumn = {
	field: "actions",
	template: TableColumnTemplate.BUTTONS,
	alignment: "center",
	style: {
		width: columnsWidthCurrentTable.actions,
	},
};

export const CustomGoalsService = {
	setTableMessage,
	setColumnsPastGoals,
	setColumnsCurrentGoals,
	columnsWidthCurrentTable,
};
