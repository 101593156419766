import { Route } from "../../../models/routing.model";
import { Panel } from "primereact/panel";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink, useParams } from "react-router-dom";
import { getSidebarMenu } from "../../../services/sidebar/sidebar.service";
import SidebarResponsiveSubmodule from "./SidebarResponsiveSubmodule";

const SidebarResponsiveModule = ({
	modules,
	pathname,
	onClose,
}: {
	modules: Route[];
	pathname: string;
	onClose: (isVisible: boolean) => void;
}) => {
	const { t } = useTranslation("common");
	const urlParams = useParams();

	const filterModules = (route: Route): boolean => {
		let result = false;

		if (route.routeProps.isModule && route.routeProps.isVisible !== false) {
			result = true;
		} else if (route.routeProps.isVisible === false && Object.keys(urlParams).length > 0) {
			// Modules that are not visible (they are accessed through links, like the pt dashboard)
			const urlFormatted =
				route.route.path && urlParams
					? generatePath(route.route.path, { ...urlParams })
					: route.route.path!;
			const basePath = getSidebarMenu(pathname).basePath.slice(1);

			if (urlFormatted === basePath) {
				result = true;
			}
		}

		return result;
	};

	return (
		<>
			{modules
				.filter((item) => filterModules(item))
				.map((item) => (
					<Panel
						key={item.routeProps.id}
						header={
							<NavLink
								to={`${item.route?.path}`}
								className={({ isActive }) =>
									isActive ? "menu-item active" : "menu-item"
								}
								onClick={() => onClose(false)}
							>
								{t(`${item.routeProps?.title}`)}
							</NavLink>
						}
						toggleable={item.route.children && item.route.children.length > 0}
						collapsed={true}
						className={classNames(
							"menu-option",
							item.route.children && item.route.children.length > 0 ? "" : "empty"
						)}
					>
						{item.route.path && item.route.children && (
							<SidebarResponsiveSubmodule
								module={item}
								onClose={onClose}
							/>
						)}
					</Panel>
				))}
		</>
	);
};
export default SidebarResponsiveModule;
