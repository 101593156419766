import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import SidebarDesktop from "../../components/sidebar/SidebarDesktop";
import { AuthRoles, Roles } from "../../models/roles.model";
import { SessionCoach } from "../../models/session.model";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { getFunctionalModules } from "../../services/header/header.service";
import { setSession, setSessionStorageUser } from "../../services/session/auth.service";
import { getSidebarMenu } from "../../services/sidebar/sidebar.service";
import { StoredCriteriaService } from "../../services/session/stored-criteria.service";

const Callback = () => {
	const auth0 = useAuth0();
	const navigate = useNavigate();

	// Login failed
	//  the unauthorized_to_signin happens when the user is not a kannact employee
	//    an auth0 action hooked into the login flow is responsible for this
	//  for any other error we group them with the "unknown" error type,
	//    to debug these errors auth0 has a logs section in the dashboard.
	if (auth0.error) {
		const errorType =
			auth0.error?.message === "unauthorized_to_signin"
				? "unauthorized_to_signin"
				: "unknown";

		auth0.logout({
			logoutParams: {
				returnTo: window.location.origin + `/login?errorType=${errorType}`,
			},
		});
	}

	// Login succeeded
	if (auth0.isAuthenticated) {
		auth0.getAccessTokenSilently().then(async (token) => {
			setSession(new SessionCoach({ email: auth0.user?.email }), token);

			try {
				const response = await EndpointsService.dataRetriever.getProviderLogged();

				// TODO: Refactor how the app session is stored/handled.
				//  The code related to handling the app session has not been changed yet with the Auth0 migration.
				//  We need to update this code, simplify it and avoid losing the app session each time the browser tab is closed.
				// IMPORTANT: This refactor will also need to take into account Twilio tokens and in progress-calls.
				//  In the past there were some issues with Twilio when we had multiple tabs open with the same user.
				setSessionStorageUser({
					...response,
					adminView:
						response.role === AuthRoles.ADMIN ||
						response.role === AuthRoles.COACH_COORDINATOR ||
						response.role === AuthRoles.COACH ||
						response.role === AuthRoles.PROVIDER,
				});
				StoredCriteriaService.deleteAll();

				navigate(
					Roles.isProvider() ? "/participants/dashboard" : "/call-center/dashboard",
					{ replace: true }
				);
			} catch (_) {
				auth0.logout({
					logoutParams: {
						returnTo: window.location.origin + "/login?errorType=unknown",
					},
				});
			}
		});
	}

	// This is a guard. If the user is not authenticated and there's no error, it means that the user accessed directly the callback page.
	//  In this case we should reditect to the login page
	if (!auth0.isAuthenticated && !auth0.error && !auth0.isLoading) {
		navigate("/login", { replace: true });
	}

	return <FakeAuthenticatedUserLayout />;
};

/**
 * Changing the secret provided by Auth0 for an access token (last step in the login flow)
 *  is done by their SDK but it takes sometime.
 *
 * Meanwhile we're doing this we want to show a fake layout to the user (non clickable header + empty content)
 *  simulating that he's already authenticated.
 *  By doing this the perceived speed of the app will be faster on the eyes of the user.
 */
const FakeAuthenticatedUserLayout = () => (
	<div style={{ pointerEvents: "none" }}>
		<Header props={[]} />
	</div>
);

export default Callback;
