import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import {
	ModalEngagementData,
	ModalEngagementMode,
	UiModalEngagementProps,
} from "../../models/components/modal-engagement.model";
import UiButton from "../button/Button";
import { Form } from "react-final-form";
import UiInputDate from "../input-date/InputDate.component";
import UiSelect from "../select/Select.component";
import { List } from "../../models/misc.model";
import { Validations } from "../../services/form/validations.service";
import { FormApi } from "final-form";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { mappedAxiosError } from "../../services/http/http.service";
import { AxiosError } from "axios";
import {
	eEngagementDetails,
	engagementType,
	IEngagement,
	EngagementType,
} from "../../models/entities/engagement.model";
import UiInputText from "../input-text/InputText.component";
import { DropdownChangeParams } from "primereact/dropdown";
import { getSessionStorageUser } from "../../services/session/auth.service";
import UiInputTextarea from "../input-textarea/InputTextarea.component";
import FormSection from "../form-section/FormSection.component";

const UiModalEngagement = (props: UiModalEngagementProps) => {
	const { t } = useTranslation("common");

	// Modal title
	const setModalTitle = (): string =>
		props.mode === ModalEngagementMode.NON_CALL
			? t("UI_COMPONENTS.MODAL_ENGAGEMENT.TITLE_MODE_1")
			: t("UI_COMPONENTS.MODAL_ENGAGEMENT.TITLE_MODE_2");

	// Engagement type: options list
	const typeList: List = [
		{ id: engagementType.EMAIL, label: "ENUMS.ENGAGEMENT_TYPE.EMAIL" },
		{ id: engagementType.CARE_MESSAGE, label: "ENUMS.ENGAGEMENT_TYPE.CARE_MESSAGE" },
	];

	// Participants (when multiple particiants have the same phone number)
	const participantList: List = props.participantList ?? [];

	// Outcome: Successful/Unsuccessful
	const outcomeList: List = [
		{ id: true, label: "UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.outcomeList.true" },
		{ id: false, label: "UI_COMPONENTS.CALL_MANAGEMENT.ENGAGEMENT.outcomeList.false" },
	];

	// Details: The coach talked to the participant
	const detailsList1: List = [
		{ id: "COACHING_SESSION", label: "ENUMS.ENGAGEMENT_DETAILS.COACHING_SESSION" },
		{ id: "TECH_SUPPORT", label: "ENUMS.ENGAGEMENT_DETAILS.TECH_SUPPORT" },
		{ id: "RESCHEDULED", label: "ENUMS.ENGAGEMENT_DETAILS.RESCHEDULED" },
		{ id: "PT_UNENROLLED", label: "ENUMS.ENGAGEMENT_DETAILS.PT_UNENROLLED" },
		{ id: "CONNECTION_ISSUES", label: "ENUMS.ENGAGEMENT_DETAILS.CONNECTION_ISSUES" },
		{ id: "INTRO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INTRO_CALL" },
		{ id: "INFO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INFO_CALL" },
		{ id: "OTHER", label: "ENUMS.ENGAGEMENT_DETAILS.OTHER" },
	];

	// Details: The coach didn't talk to tthe participant
	const detailsList2: List = [
		{ id: "LEFT_VOICEMAIL", label: "ENUMS.ENGAGEMENT_DETAILS.LEFT_VOICEMAIL" },
		{ id: "VOICEMAIL_PROBLEM", label: "ENUMS.ENGAGEMENT_DETAILS.VOICEMAIL_PROBLEM" },
		{ id: "INCORRECT_PHONE", label: "ENUMS.ENGAGEMENT_DETAILS.INCORRECT_PHONE" },
		{ id: "OTHER", label: "ENUMS.ENGAGEMENT_DETAILS.OTHER" },
	];

	// Form fields
	const [formData, setFormData] = useState<ModalEngagementData>({
		type:
			props.mode === ModalEngagementMode.CALL
				? engagementType.PHONE
				: props.entity?.callType ?? null,
		callSid: props.entity?.callSid ?? null,
		coachId: props.entity?.coachId ?? getSessionStorageUser().id!,
		details:
			props.mode === ModalEngagementMode.NON_CALL
				? eEngagementDetails.COACHING_SESSION
				: props.entity?.details ?? null,
		engagementDate: props.entity?.engagementDate
			? new Date(props.entity?.engagementDate)
			: new Date(),
		other: props.entity?.other ?? null,
		outcome: props.entity?.outcome ?? null,
		participant: props.patientId,
		formMessage: undefined,
		confirmNoEngagement: false,
		declineReason: null,
	});

	// Engagement form: outcome changed
	const outcomeChanged = (event: DropdownChangeParams): void => {
		// Update field value, and update the fields that depend on this one
		setFormData({
			...formData,
			outcome: event.target.value,
			details: null,
			other: null,
		});
	};

	// Engagement form: details changed
	const detailsChanged = (event: DropdownChangeParams): void => {
		// Update field value, and update the fields that depend on this one
		setFormData({
			...formData,
			details: event.target.value,
			other: null,
		});
	};

	// Submit: CREATE/UPDATE engagement
	const handleSubmit = async (
		data: ModalEngagementData,
		form: FormApi<ModalEngagementData, ModalEngagementData>
	) => {
		// Reset login error
		setFormData({ ...data, formMessage: undefined });

		let participant: number = Number(data.participant);
		// If the coach inputs the kannactId instead of the ID, remove the first 3 letters (of the institution)
		if (data.participant?.toString().length === 9)
			participant = Number(data.participant?.toString().substring(3));

		// Call endpoints
		if (formData.confirmNoEngagement) await createNoEngagement(data, participant);
		else if (props.edit) await update(data, participant);
		else await create(data, participant);
	};

	// Create engagement
	const create = async (data: ModalEngagementData, participant: number): Promise<void> => {
		await EndpointsService.dataRetriever
			.createEngagement({
				body: {
					callType: data.type as EngagementType,
					engagementDate: data.engagementDate,
					patientId: participant,
					outcome: data.outcome as boolean,
					details: data.details,
					coachId: data.coachId,
					callSid: data.callSid,
					other: data.other,
				},
			})
			.then((response: IEngagement) => props.closeModal(true, response))
			.catch((error: AxiosError) => {
				// Error message
				data.formMessage = mappedAxiosError(
					undefined,
					"UI_COMPONENTS.MODAL_ENGAGEMENT.MESSAGE_ERROR"
				);
				setFormData(data);
			});
	};

	// Update engagement
	const update = async (data: ModalEngagementData, participant: number): Promise<void> => {
		await EndpointsService.dataRetriever
			.updateEngagement({
				body: {
					id: props.entity?.id as number,
					createdOn: props.entity?.createdOn as Date,
					callType: data.type as EngagementType,
					engagementDate: data.engagementDate,
					patientId: participant,
					outcome: data.outcome as boolean,
					details: data.details,
					coachId: data.coachId,
					callSid: data.callSid,
					other: data.other,
				},
			})
			.then((response: IEngagement) => props.closeModal(true, response))
			.catch((error: AxiosError) => {
				// Error message
				data.formMessage = mappedAxiosError(
					undefined,
					"UI_COMPONENTS.MODAL_ENGAGEMENT.MESSAGE_ERROR"
				);
				setFormData(data);
			});
	};

	// Create a Non engagement
	const createNoEngagement = async (
		data: ModalEngagementData,
		participant: number
	): Promise<void> => {
		await EndpointsService.dataRetriever
			.createEngagement({
				body: {
					callType: engagementType.NONE,
					engagementDate: new Date(),
					patientId: null,
					outcome: true,
					details: null,
					coachId: data.coachId,
					callSid: data.callSid,
					other: null,
					declineReason: data.declineReason as string,
				},
			})
			.then((response: IEngagement) => props.closeModal(true, response))
			.catch((error: AxiosError) => {
				// Error message
				data.formMessage = mappedAxiosError(
					undefined,
					"UI_COMPONENTS.MODAL_ENGAGEMENT.MESSAGE_ERROR"
				);
				setFormData(data);
			});
	};

	return (
		<Dialog
			header={setModalTitle}
			visible={props.isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			draggable={false}
			resizable={false}
			focusOnShow={false}
			onHide={() => props.closeModal(false, null)}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						{/* No engagement: confirmation message */}
						{formData.confirmNoEngagement === true && (
							<>
								<p>{t("UI_COMPONENTS.MODAL_ENGAGEMENT.NO_ENGAGEMENT_MSG")}</p>

								<FormSection>
									<div className="row">
										<div className="col-12 col-lg-6">
											<UiInputTextarea
												id="declineReason"
												name="declineReason"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.declineReason"
												placeholder="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.declineReason"
												onChange={(e) =>
													setFormData({
														...formData,
														declineReason: e.target.value,
													})
												}
												validations={[Validations.required]}
												disabled={submitting}
											/>
										</div>
									</div>
								</FormSection>
							</>
						)}

						{/* FORM: CREATE/UPDATE engagement */}
						{formData.confirmNoEngagement === false && (
							<FormSection>
								<div className="row">
									{/* Type */}
									{props.mode === ModalEngagementMode.NON_CALL && (
										<div className="col-12 col-sm-6 col-md-6 col-lg-4">
											<UiSelect
												id="type"
												name="type"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.type"
												placeholder="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.typePlaceholder"
												options={typeList}
												onChange={(e) =>
													setFormData({
														...formData,
														type: e.target.value,
													})
												}
												validations={[Validations.required]}
												disabled={submitting}
											/>
										</div>
									)}

									{/* Date */}
									{props.mode === ModalEngagementMode.NON_CALL && (
										<div className="col-12 col-sm-6 col-md-6 col-lg-4">
											<UiInputDate
												id="engagementDate"
												name="engagementDate"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.engagementDate"
												onChange={(e) =>
													setFormData({
														...formData,
														engagementDate: e.target.value as Date,
													})
												}
												showTime={true}
												maxDate={new Date()}
												validations={[Validations.required]}
												disabled={submitting}
											/>
										</div>
									)}

									{/* Participant */}
									{props.mode === ModalEngagementMode.CALL && (
										<div className="col-12 col-sm-6 col-md-6 col-lg-4">
											<UiSelect
												id="participant"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.participant"
												name="participant"
												editable={true}
												onChange={(e) =>
													setFormData({
														...formData,
														participant: e.target.value,
													})
												}
												disabled={submitting}
												validations={[Validations.required]}
												options={participantList}
											/>
										</div>
									)}

									{/* Call outcome: Did the coach talk to the participant? */}
									{props.mode === ModalEngagementMode.CALL && (
										<div className="col-12 col-sm-6 col-md-6 col-lg-4">
											<UiSelect
												id="outcome"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.outcome"
												name="outcome"
												onChange={outcomeChanged}
												disabled={submitting}
												validations={[Validations.required]}
												options={outcomeList}
											/>
										</div>
									)}

									{/* Details */}
									{props.mode === ModalEngagementMode.CALL && (
										<div className="col-12 col-sm-6 col-md-6 col-lg-4">
											<UiSelect
												id="details"
												label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.details"
												name="details"
												onChange={detailsChanged}
												disabled={submitting}
												validations={[Validations.required]}
												options={
													formData.outcome === true
														? detailsList1
														: detailsList2
												}
											/>
										</div>
									)}

									{/* Other */}
									{props.mode === ModalEngagementMode.CALL &&
										formData.details === eEngagementDetails.OTHER && (
											<div className="col-12 col-sm-12 col-md-12 col-lg-8">
												<UiInputText
													id="other"
													label="UI_COMPONENTS.MODAL_ENGAGEMENT.FORM_FIELDS.other"
													name="other"
													onChange={(e) =>
														setFormData({
															...formData,
															other: e.target.value,
														})
													}
													disabled={submitting}
												/>
											</div>
										)}
								</div>
							</FormSection>
						)}

						{/* FORM MESSAGE */}
						{formData.formMessage && (
							<div className="form-message">
								<UiFieldMessage {...formData.formMessage}></UiFieldMessage>
							</div>
						)}

						{/* FORM ACTIONS */}
						<div className="row mt-3">
							<div className="col-6">
								{formData.confirmNoEngagement === false &&
									props.mode !== ModalEngagementMode.NON_CALL && (
										<UiButton
											id="modalBtnNoEngagement"
											label="UI_COMPONENTS.MODAL_ENGAGEMENT.BTN_NO_ENGAGEMENT"
											className="p-button-outlined p-button-rounded"
											type="button"
											onClick={() =>
												setFormData({
													...formData,
													confirmNoEngagement: true,
												})
											}
											disabled={submitting}
										></UiButton>
									)}
							</div>
							<div className="col-6 text-end">
								{formData.confirmNoEngagement === false ? (
									<>
										{/* Create engagement */}
										<UiButton
											id="modalBtnCancel"
											label="UI_COMPONENTS.BUTTONS.CANCEL"
											className="p-button-outlined p-button-rounded mx-3"
											type="button"
											onClick={() => props.closeModal(false, null)}
											disabled={submitting}
										></UiButton>
										<UiButton
											id="modalBtnSubmit"
											label={
												submitting
													? "UI_COMPONENTS.MODAL_ENGAGEMENT.BTN_SUBMITTING"
													: "UI_COMPONENTS.MODAL_ENGAGEMENT.BTN_SUBMIT"
											}
											className="p-button-rounded"
											type="submit"
											disabled={submitting}
											loading={submitting}
										></UiButton>
									</>
								) : (
									<>
										{/* No engagement */}
										<UiButton
											id="modalBtnNoEngagementCancel"
											label="UI_COMPONENTS.MODAL_ENGAGEMENT.BTN_NO_ENGAGEMENT_CANCEL"
											className="p-button-outlined p-button-rounded mx-3"
											type="button"
											onClick={() =>
												setFormData({
													...formData,
													confirmNoEngagement: false,
												})
											}
											disabled={submitting}
										></UiButton>
										<UiButton
											id="modalBtnSubmit"
											label={
												submitting
													? "UI_COMPONENTS.BUTTONS.SAVING"
													: "UI_COMPONENTS.MODAL_ENGAGEMENT.BTN_NO_ENGAGEMENT_SUBMIT"
											}
											className="p-button-rounded"
											type="submit"
											disabled={submitting}
											loading={submitting}
										></UiButton>
									</>
								)}
							</div>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default UiModalEngagement;
