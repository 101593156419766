import { useTranslation } from "react-i18next";
import { NoteProgress } from "../../../../../../models/note-progress";
import { Formatter } from "../../../../../../services/formatter/formatter.service";
import { useSmartGoals } from "../../../../../../hooks/custom-goals/useSmartGoals";
import { SmartGoal } from "../../../../../../models/custom-goals/custom-goals";

const NotesHistoryGoals = ({
	noteProgress,
	smartGoalsQuery,
}: {
	noteProgress: NoteProgress[] | null;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
}) => {
	const { t } = useTranslation("common");

	const hasProgress = (x: SmartGoal) =>
		!!noteProgress?.filter((y) => y.smartGoalId === x.id).filter((x) => x.progress).length;

	return (
		<div className="participant-notes-history__panel-item">
			<span className="participant-notes-history__panel-item-label-goals">
				{t("PARTICIPANT.DASHBOARD.NOTES.GOALS")}:{" "}
			</span>
			{!noteProgress ? (
				Formatter.NO_DATA
			) : (
				<div className="participant-notes-history__panel-goals">
					{smartGoalsQuery.data
						?.filter((x) => noteProgress?.find((y) => y.smartGoalId === x.id))
						.sort((a, b) =>
							new Date(a.endDate) > new Date(b.endDate)
								? 1
								: new Date(b.endDate) > new Date(a.endDate)
									? -1
									: 0
						)
						.map((x) => (
							<div
								key={x.id}
								className="participant-notes-history__panel-goal"
							>
								<span>{x.name}</span>
								{hasProgress(x) && (
									<ul>
										{noteProgress
											?.filter((y) => y.smartGoalId === x.id)
											.map((y) => <li key={y.id}>{y.progress}</li>)}
									</ul>
								)}
							</div>
						))}
				</div>
			)}
		</div>
	);
};
export default NotesHistoryGoals;
