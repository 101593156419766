import { useEffect, useState } from "react";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../models/components/table/table-column.model";
import {
	TablePagination,
	ITablePagination,
} from "../../../models/components/table/table-pagination.model";
import { CallHistory } from "../../../models/entities/call-history.model";
import { CallLogRow } from "../../../models/pages/call-history-row.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import UiTable from "../../../components/table/Table.component";
import { AxiosError } from "axios";
import { TableFilterType } from "../../../models/components/table/table-filter.model";
import { BackendPagination } from "../../../models/pagination.model";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const DashboardCallHistory = () => {
	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "direction",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.direction",
			template: TableColumnTemplate.TAG,
			style: {
				width: "150px",
			},
		},
		{
			field: "status",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.status",
			template: TableColumnTemplate.TAG,
			style: {
				width: "150px",
			},
		},
		{
			field: "createdOn",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.dateCreated",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
			style: {
				width: "130px",
			},
		},
		{
			field: "participant",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.participant",
			sortable: true,
			template: TableColumnTemplate.KANNACT_ID,
			style: {
				width: "150px",
			},
		},
		{
			field: "duration",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.duration",
			template: TableColumnTemplate.DURATION,
			sortable: true,
			style: {
				width: "130px",
			},
		},
		{
			field: "twilioAudioTemplate",
			title: "CALL_CENTER.DASHBOARD.HISTORY.TABLE.COLUMNS.recording",
			template: TableColumnTemplate.TWILIO_AUDIO,
			style: {
				minWidth: "150px",
			},
		},
	];

	// Rows
	const [rows, setRows] = useState<CallLogRow[]>();

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["dashboard-calls"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "createdOn",
			sortOrder: -1,
			search: undefined,
			// Filter by coach
			filters: [
				{
					field: "coachId",
					type: TableFilterType.NUMBER,
					value: getSessionStorageUser().id,
				},
			],
		}
	);

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["dashboard-calls"],
			newPagination
		);

		getData(newPagination);
	};

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.LOADING,
		label: messages.loading,
	});

	/**
	 * DATA
	 */

	// First time load
	useEffect(() => {
		// Get data -> Get count
		getData(pagination).finally(() => {
			getCount(pagination);
		});
	}, []);

	const getData = async (event: ITablePagination): Promise<void> => {
		// Reset previous result
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		await EndpointsService.dataRetriever
			.getHistory({
				config: {
					params: {
						days: 3,
						...new BackendPagination(event),
					},
				},
			})
			.then((response: CallHistory[]) => {
				if (response?.length > 0) setRows(response.map((item) => new CallLogRow(item)));
				else setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: ITablePagination): Promise<void> => {
		await EndpointsService.dataRetriever
			.getHistoryCount({
				config: {
					params: {
						days: 3,
						...new BackendPagination(event),
					},
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Call recent history: Error getting the count");
			});
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="sid"
			title="CALL_CENTER.DASHBOARD.HISTORY.TITLE"
			columns={columns}
			value={rows}
			message={message}
			isServerPaginated={true}
			pagination={pagination}
			paginationFn={onPagination}
			totalRecords={count}
		/>
	);
};

export default DashboardCallHistory;
