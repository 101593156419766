import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import UiButton from "../../../components/button/Button";
import UiTable from "../../../components/table/Table.component";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../models/components/table/table-column.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import {
	ITablePagination,
	TablePagination,
} from "../../../models/components/table/table-pagination.model";
import {
	SettingsUserRow,
	SettingsUsersCrudModal,
} from "../../../models/pages/settings/users.model";
import SettingsUsersModal from "./SettingsUsersModal";
import { useInstitutionsDetailed } from "../../../hooks/useInstitutionsDetailed";
import {
	ITableHeaderFilter,
	TableFilterFormData,
	TableFilterType,
} from "../../../models/components/table/table-filter.model";
import { eParticipantStatus } from "../../../models/entities/participant.model";
import { HelperService } from "../../../services/helpers/helper.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { BackendPagination } from "../../../models/pagination.model";
import { AxiosError } from "axios";
import { FilterService } from "../../../services/filter/filter.service";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const SettingsUsers = (props: PageProps) => {
	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "name",
			sortingField: "firstName,lastName",
			title: "SETTINGS.USERS.TABLE.COLUMNS.name",
			sortable: true,
		},
		{
			field: "isActive",
			title: "SETTINGS.USERS.TABLE.COLUMNS.isActive",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: {
				translationPrefix: "ENUMS.PARTICIPANT_STATUS",
			},
			sortable: true,
		},
		{
			field: "isActivelyCoaching",
			title: "SETTINGS.USERS.TABLE.COLUMNS.isActivelyCoaching",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: {
				translationPrefix: "ENUMS",
			},
			sortable: true,
		},
		{
			field: "role",
			title: "SETTINGS.USERS.TABLE.COLUMNS.role",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: {
				translationPrefix: "ENUMS.ROLES",
			},
			sortable: true,
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			style: {
				textAlign: "center",
			},
		},
	];

	// Rows
	const [rows, setRows] = useState<SettingsUserRow[]>([]);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Filters
	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["settings-users"]) ?? {
			filters: [
				{
					field: "isActive",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						props: {
							label: "SETTINGS.USERS.TABLE.FILTER_IS_ACTIVE",
							options: [
								{
									id: eParticipantStatus.ACTIVE,
									label: `ENUMS.PARTICIPANT_STATUS.${eParticipantStatus.ACTIVE}`,
								},
								{
									id: eParticipantStatus.INACTIVE,
									label: `ENUMS.PARTICIPANT_STATUS.${eParticipantStatus.INACTIVE}`,
								},
							],
						},
					},
					value: [eParticipantStatus.ACTIVE],
				},
				{
					field: "isActivelyCoaching",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						props: {
							label: "SETTINGS.USERS.TABLE.FILTER_IS_ACTIVELY_COACHING",
							options: [
								{ id: true, label: `ENUMS.YES` },
								{ id: false, label: `ENUMS.NO` },
							],
						},
					},
					value: [],
				},
			],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["settings-users"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "name",
			sortOrder: 1,
			search: undefined,
			filters: filters.filters,
		}
	);

	/* Data */

	useEffect(() => {
		getData(pagination);
		getCount(pagination);
	}, []);

	const institutions = useInstitutionsDetailed();

	const getData = async (event: TablePagination) => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		// Update pagination object
		const updatedEvent: ITablePagination = {
			...event,
			sortField: HelperService.compoundSortField(columns, event),
		};

		await EndpointsService.dataRetriever
			.getProviders({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response) => {
				if (response?.length > 0) {
					setRows(
						response.map(
							(x) =>
								new SettingsUserRow(x, () =>
									setModalCrud({ isVisible: true, entity: x, mode: "UPDATE" })
								)
						)
					);
				} else {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: TablePagination) => {
		const queryParams = new BackendPagination(event);
		await EndpointsService.dataRetriever
			.getProvidersCount({
				config: {
					params: {
						search: queryParams.search,
						filters: queryParams.filters,
					},
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participants: Error getting the count");
			});
	};

	/* Modal: create/edit user */

	const [modalCrud, setModalCrud] = useState<SettingsUsersCrudModal>({
		isVisible: false,
		entity: null,
		mode: "CREATE",
	});

	return (
		<>
			<UiTable
				dataKey="id"
				title="SETTINGS.USERS.TITLE"
				useAsCard={true}
				customHeader={
					<UiButton
						type="button"
						className="p-button-rounded p-button-sm"
						icon={"pi pi-plus"}
						label="SETTINGS.USERS.BUTTON_CREATE"
						onClick={() =>
							setModalCrud({ isVisible: true, entity: null, mode: "CREATE" })
						}
					/>
				}
				columns={columns}
				value={rows}
				message={message}
				totalRecords={count}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={(event: TablePagination) => {
					const newPagination: TablePagination = {
						...pagination,
						page: event.page,
						rows: event.rows,
						sortField: event.sortField,
						sortOrder: event.sortOrder,
						first: event.first,
					};
					setPagination(newPagination);
					StoredCriteriaService.setPagination(
						StoredCriteriaService.KEYS["settings-users"],
						newPagination
					);

					getData(newPagination);
					getCount(newPagination);
				}}
				search={{
					placeholder: "SETTINGS.USERS.TABLE.SEARCH_PLACEHOLDER",
					value:
						StoredCriteriaService.getPagination(
							StoredCriteriaService.KEYS["settings-users"]
						)?.search ?? undefined,
					fn: (value: string | null | undefined) => {
						// Update pagination state
						const newPagination: TablePagination = {
							...pagination,
							search: value?.trim(),
							page: 0,
							first: 0,
						};
						setPagination(newPagination);
						StoredCriteriaService.setPagination(
							StoredCriteriaService.KEYS["settings-users"],
							newPagination
						);

						// Update data
						getData(newPagination);
						getCount(newPagination);
					},
				}}
				filter={filters}
				filterFn={(value: TableFilterFormData) => {
					// Update filters state
					const newFilters = FilterService.updateFiltersValues(
						(filters as ITableHeaderFilter).filters,
						value
					);
					setFilters({ ...filters, filters: newFilters });

					// Update pagination
					const newPagination: TablePagination = {
						...pagination,
						filters: newFilters,
						page: 0,
						first: 0,
					};
					setPagination(newPagination);

					StoredCriteriaService.set(
						StoredCriteriaService.KEYS["settings-users"],
						newPagination,
						{
							...filters,
							filters: newFilters,
						}
					);

					// Update table message
					setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.filter });

					// Get data
					getData(newPagination);
					getCount(newPagination);
				}}
				refresh={{
					fn: () => {
						getData(pagination);
						getCount(pagination);
					},
				}}
			/>

			{modalCrud.isVisible && (
				<SettingsUsersModal
					isVisible={modalCrud.isVisible}
					mode={modalCrud.mode}
					entity={modalCrud.entity}
					institutions={institutions.data || []}
					onSubmit={() => {
						setModalCrud({ isVisible: false, entity: null, mode: "CREATE" });
						getData(pagination);
						getCount(pagination);
					}}
					closeModal={() =>
						setModalCrud({ isVisible: false, entity: null, mode: "CREATE" })
					}
				/>
			)}
		</>
	);
};

export default SettingsUsers;
