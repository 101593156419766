import { useTranslation } from "react-i18next";
import { useParticipantNotes } from "../../../../../../hooks/useParticipantNotes";
import NotesHistoryState from "./NotesHistoryState";
import NotesHistoryCoachNote from "./NotesHistoryCoachNote";
import NotesHistoryOtherNote from "./NotesHistoryOtherNote";
import { Formatter } from "../../../../../../services/formatter/formatter.service";
import { useSmartGoals } from "../../../../../../hooks/custom-goals/useSmartGoals";
import { Note, NoteTopics } from "../../../../../../models/notes/note";
import { Form } from "react-final-form";
import UiSelectMultiple from "../../../../../../components/select-multiple/SelectMultiple.component";
import { ListOption } from "../../../../../../models/misc.model";

const NotesHistory = ({
	topicsFilter,
	updatingNote,
	notesQuery,
	smartGoalsQuery,
	onUpdate,
	onTopicsFilter,
}: {
	topicsFilter: { filter: NoteTopics[] };
	updatingNote: Note | null;
	notesQuery: ReturnType<typeof useParticipantNotes>;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	onUpdate: (note: Note) => void;
	onTopicsFilter: (e: NoteTopics[]) => void;
}) => {
	const { t } = useTranslation("common");

	// Laoding / Error / No data
	if (notesQuery.isLoading || notesQuery.isError || !notesQuery.data.length) {
		return (
			<NotesHistoryState
				isLoading={notesQuery.isLoading}
				isError={notesQuery.isError}
				isEmpty={notesQuery.data?.length ? false : true}
				onRefresh={notesQuery.invalidate}
			/>
		);
	}

	// Data
	return (
		<div className="participant-notes-history">
			<div className="row">
				<div className="col-12 col-sm-6 col-md-6 col-lg-6 participant-notes-history__title">
					{t(`PARTICIPANT.DASHBOARD.NOTES.HISTORY_TABLE_TITLE`)}
				</div>
				<div className="col-12 col-sm-6 col-md-6 col-lg-6 participant-notes-history__filter">
					<Form
						initialValues={topicsFilter}
						onSubmit={onTopicsFilter}
						render={({ handleSubmit, submitting, form }) => (
							<form onSubmit={handleSubmit}>
								<UiSelectMultiple
									id="filter"
									name="filter"
									placeholder="PARTICIPANT.DASHBOARD.NOTES.TOPICS"
									onChange={(e) => onTopicsFilter(e.target.value)}
									options={Object.keys(NoteTopics).map(
										(x) =>
											new ListOption({
												id: x,
												label: `PARTICIPANT.DASHBOARD.NOTES.TOPICS_SPOKEN_OPTIONS.${x}`,
											})
									)}
									removeBottomSpacer={true}
									panelHeaderTemplate={<></>}
								/>
							</form>
						)}
					/>
				</div>
			</div>

			{notesQuery.data
				.filter((x) => {
					if (topicsFilter.filter.length > 0) {
						return x.topics?.some((y) => topicsFilter.filter.includes(y));
					} else {
						return x;
					}
				})
				.map((note) => {
					const topics = (note.topics ?? [])
						.map((topic) =>
							t(`PARTICIPANT.DASHBOARD.NOTES.TOPICS_SPOKEN_OPTIONS.${topic}`)
						)
						.join(", ");

					const lastEditionDate =
						note.createdOn !== note.updatedOn ? note.updatedOn : undefined;

					return note.type === "COACHING" ? (
						<NotesHistoryCoachNote
							key={note.id}
							date={note.date}
							callerName={Formatter.fullName(
								note.coach.firstName,
								note.coach.lastName
							)}
							editing={note.id === updatingNote?.id}
							subjective={note.subjective ?? undefined}
							objective={note.objective ?? undefined}
							assessment={note.assessment ?? undefined}
							stateOfChange={
								note.stateOfChange
									? t(
											`PARTICIPANT.DASHBOARD.NOTES.STATE_OF_CHANGE_OPTIONS.${note.stateOfChange}`
										)
									: undefined
							}
							plan={note.plan ?? undefined}
							topics={topics && topics !== "" ? topics : undefined}
							noteProgress={note.noteProgress}
							smartGoalsQuery={smartGoalsQuery}
							lastEditionDate={lastEditionDate}
							onClickEdit={() => onUpdate(note)}
						/>
					) : (
						<NotesHistoryOtherNote
							key={note.id}
							date={note.date}
							callerName={Formatter.fullName(
								note.coach.firstName,
								note.coach.lastName
							)}
							editing={note.id === updatingNote?.id}
							subjective={note.subjective || undefined}
							topics={topics && topics !== "" ? topics : undefined}
							lastEditionDate={lastEditionDate}
							onClickEdit={() => onUpdate(note)}
						/>
					);
				})}
		</div>
	);
};

export default NotesHistory;
