import UiButton from "../../button/Button";
import { TableHeaderCrud } from "../../../models/components/table/table-crud.model";

const UiTableHeaderCrud = (props: TableHeaderCrud) => {
	return (
		<>
			{props.create && (
				<UiButton
					id="crudCreateBtn"
					icon={props.create.icon}
					className="p-button-rounded crud create"
					label={props.create.title}
					tooltip={props.create.tooltip}
					tooltipOptions={{ position: "bottom" }}
					onClick={props.create.fn}
				></UiButton>
			)}

			{props.update && (
				<UiButton
					id="crudUpdateBtn"
					icon={props.update.icon}
					className="p-button-rounded p-button-info crud update"
					label={props.update.title}
					tooltip={props.update.tooltip}
					tooltipOptions={{ position: "bottom" }}
					onClick={props.update.fn}
				></UiButton>
			)}

			{props?.delete && (
				<UiButton
					id="crudDeleteBtn"
					icon={props.delete.icon}
					className="p-button-rounded p-button-danger crud delete"
					label={props.delete.title}
					tooltip={props.delete.tooltip}
					tooltipOptions={{ position: "bottom" }}
					onClick={props.delete.fn}
				></UiButton>
			)}
		</>
	);
};

export default UiTableHeaderCrud;
