import { matchRoutes, RouteMatch } from "react-router-dom";
import { SidebarMenu } from "../../models/routing.model";
import { getRoute } from "../routing/protected-routes.service";
import { getRoutes } from "../routing/routing.service";

/**
 * Routes:
 * @description Based on the current location, it gets the current functional module.
 * And based on the functional module, it gets the sidebar menu.
 * @returns Returns the sub-options of a functional module
 */
export const getSidebarMenu = (pathname: string): SidebarMenu => {
	const sidebar: SidebarMenu = {
		basePath: "",
		menu: [],
	};

	const matchedRoutes = matchRoutes(getRoutes(), pathname);
	let matchedRoute: RouteMatch<string> | undefined = undefined;

	// Get current functional module
	if (matchedRoutes) {
		for (let i = 0; i < matchedRoutes.length; i++) {
			const route = getRoute(matchedRoutes[Number(i)]?.route);
			if (route?.routeProps?.isModule) {
				sidebar.basePath = matchedRoutes[Number(i)]?.pathnameBase;
				matchedRoute = matchedRoutes[Number(i)];
				break;
			}
		}
	}

	// Map functional module sub-items (ignore the index route)
	if (matchedRoute?.route?.children) {
		const children = matchedRoute?.route?.children.filter((item) => !item.index);
		children.forEach((child) => {
			const item = getRoute(child);
			if (item?.routeProps?.isSubModule) {
				sidebar.menu.push(item);
			}
		});
	}

	return sidebar;
};
