import { PageProps } from "../../../models/routing.model";
import UiTable from "../../../components/table/Table.component";
import { HelperService } from "../../../services/helpers/helper.service";
import { TableColumn } from "../../../models/components/table/table-column.model";
import { useEffect, useState } from "react";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	TableFilterFormData,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { List, ListOption } from "../../../models/misc.model";
import { ProviderTwilio } from "../../../models/entities/provider.model";
import { FilterService } from "../../../services/filter/filter.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import AppointmentDetails from "../../../components/appointments/AppointmentDetails";
import { useTranslation } from "react-i18next";
import {
	Appointment,
	AppointmentBlock,
	AppointmentBlocksTypes,
	AppointmentCoach,
	AppointmentParticipant,
	AppointmentType,
	AppointmentTypes,
} from "../../../models/entities/appointments.model";
import {
	AppointmentModalCoach,
	AppointmentModalMode,
} from "../../../models/components/appointment-modal.model";
import AppointmentModal from "../../../components/appointments/AppointmentModal";
import AppointmentModalCancel from "../../../components/appointments/AppointmentModalCancel";
import { AppointmentsService } from "../../../services/appointments/appointments.service";
import { AppointmentRow } from "../../../models/pages/appointments/appointments.model";
import AppointmentBlockContainer from "./AppointmentBlockContainer";
import { ITableHeaderSearch } from "../../../models/components/table/table-search.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const ParticipantsAppointments = (props: PageProps) => {
	const { t } = useTranslation("common");

	/**
	 * TABLE
	 */

	// Table: columns
	const columns: TableColumn[] = AppointmentsService.getColumns();

	// Table: rows
	const [rows, setRows] = useState<AppointmentRow[]>([]);

	// Table: filters
	const typesList: List = Object.keys(AppointmentType).map((x) => {
		const duration = AppointmentsService.formatTypeDuration(x);
		return new ListOption({ id: x, label: `${t(`ENUMS.APPOINTMENTS_TYPE.${x}`)} ${duration}` });
	});

	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-appointments"])
			? {
				filters: [
					...StoredCriteriaService.getFilters(
						StoredCriteriaService.KEYS["participants-appointments"]
					)!.filters,
				].map((x) => {
					if (x.field === "datetime") {
						// Fix stringified validations
						x.options = AppointmentsService.getFilters(
							typesList,
							[]
						).datetime.options;
					}
					return x;
				}),
			}
			: {
				filters: [
					AppointmentsService.getFilters(typesList, []).datetime,
					AppointmentsService.getFilters(typesList, []).type,
				],
			}
	);

	// Filters: submit
	const submitFilters = (e: TableFilterFormData): void => {
		// Filter values: date
		const previousDate: Date | null = filters.filters.find(
			(x) => x.field === "datetime"
		)?.value;
		const selectedDate: Date = e["datetime"];

		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			e
		);
		setFilters({ ...filters, filters: newFilters });

		// Update pagination
		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};
		setPagination(newPagination);

		StoredCriteriaService.set(
			StoredCriteriaService.KEYS["participants-appointments"],
			newPagination,
			{ ...filters, filters: newFilters }
		);

		// Get data: only if the date filter changed (backend filter)
		const dateChanged: boolean =
			selectedDate && previousDate && selectedDate.getTime() === previousDate.getTime()
				? false
				: true;

		if (dateChanged) {
			// Filter: server-side (by date)
			getData(
				newPagination,
				appointmentTypes,
				appointmentCoaches,
				appointmentBlockTypes,
				gladstoneCoaches
			);
		} else if (selectedDate) {
			// Filter/Search: client-side
			let filteredRows: AppointmentRow[] = originalRows;
			if (
				FilterService.hasSelectedFilters(
					AppointmentsService.removeFilterDatetime(newFilters)
				)
			) {
				filteredRows = FilterService.filter(
					originalRows,
					AppointmentsService.removeFilterDatetime(newFilters)
				);
			}
			if (newPagination.search) {
				filteredRows = FilterService.search(
					filteredRows,
					["kannactId", "name", "phone"],
					newPagination.search
				);
			}

			setRows(filteredRows);
		} else if (!selectedDate) {
			setRows([]);
		}
	};

	// Table: pagination
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(
			StoredCriteriaService.KEYS["participants-appointments"]
		) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "datetime",
			sortOrder: 1,
			search: undefined,
			filters: filters.filters,
		}
	);

	// Table: messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Search
	const tableSearch: ITableHeaderSearch = {
		title: "APPOINTMENTS.TABLE.SEARCH_PLACEHOLDER",
		value: pagination.search || undefined,
		fn: (value: string | null | undefined) => {
			// Update pagination state
			const newPagination: TablePagination = {
				...pagination,
				search: value,
				page: 0,
				first: 0,
			};
			setPagination(newPagination);
			StoredCriteriaService.setPagination(
				StoredCriteriaService.KEYS["participants-appointments"],
				newPagination
			);

			// Apply filters/search client-side
			let filteredRows: AppointmentRow[] = originalRows;
			if (
				FilterService.hasSelectedFilters(
					AppointmentsService.removeFilterDatetime(filters.filters)
				)
			) {
				filteredRows = FilterService.filter(
					originalRows,
					AppointmentsService.removeFilterDatetime(filters.filters)
				);
			}
			filteredRows = FilterService.search(
				filteredRows,
				["kannactId", "name", "phone"],
				value
			);

			setRows(filteredRows);
		},
	};

	/**
	 * DATA
	 */

	const [originalRows, setOriginalRows] = useState<AppointmentRow[]>([]);
	const [appointmentTypes, setAppointmentTypes] = useState<AppointmentTypes[]>([]);
	const [appointmentCoaches, setAppointmentCoaches] = useState<AppointmentCoach[]>([]);
	const [appointmentBlockTypes, setAppointmentBlockTypes] = useState<AppointmentBlocksTypes[]>(
		[]
	);
	const [gladstoneCoaches, setGladstoneCoaches] = useState<ProviderTwilio[]>([]);

	// First time it loads
	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		let types: AppointmentTypes[] = [];
		let coaches: AppointmentCoach[] = [];
		let blockTypes: AppointmentBlocksTypes[] = [];
		let providers: ProviderTwilio[] = [];

		// Get the auxiliar data (only needed the first load time)
		await Promise.all([
			// Get data: appointment's types
			EndpointsService.appointments.getTypes(),
			// Get data: appointment's coaches
			EndpointsService.appointments.getCoaches(),
			// Get data: block's types
			EndpointsService.appointments.getBlockTypes(),
			// Get data: coaches (GS 2)
			EndpointsService.dataRetriever.getProvidersTwilio(),
		]).then((response) => {
			// Save the appointment's types
			if (response[0]?.length > 0) {
				types = response[0];
				setAppointmentTypes(response[0]);
			}

			// Save the appointment's coaches
			if (response[1]?.length > 0) {
				coaches = response[1];
				setAppointmentCoaches(response[1]);
			}

			// Save the appointment's block types
			if (response[2]?.length > 0) {
				blockTypes = response[2];
				setAppointmentBlockTypes(response[2]);
			}

			// Save the GS2's coaches
			if (response[3]?.length > 0) {
				providers = response[3];
				setGladstoneCoaches(response[3]);
			}

			// Update filter: coaches options list
			if (HelperService.isAdminViewMode()) {
				const providersList: List = response[3].map(
					(item) =>
						new ListOption({
							id: item.coachId,
							label: `${item?.firstName} ${item?.lastName}`,
						})
				);
				const updatedFilters = AppointmentsService.getFilters(typesList, providersList);
				setFilters({
					...filters,
					filters: [updatedFilters.datetime, updatedFilters.type, updatedFilters.coachId],
				});
				StoredCriteriaService.setFilters(
					StoredCriteriaService.KEYS["participants-appointments"],
					{
						...filters,
						filters: [
							updatedFilters.datetime,
							updatedFilters.type,
							updatedFilters.coachId,
						],
					}
				);
			}
		});

		// Get data: appointments & blocks/classes
		await getData(pagination, types, coaches, blockTypes, providers);
	};

	// Get data: appointments & blocks/classes
	const getData = async (
		event: TablePagination,
		types: AppointmentTypes[],
		coaches: AppointmentCoach[],
		blockTypes: AppointmentBlocksTypes[],
		providers: ProviderTwilio[]
	): Promise<void> => {
		// Backend filter: datetime (filter by date)
		const formattedDate = AppointmentsService.filterFormattedDatetime(event.filters ?? []);
		const gladstoneCoachId: string | null = !HelperService.isAdminViewMode()
			? (getSessionStorageUser().id?.toString() ?? null)
			: null;

		if (formattedDate) {
			// Reset previous data
			setOriginalRows([]);
			setRows([]);

			// Set the loading spinner
			setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

			await Promise.all([
				// Get data: appointments
				EndpointsService.appointments.getAppointments({
					config: {
						params: {
							minDate: formattedDate,
							maxDate: formattedDate,
							gladstoneCoachId,
						},
					},
				}),
				// Get data: blocks/classes
				EndpointsService.appointments.getBlocks({
					config: {
						params: {
							minDate: formattedDate,
							maxDate: formattedDate,
							gladstoneCoachId,
						},
					},
				}),
			])
				.then((response) => {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
					if (response[0]?.length > 0 || response[1]?.length > 0) {
						const rows: AppointmentRow[] = [
							// Map rows: appointments
							...mapAppointmentsRows(
								response[0] ?? [],
								types,
								coaches,
								blockTypes,
								providers
							),
							// Map rows: blocks/classes
							...mapAppointmentsRows(
								response[1] ?? [],
								types,
								coaches,
								blockTypes,
								providers
							),
						];

						// Set original data
						setOriginalRows(rows);

						// Filter client-side: by appointment type/coachId
						let filteredRows: AppointmentRow[] = rows;
						if (
							event.filters &&
							FilterService.hasSelectedFilters(
								AppointmentsService.removeFilterDatetime(event.filters)
							)
						) {
							filteredRows = FilterService.filter(
								rows,
								AppointmentsService.removeFilterDatetime(event.filters)
							);
						}
						// Search client-side
						if (event.search) {
							filteredRows = FilterService.search(
								filteredRows,
								["kannactId", "name", "phone"],
								event.search
							);
						}

						// Set rows
						setRows(filteredRows);
					} else {
						setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
					}
				})
				.catch((error) =>
					setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
				);
		} else {
			setRows([]);
			setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
		}
	};

	// Map rows: Appointments
	const mapAppointmentsRows = (
		appointments: (Appointment | AppointmentBlock)[],
		types: AppointmentTypes[],
		coaches: AppointmentCoach[],
		blockTypes: AppointmentBlocksTypes[],
		providers: ProviderTwilio[]
	) => {
		let result: AppointmentRow[] = [];

		if (appointments.length > 0) {
			result = appointments.map((item) => {
				if (item.type.includes("_BLOCK")) {
					// It's a block/class
					const block = item as Appointment;
					const row = new AppointmentRow(
						block,
						t("APPOINTMENTS.DETAILS.noProfile"),
						types,
						coaches,
						providers,
						blockTypes,
						undefined,
						undefined
					);
					return row;
				} else {
					// It' s an appointment
					const appt = item as Appointment;
					const row = new AppointmentRow(
						appt,
						t("APPOINTMENTS.DETAILS.noProfile"),
						types,
						coaches,
						providers,
						blockTypes,
						new Date(appt.datetime).getTime() >=
							AppointmentsService.getTodayDate().getTime()
							? rescheduleModalOpen.bind(this, appt)
							: undefined,
						new Date(appt.datetime).getTime() >=
							AppointmentsService.getTodayDate().getTime()
							? cancelModalOpen.bind(this, appt)
							: undefined
					);
					return row;
				}
			});
		}

		return result;
	};

	/**
	 * ACTION: Reschedule appointment
	 */

	const [rescheduleModalShow, setRescheduleModalShow] = useState<boolean>(false);
	const [rescheduleModalAppointment, setRescheduleModalAppointment] =
		useState<Appointment | null>(null);
	const [rescheduleModalCoach, setRescheduleModalCoach] = useState<AppointmentModalCoach | null>(
		null
	);
	const [rescheduleModalParticipant, setRescheduleModalParticipant] =
		useState<AppointmentParticipant | null>(null);
	const rescheduleModalOpen = (e: Appointment) => {
		setRescheduleModalShow(true);
		setRescheduleModalAppointment(e);
		setRescheduleModalCoach({
			calendarId: e.coachId,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		});
		setRescheduleModalParticipant(e.participant);
	};

	/**
	 * ACTION: Cancel appointment
	 */

	const [cancelModalShow, setCancelModalShow] = useState<boolean>(false);
	const [cancelModalAppointment, setCancelModalAppointment] = useState<Appointment | null>(null);
	const cancelModalOpen = (e: Appointment) => {
		setCancelModalShow(true);
		setCancelModalAppointment(e);
	};

	return (
		<>
			<UiTable
				useAsCard={true}
				dataKey="id"
				title={
					HelperService.isAdminViewMode()
						? "APPOINTMENTS.TABLE.TITLE_ADMIN"
						: "APPOINTMENTS.TABLE.TITLE_COACH"
				}
				columns={columns}
				value={rows}
				rowClassName={(data: AppointmentRow, options) => {
					let rowClass = " ";
					if (data.entity.type.includes("_BLOCK")) rowClass += " appointment-block-row ";
					if (
						AppointmentsService.isPast(
							data.entity.datetime,
							data.entity.type,
							appointmentTypes,
							appointmentBlockTypes
						)
					)
						rowClass += " row-inactive ";
					return rowClass;
				}}
				message={message}
				pagination={pagination}
				paginationFn={(e) => {
					const newPagination: TablePagination = {
						...pagination,
						page: e.page,
						rows: e.rows,
						sortField: e.sortField,
						sortOrder: e.sortOrder,
						first: e.first,
					};
					setPagination(newPagination);
					StoredCriteriaService.setPagination(
						StoredCriteriaService.KEYS["participants-appointments"],
						newPagination
					);
				}}
				search={tableSearch}
				filter={filters}
				filterFn={submitFilters}
				expandedTemplate={(e: AppointmentRow) =>
					e.entity.type.includes("_BLOCK") ? (
						<AppointmentBlockContainer row={e} />
					) : (
						<AppointmentDetails row={e} />
					)
				}
			/>

			{/* Modal: Cancel appointment */}
			{cancelModalShow && cancelModalAppointment && (
				<AppointmentModalCancel
					isVisible={cancelModalShow}
					appointment={cancelModalAppointment}
					onCancel={() => setCancelModalShow(false)}
					onConfirm={() => {
						setCancelModalShow(false);
						getData(
							pagination,
							appointmentTypes,
							appointmentCoaches,
							appointmentBlockTypes,
							gladstoneCoaches
						);
					}}
				/>
			)}

			{/* Modal: reschedule appointment */}
			{rescheduleModalShow &&
				rescheduleModalAppointment &&
				rescheduleModalCoach &&
				rescheduleModalParticipant && (
					<AppointmentModal
						mode={AppointmentModalMode.RESCHEDULE}
						isVisible={rescheduleModalShow}
						participant={rescheduleModalParticipant}
						coach={rescheduleModalCoach}
						appointmentCoaches={appointmentCoaches}
						appointmentTypes={appointmentTypes}
						appointment={rescheduleModalAppointment}
						onCancel={() => setRescheduleModalShow(false)}
						onConfirm={() => {
							setRescheduleModalShow(false);
							getData(
								pagination,
								appointmentTypes,
								appointmentCoaches,
								appointmentBlockTypes,
								gladstoneCoaches
							);
						}}
					/>
				)}
		</>
	);
};
export default ParticipantsAppointments;
