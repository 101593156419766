import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";
import { GoalsService } from "../services/goals/goals.service";

export const useBiometricGoals = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["biometric-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		const biometricGoals = await EndpointsService.dataRetriever.getBiometricGoals({
			participantId,
		});
		return GoalsService.getGoalsByCategory(biometricGoals?.goals ?? []);
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
