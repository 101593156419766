import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import UiTable from "../../../components/table/Table.component";
import { HelperService } from "../../../services/helpers/helper.service";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import {
	TablePagination,
	ITablePagination,
} from "../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { RecordingRow } from "../../../models/pages/call-voicemail-row.model";
import { ITableHeaderRefresh } from "../../../models/components/table/table-refresh.model";
import { ITableHeaderSearch } from "../../../models/components/table/table-search.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { BackendPagination } from "../../../models/pagination.model";
import { RecordingEntity } from "../../../models/entities/call-voicemail.model";
import { ProviderTwilio } from "../../../models/entities/provider.model";
import { List, ListOption } from "../../../models/misc.model";
import { FilterService } from "../../../services/filter/filter.service";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const CallCenterVoicemail = (props: PageProps) => {
	/* Table */

	// Columns
	const setColumns = (): TableColumn[] => {
		const columns: TableColumn[] = [
			{
				field: "startTime",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.date",
				template: TableColumnTemplate.DATE,
				templateOptions: {
					dateFormat: {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					},
				},
				sortable: true,
				style: {
					width: "200px",
				},
			},
			{
				field: "participant",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.participant",
				sortable: true,
				template: TableColumnTemplate.KANNACT_ID,
				style: {
					width: "180px",
				},
			},
			{
				field: "phone",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.phone",
				template: TableColumnTemplate.PHONE_CALL,
				style: {
					minWidth: "220px",
				},
			},
			{
				field: "duration",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.duration",
				template: TableColumnTemplate.DURATION,
				sortable: true,
				style: {
					width: "130px",
				},
			},
			{
				field: "alreadySeenTemplate",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.markedAsReadTemplate",
				template: TableColumnTemplate.TAG,
				style: {
					width: "130px",
				},
			},
			{
				field: "twilioAudioTemplate",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.recording",
				template: TableColumnTemplate.TWILIO_AUDIO,
				style: {
					width: "100px",
				},
			},
			{
				field: "actionsTemplate",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.actionsTemplate",
				template: TableColumnTemplate.BUTTONS,
				style: {
					width: "100px",
				},
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column: TableColumn = {
				field: "coachId",
				title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.coach",
				template: TableColumnTemplate.COACH,
				sortable: true,
			};
			columns.splice(3, 0, column);
		}

		return columns;
	};
	const columns: TableColumn[] = setColumns();

	const [rows, setRows] = useState<RecordingRow[]>([]);

	// Filters
	const alreadySeenFilter: ITableFilter = {
		field: "alreadySeen",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "CALL_CENTER.VOICEMAIL.FILTERS.READ_TITLE",
				options: [
					{ id: "true", label: "CALL_CENTER.VOICEMAIL.STATUS.true" },
					{ id: "false", label: "CALL_CENTER.VOICEMAIL.STATUS.false" },
				],
			},
		},
		value: [],
	};
	const coachFilter: ITableFilter = {
		field: "coachId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: [`${getSessionStorageUser()?.id}`],
	};
	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["call-center-voicemails"]) ?? {
			filters: [alreadySeenFilter],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(
			StoredCriteriaService.KEYS["call-center-voicemails"]
		) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "startTime",
			sortOrder: -1,
			search: undefined,
			filters: filters?.filters,
		}
	);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Refresh
	const refresh: ITableHeaderRefresh = {
		fn: () => {
			getData(pagination);
		},
	};

	// Search
	const tableSearch: ITableHeaderSearch = {
		title: "CALL_CENTER.VOICEMAIL.SEARCH_PLACEHOLDER",
		fn: (value: string | null | undefined) => {
			// Update pagination state
			const newPagination: TablePagination = {
				...pagination,
				search: value,
				page: 0,
				first: 0,
			};
			setPagination(newPagination);
			StoredCriteriaService.setPagination(
				StoredCriteriaService.KEYS["call-center-voicemails"],
				newPagination
			);

			// Update data
			getData(newPagination);
			getCount(newPagination);
		},
	};

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["call-center-voicemails"],
			newPagination
		);

		getData(newPagination);
	};

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);

		// Get filters
		if (
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["call-center-voicemails"])
				?.filters &&
			HelperService.isAdminViewMode()
		) {
			getFilters();
		}
	}, []);

	// Get data: participants
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode())
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) || []), coachFilter];

		await EndpointsService.dataRetriever
			.getVoicemails({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response: RecordingEntity[]) => {
				if (response?.length > 0)
					setRows(
						response.map(
							(item) =>
								new RecordingRow(
									item,
									item.alreadySeen
										? unresolveVoicemail.bind(this)
										: resolveVoicemail.bind(this)
								)
						)
					);
				else setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode())
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) || []), coachFilter];

		await EndpointsService.dataRetriever
			.getVoicemailsCount({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participants: Error getting the count");
			});
	};

	/**
	 * FILTERS
	 */

	// Filters: get options (call a backend endpoint to retreive the coaches list)
	const getFilters = async (): Promise<void> => {
		await EndpointsService.dataRetriever
			.getProvidersTwilio()
			.then((response: ProviderTwilio[]) => {
				// If there is data --> Map it
				if (response?.length > 0) mapFilters(response);
			})
			.catch((error: AxiosError) => {
				console.error("Participants - Couldn't get the providers list");
			});
	};

	// Filters: submit
	const submitFilters = (value: TableFilterFormData): void => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);
		setFilters({ ...filters, filters: newFilters });

		// Update table message
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.filter });

		// Update pagination
		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};
		setPagination(newPagination);

		StoredCriteriaService.set(
			StoredCriteriaService.KEYS["call-center-voicemails"],
			newPagination,
			{
				...filters,
				filters: newFilters,
			}
		);

		// Get data
		getData(newPagination);
		getCount(newPagination);
	};

	// Filters: map
	const mapFilters = (providers: ProviderTwilio[]): void => {
		// Map backend entity
		const providersList: List = providers.map(
			(item) =>
				new ListOption({
					id: item.coachId,
					label: `${item.firstName} ${item.lastName}`,
				})
		);

		// Update filters
		const updatedFilters: ITableFilter[] = [
			{
				field: "coachId",
				type: TableFilterType.SELECT_MULTIPLE,
				options: {
					columnClass: "col-12 col-md-3 col-lg-3",
					props: {
						label: "CALL_CENTER.VOICEMAIL.FILTERS.COACH_TITLE",
						options: providersList,
						filter: true,
					},
				},
				value: [],
			},
			...(filters?.filters as ITableFilter[]),
		];

		setFilters({
			...filters,
			filters: updatedFilters,
		});

		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["call-center-voicemails"], {
			...filters,
			filters: updatedFilters,
		});
	};

	/**
	 * ACTIONS
	 */

	// Calls voicemails: mark as resolved
	const resolveVoicemail = async (event: TableTemplateButtonsEvent): Promise<void> => {
		// Delete row & Update rows state
		setRows(
			rows.map((row: RecordingRow) => {
				if (row.entity.callSid === (event.row as RecordingRow).entity.callSid) {
					// Update read value
					const originalEntity = { ...row.entity };
					originalEntity.alreadySeen = true;
					// Create new row
					row = new RecordingRow(originalEntity, unresolveVoicemail.bind(this));
				}
				return row;
			})
		);

		// Update data from the backend
		await EndpointsService.dataRetriever
			.voicemailResolve({
				config: {
					params: {
						recordingSid: (event.row as RecordingRow).entity.recordingSid,
						alreadySeen: true,
					},
				},
			})
			.finally(() => {
				getData(pagination);
				getCount(pagination);
			});
	};

	// Calls voicemails: mark as unresolved
	const unresolveVoicemail = async (event: TableTemplateButtonsEvent): Promise<void> => {
		// Delete row & Update rows state
		setRows(
			rows.map((row: RecordingRow) => {
				if (row.entity.callSid === (event.row as RecordingRow).entity.callSid) {
					// Update read value
					const originalEntity = { ...row.entity };
					originalEntity.alreadySeen = false;
					// Create new row
					row = new RecordingRow(originalEntity, resolveVoicemail.bind(this));
				}
				return row;
			})
		);

		// Update data from the backend
		await EndpointsService.dataRetriever
			.voicemailResolve({
				config: {
					params: {
						recordingSid: (event.row as RecordingRow).entity.recordingSid,
						alreadySeen: false,
					},
				},
			})
			.finally(() => {
				getData(pagination);
				getCount(pagination);
			});
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="id"
			title={
				HelperService.isAdminViewMode()
					? "CALL_CENTER.VOICEMAIL.TABLE.TITLE_ADMIN"
					: "CALL_CENTER.VOICEMAIL.TABLE.TITLE_COACH"
			}
			columns={columns}
			value={rows}
			message={message}
			isServerPaginated={true}
			pagination={pagination}
			paginationFn={onPagination}
			totalRecords={count}
			refresh={refresh}
			search={tableSearch}
			filter={filters}
			filterFn={submitFilters}
		/>
	);
};
export default CallCenterVoicemail;
