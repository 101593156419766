import { SurveyByParticipantRequest } from "../../models/endpoints/surveys.endpoints.model";
import { ParticipantSurvey } from "../../models/entities/survey.model";
import HttpService from "../http/http.service";

const httpService = new HttpService(process.env.REACT_APP_API_SURVEY!);

// Surveys endpoints
export const SurveyEndpointsService = {
	// Get survey (questions)
	getSurveyQuestions: async (surveyName: string): Promise<ParticipantSurvey[]> =>
		httpService.get(`/api/survey/${surveyName}`),

	// Get survey results (answers)
	getSurveysResultsByParticipant: async (
		request: SurveyByParticipantRequest
	): Promise<ParticipantSurvey[]> => httpService.get("/api/survey-results/", request),
};
