import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
} from "../components/table/table-template.model";
import { Reminder, IReminder } from "../entities/reminder.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { UiButtonProps } from "../components/button.model";

export enum DateOnNavigation {
	PREV = "PREV",
	NEXT = "NEXT",
}

export type ParticipantsRemindersData = {
	dateOn: Date | null;
	modalIsVisible: boolean;
	onCloseModal: (selectedDate: Date | null) => void;
};

export interface IParticipantReminderRow {
	id: number | null;
	patientId: number;
	kannactId: string;
	coachId: number;
	name: string;
	reason: string;
	dateOn: string; // Date (ISOString)
	createdBy: string | null;
	actionsTemplate: TableTemplateButtons;
	entity: IReminder;
}

export class ParticipantReminderRow implements IParticipantReminderRow {
	id: number | null;
	patientId: number;
	kannactId: string;
	coachId: number;
	name: string;
	reason: string;
	dateOn: string; // Date (ISOString)
	createdBy: string | null;
	actionsTemplate: TableTemplateButtons;
	entity: Reminder;

	constructor(
		params: IReminder,
		resolveFn: (e: TableTemplateButtonsEvent) => void,
		snoozeFn: (e: TableTemplateButtonsEvent) => void,
		updateFn: (e: TableTemplateButtonsEvent) => void,
		deleteFn: (e: TableTemplateButtonsEvent) => void
	) {
		this.id = params.id;
		this.patientId = params.patientId;
		this.kannactId = params.kannactId;
		this.coachId = params.coachId;
		this.name = Formatter.fullName(params.firstName, params.lastName);
		this.reason = params.reason;
		this.dateOn = params.dateOn;
		this.createdBy =
			params.createdByFirstName && params.createdByLastName
				? Formatter.fullName(params.createdByFirstName, params.createdByLastName)
				: null;
		this.actionsTemplate = this.setActionsTemplate(
			params,
			resolveFn,
			snoozeFn,
			updateFn,
			deleteFn
		);
		this.entity = params;
	}

	private setActionsTemplate = (
		params: IReminder,
		resolveFn: (e: TableTemplateButtonsEvent) => void,
		snoozeFn: (e: TableTemplateButtonsEvent) => void,
		updateFn: (e: TableTemplateButtonsEvent) => void,
		deleteFn: (e: TableTemplateButtonsEvent) => void
	): TableTemplateButtons =>
		params.resolved
			? []
			: [
					{
						id: "resolveAction",
						label: "PARTICIPANTS.REMINDERS.BUTTON_RESOLVE",
						className: "p-button-sm p-button-rounded",
						disabled: false,
						onClick: resolveFn,
					},
					{
						id: "snoozeAction",
						label: "PARTICIPANTS.REMINDERS.BUTTON_SNOOZE",
						className: "p-button-sm p-button-outlined p-button-rounded",
						disabled: false,
						onClick: snoozeFn,
					},
					{
						id: "updateAction",
						className: "p-button-text neutral",
						icon: "pi pi-pencil",
						disabled: false,
						onClick: updateFn,
					},
					{
						id: "deleteAction",
						className: "p-button-text neutral",
						icon: "pi pi-trash",
						disabled: false,
						onClick: deleteFn,
					},
				];
}
