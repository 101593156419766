import { useContext } from "react";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import UiCard from "../../../components/card/Card";
import ParticipantDetailExternalLink from "../details/ParticipantDetailExternalLink";

const MentalHeatlhSurveyLinks = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const depressionSurveyLink =
		"https://ddlklzy1jno.typeform.com/to/wmUIoxTY#kannactid=" +
		participantContext.participant?.kannactId;
	const alcoholSurveyLink =
		"https://ddlklzy1jno.typeform.com/to/iBmKfuv7#kannactid=" +
		participantContext.participant?.kannactId;

	return (
		<UiCard
			title="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.MENTAL_HEALTH.SURVEY_LINKS_TITLE"
			titleUnderline={false}
		>
			<div className="mental-health-survey-links-card-container">
				<ParticipantDetailExternalLink
					href={depressionSurveyLink}
					title="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.MENTAL_HEALTH.SURVEY_LINKS.DEPRESSION_LINK"
				/>
				<ParticipantDetailExternalLink
					href={alcoholSurveyLink}
					title="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.MENTAL_HEALTH.SURVEY_LINKS.UNHEALTHY_ALCOHOL_USE_LINK"
				/>
			</div>
		</UiCard>
	);
};

export default MentalHeatlhSurveyLinks;
