import { UiFieldMessageProps } from "../../components/field-message.model";
import { GoalResponse } from "../../endpoints/goals_endpoint.model";
import { MedicationLatestResponse } from "../../endpoints/medications-endpoint.model";
import { Goal } from "../../entities/goal.model";
import { Source } from "../../entities/source.model";
import { MedicationsModalMode } from "./medications-modal";

// Component props
export type MedicationsListProps = {
	mode: MedicationsModalMode;
	participantId: number;
	medications: MedicationLatestResponse[];
	message: UiFieldMessageProps | null;
	goals?: Goal[];
	submitting: boolean;
	changeFn: () => void;
};

export type GoalTemplate =
	| {
			goals: GoalResponse[] | undefined;
			values: Array<number | null>;
	  }
	| null
	| undefined;

export interface IMedicationRow {
	id: number;
	name: string;
	dateFilled: Date; // Date
	dosage: string | undefined;
	dosageUnit: string | undefined;
	frequency: number | undefined;
	period: string | undefined;
	periodLabel: string | undefined;
	source: Source;
	sourceFormatted: string; // Source translation key
	participantId: number;
	active: boolean;
	activeFormatted: string;
	editing: boolean;
}

export class MedicationRow implements IMedicationRow {
	id: number;
	name: string;
	dateFilled: Date; // Date
	dosage: string | undefined;
	dosageUnit: string | undefined;
	frequency: number | undefined;
	period: string | undefined;
	periodLabel: string | undefined;
	source: Source;
	sourceFormatted: string; // Source translation key
	participantId: number;
	active: boolean;
	activeFormatted: string;
	editing: boolean;

	constructor(params: IMedicationRow) {
		this.id = params.id;
		this.name = params.name;
		this.dateFilled = params.dateFilled;
		this.dosage = params.dosage;
		this.dosageUnit = params.dosageUnit;
		this.frequency = params.frequency;
		this.period = params.period;
		this.periodLabel = params.periodLabel;
		this.source = params.source;
		this.sourceFormatted = params.sourceFormatted;
		this.participantId = params.participantId;
		this.active = params.active;
		this.activeFormatted = params.activeFormatted;
		this.editing = params.editing;
	}
}
