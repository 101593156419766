import { SmartGoalCategory } from "../../../../../models/custom-goals/custom-goals";
import { Form } from "react-final-form";
import { useState } from "react";
import { EndpointsService } from "../../../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { Formatter } from "../../../../../services/formatter/formatter.service";
import UiButton from "../../../../../components/button/Button";
import UiFieldMessage from "../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../models/components/field-message.model";
import UiInputTextarea from "../../../../../components/input-textarea/InputTextarea.component";
import UiInputText from "../../../../../components/input-text/InputText.component";
import UiInputDate from "../../../../../components/input-date/InputDate.component";
import { ListOption } from "../../../../../models/misc.model";
import UiInputCheckbox from "../../../../../components/input-checkbox/InputCheckbox.component";
import {
	CustomGoalsFormMode,
	SmartGoalFormData,
	SmartGoalsFormProps,
} from "../../../../../models/custom-goals/custom-goals-misc";
import { Validations } from "../../../../../services/form/validations.service";
import UiSelectMultiple from "../../../../../components/select-multiple/SelectMultiple.component";
import { NoteHelper } from "../../../../../services/helpers/note-helper";
import { useTranslation } from "react-i18next";
import { TableMessages } from "../../../../../models/components/table/table-message.model";
import FormSection from "../../../../../components/form-section/FormSection.component";

const SmartGoalForm = (props: SmartGoalsFormProps) => {
	const { t } = useTranslation("common");

	const messages = new TableMessages();
	const [message, setMessage] = useState<boolean>(false);
	const [formData, setFormData] = useState<SmartGoalFormData>({
		name: props.entity?.name ?? null,
		category: props.entity?.category ?? [],
		startDate: props.entity?.startDate ? Formatter.dateISOToUTC(props.entity.startDate) : null,
		endDate: props.entity?.endDate ? Formatter.dateISOToUTC(props.entity.endDate) : null,
		details: props.entity?.details ?? null,
		support: props.entity?.support ? props.entity?.support.map((x) => x.toString()) : [],
	});

	const handleSubmit = async (data: SmartGoalFormData) => {
		if (
			props.mode === CustomGoalsFormMode.CREATE ||
			props.mode === CustomGoalsFormMode.DUPLICATE_SMART_GOAL ||
			props.mode === CustomGoalsFormMode.DUPLICATE_MAINTENANCE_GOAL
		) {
			await create();
		} else {
			await update();
		}
	};

	const create = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.createSmartGoal({
				body: {
					participantId: props.participantId,
					name: formData.name!,
					category: formData.category!,
					startDate: Formatter.dateUTCWithoutTime(formData.startDate)?.toISOString()!,
					endDate: Formatter.dateUTCWithoutTime(formData.endDate)?.toISOString()!,
					details: formData.details,
					support: formData.support ? formData.support.map((x) => Number(x)) : [],
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	const update = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.updateSmartGoal({
				body: {
					id: props.entity!.id,
					name: formData.name!,
					category: formData.category!,
					startDate: Formatter.dateUTCWithoutTime(formData.startDate)?.toISOString()!,
					endDate: Formatter.dateUTCWithoutTime(formData.endDate)?.toISOString()!,
					details: formData.details,
					support: formData.support ? formData.support.map((x) => Number(x)) : [],
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<FormSection>
						<div className="mb-4">
							<span className="text-bold">
								{t(
									"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.INFO1_MSG"
								)}
							</span>
							<span>
								{t(
									"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.INFO2_MSG"
								)}
							</span>
						</div>

						<div className="row">
							<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<UiInputText
									id="name"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.smartGoal"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.smartGoal"
									name="name"
									onChange={(e) =>
										setFormData({ ...formData, name: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
								<UiSelectMultiple
									id="category"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.category"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.category"
									name="category"
									onChange={(e) =>
										setFormData({ ...formData, category: e.target.value })
									}
									options={Object.keys(SmartGoalCategory).map(
										(x) =>
											new ListOption({
												id: x,
												label: `ENUMS.CUSTOM_GOALS_CATEGORY.${x}`,
											})
									)}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<UiInputDate
									id="startDate"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.startDate"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.startDate"
									name="startDate"
									onChange={(e) =>
										setFormData({
											...formData,
											startDate: e.value as Date | null,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<UiInputDate
									id="endDate"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.endDate"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.endDate"
									name="endDate"
									onChange={(e) =>
										setFormData({
											...formData,
											endDate: e.value as Date | null,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
								<UiInputTextarea
									id="details"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.details"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.details"
									name="details"
									onChange={(e) =>
										setFormData({ ...formData, details: e.target.value })
									}
									disabled={submitting}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								{NoteHelper.activeLongTermGoals(
									props.longTermGoalsQuery.data,
									props.mode,
									props.entity
								).length > 0 ? (
									<UiInputCheckbox
										id="support"
										placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.support"
										label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.support"
										name="support"
										onChange={(e) =>
											setFormData({ ...formData, support: e.target.value })
										}
										options={NoteHelper.activeLongTermGoals(
											props.longTermGoalsQuery.data,
											props.mode,
											props.entity
										)}
										disabled={submitting}
									/>
								) : (
									<>
										<div className="mb-2">
											{t(
												"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.support"
											)}
										</div>
										<UiFieldMessage
											severity={FieldMessageSeverity.INFO}
											label={messages.empty}
										/>
									</>
								)}
							</div>
						</div>
					</FormSection>

					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.SUBMIT_ERROR"
							/>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							id="modalBtnCancel"
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							className="p-button-outlined p-button-rounded"
							type="button"
							onClick={props.onDone}
							disabled={submitting}
						></UiButton>
						<UiButton
							id="modalBtnSubmit"
							loading={submitting}
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							className="p-button-rounded"
							type="submit"
							onClick={handleSubmit}
							disabled={submitting || form.getState().invalid}
						></UiButton>
					</div>
				</form>
			)}
		/>
	);
};
export default SmartGoalForm;
