import { useAuth0 } from "@auth0/auth0-react";

type Auth0 = ReturnType<typeof useAuth0>;

let auth0Singleton: Auth0 | null = null;

export const getAuth0 = () => auth0Singleton;

export const setAuth0 = (auth0: Auth0) => {
	auth0Singleton = auth0;
};
