import { Timezone } from "../../models/entities/participant.model";
import { getBrowserLang } from "../i18n/i18n.service";

const getGs2Value = (timezone?: string) => {
	let result: null | string = null;
	const getTimezoneShortCode = getAbbreviation(timezone);

	if (getTimezoneShortCode) {
		switch (getTimezoneShortCode) {
			case "AKDT":
			case "AKST":
				result = Timezone.US_Alaska;
				break;
			// case 'MST': // TODO: Arizona belongs to the US/Mountain timezone
			// result = Timezone.US_Arizona;
			// 	break;
			case "CT":
			case "CST":
			case "CDT":
				result = Timezone.US_Central;
				break;
			case "ET":
			case "EST":
			case "EDT":
				result = Timezone.US_Eastern;
				break;
			case "HST":
			case "HDT":
				result = Timezone.US_Hawaii;
				break;
			case "MST":
			case "MDT":
				result = Timezone.US_Mountain;
				break;
			case "PST":
			case "PDT":
				result = Timezone.US_Pacific;
				break;
			default:
				break;
		}
	}

	return result;
};

const getAbbreviation = (timezone?: string) => {
	let result: null | string = null;

	if (timezone) {
		result =
			new Intl.DateTimeFormat(getBrowserLang(), {
				timeZoneName: "short",
				timeZone: timezone,
			})
				.formatToParts(new Date())
				.find((part) => part.type === "timeZoneName")?.value ?? null;
	}

	return result;
};

export const TimezoneService = {
	getGs2Value,
	getAbbreviation,
};
