import { useState } from "react";
import { IoPlayCircleOutline } from "react-icons/io5";
import { TableTemplateTwilioAudio } from "../../../models/components/table/table-template.model";
import UiTableTwilioAudioModal from "../../modal-twilio-audio/ModalTwilioAudio.component";

const UiTableTwilioAudioTemplate = (props: TableTemplateTwilioAudio): JSX.Element => {
	const [modal, setModal] = useState<TableTemplateTwilioAudio>(props);
	const [isVisible, setIsVisible] = useState(false);

	return (
		<>
			<div className="twilio-audio-template">
				<IoPlayCircleOutline
					className="audio-placeholder"
					onClick={() => setIsVisible(true)}
				/>
			</div>

			{isVisible && (
				<UiTableTwilioAudioModal
					callId={modal.callId}
					callDate={modal.callDate}
					kannactId={modal.kannactId}
					mediaUrl={modal.mediaUrl}
					isVisible={isVisible}
					onUpdate={(e) => setModal(e)}
					onClose={() => setIsVisible(false)}
				/>
			)}
		</>
	);
};
export default UiTableTwilioAudioTemplate;
