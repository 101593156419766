import { FormApi } from "final-form";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../components/button/Button";
import UiFormInfo from "../../../components/form-info/FormInfo.component";
import { List } from "../../../models/misc.model";
import { InputCheckboxFormData } from "../../../models/pages/showcases.model";
import { PageProps } from "../../../models/routing.model";
import { Validations } from "../../../services/form/validations.service";
import UiInputCheckbox from "../../../components/input-checkbox/InputCheckbox.component";

const InputCheckboxShowcase = (props: PageProps) => {
	const optionsList: List = [
		{ id: "1", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.1" },
		{ id: "2", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.2" },
		{ id: "3", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.3", disabled: true },
		{ id: "4", label: "SHOWCASES.SELECT_SHOWCASE.OPTIONS_LIST.4" },
	];

	const [formData, setFormData] = useState<InputCheckboxFormData>({
		default: null,
		default_value: ["1"],
		disabled: null,
		disabled_value: ["2"],
		required: null,
		multiple_validations: null,
		binary: false,
		binary_value: true,
		binary_disabled: false,
		binary_value_disabled: true,
	});
	useEffect(() => {
		console.log("Input checkbox: showcase > Form changed! ", formData);
	}, [formData]);

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	// Submit
	const handleSubmit = async (
		data: InputCheckboxFormData,
		form: FormApi<InputCheckboxFormData, InputCheckboxFormData>
	) => {
		console.log("Submitting... ", data, form, formData);
		await sleep(3000);
		// Update state onSubmit
		setFormData({ ...data });
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="default"
									label="SHOWCASES.COMMON_FIELDS.default"
									name="default"
									onChange={(e) =>
										setFormData({ ...formData, default: e.target.value })
									}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="default_value"
									placeholder="SHOWCASES.COMMON_FIELDS.default_value"
									label="SHOWCASES.COMMON_FIELDS.default_value"
									name="default_value"
									onChange={(e) =>
										setFormData({ ...formData, default_value: e.target.value })
									}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="disabled"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled"
									label="SHOWCASES.COMMON_FIELDS.disabled"
									name="disabled"
									onChange={(e) =>
										setFormData({ ...formData, disabled: e.target.value })
									}
									disabled={true}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="disabled_value"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled_value"
									label="SHOWCASES.COMMON_FIELDS.disabled_value"
									name="disabled_value"
									onChange={(e) =>
										setFormData({ ...formData, disabled_value: e.target.value })
									}
									disabled={true}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="required"
									placeholder="SHOWCASES.COMMON_FIELDS.required"
									label="SHOWCASES.COMMON_FIELDS.required"
									name="required"
									onChange={(e) =>
										setFormData({ ...formData, required: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="multiple_validations"
									placeholder="SHOWCASES.COMMON_FIELDS.multiple_validations"
									label="SHOWCASES.COMMON_FIELDS.multiple_validations"
									name="multiple_validations"
									onChange={(e) =>
										setFormData({
											...formData,
											multiple_validations: e.target.value,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
									options={optionsList}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="binary"
									name="binary"
									label="Binary"
									onChange={(e) =>
										setFormData({ ...formData, binary: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
									binary={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="binary_value"
									name="binary_value"
									label="Binary checked"
									onChange={(e) =>
										setFormData({ ...formData, binary_value: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
									binary={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="binary_disabled"
									name="binary_disabled"
									label="Binary disabled"
									onChange={(e) =>
										setFormData({
											...formData,
											binary_disabled: e.target.value,
										})
									}
									validations={[Validations.required]}
									disabled={true}
									binary={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputCheckbox
									id="binary_value_disabled"
									name="binary_value_disabled"
									label="Binary disabled"
									onChange={(e) =>
										setFormData({
											...formData,
											binary_value_disabled: e.target.value,
										})
									}
									validations={[Validations.required]}
									disabled={true}
									binary={true}
								/>
							</div>
						</div>

						{/* SUBMIT */}
						<div className="text-end">
							<UiButton
								id="btnSubmit"
								type="submit"
								label={
									submitting
										? "SHOWCASES.COMMON_ACTIONS.BTN_SUBMITTING"
										: "SHOWCASES.COMMON_ACTIONS.BTN_SUBMIT"
								}
								disabled={submitting}
								loading={submitting}
							/>
						</div>

						{/* FORM INFORMATION */}
						<UiFormInfo {...(form as any)}></UiFormInfo>
					</form>
				)}
			/>
		</>
	);
};

export default InputCheckboxShowcase;
