import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import {
	CustomGoalType,
	CustomGoalsFormMode,
} from "../../../../../models/custom-goals/custom-goals-misc";
import CustomGoalsCurrentTable from "./CustomGoalsCurrentTable";

const CustomGoalsCurrent = ({
	smartGoalsQuery,
	longTermGoalsQuery,
	onUpdate,
	onDelete,
	onComplete,
}: {
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onUpdate: (e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => void;
	onDelete: (e: CustomGoalType, e2: SmartGoal | LongTermGoal) => void;
	onComplete: (e: CustomGoalType, e2: SmartGoal | LongTermGoal) => void;
}) => {
	return (
		<>
			<CustomGoalsCurrentTable
				type={CustomGoalType.SMART_GOAL}
				smartGoalsQuery={smartGoalsQuery}
				longTermGoalsQuery={longTermGoalsQuery}
				onUpdate={onUpdate}
				onDelete={onDelete}
				onComplete={onComplete}
			/>

			<CustomGoalsCurrentTable
				type={CustomGoalType.LONG_TERM_GOAL}
				smartGoalsQuery={smartGoalsQuery}
				longTermGoalsQuery={longTermGoalsQuery}
				onUpdate={onUpdate}
				onDelete={onDelete}
				onComplete={onComplete}
			/>

			<CustomGoalsCurrentTable
				type={CustomGoalType.MAINTENANCE_GOAL}
				smartGoalsQuery={smartGoalsQuery}
				longTermGoalsQuery={longTermGoalsQuery}
				onUpdate={onUpdate}
				onDelete={onDelete}
				onComplete={onComplete}
			/>
		</>
	);
};
export default CustomGoalsCurrent;
