// The order here is important, is used to sort the goals we receive from the api
export enum SuStatus {
	off_track = "off_track",
	therapy_off_track = "therapy_off_track",
	survey_off_track = "survey_off_track",
	never_received = "never_received",
	no_data = "no_data",
	due_soon = "due_soon",
	therapy_due_soon = "therapy_due_soon",
	survey_due_soon = "survey_due_soon",
	fallback = "fallback",
	expired = "expired",
	on_track = "on_track",
}

/**
 * STRUCTURE OF THE SERVICE UTILIZATION GOALS:
 *
 * - Category 1
 * 		- Service/Goal 1
 * 			- Exam type 1
 * 			- Exam type 2
 * 			- Exam type 3
 * 		- Service/Goal 2
 * 			- Exam type 1
 * 			- Exam type 2
 *
 * - Category 2
 * 		- Service/Goal 1
 * 			- Exam type 1
 * 		- Service/Goal 2
 * 			- Exam type 1
 * 			- Exam type 2
 */

// Categories
export enum SuCategories {
	SCREENINGS = "SCREENINGS",
	VACCINES = "VACCINES",
	MENTAL_HEALTH = "MENTAL_HEALTH",
	NON_APPLICABLE = "NON_APPLICABLE",
}

// Category services (goalName)
export enum SuServices {
	/* Screenings */
	PREVENTIVE_CARE = "PREVENTIVE_CARE",
	BREAST_CANCER = "BREAST_CANCER",
	CERVICAL_CANCER = "CERVICAL_CANCER",
	COLORECTAL_CANCER = "COLORECTAL_CANCER",

	/* Vaccines */
	INFLUENZA = "INFLUENZA",
	ZOSTER = "ZOSTER",
	PNEUMOCOCCAL = "PNEUMOCOCCAL",
	TD_DAP = "TD_DAP",

	/* Mental health */
	DEPRESSION = "DEPRESSION",
	UNHEALTHY_ALCOHOL_USE = "UNHEALTHY_ALCOHOL_USE",
}

export enum SuExam {
	/* Screenings */

	// Preventive care
	CARE_VISIT = "CARE_VISIT",
	// Breast cancer
	BREAST_CANCER = "BREAST_CANCER",
	// Cervical cancer
	CERVICAL_CYTOLOGY = "CERVICAL_CYTOLOGY",
	HPV_TESTING = "HPV_TESTING",
	CERVICAL_CYTOLOGY_HPV_COTESTING = "CERVICAL_CYTOLOGY_HPV_COTESTING",
	// Colorectal cancer
	FECAL_OCCULT_BLOOD_TEST = "FECAL_OCCULT_BLOOD_TEST",
	FLEXIBLE_SIGMOIDOSCOPY = "FLEXIBLE_SIGMOIDOSCOPY",
	COLONOSCOPY = "COLONOSCOPY",
	COMPUTED_TOMOGRAPHY_COLONOGRAPHY = "COMPUTED_TOMOGRAPHY_COLONOGRAPHY",
	STOOL_DNA_TEST = "STOOL_DNA_TEST",

	/* Vaccines */

	// Vaccine: Influenza
	INFLUENZA_VACCINE = "INFLUENZA_VACCINE",
	// Vaccine: Zoster
	ZOSTER_VACCINE = "ZOSTER_VACCINE",
	// Vaccine: Pneumococal
	PNEUMOCOCCAL_VACCINE = "PNEUMOCOCCAL_VACCINE",
	// Vaccine: Td/Tdap
	TD_VACCINE = "TD_VACCINE",
	TDAP_VACCINE = "TDAP_VACCINE",

	/* Mental health */

	// Depression
	DEPRESSION_POSITIVE = "DEPRESSION_POSITIVE",
	DEPRESSION_NEGATIVE = "DEPRESSION_NEGATIVE",
	DEPRESSION_THERAPY = "DEPRESSION_THERAPY",

	// Unhealthy alcohol use
	UNHEALTHY_ALCOHOL_USE_POSITIVE = "UNHEALTHY_ALCOHOL_USE_POSITIVE",
	UNHEALTHY_ALCOHOL_USE_NEGATIVE = "UNHEALTHY_ALCOHOL_USE_NEGATIVE",
	UNHEALTHY_ALCOHOL_USE_THERAPY = "UNHEALTHY_ALCOHOL_USE_THERAPY",
}

export interface SuGoal {
	goalName: SuServices;
	date: string; // Date
	dueDate: string | null; // Date
	state: SuStatus;
	category: SuCategories;
	metadata: SuMetadata;
	eligible: boolean;
	applicable: boolean;
	examTypes: SuExam[];
}

export interface SuMetadata {
	eligibility_criteria: any[];
	events: SuEvent[];
	goal_criteria: any[];
	goal_end: string; // Date
	goal_start: string; // Date
}

export interface SuEvent {
	date: Date;
	description: string;
	source: string;
	notes: string;
	estimated: boolean;
	value: boolean;
}

export interface ServiceUtilizationExamTypes {
	category: SuCategories;
	goals: ServiceUtilizationExamGoal[];
}

export interface ServiceUtilizationExamGoal {
	name: SuServices;
	events: SuExam[];
}
