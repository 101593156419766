const CryptoJS = require("crypto-js");

const encrypt = (key: string, data: any): void => {
	const encryptedMessage = CryptoJS.AES.encrypt(
		JSON.stringify(data),
		process.env.REACT_APP_CRYPTO_JS_KEY
	).toString();
	sessionStorage.setItem(key, encryptedMessage);
};

const decrypt = (key: string): any => {
	let response: any = null;
	const encryptedData: string | null = sessionStorage.getItem(key);
	if (encryptedData) {
		const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_JS_KEY);
		response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}
	return response;
};

export const getItem = (key: string): any => decrypt(key);
export const setItem = (key: string, data: any): void => {
	encrypt(key, data);
};
export const deleteItem = (key: string): void => {
	sessionStorage.removeItem(key);
};
