export enum CallLogDirection {
	OUTBOUND_API = "outbound-api",
	OUTBOUND_DIAL = "outbound-dial",
	INBOUND = "inbound",
}

export enum CallStatus {
	QUEUED = "QUEUED",
	RINGING = "RINGING",
	IN_PROGRESS = "IN_PROGRESS",
	CANCELED = "CANCELED",
	COMPLETED = "COMPLETED",
	FAILED = "FAILED",
	BUSY = "BUSY",
	NO_ANSWER = "NO_ANSWER",
}

export interface CallerInfo {
	id: number;
	firstName: string;
	lastName: string;
	kannactId: string;
	brandLogo: string;
	brandIcon: string;
	brandName: string;
}

export interface Call {
	accountSid: string;
	annotation: string;
	answeredBy: string;
	apiVersion: string; // Date
	callerName: string;
	dateCreated: string; // Date
	dateUpdated: string; // Date
	direction: CallLogDirection;
	duration: string; // Seconds
	endTime: string; // Date
	forwardedFrom: string;
	from: string;
	fromFormatted: string;
	groupSid: string;
	parentCallSid: string;
	phoneNumberSid: string;
	price: number;
	priceUnit: string;
	sid: string;
	startTime: string; // Date
	status: CallStatus;
	subresourceUris: {
		feedback: string;
		notifications: string;
		recordings: string;
		streams: string;
		payments: string;
		siprec: string;
		events: string;
		feedback_summaries: string;
	};
	to: string;
	toFormatted: string;
	uri: string;
}
