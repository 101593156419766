import { SessionCoach } from "../../models/session.model";
import * as dataStorageService from "./session.service";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

/**
 * SESSION
 */

// Session > DELETE
export const deleteSession = (): void => {
	dataStorageService.deleteItem("k-user");
	deleteCookieToken();
};

// Session > SET
export const setSession = (userData: SessionCoach, token: string): void => {
	dataStorageService.setItem("k-user", userData);
	setCookieToken(token);
};

// Session storage > SET User
export const setSessionStorageUser = (userData: SessionCoach): void => {
	dataStorageService.setItem("k-user", userData);
};

// Session storage > GET User
export const getSessionStorageUser = (): SessionCoach => dataStorageService.getItem("k-user");

/**
 * COOKIE
 */

// Cookie > DELETE Token
export const deleteCookieToken = (): void => {
	cookies.remove("k-token", { domain: cookieHostname, path: "/" });
};

const cookieHostname = window.location.hostname.startsWith(".")
	? window.location.hostname.substring(1)
	: window.location.hostname;

// Cookie > SET expiration date (8 hours)
const setCookieExpirationDate = (): Date => {
	const expirationDate: Date = new Date();
	expirationDate.setHours(expirationDate.getHours() + 9);
	return expirationDate;
};

// Cookie > SET Token
export const setCookieToken = (token: string): void =>
	cookies.set("k-token", token, {
		expires: setCookieExpirationDate(),
		domain: cookieHostname,
		path: "/",
	});

// Cookie > GET Token
export const getCookieToken = (): string => cookies.get("k-token");

// Cookie > Check token validity
export const validCookieToken = (): boolean => !!cookies.get("k-token");

// Cookie > SET Twilio Token
export const setTwilioCookieToken = (token: string): void =>
	cookies.set("k-t-token", token, {
		expires: setCookieExpirationDate(),
		domain: cookieHostname,
		path: "/",
	});

// Cookie > GET Twilio Token
export const getTwilioCookieToken = (): string => cookies.get("k-t-token");

// Cookie > Check Twilio Token validity
export const validTwilioCookieToken = (): boolean => !!cookies.get("k-t-token");
