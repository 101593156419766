import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiInputTextarea from "../../../components/input-textarea/InputTextarea.component";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiButton from "../../../components/button/Button";
import FormSection from "../../../components/form-section/FormSection.component";

export interface MarkAsNeverReceivedModalState {
	isOpen: boolean;
	goalId: string;
	goalName: string;
	goalTitle: string;
	goalCategory: string;
}

const MarkAsNeverReceivedModal = (
	props: MarkAsNeverReceivedModalState & {
		onSubmitForm: (notes?: string) => Promise<void>;
		onCloseModal: () => void;
	}
) => {
	const { t } = useTranslation("common");
	const [errorOnSubmitForm, setErrorOnSubmitForm] = useState(false);

	const onSubmit = async ({ notes }: { notes?: string }) => {
		try {
			setErrorOnSubmitForm(false);
			await props.onSubmitForm(notes);
		} catch (err) {
			setErrorOnSubmitForm(true);
		}
	};

	return (
		<Dialog
			header={t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.TITLE")}
			visible
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "50vw" }}
			modal={true}
			draggable={false}
			focusOnShow={false}
			onHide={props.onCloseModal}
		>
			<p>
				{t(
					"PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.DESCRIPTION",
					{
						goalTitle: t(props.goalTitle),
					}
				)}
			</p>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<FormSection>
							<UiInputTextarea
								id="notes"
								name="notes"
								placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.INPUT_LABEL"
								label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.INPUT_LABEL"
								disabled={submitting}
							/>
						</FormSection>

						{errorOnSubmitForm && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
								/>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.CANCEL_BUTTON"
								type="reset"
								className="p-button-outlined p-button-rounded"
								onClick={props.onCloseModal}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.CONFIRMING_BUTTON"
										: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.MARK_AS_NEVER_RECEIVED_MODAL.CONFIRM_BUTTON"
								}
								type="submit"
								className="p-button-rounded"
								disabled={submitting}
							/>
						</div>
					</form>
				)}
			></Form>
		</Dialog>
	);
};

export const useMarkAsNeverReceivedModal = () => {
	const [state, setState] = useState<MarkAsNeverReceivedModalState>({
		isOpen: false,
		goalId: "",
		goalName: "",
		goalTitle: "",
		goalCategory: "",
	});

	return {
		state,
		openModal: (state: MarkAsNeverReceivedModalState) => {
			setState({
				...state,
			});
		},
		closeModal: () => {
			setState({
				...state,
				isOpen: false,
			});
		},
	};
};

export default MarkAsNeverReceivedModal;
