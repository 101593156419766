import { TableHeaderCrud, ITableHeaderCrud } from "./table-crud.model";
import { TableHeaderFilter } from "./table-filter.model";
import { TableHeaderRefresh, ITableHeaderRefresh } from "./table-refresh.model";
import { TableHeaderSearch, ITableHeaderSearch } from "./table-search.model";
import { UiTableProps } from "./table.model";

export interface ITableHeaderProps {
	title?: string;
	customHeader?: React.ReactNode;
	refresh?: ITableHeaderRefresh;
	crud?: ITableHeaderCrud;
	search?: ITableHeaderSearch;
	filter?: TableHeaderFilter;
}

export class TableHeaderProps implements ITableHeaderProps {
	title?: string;
	customHeader?: React.ReactNode;
	refresh?: TableHeaderRefresh;
	crud?: TableHeaderCrud;
	search?: TableHeaderSearch;
	filter?: TableHeaderFilter;

	constructor(params: UiTableProps) {
		this.title = params.title;
		this.customHeader = params.customHeader;
		this.refresh = params.refresh && new TableHeaderRefresh(params);
		this.search = params.search && new TableHeaderSearch(params);
		this.crud = params.crud && new TableHeaderCrud(params);
		this.filter = params.filter && new TableHeaderFilter(params);
	}
}
