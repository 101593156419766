import { useEffect, useState } from "react";
import { CallManagementEvent } from "../../models/call-management.model";
import TwilioVoiceService from "../../services/twilio/twilio-voice.service";
import CallPresenter from "../call-presenter/CallPresenter.component";

const UiCallBar = () => {
	const [state, setState] = useState<CallManagementEvent>();

	useEffect(() => {
		TwilioVoiceService.initialize();
		TwilioVoiceService.callManagementSubject.subscribe((event?: CallManagementEvent) =>
			setState(event)
		);
	}, []);

	return (
		<>
			{state?.callManagementState?.calls && state.callManagementState.calls.length > 0 && (
				<div className="k-call-bar">
					{TwilioVoiceService.state.calls.map((callItem) => (
						<CallPresenter
							key={callItem.state.id}
							callPresenter={callItem}
						></CallPresenter>
					))}
				</div>
			)}
		</>
	);
};

export default UiCallBar;
