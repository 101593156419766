import { useEffect, useRef, useState } from "react";
import { ParticipantProfile } from "../../../../models/entities/participant.model";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import TwilioConversationService from "../../../../services/twilio/twilio-conversations.service";
import UiButton from "../../../../components/button/Button";
import { BiMessageAltCheck } from "react-icons/bi";
import { Form } from "react-final-form";
import ConversationMessage from "./ConversationMessage";
import { ConversationMessageForm } from "../../../../models/pages/care-messages.model";
import { Conversation, Message } from "@twilio/conversations";
import { IoMdSend } from "react-icons/io";
import { FormApi } from "final-form";
import { Validations } from "../../../../services/form/validations.service";
import { useMarkConversationAsRead } from "./useMarkConversationAsRead";
import { useUnreadMessagesCounts } from "./useUnreadMessagesCounts";
import { useConversationMembers } from "./useConversationMembers";
import UiInputTextarea from "../../../../components/input-textarea/InputTextarea.component";
import { TwilioDraft, TwilioDraftService } from "../../../../services/twilio/twilio-draft.service";

const ParticipantCareMessages = ({ participant }: { participant: ParticipantProfile }) => {
	const [loadingConversation, setLoadingConversation] = useState<boolean>(true);

	// Is the coach a member of the conversation?

	const unreadMessagesCounts = useUnreadMessagesCounts({
		participantId: participant.id,
	});

	const conversationMembers = useConversationMembers({
		participantId: participant.id,
		participantFirstName: participant.firstName,
		participantLastName: participant.lastName,
	});

	const [messages, setMessages] = useState<Message[]>([]);
	const [prevMessages, setPrevMessages] = useState<Message[]>([]);

	// Messages container (scroll to top when laoding previous messages)
	const messagesContainerRef = useRef<HTMLDivElement>(null);

	// The bottom of the messages container (scroll to bottom when sending a new message)
	const messagesEndRef = useRef<HTMLDivElement>(null);

	// Spinner: loading previous messages
	const [loadingMessages, setLoadingMessages] = useState<boolean>(false);

	// Form: send new message
	const [formData, setFormData] = useState<ConversationMessageForm>({
		message: TwilioDraftService.getDraft(participant.id, TwilioDraft.CARE_MESSAGE) ?? null,
		error: null,
	});

	// When loaded for the 1st time
	useEffect(() => {
		setLoadingConversation(true);

		getConversation();
		// Listen to the conversation changes
		const conversationSubscription = TwilioConversationService.conversationSubject.subscribe(
			(event: Conversation | null) => {
				if (TwilioConversationService.conversation) {
					getMessages().finally(() => scrollToBottom());
				}
			}
		);

		// Listen to the messages changes (messageAdded - sent/received)
		const messageAddedSubscription = TwilioConversationService.messageAddedSubject.subscribe(
			(event: Message | undefined) => {
				if (event) {
					getMessages().finally(() => scrollToBottom());
				}
			}
		);

		// Listen to the unread count changes
		const unreadCountSubscription = TwilioConversationService.unreadCountSubject.subscribe(() =>
			unreadMessagesCounts.invalidate()
		);

		// Remove listeners
		return () => {
			if (unreadCountSubscription) unreadCountSubscription.unsubscribe();
			if (conversationSubscription) conversationSubscription.unsubscribe();
			if (messageAddedSubscription) messageAddedSubscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		scrollToTop();
	}, [prevMessages]);

	/**
	 * Conversation: get the conversation SID
	 * @description Check if there is already a conversation with this participant. If there is return the SID.
	 */
	const getConversation = async (): Promise<void> => {
		if (!TwilioConversationService.conversation) {
			TwilioConversationService.getConversation(participant.id).finally(() =>
				setLoadingConversation(false)
			);
		} else {
			setLoadingConversation(false);
		}
	};

	// Get the conversation messages
	const getMessages = async (): Promise<void> => {
		if (TwilioConversationService.conversation) {
			setLoadingMessages(true);
			TwilioConversationService.getMessages()
				.then((response) => {
					setMessages(response);
				})
				.finally(() => setLoadingMessages(false));
		}
	};

	// Get previous messages (when scrolling to top)
	const getPreviousMessages = async (): Promise<void> => {
		// Scrolled to bottom
		// if (scrollTop + clientHeight === scrollHeight)

		// Scrolled to top
		if (
			messagesContainerRef.current?.scrollTop === 0 &&
			TwilioConversationService.messagesPaginator?.hasPrevPage
		) {
			setLoadingMessages(true);
			TwilioConversationService.getPreviousMessages()
				.then((response) => {
					setPrevMessages(response);
				})
				.finally(() => setLoadingMessages(false));
		}
	};

	const scrollToTop = (): void =>
		messagesContainerRef.current?.scrollTo({ top: 10, behavior: "smooth" });

	const scrollToBottom = (): void =>
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

	// Submit: send a new message
	const handleSubmit = async (
		_data: ConversationMessageForm,
		form: FormApi<ConversationMessageForm, ConversationMessageForm>
	) => {
		setFormData({ ...formData, error: null });

		await TwilioConversationService.sendMessage(`${formData.message}`, participant.id)
			.then((response) => {
				// When sending a new message, scroll to bottom
				scrollToBottom();

				// Reset message form
				setFormData({ ...formData, message: null, error: null });
				TwilioDraftService.deleteDraft(participant.id, TwilioDraft.CARE_MESSAGE);
				form.resetFieldState("message");
			})
			.catch((error) => {
				console.error("[ Conversations ] Error sending message: ", error);
				// Show error message
				setFormData({
					...formData,
					error: "PARTICIPANT.MESSAGES.CARE_MESSAGES.CONVERSATION_INFO.ERROR_SEND_MESSAGE",
				});
			});
	};

	const markConversationAsRead = useMarkConversationAsRead();

	const hasUnreadMessages = !!unreadMessagesCounts.data?.careMessageUnreadCount;

	return (
		<div className="messages-wrapper">
			{/* Loading spinner */}
			{loadingConversation ? (
				<div className="k-empty-message">
					<UiFieldMessage
						severity={FieldMessageSeverity.LOADING}
						label="PARTICIPANT.MESSAGES.CARE_MESSAGES.EMPTY_MESSAGE_LOADING"
					/>
				</div>
			) : (
				<>
					{/* Conversation: information */}
					<div className="messages-info-responsive">
						{/* Mark all the messages as read */}
						{hasUnreadMessages && (
							<UiButton
								label="PARTICIPANT.MESSAGES.CARE_MESSAGES.CONVERSATION_INFO.MARK_ALL_READ"
								className="p-button-sm p-button-rounded mb-2"
								iconPos="left"
								disabled={markConversationAsRead.isLoading}
								onClick={() =>
									markConversationAsRead.mutate({
										participantId: participant.id,
										type: "message",
									})
								}
							/>
						)}
					</div>

					{/* Conversation: chat */}
					<div className="messages-container">
						{/* Message: history */}
						<div
							className="messages-history"
							onScroll={getPreviousMessages}
							ref={messagesContainerRef}
						>
							{/* Loading spinner */}
							{loadingMessages && <span className="pi pi-spin pi-spinner"></span>}

							{/* Messages: list */}
							{prevMessages.map((message) => (
								<ConversationMessage
									key={message.sid}
									message={message}
									members={conversationMembers.data ?? {}}
								/>
							))}
							{messages.map((message) => (
								<ConversationMessage
									key={message.sid}
									message={message}
									members={conversationMembers.data ?? {}}
								/>
							))}

							{/* Messages: dummy div to scroll to when a new messages is sent/received */}
							<div ref={messagesEndRef} />
						</div>

						{/* Message: write */}
						<Form
							onSubmit={handleSubmit}
							initialValues={formData}
							render={({ handleSubmit, form, submitting }) => (
								<form onSubmit={handleSubmit}>
									<div className="messages-write">
										<UiInputTextarea
											id="message"
											placeholder="PARTICIPANT.MESSAGES.CARE_MESSAGES.CONVERSATION_MESSAGES.PLACEHOLDER"
											name="message"
											autoResize={true}
											onChange={(e) => {
												setFormData({
													...formData,
													message: e.target.value,
												});
												TwilioDraftService.saveDraft(
													participant.id,
													TwilioDraft.CARE_MESSAGE,
													e.target.value
												);
											}}
											validations={[Validations.required]}
											disabled={submitting}
										/>
										<UiButton
											label={
												submitting
													? "PARTICIPANT.MESSAGES.CARE_MESSAGES.CONVERSATION_MESSAGES.SUBMITTING"
													: "PARTICIPANT.MESSAGES.CARE_MESSAGES.CONVERSATION_MESSAGES.SUBMIT"
											}
											className="p-button-rounded"
											type="submit"
											disabled={submitting}
										/>
									</div>
								</form>
							)}
						/>

						{formData.error && (
							<div className="k-empty-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label={formData.error}
								/>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ParticipantCareMessages;
