import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import FormSection from "../../../components/form-section/FormSection.component";
import { Form } from "react-final-form";
import { useState } from "react";
import UiButton from "../../../components/button/Button";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiInputText from "../../../components/input-text/InputText.component";
import UiSelect from "../../../components/select/Select.component";
import {
	eParticipantStatus,
	ParticipantProfile,
	UnenrollmentReason,
} from "../../../models/entities/participant.model";
import { ListOption } from "../../../models/misc.model";
import { Validations } from "../../../services/form/validations.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";

export interface ModalProfileEventsProps {
	isVisible: boolean;
	participantId: number;
	mode: "OPT_OUT" | "OPT_IN" | "ENROLLED";
	onComplete: (e: ParticipantProfile | null) => void;
	onClose: () => void;
}

/**
 * OPT_IN:
 * - Mark as contactable
 * - Create event: PARTICIPANT_OPT_IN
 * - Status changes to: ACTIVE / PROSPECTIVE
 *
 * OPT_OUT:
 * - Mark as do not contact
 * - Create event: PARTICIPANT_OPT_OUT
 * - Status changes to: DNC
 *
 * ENROLLED:
 * - Create event: PARTICIPANT_ENROLLED
 * - Status changes to: ACTIVE
 * - Sets enrollment date
 */

const ModalProfileEvents = (props: ModalProfileEventsProps) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState(false);
	const [formData, setFormData] = useState<{
		unenrollmentReason: string | null;
		unenrollmentOtherReason: string | null;
	}>({
		unenrollmentReason: null,
		unenrollmentOtherReason: null,
	});

	const handleSubmit = async () => {
		setMessage(false);
		if (props.mode === "OPT_OUT") {
			await onOptOut();
		} else if (props.mode === "OPT_IN") {
			await onOptIn();
		} else if (props.mode === "ENROLLED") {
			await onEnrolled();
		}
	};

	const onOptOut = async () => {
		// This endpoint internally sends the PARTICIPANT_OPT_OUT event
		await EndpointsService.dataRetriever
			.updateProfileKannactInfo({
				body: {
					participantId: props.participantId,
					status: eParticipantStatus.DNC,
					unenrollmentReason: formData.unenrollmentReason,
					unenrollmentOtherReason: formData.unenrollmentOtherReason,
				},
			})
			.then((e) => props.onComplete(e))
			.catch(() => setMessage(true));
	};

	const onOptIn = async () => {
		await EndpointsService.dataRetriever
			.participantOptIn({
				config: {
					params: { id: props.participantId },
				},
			})
			.then(() => props.onComplete(null))
			.catch(() => setMessage(true));
	};

	const onEnrolled = async () => {
		// This endpoint internally sends the PARTICIPANT_OPT_OUT event
		await EndpointsService.dataRetriever
			.updateProfileKannactInfo({
				body: {
					participantId: props.participantId,
					status: eParticipantStatus.ACTIVE,
				},
			})
			.then((e) => props.onComplete(e))
			.catch(() => setMessage(true));
	};

	return (
		<Dialog
			header={t(
				props.mode === "OPT_OUT"
					? "PARTICIPANT.DETAILS.MODALS.TITLE_OPT_OUT"
					: props.mode === "OPT_IN"
						? "PARTICIPANT.DETAILS.MODALS.TITLE_OPT_IN"
						: "PARTICIPANT.DETAILS.MODALS.TITLE_ENROLLED"
			)}
			visible={props.isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={props.onClose}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						{/* OPT_IN */}
						{props.mode === "OPT_IN" && (
							<p>{t("PARTICIPANT.DETAILS.MODALS.MSG_OPT_IN")}</p>
						)}

						{/* ENROLLED */}
						{props.mode === "ENROLLED" && (
							<p>{t("PARTICIPANT.DETAILS.MODALS.MSG_ENROLLED")}</p>
						)}

						{/* OPT_OUT */}
						{props.mode === "OPT_OUT" && (
							<>
								{/* Message */}
								<div className="mb-4">
									{t("PARTICIPANT.DETAILS.MODALS.MSG_OPT_OUT")}
								</div>

								<FormSection className="row">
									<div className="col-12 col-md-8 col-lg-8 col-xl-6 col-xxl-6">
										<UiSelect
											id="unenrollmentReason"
											label="PARTICIPANT.DETAILS.MODALS.PLACEHOLDER_OPT_OUT"
											name="unenrollmentReason"
											removeBottomSpacer={
												formData.unenrollmentReason !==
												UnenrollmentReason.OTHER
											}
											onChange={(e) =>
												setFormData({
													...formData,
													unenrollmentReason: e.target.value,
												})
											}
											options={Object.keys(UnenrollmentReason).map(
												(x) =>
													new ListOption({
														id: x,
														label: `ENUMS.UNENROLLMENT_REASON.${x}`,
													})
											)}
											disabled={submitting}
											validations={[Validations.required]}
										/>
									</div>

									{formData.unenrollmentReason === "OTHER" && (
										<div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
											<UiInputText
												id="unenrollmentOtherReason"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.unenrollmentOtherReason"
												name="unenrollmentOtherReason"
												removeBottomSpacer={true}
												onChange={(e) =>
													setFormData({
														...formData,
														unenrollmentOtherReason: e.target.value,
													})
												}
												validations={
													formData.unenrollmentReason ===
													UnenrollmentReason.OTHER
														? [Validations.required]
														: []
												}
												disabled={submitting}
											/>
										</div>
									)}
								</FormSection>
							</>
						)}

						{message && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label="PARTICIPANT.DETAILS.SUBMIT_ERROR"
								/>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								label="UI_COMPONENTS.BUTTONS.NEVERMIND"
								type="button"
								className="p-button-outlined p-button-rounded"
								onClick={props.onClose}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "UI_COMPONENTS.BUTTONS.SAVING"
										: "UI_COMPONENTS.BUTTONS.CONFIRM"
								}
								className="p-button-rounded"
								type="submit"
								loading={submitting}
								disabled={submitting}
								onClick={handleSubmit}
							/>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default ModalProfileEvents;
