import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { PageProps } from "../../../models/routing.model";
import UiCard from "../../../components/card/Card";
import ParticipantEngagementHistory from "./engagement-history/ParticipantEngagementHistory";
import ParticipantPastAppointments from "./past-appointments/ParticipantPastAppointments";
import ParticipantUpcomingAppointments from "./upcoming-appointments/ParticipantUpcomingAppointments";

const ParticipantAppointments = (props: PageProps) => {
	const { t } = useTranslation("common");

	// Navigation / URL params
	const navigate = useNavigate();
	const urlParams = useParams<{ participantId: string; type?: string }>();

	// Tabs
	const tabs: { [x: number]: string } = {
		0: "upcoming",
		1: "past",
		2: "engagement-history",
	};
	const activeIndex =
		urlParams.type === "engagement-history" ? 2 : urlParams.type === "past" ? 1 : 0;
	const switchTab = (index: number) =>
		navigate(`/participant/${urlParams.participantId}/appointments/${tabs[index]}`, {
			replace: true,
		});

	return (
		<UiCard
			title="PARTICIPANT.APPOINTMENTS.TITLE"
			titleUnderline={false}
		>
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => switchTab(e.index)}
			>
				{/* Upcoming */}
				<TabPanel header={t("PARTICIPANT.APPOINTMENTS.TAB_TITLE_UPCOMING")}>
					<ParticipantUpcomingAppointments />
				</TabPanel>

				{/* Past */}
				<TabPanel header={t("PARTICIPANT.APPOINTMENTS.TAB_TITLE_PAST")}>
					<ParticipantPastAppointments />
				</TabPanel>

				{/* Engagements history */}
				<TabPanel header={t("PARTICIPANT.APPOINTMENTS.ENGAGEMENT_HISTORY.TITLE")}>
					<ParticipantEngagementHistory />
				</TabPanel>
			</TabView>
		</UiCard>
	);
};
export default ParticipantAppointments;
