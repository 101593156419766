import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { Formatter } from "../../services/formatter/formatter.service";
import UiButton from "../button/Button";
import UiInputText from "../input-text/InputText.component";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import TwilioVoiceService from "../../services/twilio/twilio-voice.service";
import { UiFieldMessageProps } from "../../models/components/field-message.model";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { Device } from "@twilio/voice-sdk";
import { getSessionStorageUser } from "../../services/session/auth.service";
import { CallManagementEvent } from "../../models/call-management.model";
import { OverlayPanel } from "primereact/overlaypanel";
import { useMediaQuery } from "react-responsive";
import { BsTelephoneFill } from "react-icons/bs";

const UiDialPad = () => {
	const { t } = useTranslation("common");
	const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const op = useRef<OverlayPanel>(null);

	const setKeypadTemplate = (options: PanelHeaderTemplateOptions): React.ReactNode => <></>;

	const keypadKeys: { [x: string]: { value: string; subValue?: string }[] } = {
		row1: [
			{ value: "1", subValue: "-" },
			{ value: "2", subValue: "ABC" },
			{ value: "3", subValue: "DEF" },
		],
		row2: [
			{ value: "4", subValue: "GHI" },
			{ value: "5", subValue: "JKL" },
			{ value: "6", subValue: "MNO" },
		],
		row3: [
			{ value: "7", subValue: "PQRS" },
			{ value: "8", subValue: "TUV" },
			{ value: "9", subValue: "WXYZ" },
		],
		row4: [{ value: "*" }, { value: "0", subValue: "+" }, { value: "#" }],
	};

	const setKeypadKeyTemplate = (
		key: string,
		keyRow: { value: string; subValue?: string }[]
	): React.ReactNode => {
		return (
			<div
				key={key}
				className="dialkey-row"
			>
				{keyRow.map((key) => (
					<div
						className="key-item"
						key={key.value}
						onClick={(e) => updatePhoneNumber(true, key.value)}
						role="button"
						tabIndex={0}
					>
						<span className="key-value">{key.value}</span>
						{key.subValue && <span className="key-subvalue">{key.subValue}</span>}
					</div>
				))}
			</div>
		);
	};

	const setKeypadRowTemplate = (): React.ReactNode[] => {
		const rows: React.ReactNode[] = [];
		Object.entries(keypadKeys).forEach(([key, val]) => {
			const row: React.ReactNode = setKeypadKeyTemplate(key, val);
			rows.push(row);
		});
		return rows;
	};

	/**
	 * Phone number
	 */

	const [formData, setFormData] = useState<{
		phoneNumberTo: string;
		formMessage?: UiFieldMessageProps;
	}>({
		phoneNumberTo: "+1",
		formMessage: undefined,
	});
	const updatePhoneNumber = (keypad: boolean, value: string) => {
		if (keypad) setFormData({ ...formData, phoneNumberTo: formData.phoneNumberTo + value });
		else setFormData({ ...formData, phoneNumberTo: value });
	};

	// Outbound call
	const call = (data: { phoneNumberTo: string }, form: any): void => {
		// Reset previous data
		op.current?.hide();
		TwilioVoiceService.outboundCall(data.phoneNumberTo);
		formData.phoneNumberTo = "+1";
		setFormData({ ...formData, formMessage: undefined });
	};

	useEffect(() => {
		TwilioVoiceService.callManagementSubject.subscribe((event?: CallManagementEvent) => {
			// Device availability: is registered and is not on an active call
			if (event)
				setIsDisabled(
					event?.callManagementState.device?.state !== Device.State.Registered ||
						!!event?.callManagementState.device?.isBusy
				);
		});
	}, []);

	return (
		<div className="k-dialpad-container">
			{/* Phone icon (clickable) */}
			<UiButton
				icon={<BsTelephoneFill />}
				className={isDesktop ? "p-button-rounded p-button-sm" : "call-responsive"}
				label={isDesktop ? undefined : "UI_COMPONENTS.DIALPAD.TITLE"}
				onClick={(e) => op.current?.toggle(e)}
				aria-haspopup
				aria-controls="overlay_panel"
				disabled={!formData.phoneNumberTo || isDisabled}
			></UiButton>

			<OverlayPanel
				className="k-dialpad-overlay"
				ref={op}
			>
				{/* Calling from */}
				<div className="dial-from-container">
					<span className="dial-from-text">{t("UI_COMPONENTS.DIALPAD.FROM")}</span>
					<span className="dial-from-number">
						{Formatter.phoneNumber(
							`${getSessionStorageUser()?.countryCode}${getSessionStorageUser()?.phone}`
						)}
					</span>
				</div>

				<div className="dial-to-container">
					<Form
						onSubmit={call}
						initialValues={formData}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<form onSubmit={handleSubmit}>
								<div className="dial-to-input-container">
									{/* Phone number (input) */}
									<UiInputText
										id="phoneNumberTo"
										placeholder="UI_COMPONENTS.DIALPAD.TO_PLACEHOLDER"
										label="UI_COMPONENTS.DIALPAD.TO_LABEL"
										name="phoneNumberTo"
										onChange={(e) => updatePhoneNumber(false, e.target.value)}
										disabled={isDisabled}
									></UiInputText>

									{/* Make outbound call */}
									<UiButton
										className="p-button-rounded"
										icon={<BsTelephoneFill />}
										disabled={!formData.phoneNumberTo || isDisabled}
									/>
								</div>

								{/* Dialpad messages (error, info, etc.) */}
								{formData?.formMessage && (
									<div className="mb-4">
										<UiFieldMessage {...formData?.formMessage}></UiFieldMessage>
									</div>
								)}

								{/* Keypad */}
								<div className="dial-to-dialpad-container">
									<Panel
										headerTemplate={setKeypadTemplate}
										className="dial-to-keypad-container"
									>
										{setKeypadRowTemplate()}
									</Panel>
								</div>
							</form>
						)}
					/>
				</div>
			</OverlayPanel>
		</div>
	);
};
export default UiDialPad;
