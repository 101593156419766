import { useTranslation } from "react-i18next";
import { Field, FieldInputProps } from "react-final-form";

// Primereact
import { ObjectUtils, classNames } from "primereact/utils";
import { MultiSelect, MultiSelectChangeParams } from "primereact/multiselect";

// Own "library"
import UiFieldLabel from "../field-label/FieldLabel.component";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { List } from "../../models/misc.model";
import { UiSelectMultipleProps } from "../../models/components/select-multiple.model";
import { Validations } from "../../services/form/validations.service";

const UiSelectMultiple = (props: UiSelectMultipleProps) => {
	const { t } = useTranslation("common");

	const optionsList: List =
		props.options?.map((item) => {
			item.label = t(item.label.toString());
			return item;
		}) || [];

	// Props without the following specific keys (these specific attributes have to be treated differently)
	const otherProps = ObjectUtils.findDiffKeys(props, {
		name: props.name,
		value: props.value,
		placeholder: props.placeholder,
		className: props.className,
		options: props.options,
		onChange: props.onChange,
		// emptyMessage: props.emptyMessage,
		emptyFilterMessage: props.emptyFilterMessage,
		optionValue: props.optionValue || "id",
		optionLabel: props.optionLabel || "label",
		leftIcon: props.leftIcon,
		leftIconFn: props.leftIconFn,
		filterPlaceholder: props.filterPlaceholder,
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop, and integrate it with
	const handleChange = (
		event: MultiSelectChangeParams,
		input: FieldInputProps<any, HTMLElement>
	) => {
		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		if (input) {
			input.onChange(event);
			input.onBlur();
		}
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div className="k-select-multiple-container">
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							leftIcon={props.leftIcon}
							meta={meta}
						>
							{/* Left icon */}
							{props.leftIcon && (
								<i
									className={classNames(
										"input-icon",
										props.leftIcon,
										props.leftIconFn ? "is-clickable" : "",
										props.disabled ? "p-disabled" : ""
									)}
									onClick={props.leftIconFn}
									role="button"
									tabIndex={0}
								/>
							)}

							{/* Select */}
							<MultiSelect
								placeholder={t(
									props.placeholder || "UI_COMPONENTS.SELECT.PLACEHOLDER"
								)}
								name={input.name}
								value={input.value}
								options={optionsList}
								className={classNames(
									{ "p-invalid": !!(meta.touched && meta.error) },
									props.className
								)}
								onChange={(event) => handleChange(event, input)}
								emptyFilterMessage={
									props.emptyFilterMessage
										? t(props.emptyFilterMessage.toString())
										: t("UI_COMPONENTS.SELECT.EMPTY_MESSAGE_FILTER")
								}
								optionValue={props.optionValue || "id"}
								optionLabel={props.optionLabel || "label"}
								filterPlaceholder={t(
									props.filterPlaceholder ||
										"UI_COMPONENTS.SELECT.PLACEHOLDER_FILTER"
								)}
								{...otherProps}
							/>
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiSelectMultiple;
