import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { SidebarResponsiveProps } from "../../../models/components/sidebar-responsive.model";
import { getFunctionalModules } from "../../../services/header/header.service";
import { Route } from "../../../models/routing.model";
import SidebarResponsiveModule from "./SidebarResponsiveModule";

const SidebarResponsive = ({ props }: { props: SidebarResponsiveProps }) => {
	const location = useLocation();
	const [modules, setModules] = useState<Route[]>([]);

	useEffect(() => {
		setModules(getFunctionalModules());
	}, [location]);

	return (
		<Sidebar
			visible={props.isVisible}
			onHide={() => props.closeModal(false)}
			className="sidebar-responsive"
			showCloseIcon={false}
		>
			{/* Modules */}
			{location.pathname && (
				<SidebarResponsiveModule
					modules={modules}
					pathname={location.pathname}
					onClose={props.closeModal}
				/>
			)}
		</Sidebar>
	);
};

export default SidebarResponsive;
