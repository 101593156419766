import { Goal } from "../../entities/goal.model";

// Component modes
export enum MedicationsModalMode {
	CREATE = "CREATE",
	READ = "READ",
	DELETE = "DELETE",
}

// Component props
export type MedicationsModalProps = {
	mode: MedicationsModalMode;
	participantId: number;
	goals?: Goal[];
	isVisible: boolean;
	closeModal: () => void;
};
