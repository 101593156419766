import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MedicationsTemplate } from "../../../../services/medications/medications-templates.service";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { Form } from "react-final-form";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import UiTable from "../../../../components/table/Table.component";
import { TableColumnTemplate } from "../../../../models/components/table/table-column.model";
import { TableStyle } from "../../../../models/components/table/table.model";

type MedicationsHistoryRow = {
	medicationName: string;
	dateFilled: Date;
	source: string;
	active: boolean;
};

const MedicationsHistory = (props: {
	participantId: number;
	categoryName: string;
	medications: MedicationsHistoryRow[];
	drugCategoryNotes: string | null;
	onSubmit: (info: { notes: string | null; categoryName: string }) => void;
}) => {
	const { t } = useTranslation("common");

	const rows: MedicationsHistoryRow[] = props.medications;

	const submit = async (values: any) => {
		await EndpointsService.dataRetriever
			.createOrUpdateMedicationCategoryNotes({
				participantId: props.participantId,
				category: props.categoryName,
				body: {
					notes: values.notes,
				},
			})
			.then(() => {
				setUpdateForm({ editing: false, notes: values.notes });
				props.onSubmit({
					notes: values.notes,
					categoryName: props.categoryName,
				});
			});
	};

	const initialFormValues = {
		editing: false,
		notes: props.drugCategoryNotes ?? null,
	};

	const [updateForm, setUpdateForm] = useState({
		editing: false,
		notes: props.drugCategoryNotes ?? null,
	});

	return (
		<Form
			onSubmit={submit}
			initialValues={updateForm}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<div>
						{/* Category notes */}
						<div className="medications-category-notes">
							{MedicationsTemplate.templateNotes(
								updateForm,
								t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.MESSAGE_CREATE_NOTE"),
								() => setUpdateForm({ ...updateForm, editing: true }),
								t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.NOTES"),
								(value) => setUpdateForm({ ...updateForm, notes: value }),
								() => {
									setUpdateForm(initialFormValues);
								},
								submitting
							)}
						</div>

						<UiTable
							dataKey="id"
							type={TableStyle.NESTED}
							useAsCard={false}
							columns={[
								{
									field: "medicationName",
									sortable: false,
									style: {
										width: MedicationsTemplate.columnsWidth.medicationName,
									},
								},
								{
									field: "pdcAmr",
									sortable: false,
									style: { width: MedicationsTemplate.columnsWidth.pdcAmr },
								},
								{
									field: "goal",
									sortable: false,
									style: { width: MedicationsTemplate.columnsWidth.goal },
									template: TableColumnTemplate.CUSTOM,
									templateOptions: {
										customTemplate: (row) => <></>,
									},
								},
								{
									field: "dateFilled",
									sortable: false,
									style: { width: MedicationsTemplate.columnsWidth.dateFilled },
									template: TableColumnTemplate.CUSTOM,
									templateOptions: {
										customTemplate: (row: MedicationsHistoryRow) =>
											Formatter.date(row.dateFilled),
									},
								},
								{
									field: "source",
									sortable: false,
									style: { width: MedicationsTemplate.columnsWidth.source },
									template: TableColumnTemplate.CUSTOM,
									templateOptions: {
										customTemplate: (row: MedicationsHistoryRow) =>
											MedicationsTemplate.templateSource(
												row.source
													? t(`ENUMS.SOURCE.${row.source}`)
													: Formatter.NO_DATA
											),
									},
								},
								{
									field: "expandCollapse",
									sortable: false,
									style: {
										width: MedicationsTemplate.columnsWidth.expandCollapse,
									},
								},
							]}
							value={rows}
							paginator={false}
							pagination={{
								first: 0,
								rows: 10,
								page: 0,
								sortField: undefined,
								sortOrder: 1,
								search: undefined,
								filters: undefined,
							}}
							rowClassName={(row: MedicationsHistoryRow) => {
								return { "is-inactive": !row.active };
							}}
						/>
					</div>
				</form>
			)}
		></Form>
	);
};

export default MedicationsHistory;
