import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { getSessionStorageUser } from "../../../../services/session/auth.service";

export const useConversationMembers = ({
	participantId,
	participantFirstName,
	participantLastName,
}: {
	participantId: number;
	participantFirstName: string;
	participantLastName: string;
}) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-conversation-members", { participantId }];

	const query = useQuery(queryKey, async () => {
		const coaches = await EndpointsService.dataRetriever.getProvidersInfo();
		const members: { [x: string]: string } = {};

		// Map coaches names
		if (coaches?.length > 0) {
			coaches.forEach((item) => {
				members[`${item.id}`] = Formatter.fullName(item.firstName, item.lastName);
			});
		}

		// Add the participant
		members[`${participantId}`] = Formatter.fullName(participantFirstName, participantLastName);

		// Add the coach
		const loggedCoach = getSessionStorageUser();
		members[`${loggedCoach.id}`] = Formatter.fullName(
			loggedCoach.firstName,
			loggedCoach.lastName
		);

		return members;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
