import { useState } from "react";
import { useTranslation } from "react-i18next";
import ParticipantDetailsCard from "./ParticipantDetailsCard";
import {
	PrincipalMethod,
	TestingFrequency,
	UnenrollmentReason,
	eParticipantStatus,
	ParticipantProfile,
} from "../../../models/entities/participant.model";
import { Form } from "react-final-form";
import ParticipantDetailsField from "./ParticipantDetailsField";
import { Validations } from "../../../services/form/validations.service";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiSelect from "../../../components/select/Select.component";
import { ListOption } from "../../../models/misc.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import UiInputText from "../../../components/input-text/InputText.component";
import UiInputNumber from "../../../components/input-number/InputNumber.component";
import { FormApi } from "final-form";
import ParticipantDetailExternalLink from "./ParticipantDetailExternalLink";
import { HelperService } from "../../../services/helpers/helper.service";
import { useProvidersActive } from "../../../hooks/useProvidersActive";
import { Institution } from "../../../models/entities/institution.model";
import { useBillingProviders } from "../../../hooks/useBillingProviders";
import { classNames } from "primereact/utils";
import { PtKannactInfoForm } from "../../../models/pages/participant-details/kannact-info.model";
import { PtDetailsMode } from "../../../models/pages/participant-details/participant-details.model";
import { Roles } from "../../../models/roles.model";

const ParticipantDetailsKannactInfo = ({
	participant,
	institutions,
	onUpdate,
}: {
	participant: ParticipantProfile;
	institutions: Institution[];
	onUpdate: (updatedParticipant: ParticipantProfile) => void;
}) => {
	const { t } = useTranslation("common");

	const [mode, setMode] = useState<PtDetailsMode>(PtDetailsMode.READ);
	const [showError, setShowError] = useState<boolean>(false);
	const [formData, setFormData] = useState<PtKannactInfoForm>(new PtKannactInfoForm(participant));

	const providersActiveQuery = useProvidersActive({
		search: undefined,
		filters: `institutionId==${formData.institutionId}`,
	});
	const billingProvidersQuery = useBillingProviders();

	// Time with Kannact
	const formatKannactTime = () => {
		const result: string | null = null;

		if (participant.enrollmentDate) {
			const now = new Date();
			const enrollmentDate = new Date(participant.enrollmentDate);
			return Formatter.getDateDistance(enrollmentDate, now);
		}

		return result;
	};

	// Coaches
	const mapCoachName = (
		data: { id: number; name: string }[] | undefined,
		coachId: number | null
	) => {
		let result: string | null = null;
		if (data?.length) {
			const coach = data.find((item) => item.id === coachId);
			if (coach) {
				result = Formatter.fullName(coach.name);
			}
		}
		return result;
	};

	// Device testing frequency
	const mapDeviceFreq = (reading: number | null, period: TestingFrequency | null) => {
		let result: string | null = null;
		if (reading && period) {
			result = `${reading} x ${t(`ENUMS.FREQUENCY.${period}`)}`;
		}
		return result;
	};

	/* Submit  */

	const setRequest = () => {
		const result: { [x: string]: any } = {};

		for (const key in formData) {
			if (Object.hasOwn(formData, key)) {
				switch (key) {
					case "emailPrincipal":
						if (
							formData.emailPrincipal !== HelperService.getPrincipalEmail(participant)
						) {
							const newEntry = {
								method: PrincipalMethod.EMAIL,
								principal:
									formData.emailPrincipal === "" ? null : formData.emailPrincipal,
							};

							if (result.principals) {
								result.principals.push(newEntry);
							} else {
								result.principals = [newEntry];
							}
						}
						break;
					case "phonePrincipal":
						const hasPhonePrincipalChanged =
							formData.phonePrincipal !==
							HelperService.getPrincipalPhone(participant, false);
						if (hasPhonePrincipalChanged) {
							const newEntry = {
								method: PrincipalMethod.PHONE,
								principal:
									formData.phonePrincipal === null
										? null
										: "+1" + formData.phonePrincipal,
							};

							if (result.principals) {
								result.principals.push(newEntry);
							} else {
								result.principals = [newEntry];
							}
						}
						break;
					case "coachId":
						// Primary coach
						const initialCoach = providersActiveQuery.data?.find(
							(item) => item.id === participant.coachId
						);
						if (formData.coachId && initialCoach?.id !== formData.coachId) {
							result.coachId = formData.coachId;
						}
						break;
					case "billingProviderId":
						if (
							formData[key as keyof PtKannactInfoForm] !==
							participant[key as keyof ParticipantProfile]
						) {
							result[key as keyof PtKannactInfoForm] =
								formData[key as keyof PtKannactInfoForm] || null;
						}
						break;
					default:
						if (
							formData[key as keyof PtKannactInfoForm] !==
							participant[key as keyof ParticipantProfile]
						) {
							result[key as keyof PtKannactInfoForm] =
								formData[key as keyof PtKannactInfoForm];
						}
						break;
				}
			}
		}

		return result;
	};

	const handleSubmit = async (
		data: PtKannactInfoForm,
		form: FormApi<PtKannactInfoForm, PtKannactInfoForm>
	) => {
		setShowError(false);

		// Set request
		const body = setRequest();

		await EndpointsService.dataRetriever
			.updateProfileKannactInfo({
				body: {
					participantId: participant.id,
					...body,
				},
			})
			.then((response) => {
				// Reset form state
				form.reset();

				// Update participant state
				onUpdate(response);

				// Update mode
				setMode(PtDetailsMode.READ);
			})
			.catch(() => setShowError(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<ParticipantDetailsCard
						title="PARTICIPANT.DETAILS.KANNACT_INFO.TITLE"
						submitting={submitting}
						mode={mode}
						enableEditing={!Roles.isProvider()}
						showError={showError}
						enableSubmit={!!Object.keys(setRequest())}
						onCancel={() => {
							form.reset();
							setShowError(false);
							setFormData(new PtKannactInfoForm(participant));
							setMode(PtDetailsMode.READ);
						}}
						onModeChange={() => setMode(PtDetailsMode.UPDATE)}
					>
						<div className="row">
							{/* Kannact ID */}
							<div className="col-12 col-md-3">
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.kannactId"
									value={participant.kannactId}
								/>
							</div>

							{/* Enrollment date */}
							<div className="col-12 col-md-3">
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.enrollmentDate"
									value={Formatter.dateISOToString(participant.enrollmentDate, {
										day: "numeric",
										month: "short",
										year: "numeric",
										hour: "2-digit",
										minute: "2-digit",
									})}
								/>
							</div>

							{/* Time with kannact */}
							<div className="col-12 col-md-3">
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.kannactTime"
									value={formatKannactTime()}
								/>
							</div>
						</div>

						<div className="row">
							{/* Phone principal */}
							<div className="col-12 col-md-8 col-lg-8 col-xl-3">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.PHONE_PRINCIPAL"
										value={
											Formatter.phoneNumber(
												HelperService.getPrincipalPhone(
													participant,
													true
												) ?? undefined
											) ?? Formatter.NO_DATA
										}
									/>
								) : (
									<UiInputNumber
										id="phonePrincipal"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.PHONE_PRINCIPAL"
										name="phonePrincipal"
										prefix="+1 "
										useGrouping={false}
										onChange={(e) => {
											setFormData({
												...formData,
												phonePrincipal:
													e.value == null ? null : e.value.toString(),
											});
										}}
										validations={
											formData.phonePrincipal ? [Validations.numbers] : []
										}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Email Principal */}
							<div className="col-12 col-xl-4">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.EMAIL_PRINCIPAL"
										value={HelperService.getPrincipalEmail(participant)}
									/>
								) : (
									<UiInputText
										id="emailPrincipal"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.EMAIL_PRINCIPAL"
										name="emailPrincipal"
										onChange={(e) => {
											setFormData({
												...formData,
												emailPrincipal:
													e.target.value === "" ? null : e.target.value,
											});
										}}
										validations={
											formData.emailPrincipal ? [Validations.email] : []
										}
										disabled={submitting}
									/>
								)}
							</div>
						</div>
						<div className="row">
							{/* Is whole health */}
							<div className="col-12 col-md-3">
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.isWholeHealth"
									value={participant.isWholeHealth}
								/>
							</div>

							{/* Institution */}
							<div className="col-12 col-md-3">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.institution"
										value={participant.institution}
									/>
								) : (
									<UiSelect
										id="institutionId"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.institution"
										name="institutionId"
										onChange={(e) => {
											setFormData({
												...formData,
												institutionId: e.target.value,
												institution: institutions.find(
													(x) => x.institutionId === e.target.value
												)!.institutionName,
												coachId: null,
											});
											providersActiveQuery.invalidate();
										}}
										options={
											institutions?.map(
												(item) =>
													new ListOption({
														id: item.institutionId,
														label: item.institutionName,
													})
											) ?? []
										}
										validations={[Validations.required]}
										disabled={submitting}
										filter={true}
									/>
								)}
							</div>
						</div>

						<div className="row">
							{/* Primary coach */}
							<div
								className={classNames(
									"col-12",
									mode === PtDetailsMode.READ ? "col-md-3" : "col-md-6"
								)}
							>
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.coach"
										value={mapCoachName(
											providersActiveQuery.data,
											participant.coachId
										)}
									/>
								) : (
									<UiSelect
										id="coachId"
										label={
											!formData.coachId &&
											(providersActiveQuery.isFetching ||
												providersActiveQuery.isLoading)
												? "UI_COMPONENTS.SELECT.LOADING"
												: "PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.coach"
										}
										name="coachId"
										onChange={(e) =>
											setFormData({ ...formData, coachId: e.target.value })
										}
										options={
											providersActiveQuery.data?.map(
												(item) =>
													new ListOption({
														id: item.id,
														label: Formatter.fullName(item.name),
													})
											) ?? []
										}
										disabled={submitting}
										filter={true}
									/>
								)}
							</div>
							{/** Billing provider */}
							<div
								className={classNames(
									"col-12",
									mode === PtDetailsMode.READ ? "col-md-3" : "col-md-6"
								)}
							>
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.billingProvider"
										value={mapCoachName(
											billingProvidersQuery.data,
											participant.billingProviderId
										)}
									/>
								) : (
									<UiSelect
										id="billingProviderId"
										label={
											!formData.billingProviderId &&
											(billingProvidersQuery.isFetching ||
												billingProvidersQuery.isLoading)
												? "UI_COMPONENTS.SELECT.LOADING"
												: "PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.billingProvider"
										}
										name="billingProviderId"
										onChange={(e) =>
											setFormData({
												...formData,
												billingProviderId: e.target.value,
											})
										}
										options={
											billingProvidersQuery.data?.map(
												(item) =>
													new ListOption({
														id: item.id,
														label: Formatter.fullName(item.name),
													})
											) ?? []
										}
										disabled={submitting}
										filter={true}
										showClear={true}
										showFilterClear={true}
									/>
								)}
							</div>
						</div>

						{/* Status */}
						<div className="">
							<ParticipantDetailsField
								title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.status"
								value={t(`ENUMS.PARTICIPANT_STATUS.${participant.status}`)}
							/>
						</div>

						{/* Unenrollment reason */}
						{formData.status === eParticipantStatus.DNC && (
							<div className="">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.unenrollmentReason"
										value={
											participant.unenrollmentReason
												? t(
														`ENUMS.UNENROLLMENT_REASON.${participant.unenrollmentReason}`
													)
												: null
										}
									/>
								) : (
									<UiSelect
										id="unenrollmentReason"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.unenrollmentReason"
										name="unenrollmentReason"
										onChange={(e) =>
											setFormData({
												...formData,
												unenrollmentReason: e.target.value,
											})
										}
										options={Object.keys(UnenrollmentReason).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.UNENROLLMENT_REASON.${x}`,
												})
										)}
										disabled={submitting}
									/>
								)}
							</div>
						)}

						{/* Unenrollment reason other */}
						{formData.status === eParticipantStatus.DNC &&
							formData.unenrollmentReason === "OTHER" && (
								<div className="">
									{mode === PtDetailsMode.READ ? (
										<ParticipantDetailsField
											title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.unenrollmentOtherReason"
											value={participant.unenrollmentOtherReason}
										/>
									) : (
										<UiInputText
											id="unenrollmentOtherReason"
											label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.unenrollmentOtherReason"
											name="unenrollmentOtherReason"
											onChange={(e) =>
												setFormData({
													...formData,
													unenrollmentOtherReason: e.target.value,
												})
											}
											validations={
												formData.unenrollmentReason ===
												UnenrollmentReason.OTHER
													? [Validations.required]
													: []
											}
											disabled={submitting}
										/>
									)}
								</div>
							)}

						<div className="row">
							{/* Claims elegible */}
							<div className="col-12 col-md-3">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.claimsElegible"
										value={participant.claimsElegible}
									/>
								) : (
									<UiSelect
										id="claimsElegible"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.claimsElegible"
										name="claimsElegible"
										onChange={(e) =>
											setFormData({
												...formData,
												claimsElegible: e.target.value,
											})
										}
										options={[
											{ id: true, label: "ENUMS.YES" },
											{ id: false, label: "ENUMS.NO" },
										]}
										disabled={submitting}
									/>
								)}
							</div>

							{/* Covered */}
							<div className="col-12 col-md-3">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.covered"
										value={participant.isCovered}
									/>
								) : (
									<UiSelect
										id="isCovered"
										label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.covered"
										name="isCovered"
										onChange={(e) =>
											setFormData({
												...formData,
												isCovered: e.target.value,
											})
										}
										options={[
											{ id: true, label: "ENUMS.YES" },
											{ id: false, label: "ENUMS.NO" },
										]}
										disabled={submitting}
									/>
								)}
							</div>
						</div>

						{/* Devices */}
						<div className="">
							<div className="fields-section-title">
								{t("PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.devices")}
							</div>

							{/* Show device list elements */}
							{participant.devices?.map((item, index) => (
								<div
									className="row"
									key={index}
								>
									{/* Device */}
									<div className="col-12 col-md-3">
										<ParticipantDetailsField
											title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.deviceType"
											value={t("ENUMS.DEVICES." + item.deviceType)}
										/>
									</div>

									<div className="col-12 col-md-3">
										<ParticipantDetailsField
											title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.deviceName"
											value={item.deviceName}
										/>
									</div>

									{/* Device ID */}
									<div className="col-12 col-md-3">
										<ParticipantDetailsField
											title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.deviceSerialNumber"
											value={item.serialNumber}
										/>
									</div>

									<div className="col-12 col-md-3">
										<ParticipantDetailsField
											title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.status"
											value={item.status}
										/>
									</div>
								</div>
							))}
						</div>

						{/* Devices frequencies */}
						<div className="row">
							{/* Glucometer testing frequency */}
							<div className="col-12 col-md-12 col-lg-12 col-xl-6">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.glucometerFreq"
										value={mapDeviceFreq(
											participant.glucometerFrequencyReading,
											participant.glucometerFrequencyPeriod
										)}
									/>
								) : (
									<div className="row">
										<div className="pt-details-field col-12">
											<span className="field-title">
												{t(
													"PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.glucometerFreq"
												)}
											</span>
										</div>

										{/* Times */}
										<div className="col-12 col-md-6">
											<UiInputNumber
												id="glucometerFrequencyReading"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.times"
												placeholder="00"
												name="glucometerFrequencyReading"
												onChange={(e) =>
													setFormData({
														...formData,
														glucometerFrequencyReading: e.value,
													})
												}
												maxDigits={2}
												maxFractionDigits={0}
												disabled={submitting}
											/>
										</div>

										{/* Frequency */}
										<div className="col-12 col-md-6">
											<UiSelect
												id="glucometerFrequencyPeriod"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.frequency"
												name="glucometerFrequencyPeriod"
												onChange={(e) =>
													setFormData({
														...formData,
														glucometerFrequencyPeriod: e.target.value,
													})
												}
												options={[
													{
														id: TestingFrequency.DAILY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.DAILY}`,
													},
													{
														id: TestingFrequency.WEEKLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.WEEKLY}`,
													},
													{
														id: TestingFrequency.MONTHLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.MONTHLY}`,
													},
													{
														id: TestingFrequency.YEARLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.YEARLY}`,
													},
												]}
												disabled={submitting}
											/>
										</div>
									</div>
								)}
							</div>

							{/* Blood pressure testing frequency */}
							<div className="col-12 col-md-12 col-lg-12 col-xl-6">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.bpFreq"
										value={mapDeviceFreq(
											participant.bpFrequencyReading,
											participant.bpFrequencyPeriod
										)}
									/>
								) : (
									<div className="row">
										<div className="pt-details-field col-12">
											<span className="field-title">
												{t(
													"PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.bpFreq"
												)}
											</span>
										</div>

										{/* Times */}
										<div className="col-12 col-md-6">
											<UiInputNumber
												id="bpFrequencyReading"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.times"
												placeholder="00"
												name="bpFrequencyReading"
												onChange={(e) =>
													setFormData({
														...formData,
														bpFrequencyReading: e.value,
													})
												}
												maxDigits={2}
												maxFractionDigits={0}
												disabled={submitting}
											/>
										</div>

										{/* Frequency */}
										<div className="col-12 col-md-6">
											<UiSelect
												id="bpFrequencyPeriod"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.frequency"
												name="bpFrequencyPeriod"
												onChange={(e) =>
													setFormData({
														...formData,
														bpFrequencyPeriod: e.target.value,
													})
												}
												options={[
													{
														id: TestingFrequency.DAILY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.DAILY}`,
													},
													{
														id: TestingFrequency.WEEKLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.WEEKLY}`,
													},
													{
														id: TestingFrequency.MONTHLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.MONTHLY}`,
													},
													{
														id: TestingFrequency.YEARLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.YEARLY}`,
													},
												]}
												disabled={submitting}
											/>
										</div>
									</div>
								)}
							</div>

							{/* Weight testing frequency */}
							<div className="col-12 col-md-12 col-lg-12 col-xl-6">
								{mode === PtDetailsMode.READ ? (
									<ParticipantDetailsField
										title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.weightFreq"
										value={mapDeviceFreq(
											participant.weightFrequencyReading,
											participant.weightFrequencyPeriod
										)}
									/>
								) : (
									<div className="row">
										<div className="pt-details-field col-12">
											<span className="field-title">
												{t(
													"PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.weightFreq"
												)}
											</span>
										</div>

										{/* Times */}
										<div className="col-12 col-md-6">
											<UiInputNumber
												id="weightFrequencyReading"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.times"
												placeholder="00"
												name="weightFrequencyReading"
												onChange={(e) =>
													setFormData({
														...formData,
														weightFrequencyReading: e.value,
													})
												}
												maxDigits={2}
												maxFractionDigits={0}
												disabled={submitting}
											/>
										</div>

										{/* Frequency */}
										<div className="col-12 col-md-6">
											<UiSelect
												id="weightFrequencyPeriod"
												label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.frequency"
												name="weightFrequencyPeriod"
												onChange={(e) =>
													setFormData({
														...formData,
														weightFrequencyPeriod: e.target.value,
													})
												}
												options={[
													{
														id: TestingFrequency.DAILY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.DAILY}`,
													},
													{
														id: TestingFrequency.WEEKLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.WEEKLY}`,
													},
													{
														id: TestingFrequency.MONTHLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.MONTHLY}`,
													},
													{
														id: TestingFrequency.YEARLY,
														label: `ENUMS.FREQUENCY.${TestingFrequency.YEARLY}`,
													},
												]}
												disabled={submitting}
											/>
										</div>
									</div>
								)}
							</div>
						</div>

						{/* Scheduling link */}
						<ParticipantDetailExternalLink
							href={participant.schedulingLink}
							title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.schedulingLink"
						/>

						{/* Privacy policy & Terms of service */}
						<div className="">
							<ParticipantDetailsField
								title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.tos"
								value={
									participant.latestTermsOfServiceAccepted
										? t(
												"PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.tosFormatted",
												{
													version:
														participant.latestTermsOfServiceAccepted,
												}
											)
										: participant.latestTermsOfServiceAccepted
								}
							/>
						</div>

						{/* Preferred email */}
						<div className="">
							{mode === PtDetailsMode.READ ? (
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerEmail"
									value={participant.referrerEmail}
								/>
							) : (
								<UiInputText
									id="referrerEmail"
									placeholder="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerEmail"
									label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerEmail"
									name="referrerEmail"
									onChange={(e) =>
										setFormData({ ...formData, referrerEmail: e.target.value })
									}
									disabled={submitting}
								/>
							)}
						</div>

						{/* Preferred name */}
						<div className="">
							{mode === PtDetailsMode.READ ? (
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerName"
									value={participant.referrerName}
								/>
							) : (
								<UiInputText
									id="referrerName"
									placeholder="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerName"
									label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.referrerName"
									name="referrerName"
									onChange={(e) =>
										setFormData({ ...formData, referrerName: e.target.value })
									}
									disabled={submitting}
								/>
							)}
						</div>

						{/* Test user */}
						<div className="">
							{mode === PtDetailsMode.READ ? (
								<ParticipantDetailsField
									title="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.isTest"
									value={participant.isTest}
								/>
							) : (
								<UiSelect
									id="isTest"
									label="PARTICIPANT.DETAILS.KANNACT_INFO.FORM_FIELDS.isTest"
									name="isTest"
									onChange={(e) =>
										setFormData({ ...formData, isTest: e.target.value })
									}
									options={[
										{ id: true, label: "ENUMS.YES" },
										{ id: false, label: "ENUMS.NO" },
									]}
									disabled={submitting}
								/>
							)}
						</div>
					</ParticipantDetailsCard>
				</form>
			)}
		/>
	);
};

export default ParticipantDetailsKannactInfo;
