import { TwilioEndpointsService } from "./twilio-endpoints.service";
import { DataRetrieverEndpointsService } from "./data-retriever-endpoints.service";
import { AppointmentsEndpointsService } from "./appointments-endpoints.service";
import { SurveyEndpointsService } from "./survey-endpoints.service";

/**
 * Endpoints service:
 * @description Contains all of the different endpoints, divided by micro-services
 */
class EndpointsImpl {
	twilio = TwilioEndpointsService;
	dataRetriever = DataRetrieverEndpointsService;
	appointments = AppointmentsEndpointsService;
	surveys = SurveyEndpointsService;
}
export const EndpointsService = new EndpointsImpl();
