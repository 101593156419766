import { useEffect, useState } from "react";
import UiTable from "../../../../components/table/Table.component";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../../models/components/table/table-column.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { RecordingEntity } from "../../../../models/entities/call-voicemail.model";
import { RecordingRow } from "../../../../models/pages/call-voicemail-row.model";
import { PageProps } from "../../../../models/routing.model";

const TemplatesTableShowcase = (props: PageProps) => {
	// Other data
	const date2 = new Date();
	date2.setHours(3);
	date2.setMinutes(25);
	const date3 = new Date();
	date3.setDate(16);

	// Table: columns
	const columns: TableColumn[] = [
		{
			field: "date",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.date",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "numeric",
					month: "short",
					year: "numeric",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "participant",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.participant",
			sortable: true,
		},
		{
			field: "coachId",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.coach",
			template: TableColumnTemplate.COACH,
			sortable: true,
		},
		{
			field: "duration",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.duration",
			template: TableColumnTemplate.DURATION,
			sortable: true,
			style: {
				width: "125px",
			},
		},
		{
			field: "alreadySeenTemplate",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.markedAsReadTemplate",
			template: TableColumnTemplate.TAG,
			style: {
				width: "165px",
			},
		},
		{
			field: "recording",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.recording",
			template: TableColumnTemplate.AUDIO,
		},
		{
			field: "actionsTemplate",
			title: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
		},
	];

	// Table: rows
	const [rows, setRows] = useState<RecordingRow[]>();

	// Table: pagination
	const pagination: TablePagination = {
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
	};

	// Table: message
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.LOADING,
		label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
	});

	// First time load: get the table data
	useEffect(() => {
		getData();
	}, []);

	// Simulate a backend call
	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	const dummuFn = (): void => {
		console.log("DummyFn");
	};

	// Get data
	const getData = async (): Promise<void> => {
		// Message: loading
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING",
		});

		// Simulate a backend call
		await sleep(2000);

		// Set rows
		const backendData: RecordingEntity[] = [
			{
				alreadySeen: true,
				callSid: "CA0202688fa3b4414f3fdad265b5e7079b",
				coachId: 189164,
				createdOn: "2022-10-25T14:34:55",
				duration: 7,
				id: 8795,
				mediaUrl:
					"https://api.twilio.com/2010-04-01/Accounts/AC5d5442e687d7507387335de67f7ea78e/Recordings/RE0fb3eef7c05d93e4519fd09558b3487a",
				participant: "+15033831563",
				fromnumber: "+15033831563",
				recordingSid: "RE0fb3eef7c05d93e4519fd09558b3487a",
				source: "RE0fb3eef7c05d93e4519fd09558b3487a",
				startTime: new Date("2022-10-25T14:34:55"),
				status: "completed",
				uri: "/2010-04-01/Accounts/AC5d5442e687d7507387335de67f7ea78e/Recordings/RE0fb3eef7c05d93e4519fd09558b3487a.json",
			},
			{
				alreadySeen: false,
				callSid: "CAe0f0bf63dbf242a21142482482898f64",
				coachId: 189164,
				createdOn: "2022-10-25T14:27:54",
				duration: 4,
				id: 8796,
				mediaUrl:
					"https://api.twilio.com/2010-04-01/Accounts/AC5d5442e687d7507387335de67f7ea78e/Recordings/RE75a1abc259e7fbc1efddc84ca4a9824c",
				participant: "+15033831563",
				fromnumber: "+15033831563",
				recordingSid: "RE75a1abc259e7fbc1efddc84ca4a9824c",
				source: "RE75a1abc259e7fbc1efddc84ca4a9824c",
				startTime: new Date("2022-10-25T14:27:53"),
				status: "completed",
				uri: "/2010-04-01/Accounts/AC5d5442e687d7507387335de67f7ea78e/Recordings/RE75a1abc259e7fbc1efddc84ca4a9824c.json",
			},
		];
		setRows(backendData.map((item) => new RecordingRow(item, dummuFn)));
	};

	return (
		<>
			<UiTable
				title="SHOWCASES.TABLE_SHOWCASES.SIMPLE_TABLE_SHOWCASE.SUBTITLE"
				dataKey="id"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
			/>
		</>
	);
};

export default TemplatesTableShowcase;
