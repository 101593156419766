import { useTranslation } from "react-i18next";
import { Formatter } from "../../../../../services/formatter/formatter.service";
import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import { SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import { TableMessages } from "../../../../../models/components/table/table-message.model";
import { classNames } from "primereact/utils";

const SmartGoalDetails = ({
	entity,
	longTermGoalsQuery,
}: {
	entity: SmartGoal;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
}) => {
	const { t } = useTranslation("common");
	const messages = new TableMessages();

	return (
		<>
			<ul className="custom-goals-modal__details">
				<li>
					<label className="field">
						{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.SMART_GOALS_DETAILS.details")}
					</label>
					<span className="value">{entity.details || Formatter.NO_DATA}</span>
				</li>
				<li>
					<label className="field">
						{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.SMART_GOALS_DETAILS.support")}
					</label>
					<>
						{entity.support?.map((x, i) => (
							<span key={x}>
								<span
									className={classNames(
										longTermGoalsQuery?.data?.find((y) => x === y.id)
											?.completed && "text-success",
										longTermGoalsQuery?.data?.find((y) => x === y.id)
											?.deleted && "text-danger"
									)}
								>
									{longTermGoalsQuery?.data?.find((y) => x === y.id)?.name || x}
								</span>
								{i !== entity.support!.length - 1 && <>,&nbsp;</>}
							</span>
						)) || Formatter.NO_DATA}
					</>
				</li>
				{entity.reason && (
					<li>
						<label className="field">
							{t(
								"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.SMART_GOALS_DETAILS.reason"
							)}
						</label>
						<span className="value">{entity.reason || Formatter.NO_DATA}</span>
					</li>
				)}
			</ul>
			<div className="custom-goals-modal__progress">
				<h4 className="text-success-darker mt-3 mb-1">
					{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.PROGRESS_HISTORY")}
				</h4>
				{!entity.noteProgress ? (
					<span>{t(messages.empty)}</span>
				) : (
					<ul className="custom-goals-modal__progress--list">
						{entity.noteProgress
							.sort((a, b) =>
								new Date(a.createdOn) > new Date(b.createdOn)
									? -1
									: new Date(b.createdOn) > new Date(a.createdOn)
										? 1
										: 0
							)
							.map((x) => (
								<li key={x.id}>
									<span className="custom-goals-modal__progress--list--date">
										{Formatter.date(x.createdOn, {
											day: "2-digit",
											month: "2-digit",
											year: "2-digit",
											hour: "2-digit",
											minute: "2-digit",
										})}
									</span>
									<span>{x.progress || Formatter.NO_DATA}</span>
								</li>
							))}
					</ul>
				)}
			</div>
		</>
	);
};
export default SmartGoalDetails;
