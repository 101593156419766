import { RolesService } from "../session/roles.service";

/**
 * Components: protected
 * @description Protect components so that they are not visible when the user has not the right permissions
 */

export const ProtectedComponent = ({
	children,
	allowedRoles,
	deniedRoles,
}: {
	children: JSX.Element;
	allowedRoles?: number[];
	deniedRoles?: number[];
}) => {
	// Verify allowed and denied roles
	if (!RolesService.hasValidRoles(deniedRoles, allowedRoles)) return <></>;

	return children;
};
