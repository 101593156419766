import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import {
	ModalReminderMode,
	UiModalReminderProps,
} from "../../models/components/modal-reminder.model";
import { Reminder } from "../../models/entities/reminder.model";
import ModalReminderDelete from "./ModalReminderDelete.component";
import ModalReminderForm from "./ModalReminderForm";

const UiModalReminder = (props: UiModalReminderProps) => {
	const { t } = useTranslation("common");

	// Modal title
	const setModalTitle = (): string => {
		let result = "";
		if (props.mode === ModalReminderMode.CREATE) {
			result = t("UI_COMPONENTS.MODAL_REMINDER.TITLE_MODE_CREATE");
		} else if (props.mode === ModalReminderMode.DELETE) {
			result = t("UI_COMPONENTS.MODAL_REMINDER.TITLE_MODE_DELETE");
		} else {
			result = t("UI_COMPONENTS.MODAL_REMINDER.TITLE_MODE_DETAILS");
		}
		return result;
	};

	return (
		<Dialog
			header={setModalTitle}
			visible={true}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: props.mode === ModalReminderMode.DELETE ? "40vw" : "70vw" }}
			modal={true}
			focusOnShow={false}
			draggable={false}
			closable={true}
			onHide={props.onCancel}
		>
			{/* Reminder: DELETE */}
			{props.mode === ModalReminderMode.DELETE && (
				<ModalReminderDelete
					entity={props.entity as Reminder}
					onCancel={props.onCancel}
					onComplete={() => props.onComplete(true, null)}
				/>
			)}

			{/* Reminder: CREATE, UPDATE, SNOOZE */}
			{(props.mode === ModalReminderMode.CREATE ||
				props.mode === ModalReminderMode.DETAILS ||
				props.mode === ModalReminderMode.SNOOZE) && (
				<ModalReminderForm
					mode={props.mode}
					entity={props.entity}
					onCancel={props.onCancel}
					onComplete={props.onComplete}
				/>
			)}
		</Dialog>
	);
};

export default UiModalReminder;
