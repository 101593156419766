import { AxiosError } from "axios";
import { BehaviorSubject } from "rxjs";
import { Call, Device } from "@twilio/voice-sdk";
import { getSessionStorageUser } from "../session/auth.service";
import { Formatter } from "../formatter/formatter.service";
import { CallManagementEvent, CallManagementState } from "../../models/call-management.model";
import { CallerInfo } from "../../models/entities/call.model";
import CallPresenter from "../../models/components/call-presenter.model";
import { EndpointsService } from "../endpoints/endpoints.service";

class TwilioVoiceService {
	// State
	state: CallManagementState = {
		calls: [],
		device: undefined,
		initialized: false,
		initializing: false,
	};
	callManagementSubject: BehaviorSubject<CallManagementEvent | undefined> = new BehaviorSubject<
		CallManagementEvent | undefined
	>(undefined);

	initialize = (): void => {
		this.state.initializing = true;
		this.getAccessToken()
			.then((response: string) => this.registerDevice(response))
			.then(() => {
				// Device is initialized
				this.state.initialized = true;
				// Emit event: Device is Registered
				this.callManagementSubject.next({ callSid: "", callManagementState: this.state });
			})
			.finally(() => (this.state.initializing = false));
	};

	/**
	 * Access token
	 * @description Get a Twilio access token. It's necessary to register the Device.
	 */
	getAccessToken = async (): Promise<string> => {
		return new Promise((resolve, reject) => {
			EndpointsService.twilio
				.getVoiceAccessToken({
					config: {
						params: {
							ttl: 86400, //60 * 60 * 24 Seconds in a day (max ttl)
						},
					},
				})
				.then((response) => {
					console.log("Twilio access token: ", response);
					if (response?.token) resolve(response.token);
					else reject(response);
				})
				.catch((error: AxiosError) => reject(error));
		});
	};

	/**
	 * Device: Register
	 * @description Register the Device to the Twilio backend, allowing it to receive calls.
	 */
	registerDevice = async (accessToken: string): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			// Initialize Device instance
			this.state.device = new Device(accessToken, {
				appName: "gladstone2",
				// Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
				// providing better audio quality in restrained network conditions. Opus will be default in 2.0.
				codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
				allowIncomingWhileBusy: true,
			});

			// Register the Device
			this.state.device
				.register()
				.then(() => {
					// Device registered OK: ready to recieve incoming calls and make outgoing calls
					console.log("Device regitered: ", this.state.device);

					this.state.device?.on("error", (event) => {
						console.log("Device [ERROR]: ", event);
					});

					this.state.device?.on("destroyed", (event) => {
						console.log("Device [DESTROYED]: ", event);
					});

					this.state.device?.on("registered", (event) => {
						console.log("Device [REGISTERED]: ", event);
					});

					// Listen on incoming calls
					this.state.device?.on("incoming", (event: Call) => {
						console.log(
							"1) Incoming call: ",
							event,
							event.parameters["CallSid"],
							event.parameters["From"],
							getSessionStorageUser()?.countryCode,
							getSessionStorageUser()?.phone
						);
						// Create new call
						const newCall = new CallPresenter({
							id: event.parameters["CallSid"],
							call: event,
							fromNumber: event.parameters["From"],
							toNumber: `${getSessionStorageUser()?.countryCode}${getSessionStorageUser()?.phone}`,
							device: this.state.device,
							updateState: this.updateCallById,
							hangupOtherCalls: this.hangupOtherCalls,
						});
						console.log("2) Incoming call: ", newCall);
						newCall.setCallListeners();
						this.state.calls.push(newCall);

						// Get the participant information
						this.retrieveCallInformation(newCall);

						// Emit event: new call
						this.callManagementSubject.next({
							callSid: newCall.state.id,
							callManagementState: this.state,
						});
					});

					this.state.device?.on("offline", () => {
						console.log("Refreshing token due to near expiration");
						this.getAccessToken()
							.then((token) => this.state.device?.updateToken(token))
							.catch(() => (this.state.initialized = false));
					});

					resolve(true);
				})
				.catch((error: any) => {
					console.log("Register device - error: ", error);
					reject(false);
				});
		});
	};

	/**
	 * Participant information
	 * @description When receiving an incoming call, map the FROM phone number with our participant's DB.
	 * When making an outbound call, map the TO phone number with our participant's DB.
	 */
	retrieveCallInformation = async (callPresenter: CallPresenter): Promise<void> => {
		const phoneNumber =
			callPresenter.state.call?.direction === Call.CallDirection.Incoming
				? callPresenter.state.fromNumber
				: callPresenter.state.toNumber;
		EndpointsService.dataRetriever
			.getCallInfo({
				config: {
					params: { phoneNumber },
				},
			})
			.then((response: CallerInfo[]) => {
				callPresenter.state.callerInfo = response;
				this.callManagementSubject.next({
					callSid: callPresenter.state.id,
					callManagementState: this.state,
				});
			})
			.catch((error: AxiosError) => {
				console.log("Call: error getting the participants information");
			});
	};

	/**
	 * Call: outbound
	 * @description Make an outbound call using the Twilio Device
	 */
	outboundCall = async (phoneNumberTo: string) => {
		if (this.state.device) {
			const toFormatted = Formatter.phoneNumber(phoneNumberTo, true) ?? "";
			// Make outbound call using the Device
			this.state.device
				.connect({
					params: {
						To: toFormatted,
						From: `${getSessionStorageUser()?.countryCode}${getSessionStorageUser()?.phone}`,
						debug: "true",
					},
				})
				.then((event: Call) => {
					// Create new call
					const newCall = new CallPresenter({
						id: event.outboundConnectionId as string, // At this point, the call still doesn't have a CallSid
						call: event,
						fromNumber: `${getSessionStorageUser()?.countryCode}${getSessionStorageUser()?.phone}`,
						toNumber: toFormatted,
						device: this.state.device,
						updateState: this.updateCallById,
						hangupOtherCalls: this.hangupOtherCalls,
					});
					newCall.setCallListeners();
					this.state.calls.push(newCall);

					// Get the participant information
					this.retrieveCallInformation(newCall);

					// Emit event: new call
					this.callManagementSubject.next({
						callSid: newCall.state.id,
						callManagementState: this.state,
					});
				})
				.catch((error: any) => {
					console.error("connect - error: ", error);
				});
		}
	};

	/**
	 * Incoming call: answered
	 * description When a coach answers a new incoming call, hang up on the other possible active calls
	 */
	hangupOtherCalls = (activeCallSid: string): void =>
		this.state.calls.forEach((call) => {
			if (call.state.id !== activeCallSid) call.hangUp();
		});

	getCallById = (id: string): CallPresenter | undefined =>
		this.state.calls.find((call) => call.state.id === id);

	updateCallById = (id: string): void => {
		// Update
		const index: number = this.state.calls.findIndex((call) => call.state.id === id);
		if (this.state.calls[index] && !this.state.calls[index]?.state?.call)
			this.state.calls.splice(index, 1);

		// Emit events
		this.callManagementSubject.next({ callSid: id, callManagementState: this.state });
	};
}

const instance = new TwilioVoiceService();
export default instance;
