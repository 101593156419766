import { Formatter } from "../../services/formatter/formatter.service";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateText,
} from "../components/table/table-template.model";
import { SmartGoal } from "./custom-goals";

export class MaintenanceGoalCurrentRow {
	id: number;
	name: TableTemplateText;
	startDate: string; // Date
	category: string;
	updatedOn: string; // Date
	actions: TableTemplateButtons;
	entity: SmartGoal;

	constructor(
		params: SmartGoal,
		expired: string,
		categories: { [x: string]: string },
		duplicateFn: (e: TableTemplateButtonsEvent) => void
	) {
		this.id = params.id;
		this.name = {
			className: "text-bold",
			value: params.name,
		};
		this.startDate = params.startDate;
		this.category = this.setCategories(params.category, categories);
		this.updatedOn = params.updatedOn;
		this.actions = this.setActions(duplicateFn);
		this.entity = params;
	}

	private setCategories = (category: string[], categories: { [x: string]: string }) =>
		category.map((x) => categories[x]).join(", ");

	private setActions = (duplicateFn: (e: TableTemplateButtonsEvent) => void) => [
		{
			id: "actionDuplicate",
			icon: "pi pi-copy",
			className: "p-button-text neutral",
			disabled: false,
			onClick: duplicateFn,
		},
	];
}
