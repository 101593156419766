import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Route } from "../../models/routing.model";
import { InputSwitch } from "primereact/inputswitch";
import { useAuth0 } from "@auth0/auth0-react";
import { getSessionStorageUser, setSessionStorageUser } from "../../services/session/auth.service";
import { Roles } from "../../models/roles.model";
import UiDialPad from "../dialpad/Dialpad.component";
import icon from "../../assets/img/kannact-icon.svg";
import { FiLogOut } from "react-icons/fi";
import { StoredCriteriaService } from "../../services/session/stored-criteria.service";

const HeaderDesktop = ({ props }: { props: Route[] }) => {
	const { t } = useTranslation("common");
	const [adminView, setAdminView] = useState<boolean>(
		getSessionStorageUser()?.adminView ?? false
	);
	const { logout } = useAuth0();

	const updateViewMode = (event: boolean): void => {
		// Update stored user
		setSessionStorageUser({ ...getSessionStorageUser(), adminView: event });

		// Reload the page
		window.location.reload();
	};

	return (
		<header
			data-testid="header-desktop"
			className="k-header-container"
		>
			{/* Company logo (redirects to /home) */}
			<NavLink
				to="/"
				className="header-logo"
			>
				<img
					src={icon}
					alt="brand logo"
				/>
			</NavLink>

			{/* Main menu */}
			<ul className="header-menu">
				{props
					.filter((item) => item.routeProps.isVisible !== false)
					.map((item: Route) => (
						<li key={item.routeProps?.id || item.route.path}>
							<NavLink
								to={`${item.route?.path}`}
								className={({ isActive }) =>
									isActive ? "menu-item active" : "menu-item"
								}
							>
								{t(`${item.routeProps?.title}`)}
							</NavLink>
						</li>
					))}
			</ul>

			{/* Switch: admin/coach view */}
			{Roles.isAdmin() && (
				<div className="switch-view">
					<span className={adminView ? "view-mode" : "view-mode current"}>
						{t("UI_COMPONENTS.HEADER.COACH_VIEW")}
					</span>
					<InputSwitch
						checked={adminView}
						onChange={(e) => {
							setAdminView(e.value);
							updateViewMode(e.value);
						}}
					/>
					<span className={adminView ? "view-mode current" : "view-mode"}>
						{t("UI_COMPONENTS.HEADER.ADMIN_VIEW")}
					</span>
				</div>
			)}

			{/* Dialpad */}
			{getSessionStorageUser()?.phone && <UiDialPad />}

			{/* Logout */}
			<NavLink
				to="#"
				className="header-logout"
				onClick={() => {
					StoredCriteriaService.deleteAll();
					logout({ logoutParams: { returnTo: window.location.origin + "/login" } });
				}}
			>
				<>
					<FiLogOut className="logout-icon" />
					{t("LOGOUT.TITLE")}
				</>
			</NavLink>
		</header>
	);
};

export default HeaderDesktop;
