import { useTranslation } from "react-i18next";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import {
	CHART_DATA,
	CHART_FILTER,
} from "../../../../models/pages/biometrics/biometrics-charts.model";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";
import { useGlucoseHistograms } from "../../../../hooks/useGlucoseHistograms";

const BiometricsChartsHistograms = ({
	participantId,
	range,
	datasets,
}: {
	participantId: number;
	range: CHART_FILTER;
	datasets: CHART_DATA | undefined;
}) => {
	const { t } = useTranslation("common");

	const query = useGlucoseHistograms({
		participantId,
		startDate: BiometricsHelper.setStartDate(range),
	});

	return (
		<div className="">
			<div className="text-bold fs-5 mt-2">
				{t("PARTICIPANT.BIOMETRICS.CHARTS.TITLE_HISTOGRAMS")}
			</div>
			{query.isLoading ||
			query.isError ||
			!query.data ||
			query.data?.size === 0 ||
			BiometricsHelper.emptyDataset(datasets) ? (
				<div className="k-empty-message mt-2">
					<UiFieldMessage
						severity={
							query.isLoading
								? FieldMessageSeverity.LOADING
								: query.isError
									? FieldMessageSeverity.DANGER
									: FieldMessageSeverity.INFO
						}
						label={
							query.isLoading
								? "PARTICIPANT.BIOMETRICS.MESSAGES.LOADING_HISTOGRAMS"
								: query.isError
									? "PARTICIPANT.BIOMETRICS.MESSAGES.ERROR"
									: "PARTICIPANT.BIOMETRICS.MESSAGES.NO_DATA_HISTOGRAMS"
						}
					/>
				</div>
			) : (
				<div className="">
					<img
						src={URL.createObjectURL(query.data)}
						height="400px"
						width="auto"
						alt="histograms chart"
					/>
				</div>
			)}
		</div>
	);
};
export default BiometricsChartsHistograms;
