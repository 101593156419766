import { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { AppointmentRow } from "../../../../models/pages/appointments/appointments.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { Appointment, AppointmentTypes } from "../../../../models/entities/appointments.model";
import AppointmentDetails from "../../../../components/appointments/AppointmentDetails";
import { AppointmentsByParticipantRequest } from "../../../../models/endpoints/appointments-endpoint.model";
import { PastAppointmentRow } from "../../../../models/pages/appointments/past-appointment.model";

const ParticipantPastAppointments = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const [appointmentTypes, setAppointmentTypes] = useState<AppointmentTypes[]>([]);
	const today = new Date();

	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "type",
			title: "APPOINTMENTS.TABLE.COLUMNS.appointmentType",
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.APPOINTMENTS_TYPE" },
			sortable: true,
		},
		{
			field: "datetime",
			title: "APPOINTMENTS.TABLE.COLUMNS.dateOn",
			sortable: true,
		},
		{
			field: "duration",
			title: "APPOINTMENTS.TABLE.COLUMNS.duration",
			sortable: true,
		},
		{
			field: "expandCollapse",
			template: TableColumnTemplate.EXPANDER,
			style: {
				minWidth: "120px",
			},
		},
	];

	// Rows
	const [rows, setRows] = useState<PastAppointmentRow[]>([]);

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "datetime",
		sortOrder: 1,
		search: undefined,
		filters: undefined,
	});

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
	}, []);

	// Get data: upcoming appointments
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		// Get appointments types
		let types: AppointmentTypes[] = appointmentTypes || [];
		if (appointmentTypes.length === 0) {
			types = await EndpointsService.appointments.getTypes();
			setAppointmentTypes(types);
		}

		// Max date
		const year = `${today.getFullYear()}`;
		const month =
			today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : `${today.getMonth() + 1}`;
		const day = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;

		// Request
		const request: AppointmentsByParticipantRequest = {
			config: {
				params: {
					minDate: "2021-01-01",
					maxDate: `${year}-${month}-${day}`,
					kannactId: participantContext.participant!.kannactId,
				},
			},
		};
		const ptEmail =
			participantContext.participant!.emails?.filter((x) => x.isPrimary)[0]?.emailAddress ||
			undefined;
		if (ptEmail) {
			request.config.params.email = ptEmail;
		}

		// Call endpoint
		await EndpointsService.appointments
			.getAppointmentsByParticipant(request)
			.then((response: Appointment[]) => {
				if (response?.length > 0) {
					setRows(
						response
							.filter((x) => new Date(x.datetime).getTime() < new Date().getTime())
							.map((item) => new PastAppointmentRow(item, types))
					);
				} else {
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
				}
			})
			.catch((error: AxiosError) =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	// Table template: expanded
	const templateExpanded = (row: AppointmentRow): React.ReactNode => (
		<AppointmentDetails row={row} />
	);

	return (
		<UiTable
			useAsCard={false}
			dataKey="id"
			columns={columns}
			value={rows}
			message={message}
			pagination={pagination}
			paginationFn={onPagination}
			expandedTemplate={templateExpanded}
		/>
	);
};
export default ParticipantPastAppointments;
