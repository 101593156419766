import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../services/endpoints/endpoints.service";

export const useLongTermGoals = (participantId: number) => {
	const queryClient = useQueryClient();
	const queryKey = ["long-term-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		return await EndpointsService.dataRetriever.getLongTermGoals({
			config: {
				params: { participantId },
			},
		});
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
