import { Email } from "../../models/entities/email.model";
import {
	ParticipantProfile,
	PreferredCoachingMethod,
} from "../../models/entities/participant.model";
import { Phone } from "../../models/entities/phone.model";
import { PtContactForm } from "../../models/pages/participant-details/contact-info.model";

const setRequestPhone = (
	phones: Partial<Phone>[],
	participantPhone: Phone | null,
	formPhone: number | null,
	primaryPhone: number | null,
	primaryChanged: boolean
) => {
	if (
		participantPhone &&
		formPhone &&
		(participantPhone.phoneNumber !== formPhone.toString() || primaryChanged)
	) {
		// The phone has to be updated
		phones.push({
			phoneId: participantPhone?.phoneId as number,
			phoneNumber: formPhone?.toString(),
			isPrimary: primaryPhone === formPhone,
		});
	} else if (participantPhone && !formPhone) {
		// The phone has to be deleted
		phones.push({
			phoneId: participantPhone.phoneId as number,
			deleted: true,
		});
	} else if (!participantPhone && formPhone) {
		// The phone has to be created
		phones.push({
			phoneId: null,
			phoneNumber: formPhone.toString(),
			isPrimary: primaryPhone === formPhone,
		});
	}
};

const setRequestPhones = (participant: ParticipantProfile, formData: PtContactForm) => {
	const result: { [x: string]: any } = {};
	const participantPhone1 = participant.phones?.length > 0 ? participant.phones[0] : null;
	const participantPhone2 = participant.phones?.length > 1 ? participant.phones[1] : null;
	const participantPhone3 = participant.phones?.length > 2 ? participant.phones[2] : null;
	const phones: Partial<Phone>[] = [];

	// Check if the primary phone number has changed
	const participantPrimaryPhone =
		participant.phones?.length > 0
			? participant.phones.filter((x) => x.isPrimary)[0].phoneNumber
			: null;
	const primaryChanged =
		participantPrimaryPhone &&
		formData.primaryPhone &&
		participantPrimaryPhone !== formData.primaryPhone?.toString()
			? true
			: false;

	setRequestPhone(
		phones,
		participantPhone1,
		formData.phone1,
		formData.primaryPhone,
		primaryChanged
	);
	setRequestPhone(
		phones,
		participantPhone2,
		formData.phone2,
		formData.primaryPhone,
		primaryChanged
	);
	setRequestPhone(
		phones,
		participantPhone3,
		formData.phone3,
		formData.primaryPhone,
		primaryChanged
	);

	if (phones.length) {
		result.contactId = participant.contactId;
		result.phones = phones;
	}

	return result;
};

const setRequestEmail = (
	emails: Partial<Email>[],
	participantEmail: Email | null,
	formEmail: string | null,
	primaryEmail: string | null,
	primaryChanged: boolean
) => {
	if (
		participantEmail &&
		formEmail &&
		(participantEmail.emailAddress !== formEmail || primaryChanged)
	) {
		// The email has to be updated
		emails.push({
			emailId: participantEmail?.emailId as number,
			emailAddress: formEmail as string,
			isPrimary: primaryEmail === formEmail,
		});
	} else if (participantEmail && !formEmail) {
		// The email has to be deleted
		emails.push({
			emailId: participantEmail.emailId as number,
			deleted: true,
		});
	} else if (!participantEmail && formEmail) {
		// The email has to be created
		emails.push({
			emailId: null,
			emailAddress: formEmail,
			isPrimary: primaryEmail === formEmail,
		});
	}
};

const setRequestEmails = (participant: ParticipantProfile, formData: PtContactForm) => {
	const result: { [x: string]: any } = {};
	const participantEmail1 = participant.emails?.length > 0 ? participant.emails[0] : null;
	const participantEmail2 = participant.emails?.length > 1 ? participant.emails[1] : null;
	const emails: Partial<Email>[] = [];

	// Check if the primary phone number has changed
	const participantPrimaryEmail =
		participant.emails?.length > 0
			? participant.emails.filter((x) => x.isPrimary)[0].emailAddress
			: null;
	const primaryChanged =
		participantPrimaryEmail &&
		formData.primaryEmail &&
		participantPrimaryEmail !== formData.primaryEmail?.toString()
			? true
			: false;

	setRequestEmail(
		emails,
		participantEmail1,
		formData.email1,
		formData.primaryEmail,
		primaryChanged
	);
	setRequestEmail(
		emails,
		participantEmail2,
		formData.email2,
		formData.primaryEmail,
		primaryChanged
	);

	if (emails.length) {
		result.contactId = participant.contactId;
		result.emails = emails;
	}

	return result;
};

const setRequestScheduling = (participant: ParticipantProfile, formData: PtContactForm) => {
	const result: { [x: string]: any } = {};

	if (participant.timezone !== formData.timezone) {
		result.timezone = formData.timezone;
	}

	if (participant.timeOfDay !== formData.timeOfDay) {
		result.timeOfDay = formData.timeOfDay;
	}

	const originalDaysOfWeek = participant.daysOfWeek ? participant.daysOfWeek.split(",") : [];
	const updatedDaysOfWeek = formData.daysOfWeek || [];
	if (originalDaysOfWeek.length || updatedDaysOfWeek.length) {
		const containsAll1 = originalDaysOfWeek.every((item) => updatedDaysOfWeek.includes(item));
		const containsAll2 = updatedDaysOfWeek.every((item) => originalDaysOfWeek.includes(item));
		if (!containsAll1 || !containsAll2) {
			result.daysOfWeek = formData.daysOfWeek.join(",");
		}
	}

	return result;
};

const setRequestShipmentAddress = (participant: ParticipantProfile, formData: PtContactForm) => {
	const result: { [x: string]: any } = {};

	if (
		participant.shippingAddressId &&
		(participant.shippingAddressAddressLine1 !== formData.shippingAddressAddressLine1 ||
			participant.shippingAddressAddressLine2 !== formData.shippingAddressAddressLine2 ||
			participant.shippingAddressCity !== formData.shippingAddressCity ||
			participant.shippingAddressState !== formData.shippingAddressState ||
			participant.shippingAddressPostalCode !== formData.shippingAddressPostalCode)
	) {
		result.shippingAddressId = participant.shippingAddressId;
	}

	if (participant.shippingAddressAddressLine1 !== formData.shippingAddressAddressLine1) {
		result.shippingAddressAddressLine1 = formData.shippingAddressAddressLine1;
	}

	if (participant.shippingAddressAddressLine2 !== formData.shippingAddressAddressLine2) {
		result.shippingAddressAddressLine2 = formData.shippingAddressAddressLine2;
	}

	if (participant.shippingAddressCity !== formData.shippingAddressCity) {
		result.shippingAddressCity = formData.shippingAddressCity;
	}

	if (participant.shippingAddressState !== formData.shippingAddressState) {
		result.shippingAddressState = formData.shippingAddressState;
	}

	if (participant.shippingAddressPostalCode !== formData.shippingAddressPostalCode) {
		result.shippingAddressPostalCode = formData.shippingAddressPostalCode;
	}

	return result;
};

const setRequestPreferredCoachingMethod = (
	participant: ParticipantProfile,
	formData: PtContactForm
) => {
	const result: { [x: string]: any } = {};

	if (participant.preferredCoachingMethod !== formData.preferredCoachingMethod) {
		result.preferredCoachingMethod =
			formData.preferredCoachingMethod as PreferredCoachingMethod;
	}

	return result;
};

export const ParticipantDetailsContactService = {
	setRequestPhones,
	setRequestEmails,
	setRequestScheduling,
	setRequestShipmentAddress,
	setRequestPreferredCoachingMethod,
};
