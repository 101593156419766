import { Tooltip } from "primereact/tooltip";
import { useTranslation } from "react-i18next";

const NoteFormCustomField = ({
	fieldName,
	fieldLabel,
	tooltip,
	children,
}: {
	fieldName: string;
	fieldLabel: string;
	tooltip: string;
	children: JSX.Element;
}) => {
	const { t } = useTranslation("common");

	return (
		<div className="custom-field">
			<div className="label-wrapper">
				<label className="field-label">{fieldLabel}</label>
				<span
					id={`tooltip_${fieldName}`}
					className="pi pi-info-circle field-icon"
				/>
				<Tooltip
					target={`#tooltip_${fieldName}`}
					position="top"
					content={t(tooltip)}
					event="hover"
				/>
			</div>
			<div className="input-wrapper">{children}</div>
		</div>
	);
};

export default NoteFormCustomField;
