import { useState } from "react";
import UiTable from "../../../../../components/table/Table.component";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { TablePagination } from "../../../../../models/components/table/table-pagination.model";
import { CustomGoalsService } from "../../../../../services/custom-goals/custom-goals.service";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import { useTranslation } from "react-i18next";
import SmartGoalDetails from "./SmartGoalDetails";
import { SmartGoalCurrentRow } from "../../../../../models/custom-goals/smart-goal";
import {
	CustomGoalType,
	CustomGoalsFormMode,
} from "../../../../../models/custom-goals/custom-goals-misc";
import { TableTemplateButtonsEvent } from "../../../../../models/components/table/table-template.model";
import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import { TableStyle } from "../../../../../models/components/table/table.model";
import LongTermGoalDetails from "./LongTermGoalDetails";
import { LongTermGoalCurrentRow } from "../../../../../models/custom-goals/long-term-goal";
import { MaintenanceGoalCurrentRow } from "../../../../../models/custom-goals/maintenance-goal";

const CustomGoalsCurrentTable = ({
	type,
	smartGoalsQuery,
	longTermGoalsQuery,
	onUpdate,
	onDelete,
	onComplete,
}: {
	type: CustomGoalType;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onUpdate: (e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => void;
	onDelete: (e: CustomGoalType, e2: SmartGoal | LongTermGoal) => void;
	onComplete: (e: CustomGoalType, e2: SmartGoal | LongTermGoal) => void;
}) => {
	const { t } = useTranslation("common");

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: type === CustomGoalType.MAINTENANCE_GOAL ? "updatedOn" : "endDate",
		sortOrder: 1,
		search: undefined,
		filters: undefined,
	});

	// Rows
	const mapRows = (data?: (SmartGoal | LongTermGoal)[]) => {
		let result: (SmartGoalCurrentRow | LongTermGoalCurrentRow | MaintenanceGoalCurrentRow)[] =
			[];

		if (data?.length) {
			switch (type) {
				case CustomGoalType.SMART_GOAL:
					result = (data as SmartGoal[])
						.filter((x) => !x.deleted && !x.completed && !x.maintenance)
						.map(
							(x) =>
								new SmartGoalCurrentRow(
									x,
									t(
										"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.endDateExpired"
									),
									t("ENUMS.CUSTOM_GOALS_CATEGORY", { returnObjects: true }),
									complete.bind(this),
									update.bind(this),
									deleteModal.bind(this),
									duplicate.bind(this)
								)
						);
					break;
				case CustomGoalType.LONG_TERM_GOAL:
					result = (data as LongTermGoal[])
						.filter((x) => !x.deleted && !x.completed)
						.map(
							(x) =>
								new LongTermGoalCurrentRow(
									x,
									t(
										"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.endDateExpired"
									),
									complete.bind(this),
									update.bind(this),
									deleteModal.bind(this),
									duplicate.bind(this)
								)
						);
					break;
				case CustomGoalType.MAINTENANCE_GOAL:
					result = (data as SmartGoal[])
						.filter((x) => !x.deleted && x.maintenance)
						.map(
							(x) =>
								new MaintenanceGoalCurrentRow(
									x,
									t(
										"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.endDateExpired"
									),
									t("ENUMS.CUSTOM_GOALS_CATEGORY", { returnObjects: true }),
									duplicate.bind(this)
								)
						);
					break;
			}
		}

		return result;
	};

	/**
	 * ACTIONS
	 */

	const complete = (event: TableTemplateButtonsEvent) => onComplete(type, event.row.entity);

	const update = (event: TableTemplateButtonsEvent) =>
		onUpdate(
			type === CustomGoalType.SMART_GOAL
				? CustomGoalsFormMode.UPDATE_SMART_GOAL
				: CustomGoalsFormMode.UPDATE_LONG_TERM_GOAL,
			event.row.entity
		);

	const deleteModal = (event: TableTemplateButtonsEvent) => onDelete(type, event.row.entity);

	const duplicate = (event: TableTemplateButtonsEvent) =>
		onUpdate(
			type === CustomGoalType.SMART_GOAL
				? CustomGoalsFormMode.DUPLICATE_SMART_GOAL
				: type === CustomGoalType.LONG_TERM_GOAL
					? CustomGoalsFormMode.DUPLICATE_LONG_TERM_GOAL
					: CustomGoalsFormMode.DUPLICATE_MAINTENANCE_GOAL,
			event.row.entity
		);

	return (
		<UiTable
			dataKey="id"
			title={
				type === CustomGoalType.SMART_GOAL
					? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.SMART_GOALS"
					: type === CustomGoalType.LONG_TERM_GOAL
						? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.LONG_TERM_GOALS"
						: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.MAINTENANCE_GOALS"
			}
			useAsCard={false}
			type={TableStyle.GREEN}
			tableClassName="mb-4"
			columns={CustomGoalsService.setColumnsCurrentGoals(type)}
			value={mapRows(
				type === CustomGoalType.LONG_TERM_GOAL
					? longTermGoalsQuery.data
					: smartGoalsQuery.data
			)}
			message={CustomGoalsService.setTableMessage(
				type === CustomGoalType.LONG_TERM_GOAL ? longTermGoalsQuery : smartGoalsQuery
			)}
			paginator={false}
			pagination={pagination}
			paginationFn={(event: TablePagination) => {
				const newPagination: TablePagination = { ...pagination, ...event };
				setPagination(newPagination);
			}}
			expandedTemplate={(row: SmartGoalCurrentRow | LongTermGoalCurrentRow) =>
				type === CustomGoalType.LONG_TERM_GOAL ? (
					<LongTermGoalDetails entity={row.entity as LongTermGoal} />
				) : (
					<SmartGoalDetails
						entity={row.entity as SmartGoal}
						longTermGoalsQuery={longTermGoalsQuery}
					/>
				)
			}
		/>
	);
};
export default CustomGoalsCurrentTable;
