import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	PaginatorCurrentPageReportOptions,
	PaginatorRowsPerPageDropdownOptions,
	PaginatorTemplate,
} from "primereact/paginator";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
// Models
import { Column, ColumnAlignType, ColumnBodyOptions } from "primereact/column";
import { TableStyle, UiTableProps } from "../../models/components/table/table.model";
import { TableColumn, TableColumnTemplate } from "../../models/components/table/table-column.model";
// Components
import { Dropdown } from "primereact/dropdown";
import { UiFieldMessageProps } from "../../models/components/field-message.model";
import UiTableCell from "../table-cell/TableCell.component";
import { UiTableCellProps } from "../../models/components/table/table-cell.model";
import UiTableHeader from "./header/TableHeader.component";
import { TableHeaderProps } from "../../models/components/table/table-header.model";
import { classNames } from "primereact/utils";
import TableEmptyMessage from "./empty-message/TableEmptyMessage.component";
import { TableService } from "../../services/table/table.service";

const UiTable = (props: UiTableProps) => {
	const { t } = useTranslation("common");

	// Column: alignment (horizontal: left | right  middle), and css class (for template styling)
	const setColumnCss = (col: TableColumn): { alignment: ColumnAlignType; extraClass: string } => {
		let alignment: ColumnAlignType = col.alignment || "left";
		let extraClass: string = "row-cell ";

		switch (col.template) {
			case TableColumnTemplate.DATE:
			case TableColumnTemplate.CURRENCY:
			case TableColumnTemplate.DURATION:
			case TableColumnTemplate.PHONE:
				alignment = "right";
				break;
			case TableColumnTemplate.AUDIO:
				extraClass += " audio-template";
				break;
			case TableColumnTemplate.PHONE_CALL:
				alignment = "right";
				extraClass += " phone-call-template";
				break;
			case TableColumnTemplate.ACTIONS:
				extraClass += " actions-template";
				break;
			case TableColumnTemplate.BUTTONS:
				extraClass += " buttons-template";
				break;
			case TableColumnTemplate.TAG:
				extraClass += " tag-template";
				break;
			default:
				break;
		}

		return { alignment, extraClass };
	};

	// Column: width
	const setColumnStyle = (col: TableColumn): React.CSSProperties | undefined => {
		let style: React.CSSProperties | undefined;

		// Default sizes
		if (col.template === TableColumnTemplate.ACTIONS) {
			style = {
				width: "40px",
			};
		}

		// Pre-defined width
		if (col.style) {
			style = col.style;
		}

		return style;
	};

	// Template: row cell
	const setRowCellTemplate = (
		rowData: any,
		col: TableColumn,
		colBody: ColumnBodyOptions
	): React.ReactNode => {
		const rowCellProps: UiTableCellProps = {
			column: col,
			rowData,
			isExpanded: isExpanded(rowData),
			toggleExpand: toggleRow.bind(this),
			colBody,
		};
		const template = <UiTableCell {...rowCellProps} />;
		return template;
	};

	/**
	 * FOOTER
	 */

	const footerTemplate: PaginatorTemplate = {
		layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
		RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
			const dropdownOptions = [10, 20, 50];
			return (
				<React.Fragment>
					<div className="footer-rpp">
						<span className="footer-rpp-text">
							{t("UI_COMPONENTS.TABLE.FOOTER.RPP")}
						</span>
						<Dropdown
							value={options.value}
							options={dropdownOptions}
							onChange={options.onChange}
						/>
					</div>
				</React.Fragment>
			);
		},
		CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
			return (
				<span className="footer-current-page">
					{t("UI_COMPONENTS.TABLE.FOOTER.CURRENT_PAGE", {
						first: options.first,
						last: options.last,
						totalRecords: options.totalRecords,
					})}
				</span>
			);
		},
		FirstPageLink: undefined,
		JumpToPageInput: undefined,
		LastPageLink: undefined,
		NextPageLink: undefined,
		PageLinks: undefined,
		PrevPageLink: undefined,
	};

	/**
	 * PAGINATION
	 */

	// Pagination: client and server-side
	const onPage = (event: DataTablePFSEvent): void => {
		if (props.paginationFn)
			props.paginationFn({
				first: event.first,
				page: event.page,
				rows: event.rows,
				sortField: event.sortField,
				sortOrder: event.sortOrder,
			});
	};

	/**
	 * SORT
	 */

	// Sorting: by column (asc/desc)
	const onSort = (event: DataTablePFSEvent): void => {
		if (props.paginationFn)
			props.paginationFn({
				...props.pagination,
				sortField: event.sortField,
				sortOrder: event.sortOrder,
			});
	};

	/**
	 * MESSAGE
	 * @description Show a message (loading/error/no data)
	 */
	const emptyMessage = props.emptyMessage ?? (
		<TableEmptyMessage {...(props.message as UiFieldMessageProps)} />
	);

	/**
	 * EXPANDABLE ROWS
	 */

	useEffect(() => {
		setExpandedRows(props.expandedRows);
	}, [props.expandedRows]);

	const [expandedRows, setExpandedRows] = useState<any>(props.expandedRows || null);

	const isExpanded = (row: any): boolean =>
		expandedRows && props.dataKey && !!expandedRows[row[props.dataKey as string]];

	const toggleRow = (event: any): void => {
		const _expandedRows: { [x: string]: boolean } = {};
		if (props.value && props.value.length > 0 && props.dataKey) {
			props.value.forEach((row) => {
				if (
					row[props.dataKey as string] === event[props.dataKey as string] &&
					(!expandedRows || (expandedRows && !expandedRows[row[props.dataKey as string]]))
				) {
					_expandedRows[row[props.dataKey as string]] = true;
				} else {
					delete _expandedRows[row[props.dataKey as string]];
				}
			});
		}
		setExpandedRows(_expandedRows);
	};

	const showPaginator = (): boolean => (props.paginator === false ? false : !!props.value);

	return (
		<DataTable
			id={props.id}
			value={props.value}
			responsiveLayout="scroll"
			sortMode="single"
			rowHover={true}
			className={classNames(
				"k-table",
				props.type ?? TableStyle.DEFAULT,
				props.useAsCard ? "as-card" : "",
				props.value && props.value.length > 0 ? "has-values" : "is-empty",
				props.tableClassName
			)}
			header={
				TableService.showHeader(props) ? (
					<UiTableHeader {...new TableHeaderProps(props)} />
				) : undefined
			}
			paginator={showPaginator()}
			paginatorTemplate={footerTemplate}
			emptyMessage={emptyMessage}
			rows={props.pagination.rows}
			rowClassName={props.rowClassName}
			totalRecords={props.totalRecords}
			loading={props.loading}
			lazy={props.isServerPaginated}
			onPage={onPage}
			onSort={onSort}
			first={props.pagination.first}
			sortField={props.pagination.sortField}
			sortOrder={props.pagination.sortOrder}
			dataKey={props.dataKey}
			expandedRows={expandedRows}
			rowExpansionTemplate={props.expandedTemplate}
			onRowToggle={toggleRow}
		>
			{/* Columns */}
			{props.columns?.map((col) => (
				<Column
					key={col.field}
					field={col.field}
					resizeable={true}
					className={setColumnCss(col).extraClass}
					align={setColumnCss(col).alignment}
					style={setColumnStyle(col)}
					header={col.title ? t(col.title) : undefined}
					sortable={col.sortable}
					sortFunction={col.onSort}
					body={(rowData, colBody) => setRowCellTemplate(rowData, col, colBody)}
				/>
			))}
		</DataTable>
	);
};

export default UiTable;
