import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ParticipantContext } from "../../contexts/Participant.context";
import { PageProps } from "../../models/routing.model";
import { classNames } from "primereact/utils";
import TwilioConversationService from "../../services/twilio/twilio-conversations.service";
import ParticipantProfileInfo from "./participant-profile/ParticipantProfile";
import { useParticipantProfile } from "../../hooks/useParticipantProfile";
import ParticipantState from "./ParticipantState";
import { useMatomoTrackParticipant } from "../../hooks/useMatomoTrackParticipant";

const Participant = (props: PageProps) => {
	// URL param: participant ID
	const urlParams = useParams<{ participantId: string }>();

	// GET: Participant by ID
	const participantProfileQuery = useParticipantProfile({
		participantId: Number(urlParams.participantId),
	});

	/* MATOMO (usage tracking) */

	useMatomoTrackParticipant();

	/* Twilio conversations */

	const initializeTwilioConversations = async (participantId: number): Promise<void> => {
		await TwilioConversationService.initializeClient();
		await TwilioConversationService.getConversation(participantId);
	};

	useEffect(() => {
		const participantId = urlParams.participantId ? Number(urlParams.participantId) : null;
		if (participantId) {
			initializeTwilioConversations(participantId);
		}
	}, [urlParams.participantId]);

	// LOADING / ERROR
	if (participantProfileQuery.isLoading || participantProfileQuery.isError) {
		return (
			<div className="participant-container">
				<section className="participant-profile-container">
					<ParticipantState
						isLoading={participantProfileQuery.isLoading}
						isError={participantProfileQuery.isError}
					/>
				</section>
			</div>
		);
	}

	return (
		<ParticipantContext.Provider
			value={{
				participant: participantProfileQuery.data,
				onRefresh: () => participantProfileQuery.invalidate(),
			}}
		>
			<div className="participant-container">
				{/* Participant profile */}
				<section
					className={classNames(
						"participant-profile-container",
						participantProfileQuery.data.status
					)}
				>
					<ParticipantProfileInfo />
				</section>

				{/* Pages content */}
				<div className="participant-content">
					<Outlet />
				</div>
			</div>
		</ParticipantContext.Provider>
	);
};
export default Participant;
