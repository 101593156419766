import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { ParticipantSUGData } from "../../../models/pages/service-utilization-goals/participant-sug.model";
import { SUGModalHistoryProps } from "../../../models/pages/service-utilization-goals/sug-modal-history.model";
import { TabPanel, TabView } from "primereact/tabview";
import ServiceUtilizationGoalsHistory from "./ServiceUtilizationGoalsHistory";
import ProcedureHistory from "./ProcedureHistory";
import UiButton from "../../../components/button/Button";
import ProcedureCreate from "./ProcedureCreate";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";

const ServiceUtilizationGoalsModalHistory = ({ props }: { props: SUGModalHistoryProps }) => {
	const { t } = useTranslation("common");

	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const [mode, setMode] = useState<"TABS" | "ADD_PROCEDURE">("TABS");

	/* Tabs */

	const [activeTab, setActiveTab] = useState<number>(props.activeTab);
	const [categories] = useState<{ [x: string]: ParticipantSUGData }>(props.categories);

	// Template: tab
	const tabTemplate = (): React.ReactNode[] => {
		const tabTemplates: React.ReactNode[] = [];

		for (const key in categories) {
			if (Object.hasOwn(categories, key)) {
				// Create tab
				const tabTemplate: React.ReactNode = (
					<TabPanel
						key={key}
						header={t(categories[key].title)}
						disabled={categories[key].disabled}
					>
						{/* Tab content */}
						<ServiceUtilizationGoalsHistory props={categories[key].services} />
					</TabPanel>
				);

				tabTemplates.push(tabTemplate);
			}
		}

		// Tab: procedures
		if (participantContext.participant?.id) {
			tabTemplates.push(
				<TabPanel
					key="procedures"
					header={t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.TITLE")}
					disabled={false}
				>
					{/* Tab content */}
					<ProcedureHistory participantId={participantContext.participant.id} />
				</TabPanel>
			);
		}

		return tabTemplates;
	};

	return (
		<Dialog
			header={
				<div className="dialog-custom-header">
					{/* Title */}
					<span className="dialog-custom-header__title">
						{t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.TITLE")}
					</span>
					{mode === "TABS" && (
						<div className="dialog-custom-header__actions">
							<UiButton
								label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.PROCEDURES.ADD_PROCEDURE.BUTTON_ADD"
								type="button"
								icon="pi pi-plus"
								className="p-button-sm p-button-rounded"
								onClick={() => setMode("ADD_PROCEDURE")}
							/>
						</div>
					)}
				</div>
			}
			visible={props.isVisible}
			breakpoints={{ "960px": "70vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			draggable={false}
			focusOnShow={false}
			onHide={() => props.closeModal(false)}
		>
			{mode === "TABS" ? (
				<TabView activeIndex={activeTab}>{tabTemplate()}</TabView>
			) : (
				<ProcedureCreate
					participantId={participantContext.participant!.id!}
					onClose={() => {
						setMode("TABS");
						setActiveTab(4);
					}}
				/>
			)}
		</Dialog>
	);
};

export default ServiceUtilizationGoalsModalHistory;
