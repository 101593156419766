import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { TableHeaderProps } from "../../../models/components/table/table-header.model";
import UiButton from "../../button/Button";
import UiTableHeaderSearch from "./TableHeaderSearch.component";
import UiTableHeaderFilter from "./TableHeaderFilter.component";
import UiTableHeaderCrud from "./TableHeaderCrud.component";

const UiTableHeader = (props: TableHeaderProps) => {
	const { t } = useTranslation("common");

	// Check if the table has any action button
	const hasActions = (): boolean => !!props.search || !!props.refresh || !!props.crud;

	// Search
	const searchChanged = (value: string | null): void => {
		if (props.search?.fn) {
			props.search.fn(value);
		}
	};

	// Filters: filter selection has changed
	const filtersChanged = (value: any): void => {
		// Filter
		if (props.filter?.submitFn) props.filter.submitFn(value);
	};

	return (
		<div className="table-header-container">
			{/* PRIMARY HEADER */}
			<div className={classNames("table-header", hasActions() && "has-actions")}>
				{/* Table title */}
				{(props.title || props.customHeader) && (
					<div className="table-header-title">
						{props.title && (
							<span
								className={classNames(
									"table-title",
									props.customHeader && "has-custom-header"
								)}
							>
								{t(props.title)}
							</span>
						)}
						{props.customHeader ? props.customHeader : <></>}
					</div>
				)}

				{/* Actions */}
				<div className="table-actions">
					{/* Search */}
					{props.search && (
						<div className="search-container action-spacer">
							<UiTableHeaderSearch
								placeholder={props.search.placeholder}
								value={props.search.value ?? null}
								searchFn={searchChanged}
							/>
						</div>
					)}

					{/* Refresh */}
					{props.refresh && (
						<div className="refresh-container action-spacer">
							<UiButton
								id="refreshBtn"
								icon="pi pi-refresh"
								className="p-button-text p-button-rounded"
								label={props.refresh.title}
								tooltip={props.refresh.tooltip}
								tooltipOptions={{ position: "bottom" }}
								onClick={props.refresh.fn}
							></UiButton>
						</div>
					)}

					{/* CRUD */}
					{props.crud && (
						<div className="crud-container action-spacer">
							<UiTableHeaderCrud {...props.crud} />
						</div>
					)}
				</div>
			</div>

			{/* COLLAPSIBLE FILTERS PANEL */}
			{props.filter?.filters && props.filter.filters.length > 0 && (
				<UiTableHeaderFilter
					title={props.filter.title}
					filters={props.filter.filters}
					fn={filtersChanged}
				/>
			)}
		</div>
	);
};

export default UiTableHeader;
