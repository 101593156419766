import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { Dialog } from "primereact/dialog";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";
import { HelperService } from "../../services/helpers/helper.service";
import UiLink from "../link/Link.component";
import { TableTemplateKannactId } from "../../models/components/table/table-template.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import UiFieldMessage from "../field-message/FieldMessage.component";
import FormSection from "../form-section/FormSection.component";
import { ModalTwilioAudioProps } from "../../models/modal-twilio-audio.model";

const ModalTwilioAudio = (props: ModalTwilioAudioProps): JSX.Element => {
	const { t } = useTranslation("common");

	useEffect(() => {
		if (!props.mediaUrl) getRecordingData();
	}, [props.callId]);

	// Messages
	const messages = {
		loading: "UI_COMPONENTS.TABLE.TEMPLATES.TWILIO_AUDIO.MESSAGE_LOADING",
		empty: "UI_COMPONENTS.TABLE.TEMPLATES.TWILIO_AUDIO.MESSAGE_EMPTY",
		error: "UI_COMPONENTS.TABLE.TEMPLATES.TWILIO_AUDIO.MESSAGE_ERROR",
	};
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.LOADING,
		label: messages.loading,
	});

	/**
	 * Endpoint: get call recording
	 * @description Call the endpoint to retreive the recording.json file for this specific call
	 */
	const getRecordingData = async (): Promise<void> => {
		// Loading
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		// Get recording
		await EndpointsService.twilio
			.getRecording(props.callId as string)
			.then((response: string) => {
				if (response) {
					props.onUpdate({ ...props, mediaUrl: response });
				} else {
					// No data
					props.onUpdate({ ...props, mediaUrl: null });
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	return (
		<Dialog
			header={t("UI_COMPONENTS.TABLE.TEMPLATES.TWILIO_AUDIO.TITLE")}
			visible={props.isVisible}
			breakpoints={{ "960px": "50vw", "640px": "100vw" }}
			style={{ width: "40vw" }}
			className="twilio-audio-modal"
			modal={false}
			draggable={true}
			resizable={false}
			focusOnShow={false}
			onHide={props.onClose}
		>
			<FormSection className="mb-3">
				{/* Kannact ID */}
				{HelperService.isKannactId(props.kannactId as string) ? (
					<UiLink {...new TableTemplateKannactId(props.kannactId as string).props} />
				) : (
					<span>{props.kannactId}</span>
				)}

				{/* Call date/time */}
				{props.callDate && (
					<span className="mx-3">
						{Formatter.date(props.callDate, {
							day: "2-digit",
							month: "2-digit",
							year: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
						})}
					</span>
				)}
			</FormSection>

			{/* There is an audio file */}
			{props?.mediaUrl && (
				<audio
					style={{ width: "100%" }}
					controls
					src={props?.mediaUrl}
				></audio>
			)}

			{/* There isn't an audio file --> show message (empty/error) */}
			{!props?.mediaUrl && (
				<UiFieldMessage
					severity={message.severity}
					label={message.label}
				></UiFieldMessage>
			)}
		</Dialog>
	);
};
export default ModalTwilioAudio;
