import { UiButtonProps } from "../../../models/components/button.model";
import { UiTableCellProps } from "../../../models/components/table/table-cell.model";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import UiButton from "../../button/Button";

const UiTableButtonsTemplate = (props: UiTableCellProps): JSX.Element => {
	// Props that need special treatment
	const getProps = (item: UiButtonProps): UiButtonProps => {
		return { ...item, onClick: () => handleClick(item) };
	};

	const handleClick = (item: UiButtonProps): void => {
		// Send: column ID, row, button clicked
		const event: TableTemplateButtonsEvent = {
			columnId: props.column.field,
			row: props.rowData,
			buttonProps: item,
		};
		if (item.onClick) item.onClick(event);
	};

	return (
		<>
			{props.rowData[String(props.column.field)]?.length > 0 &&
				props.rowData[String(props.column.field)].map(
					(item: UiButtonProps, index: number) => (
						<UiButton
							key={index}
							{...getProps(item)}
						></UiButton>
					)
				)}
		</>
	);
};
export default UiTableButtonsTemplate;
