import { Goal, GoalState } from "../entities/goal.model";
import { SuServices, SuStatus } from "../entities/service-utilization-goals.model";
import { EndpointRequest } from "./endpoints.model";

export interface GoalsRequest extends EndpointRequest {
	participantId: number;
}

// Get goals by participant
export interface GoalResponse {
	participantId: number;
	goals: Goal[];
}

export interface HedisGoalResponse {
	name: HedisGoalCategory;
	count: number;
	goals: HedisGoal[];
}

export enum HedisGoalCategory {
	MEDICATIONS = "MEDICATIONS",
	BIOMETRICS = "BIOMETRICS",
	SERVICE_UTILIZATION = "SERVICE_UTILIZATION",
}

export interface HedisGoal {
	// Biometrics & Medications | Service Utilization
	name: string | SuServices;
	// Biometrics & Medications | Service Utilization
	state: GoalState | SuStatus;
}
