import { useTranslation } from "react-i18next";
import GoalIndicator from "../../../../components/goal-indicator/GoalIndicator";
import { MedicationLatestResponse } from "../../../../models/endpoints/medications-endpoint.model";
import QuickViewPanelWrapper from "../../../../components/quick-view-panel-wrapper/QuickViewPanelWrapper";
import Spacer from "../../../../components/spacer/Spacer";
import { Goal } from "../../../../models/entities/goal.model";
import { GoalsService } from "../../../../services/goals/goals.service";

const MedicationsQuickViewPanel = (props: {
	isLoading: boolean;
	isError: boolean;
	latestActiveMedications: MedicationLatestResponse[] | undefined;
	goals: Goal[] | undefined;
	onClickAction: () => void;
}) => {
	const { t } = useTranslation("common");

	const areGoalsAvailable = props.goals && props.goals.length > 0;
	const areMedicationsAvailable =
		props.latestActiveMedications && props.latestActiveMedications.length > 0;
	const isDataAvailable = areGoalsAvailable || areMedicationsAvailable;

	if (props.isLoading) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data loading">
					<i className="pi pi-spin pi-spinner message-icon"></i>
					{t("PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.LOADING")}
				</p>
			</QuickViewPanelWrapper>
		);
	}

	if (props.isError) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data has-error">
					{t("PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.ERROR")}
				</p>
			</QuickViewPanelWrapper>
		);
	}

	if (!isDataAvailable) {
		return (
			<QuickViewPanelWrapper
				title="PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.PANEL_TITLE"
				onClickAction={props.onClickAction}
				isLoading={props.isLoading}
				isError={props.isError}
			>
				<p className="panel-no-data">
					{t("PARTICIPANT.DASHBOARD.MEDICATIONS.MESSAGES.NO_DATA")}
				</p>
			</QuickViewPanelWrapper>
		);
	}

	return (
		<QuickViewPanelWrapper
			title="PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.PANEL_TITLE"
			onClickAction={props.onClickAction}
			isLoading={props.isLoading}
			isError={props.isError}
		>
			<>
				{areGoalsAvailable && <MedicationAdherenceList goals={props.goals!} />}
				{areGoalsAvailable && areMedicationsAvailable && <Spacer y={12} />}
				{areMedicationsAvailable && (
					<CurrentMedicationList
						latestActiveMedications={props.latestActiveMedications!}
					/>
				)}
			</>
		</QuickViewPanelWrapper>
	);
};

const MedicationAdherenceList = (props: { goals: Goal[] }) => {
	const { t } = useTranslation("common");

	return (
		<>
			<div style={{ fontWeight: "bold" }}>
				{t("PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.MEDICATION_ADHERENCE")}
			</div>
			<ul className="panel-list">
				{props.goals.map((goal, index) => (
					<li
						key={index}
						className="panel-list-item"
					>
						<span className="item-label">
							{GoalsService.getMedicationGoalCategory(goal)}
						</span>
						<span className="item-value">
							{GoalsService.getMedicationGoalValue(goal)}
						</span>{" "}
						<GoalIndicator
							goal={goal}
							detailed={false}
						/>
					</li>
				))}
			</ul>
		</>
	);
};

const CurrentMedicationList = (props: { latestActiveMedications: MedicationLatestResponse[] }) => {
	const { t } = useTranslation("common");

	return (
		<>
			<div className="text-bold text-black">
				{t("PARTICIPANT.DASHBOARD.MEDICATIONS.QUICK_VIEW.CURRENT_MEDICATIONS")}
			</div>
			<ul className="panel-list">
				{props.latestActiveMedications.map((item, index) => (
					<li
						key={index}
						className="panel-list-item"
					>
						<span className="item-value">{item.medicationName}</span>
					</li>
				))}
			</ul>
		</>
	);
};

export default MedicationsQuickViewPanel;
