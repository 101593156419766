export interface Goal {
	applicable: boolean;
	category: GoalCategory;
	date: string; // Date
	dueDate: string; // Date
	eligible: boolean;
	examTypes: string[] | null;
	goalName: string;
	metadata: any;
	state: GoalState;
}

export enum GoalCategory {
	BIOMETRIC = "BIOMETRIC",
	DRUGS = "DRUGS",
}

export enum GoalState {
	on_track = "on_track",
	off_track = "off_track",
	expired = "expired", // Only if elegible === true
	no_data = "no_data", // Only if elegible === true
	fallback = "fallback", // Only used in UAT for some cases that need manual revision from the data team
	due_soon = "due_soon",
}

export enum BiometricGoal {
	A1C = "A1C",
	BPS = "BPS",
}

export enum MedicationGoal {
	STATIN_PDC = "STATIN_PDC",
	AMR = "AMR",
}
