import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPlus } from "react-icons/bs";
import { TbTrash } from "react-icons/tb";
import UiInputDate from "../../../../components/input-date/InputDate.component";
import { Validations } from "../../../../services/form/validations.service";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import UiAutocomplete from "../../../../components/autocomplete/Autocomplete";
import UiInputText from "../../../../components/input-text/InputText.component";
import UiInputNumber from "../../../../components/input-number/InputNumber.component";
import UiSelect from "../../../../components/select/Select.component";
import { MedicationsCreateData } from "../../../../models/pages/medications/medications-create.model";
import UiButton from "../../../../components/button/Button";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { Form } from "react-final-form";
import { Source } from "../../../../models/entities/source.model";
import FormSection from "../../../../components/form-section/FormSection.component";
import { TestingFrequency } from "../../../../models/entities/participant.model";
import { ListOption } from "../../../../models/misc.model";

const MedicationsCreate = (props: {
	participantId: number;
	onCancel: () => void;
	onComplete: () => void;
}) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState({
		data: [new MedicationsCreateData()],
	});
	const [message, setMessage] = useState(false);
	const [medications, setMedications] = useState<string[]>([]);

	const getMedications = async (event: { query: string | null }): Promise<void> => {
		await EndpointsService.dataRetriever
			.getMedicationsList({
				config: {
					params: { search: event.query },
				},
			})
			.then((response: string[]) => {
				setMedications(response.length > 0 ? response : []);
			})
			.catch((error: AxiosError) => {
				// TODO
			});
	};

	const handleSubmit = async (data: any) => {
		setMessage(false);

		const medications = formData.data.map((medication) => ({
			participantId: props.participantId,
			source: Source.COACH,
			isActive: true,
			medicationName: medication.medicationName as string,
			filledDate: (medication.dateFilled as Date).toISOString(),
			dosage: medication.dosage as string,
			dosageUnit: medication.dosageUnit as string,
			frequency:
				medication.frequency || medication.period
					? medication.frequency + "_" + medication.period
					: undefined,
		}));

		await EndpointsService.dataRetriever
			.createMedication({
				body: medications,
			})
			.then((response) => props.onComplete())
			.catch((error) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					{/* Form (dynamic) */}
					{formData.data.map((medication, index) => (
						<div
							key={`medication_${index}`}
							className="medication-create-form"
						>
							<FormSection key={index}>
								<div className="row">
									{/* Date filled */}
									<div className="col-12 col-md-6 col-lg-4 col-xxl-2 mt-4">
										<UiInputDate
											id={`data[${index}].dateFilled`}
											name={`data[${index}].dateFilled`}
											placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.DATE_FILLED"
											label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.DATE_FILLED"
											validations={[Validations.required]}
											disabled={submitting}
											maxDate={new Date()}
											onChange={(e) => {
												const newMedications = [...formData.data];
												newMedications[index] = {
													...medication,
													dateFilled: e.target.value as Date,
												};
												setFormData({ data: newMedications });
											}}
										/>
									</div>

									{/* Name */}
									<div className="col-12 col-md-12 col-lg-8 col-xxl-4 mt-4">
										<UiAutocomplete
											id={`data[${index}].medicationName`}
											name={`data[${index}].medicationName`}
											label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.MEDICATION_NAME"
											suggestions={medications}
											completeMethod={getMedications}
											validations={[Validations.required]}
											dropdown={true}
											forceSelection={false}
											panelClassName="medications-suggestions"
											disabled={submitting}
											onChange={(e) => {
												const newMedications = [...formData.data];
												newMedications[index] = {
													...medication,
													medicationName: e.target.value,
												};
												setFormData({ data: newMedications });
											}}
										/>
									</div>

									{/* Dosage */}
									<div className="col-12 col-md-12 col-lg-6 col-xxl-3">
										<div>
											{t(
												"PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.DOSAGE"
											)}
										</div>
										<div className="row">
											{/* Dosage: value */}
											<div className="col-12 col-md-6">
												<UiInputNumber
													id={`data[${index}].dosage`}
													name={`data[${index}].dosage`}
													label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.DOSAGE"
													disabled={submitting}
													maxFractionDigits={2}
													onChange={(e) => {
														const newMedications = [...formData.data];
														newMedications[index] = {
															...medication,
															dosage: e.value
																? String(e.value)
																: null,
														};
														setFormData({ data: newMedications });
													}}
												/>
											</div>

											{/* Dosage: unit */}
											<div className="col-12 col-md-6">
												<UiInputText
													id={`data[${index}].dosageUnit`}
													name={`data[${index}].dosageUnit`}
													label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.DOSAGE_UNIT"
													disabled={submitting}
													onChange={(e) => {
														const newMedications = [...formData.data];
														newMedications[index] = {
															...medication,
															dosageUnit: e.target.value,
														};
														setFormData({ data: newMedications });
													}}
												/>
											</div>
										</div>
									</div>

									{/* Frequency */}
									<div className="col-12 col-md-12 col-lg-6 col-xxl-3">
										<div>
											{t(
												"PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.FREQUENCY"
											)}
										</div>
										<div className="row">
											{/* Frequency: times */}
											<div className="col-12 col-md-6">
												<UiInputNumber
													id={`data[${index}].frequency`}
													name={`data[${index}].frequency`}
													label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PLACEHOLDERS.FREQUENCY"
													disabled={submitting}
													onChange={(e) => {
														const newMedications = [...formData.data];
														newMedications[index] = {
															...medication,
															frequency: e.value,
														};
														setFormData({ data: newMedications });
													}}
												/>
											</div>

											{/* Frequency: frequency */}
											<div className="col-12 col-md-6">
												<UiSelect
													id={`data[${index}].period`}
													name={`data[${index}].period`}
													placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.FREQUENCY"
													options={Object.keys(TestingFrequency).map(
														(x) =>
															new ListOption({
																id: x,
																label: `PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PERIODS.${x}`,
															})
													)}
													disabled={submitting}
													onChange={(e) => {
														const newMedications = [...formData.data];
														newMedications[index] = {
															...medication,
															period: e.value,
														};
														setFormData({ data: newMedications });
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</FormSection>

							{/* Remove form */}
							<div className="medication-create-form__remove">
								{index !== 0 && (
									<UiButton
										className="p-button-text neutral"
										type="button"
										icon={<TbTrash style={{ fontSize: "1.5rem" }} />}
										onClick={() =>
											setFormData({
												data: [
													...formData.data.slice(0, index),
													...formData.data.slice(index + 1),
												],
											})
										}
									/>
								)}
							</div>
						</div>
					))}

					{/* Add another medication form */}
					<div className="mt-3 text-center">
						<UiButton
							label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.ACTION_ADD_MEDICATION"
							className="p-button-sm p-button-rounded"
							type="button"
							icon={<BsPlus />}
							onClick={() =>
								setFormData({
									data: [...formData.data, new MedicationsCreateData()],
								})
							}
							disabled={submitting || formData.data.length === 5}
						/>
					</div>

					{/* Create endpoint messages */}
					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.CREATE_FORM.GENERIC_ERROR_CREATE"
							/>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							type="button"
							className="p-button-outlined p-button-rounded"
							onClick={() => props.onCancel()}
							disabled={submitting}
						/>
						<UiButton
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							className="p-button-rounded"
							type="submit"
							loading={submitting}
							disabled={submitting}
						/>
					</div>
				</form>
			)}
		/>
	);
};

export default MedicationsCreate;
