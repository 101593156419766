import { PageProps } from "../../../models/routing.model";
import DashboardCallHistory from "./DashboardCallHistory";
import DashboardVoicemail from "./DashboardVoicemail";

const CallCenterDashboard = (props: PageProps) => {
	return (
		<div className="row">
			{/* Left column */}
			<div className="col-12 col-lg-6 mb-4">
				<DashboardCallHistory />
			</div>

			{/* Right column */}
			<div className="col-12 col-lg-6 mb-4">
				<DashboardVoicemail />
			</div>
		</div>
	);
};
export default CallCenterDashboard;
