import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { AxiosError } from "axios";
import { EndpointsService } from "../../../../../services/endpoints/endpoints.service";
import { Formatter } from "../../../../../services/formatter/formatter.service";
import UiButton from "../../../../../components/button/Button";
import UiFieldMessage from "../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../models/components/field-message.model";
import UiInputTextarea from "../../../../../components/input-textarea/InputTextarea.component";
import UiInputText from "../../../../../components/input-text/InputText.component";
import UiInputDate from "../../../../../components/input-date/InputDate.component";
import {
	CustomGoalsFormMode,
	LongTermGoalFormData,
	LongTermGoalsFormProps,
} from "../../../../../models/custom-goals/custom-goals-misc";
import { Validations } from "../../../../../services/form/validations.service";
import FormSection from "../../../../../components/form-section/FormSection.component";

const LongTermGoalForm = (props: LongTermGoalsFormProps) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<boolean>(false);
	const [formData, setFormData] = useState<LongTermGoalFormData>({
		name: props.entity?.name ?? null,
		startDate: props.entity?.startDate ? Formatter.dateISOToUTC(props.entity.startDate) : null,
		endDate: props.entity?.endDate ? Formatter.dateISOToUTC(props.entity.endDate) : null,
		motivations: props.entity?.motivations ?? null,
		barriers: props.entity?.barriers ?? null,
	});

	const handleSubmit = async (data: LongTermGoalFormData) => {
		if (
			props.mode === CustomGoalsFormMode.CREATE ||
			props.mode === CustomGoalsFormMode.DUPLICATE_LONG_TERM_GOAL
		) {
			await create();
		} else {
			await update();
		}
	};

	const create = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.createLongTermGoal({
				body: {
					participantId: props.participantId,
					name: formData.name!,
					startDate: Formatter.dateUTCWithoutTime(formData.startDate)?.toISOString()!,
					endDate: Formatter.dateUTCWithoutTime(formData.endDate)?.toISOString()!,
					motivations: formData.motivations,
					barriers: formData.barriers,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	const update = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.updateLongTermGoal({
				body: {
					id: props.entity!.id,
					name: formData.name!,
					startDate: Formatter.dateUTCWithoutTime(formData.startDate)?.toISOString()!,
					endDate: Formatter.dateUTCWithoutTime(formData.endDate)?.toISOString()!,
					motivations: formData.motivations,
					barriers: formData.barriers,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<FormSection>
						<div className="mb-4">
							<span className="text-bold">
								{t(
									"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.INFO1_MSG"
								)}
							</span>
							<span>
								{t(
									"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.INFO2_MSG"
								)}
							</span>
						</div>

						<div className="row">
							<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<UiInputText
									id="name"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.longTermGoal"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.longTermGoal"
									name="name"
									onChange={(e) =>
										setFormData({ ...formData, name: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<UiInputDate
									id="startDate"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.startDate"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.startDate"
									name="startDate"
									onChange={(e) =>
										setFormData({
											...formData,
											startDate: e.value as Date | null,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
								<UiInputDate
									id="endDate"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.endDate"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.endDate"
									name="endDate"
									onChange={(e) =>
										setFormData({
											...formData,
											endDate: e.value as Date | null,
										})
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
								<UiInputTextarea
									id="motivations"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.motivations"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.motivations"
									name="motivations"
									onChange={(e) =>
										setFormData({ ...formData, motivations: e.target.value })
									}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
								<UiInputTextarea
									id="barriers"
									placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.barriers"
									label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.barriers"
									name="barriers"
									onChange={(e) =>
										setFormData({ ...formData, barriers: e.target.value })
									}
									disabled={submitting}
								/>
							</div>
						</div>
					</FormSection>

					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.SUBMIT_ERROR"
							/>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							id="modalBtnCancel"
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							className="p-button-outlined p-button-rounded"
							type="button"
							onClick={props.onDone}
							disabled={submitting}
						></UiButton>
						<UiButton
							id="modalBtnSubmit"
							loading={submitting}
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							className="p-button-rounded"
							type="submit"
							onClick={handleSubmit}
							disabled={submitting || form.getState().invalid}
						></UiButton>
					</div>
				</form>
			)}
		/>
	);
};
export default LongTermGoalForm;
