import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";

export const useConditions = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-conditions", { participantId }];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getConditions({
			config: { params: { participantId } },
		});
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
