import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { TableTemplateButtonsEvent } from "../../../../../models/components/table/table-template.model";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import {
	CustomGoalsFormMode,
	CustomGoalType,
} from "../../../../../models/custom-goals/custom-goals-misc";
import { SmartGoalPastRow } from "../../../../../models/custom-goals/smart-goal";
import { TablePagination } from "../../../../../models/components/table/table-pagination.model";
import { CustomGoalsService } from "../../../../../services/custom-goals/custom-goals.service";
import UiTable from "../../../../../components/table/Table.component";
import SmartGoalDetails from "./SmartGoalDetails";
import { TableStyle } from "../../../../../models/components/table/table.model";
import { LongTermGoalPastRow } from "../../../../../models/custom-goals/long-term-goal";
import LongTermGoalDetails from "./LongTermGoalDetails";

const CustomGoalsPastTable = ({
	type,
	smartGoalsQuery,
	longTermGoalsQuery,
	onUpdate,
}: {
	type: CustomGoalType;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onUpdate: (e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => void;
}) => {
	const { t } = useTranslation("common");

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "updatedOn",
		sortOrder: -1,
		search: undefined,
		filters: undefined,
	});

	// Rows
	const mapRows = (data?: (SmartGoal | LongTermGoal)[]) => {
		let result: (SmartGoalPastRow | LongTermGoalPastRow)[] = [];

		if (data?.length) {
			result = data
				.filter((x) => x.deleted || x.completed)
				.map((x) =>
					type === CustomGoalType.SMART_GOAL
						? new SmartGoalPastRow(
								x as SmartGoal,
								t("ENUMS.CUSTOM_GOALS_CATEGORY", { returnObjects: true }),
								duplicate.bind(this)
							)
						: new LongTermGoalPastRow(x as LongTermGoal, duplicate.bind(this))
				);
		}

		return result;
	};

	const duplicate = (event: TableTemplateButtonsEvent) =>
		onUpdate(
			type === CustomGoalType.SMART_GOAL
				? CustomGoalsFormMode.DUPLICATE_SMART_GOAL
				: CustomGoalsFormMode.DUPLICATE_LONG_TERM_GOAL,
			event.row.entity
		);

	return (
		<UiTable
			dataKey="id"
			title={
				type === CustomGoalType.SMART_GOAL
					? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.SMART_GOALS"
					: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.LONG_TERM_GOALS"
			}
			useAsCard={false}
			type={TableStyle.GREEN}
			tableClassName="mb-4"
			columns={CustomGoalsService.setColumnsPastGoals(type)}
			value={mapRows(
				type === CustomGoalType.SMART_GOAL ? smartGoalsQuery.data : longTermGoalsQuery.data
			)}
			message={CustomGoalsService.setTableMessage(
				type === CustomGoalType.SMART_GOAL ? smartGoalsQuery : longTermGoalsQuery
			)}
			paginator={true}
			pagination={pagination}
			paginationFn={(event: TablePagination) => {
				const newPagination: TablePagination = { ...pagination, ...event };
				setPagination(newPagination);
			}}
			expandedTemplate={(row: SmartGoalPastRow | LongTermGoalPastRow) =>
				type === CustomGoalType.SMART_GOAL ? (
					<SmartGoalDetails
						entity={row.entity as SmartGoal}
						longTermGoalsQuery={longTermGoalsQuery}
					/>
				) : (
					<LongTermGoalDetails entity={row.entity as LongTermGoal} />
				)
			}
		/>
	);
};
export default CustomGoalsPastTable;
