import * as DataStorageService from "../session/session.service";

const SMS_DRAFT_KEY = "pt-sms-draft";
const CARE_MESSAGE_DRAFT_KEY = "pt-care-message-draft";

export enum TwilioDraft {
	SMS = "SMS",
	CARE_MESSAGE = "CARE_MESSAGE",
}

const getDraftKey = (participantId: number, type: TwilioDraft) =>
	type === TwilioDraft.SMS
		? `${SMS_DRAFT_KEY}/${participantId}`
		: `${CARE_MESSAGE_DRAFT_KEY}/${participantId}`;

const saveDraft = (participantId: number, type: TwilioDraft, data: string) =>
	DataStorageService.setItem(getDraftKey(participantId, type), data);

const getDraft = (participantId: number, type: TwilioDraft): string | null =>
	DataStorageService.getItem(getDraftKey(participantId, type));

const deleteDraft = (participantId: number, type: TwilioDraft) =>
	DataStorageService.deleteItem(getDraftKey(participantId, type));

const deleteAllDraft = () => {
	Object.keys(sessionStorage)
		.filter(function (k) {
			return k.includes(SMS_DRAFT_KEY) || k.includes(CARE_MESSAGE_DRAFT_KEY);
		})
		.forEach(function (k) {
			DataStorageService.deleteItem(k);
		});
};

export const TwilioDraftService = {
	getDraftKey,
	saveDraft,
	getDraft,
	deleteDraft,
	deleteAllDraft,
};
