import { AppointmentsService } from "../../../services/appointments/appointments.service";
import { Appointment, AppointmentTypes } from "../../entities/appointments.model";
import { AppointmentRow } from "./appointments.model";

export type PastAppointment = Omit<
	AppointmentRow,
	| "name"
	| "kannactId"
	| "coachId"
	| "phone"
	| "actionsTemplate"
	| "typeTemplate"
	| "kannactIdTemplate"
	| "phoneTemplate"
> & { entity: Appointment };
export class PastAppointmentRow implements PastAppointment {
	id: string;
	type: string;
	datetime: string; // Date
	duration: string;
	entity: Appointment;

	constructor(params: Appointment, appointmentTypes: AppointmentTypes[]) {
		this.id = params.id;
		this.type = params.type;
		this.datetime = AppointmentsService.templateDatetime(params.datetime)!;
		this.duration = AppointmentsService.templateDuration(params.type, appointmentTypes);
		this.entity = params;
	}
}
