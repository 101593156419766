import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiInputTextarea from "../../../components/input-textarea/InputTextarea.component";
import { Validations } from "../../../services/form/validations.service";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiButton from "../../../components/button/Button";

export interface GoalApplicableStatusModalState {
	status: "APPLICABLE_MODAL_OPEN" | "NON_APPLICABLE_MODAL_OPEN" | "MODAL_CLOSED";
	goalId: string;
	goalName: string;
	goalTitle: string;
	goalCategory: string;
}

const GoalApplicableStatusModal = (
	props: GoalApplicableStatusModalState & {
		onSubmitForm: (reason: string) => Promise<void>;
		onCloseModal: () => void;
	}
) => {
	const { t } = useTranslation("common");
	const [reason, setReason] = useState("");
	const [errorOnSubmitForm, setErrorOnSubmitForm] = useState(false);
	const modalTitle =
		props.status === "APPLICABLE_MODAL_OPEN"
			? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.APPLICABLE_TITLE"
			: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.NON_APPLICABLE_TITLE";
	const modalDescription =
		props.status === "APPLICABLE_MODAL_OPEN"
			? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.APPLICABLE_DESCRIPTION"
			: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.NON_APPLICABLE_DESCRIPTION";

	const onSubmit = async () => {
		try {
			setErrorOnSubmitForm(false);
			await props.onSubmitForm(reason);
		} catch (err) {
			setErrorOnSubmitForm(true);
		}
	};

	return (
		<Dialog
			header={t(modalTitle)}
			visible
			style={{ width: "600px" }}
			headerStyle={{ paddingBottom: "0.5rem" }}
			contentStyle={{ padding: "1rem", paddingTop: "0" }}
			modal={true}
			draggable={false}
			focusOnShow={false}
			onHide={props.onCloseModal}
		>
			<p>
				{t(modalDescription, {
					goalTitle: t(props.goalTitle),
				})}
			</p>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<UiInputTextarea
							id="reason"
							name="reason"
							placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.INPUT_LABEL"
							label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.INPUT_LABEL"
							disabled={submitting}
							onChange={(evt) => setReason(evt.target.value)}
							validations={[Validations.required]}
						/>

						{errorOnSubmitForm && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
								></UiFieldMessage>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.CANCEL_BUTTON"
								type="reset"
								className="p-button-outlined p-button-rounded"
								onClick={props.onCloseModal}
								disabled={submitting}
							/>
							<UiButton
								label={
									submitting
										? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.CONFIRMING_BUTTON"
										: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.GOAL_APPLICABLE_STATUS_MODAL.CONFIRM_BUTTON"
								}
								className="p-button-rounded"
								type="submit"
								disabled={submitting}
							/>
						</div>
					</form>
				)}
			></Form>
		</Dialog>
	);
};

export const useGoalApplicableStatusModal = () => {
	const [state, setState] = useState<GoalApplicableStatusModalState>({
		status: "MODAL_CLOSED",
		goalId: "",
		goalName: "",
		goalTitle: "",
		goalCategory: "",
	});

	return {
		state,
		openModal: (state: GoalApplicableStatusModalState) => {
			setState({
				...state,
			});
		},
		closeModal: () => {
			setState({
				...state,
				status: "MODAL_CLOSED",
			});
		},
	};
};

export default GoalApplicableStatusModal;
