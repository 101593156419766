import { SU_Service } from "../../../services/service-utilization-goals/service-utilization-goals.service";
import {
	SuCategories,
	SuExam,
	SuServices,
	SuStatus,
	ServiceUtilizationExamGoal,
	SuGoal,
} from "../../entities/service-utilization-goals.model";
import { Source } from "../../entities/source.model";
import { List } from "../../misc.model";

export interface ParticipantSUGData {
	id: SuCategories;
	disabled: boolean;
	title: string;
	warningsCount: number;
	services: IParticipantSUGService[];
}

export interface IParticipantSUGService {
	id: SuServices;
	category: SuCategories;
	title: string;
	status: SuStatus | null;
	date: string | null;
	dueDate: string | null;
	applicable: boolean;
	eligible: boolean;
	examTypes: List;
	history: SuHistory[];
	recentHistory: SuHistory[];
}

export class ParticipantSUGService implements IParticipantSUGService {
	id: SuServices;
	category: SuCategories;
	title: string;
	status: SuStatus | null;
	date: string | null;
	dueDate: string | null;
	applicable: boolean;
	eligible: boolean;
	examTypes: List;
	history: SuHistory[];
	recentHistory: SuHistory[];

	constructor(params: SuGoal, examTypes: ServiceUtilizationExamGoal[]) {
		this.id = params.goalName;
		this.category = params.category;
		this.title = `PARTICIPANT.SERVICE_UTILIZATION_GOALS.TABS.${params.category}.SERVICES.${params.goalName}`;
		this.status = params.state;
		this.date = params.date ?? null;
		this.dueDate = params.dueDate ?? null;
		this.applicable = params.applicable;
		this.eligible = params.eligible;
		this.examTypes = SU_Service.getExamType(params.goalName, examTypes);
		this.recentHistory = params.metadata.events
			? SU_Service.getHistory(params.metadata.events, 5)
			: [];
		this.history = params.metadata.events ? SU_Service.getHistory(params.metadata.events) : [];
	}
}

export interface SuForm {
	dateOn: Date | null;
	estimated: boolean;
	examType: SuExam | null;
	notes: string | null;
}

export interface SuHistory {
	id: number;
	examType: string;
	estimated: boolean;
	dateOn: Date;
	source: Source;
	notes: string | null;
}

export interface SuHistoryRow {
	id: number;
	service: string;
	examType: string;
	dateOn: Date;
	source: string;
	notes: string | null;
	estimated: boolean;
}
