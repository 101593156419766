import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CustomGoalType,
	CustomGoalsFormMode,
	CustomGoalsFormProps,
	CustomGoalsModalMode,
} from "../../../../../models/custom-goals/custom-goals-misc";
import UiButton from "../../../../../components/button/Button";
import CustomGoalsForm from "./CustomGoalsForm";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import CustomGoalsCurrent from "./CustomGoalsCurrent";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import CustomGoalsDelete from "./CustomGoalsDelete";
import CustomGoalsComplete from "./CustomGoalsComplete";
import CustomGoalsPast from "./CustomGoalsPast";

const CustomGoalsModal = ({
	isVisible,
	participantId,
	smartGoalsQuery,
	longTermGoalsQuery,
	onClose,
}: {
	isVisible: boolean;
	participantId: number;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");
	const [mode, setMode] = useState<CustomGoalsModalMode>(CustomGoalsModalMode.READ);
	const [form, setForm] = useState<Required<Pick<CustomGoalsFormProps, "mode" | "entity">>>({
		mode: CustomGoalsFormMode.CREATE,
		entity: null,
	});
	const [deleteCompleteForm, setDeleteCompleteForm] = useState<{
		mode: CustomGoalType | null;
		entity: SmartGoal | LongTermGoal | null;
	}>({
		mode: null,
		entity: null,
	});

	return (
		<Dialog
			header={
				<div className="dialog-custom-header">
					{/* Title */}
					<span className="dialog-custom-header__title">
						{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.TITLE")}
					</span>

					{/* Actions */}
					{mode === CustomGoalsModalMode.READ && (
						<div className="dialog-custom-header__actions">
							<UiButton
								label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.BUTTON_GOAL"
								type="button"
								className="p-button-sm p-button-rounded"
								icon="pi pi-plus"
								onClick={() => {
									setMode(CustomGoalsModalMode.CREATE);
									setForm({ mode: CustomGoalsFormMode.CREATE, entity: null });
								}}
							/>
						</div>
					)}
				</div>
			}
			visible={true}
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: "80vw" }}
			className="custom-goals-modal"
			modal={true}
			focusOnShow={false}
			onHide={onClose}
		>
			{/* CRUD: CREATE  */}
			{mode === CustomGoalsModalMode.CREATE && (
				<CustomGoalsForm
					participantId={participantId}
					mode={form.mode}
					entity={form.entity}
					longTermGoalsQuery={longTermGoalsQuery}
					onDone={() => {
						setMode(CustomGoalsModalMode.READ);
						smartGoalsQuery.invalidate();
						longTermGoalsQuery.invalidate();
					}}
				/>
			)}

			{/* CRUD: DELETE  */}
			{mode === CustomGoalsModalMode.DELETE && (
				<CustomGoalsDelete
					participantId={participantId}
					mode={deleteCompleteForm.mode!}
					entity={deleteCompleteForm.entity!}
					onDone={() => {
						setMode(CustomGoalsModalMode.READ);
						smartGoalsQuery.invalidate();
						longTermGoalsQuery.invalidate();
					}}
				/>
			)}

			{/* CRUD: COMPLETE  */}
			{mode === CustomGoalsModalMode.COMPLETE && (
				<CustomGoalsComplete
					participantId={participantId}
					mode={deleteCompleteForm.mode!}
					entity={deleteCompleteForm.entity!}
					onDone={() => {
						setMode(CustomGoalsModalMode.READ);
						smartGoalsQuery.invalidate();
						longTermGoalsQuery.invalidate();
					}}
				/>
			)}

			{/* CRUD: READ (display tables) */}
			{mode === CustomGoalsModalMode.READ && (
				<TabView>
					{/* Custom goals: Current */}
					<TabPanel
						header={t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.TAB_TITLE_CURRENT")}
					>
						<CustomGoalsCurrent
							smartGoalsQuery={smartGoalsQuery}
							longTermGoalsQuery={longTermGoalsQuery}
							onUpdate={(e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => {
								setMode(CustomGoalsModalMode.CREATE);
								setForm({ mode: e, entity: e2 });
							}}
							onDelete={(e: CustomGoalType, e2: SmartGoal | LongTermGoal) => {
								setMode(CustomGoalsModalMode.DELETE);
								setDeleteCompleteForm({ mode: e, entity: e2 });
							}}
							onComplete={(e: CustomGoalType, e2: SmartGoal | LongTermGoal) => {
								setMode(CustomGoalsModalMode.COMPLETE);
								setDeleteCompleteForm({ mode: e, entity: e2 });
							}}
						/>
					</TabPanel>

					{/* Custom goals: Past */}
					<TabPanel header={t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.TAB_TITLE_PAST")}>
						<CustomGoalsPast
							smartGoalsQuery={smartGoalsQuery}
							longTermGoalsQuery={longTermGoalsQuery}
							onUpdate={(e: CustomGoalsFormMode, e2: SmartGoal | LongTermGoal) => {
								setMode(CustomGoalsModalMode.CREATE);
								setForm({ mode: e, entity: e2 });
							}}
						/>
					</TabPanel>
				</TabView>
			)}
		</Dialog>
	);
};

export default CustomGoalsModal;
