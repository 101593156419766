import { useState } from "react";
import { useTranslation } from "react-i18next";
import UiTable from "../../../../components/table/Table.component";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import { TablePagination } from "../../../../models/components/table/table-pagination.model";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import { ConditionsExternalRow } from "../../../../models/pages/conditions/conditions-external.model";
import { useConditions } from "./useConditions";
import { TableStyle } from "../../../../models/components/table/table.model";

const ConditionsExternal = ({
	conditionsQuery,
}: {
	conditionsQuery: ReturnType<typeof useConditions>;
}) => {
	const { t } = useTranslation("common");

	// Columns
	const columns: TableColumn[] = [
		{
			field: "conditionName",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.conditionName",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isExternalFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.source",
			sortable: false,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "isActiveFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.status",
			sortable: false,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
		},
		{
			field: "notesFormatted",
			title: "PARTICIPANT.DASHBOARD.CONDITIONS.MODAL.COLUMNS.notes",
			sortable: false,
		},
	];

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: undefined,
		sortOrder: undefined,
		search: undefined,
		filters: undefined,
	});

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	// Messages
	const messages = new TableMessages();
	const message: UiFieldMessageProps = {
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	};

	// Loading
	if (conditionsQuery.isLoading) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.LOADING,
					label: messages.loading,
				}}
				pagination={pagination}
				paginationFn={onPagination}
			/>
		);
	}

	// Error
	if (conditionsQuery.isError) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				}}
				pagination={pagination}
				paginationFn={onPagination}
			/>
		);
	}

	// Empty
	if (!conditionsQuery.data.length) {
		return (
			<UiTable
				dataKey="participantConditionId"
				type={TableStyle.GREEN}
				columns={columns}
				value={[]}
				message={{
					severity: FieldMessageSeverity.INFO,
					label: messages.empty,
				}}
				pagination={pagination}
				paginationFn={onPagination}
			/>
		);
	}

	/* There is data */

	const rows = conditionsQuery.data
		.filter((item) => item.isExternal)
		.map((item) => new ConditionsExternalRow(item))
		.sort((a, b) => {
			const nameA = t(a.conditionName).toLowerCase();
			const nameB = t(b.conditionName).toLowerCase();
			return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
		});

	return (
		<UiTable
			dataKey="participantConditionId"
			type={TableStyle.DEFAULT}
			columns={columns}
			value={rows}
			message={message}
			pagination={pagination}
			paginationFn={onPagination}
		/>
	);
};
export default ConditionsExternal;
