import { BsFillChatLeftFill } from "react-icons/bs";
import { Badge } from "primereact/badge";
import TwilioConversationService from "../../../services/twilio/twilio-conversations.service";
import useNumUnreadConversations from "./useNumUnreadConversations";
import { HelperService } from "../../../services/helpers/helper.service";
import { getSessionStorageUser } from "../../../services/session/auth.service";

const ParticipantMessagesIndicator = () => {
	const isAdminView = HelperService.isAdminViewMode();
	const coachId = getSessionStorageUser().id;
	const numUnreadConversations = useNumUnreadConversations({
		isAdminView,
		coachId,
	});

	const number = numUnreadConversations.data ?? 0;

	return (
		<span className="p-overlay-badge conversation-menu">
			{number > 0 && (
				<Badge
					value={TwilioConversationService.beautifyUnreadCount(number)}
					severity="danger"
					className="conversation-menu-badge"
				/>
			)}
			<BsFillChatLeftFill />
		</span>
	);
};

export default ParticipantMessagesIndicator;
