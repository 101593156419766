import { AuthRoles } from "./roles.model";

export interface ISessionCoach {
	id: number | undefined;
	email?: string;
	phone?: string;
	role?: AuthRoles;
	firstName?: string;
	lastName?: string;
	countryCode?: string;
	adminView: boolean;
}

export class SessionCoach {
	id: number | undefined;
	phone?: string;
	role?: AuthRoles;
	firstName?: string;
	lastName?: string;
	countryCode?: string;
	adminView: boolean;

	constructor(params?: Partial<ISessionCoach>) {
		this.id = params?.id;
		this.phone = params?.phone ?? undefined;
		this.role = params?.role ?? undefined;
		this.firstName = params?.firstName ?? undefined;
		this.lastName = params?.lastName ?? undefined;
		this.countryCode = params?.countryCode ?? undefined;
		this.adminView = params?.adminView ?? false;
	}
}
