import { Form } from "react-final-form";
import {
	TableFilterFormData,
	TableFilterType,
	UiTableHeaderFilterProps,
	ITableFilter,
} from "../../../models/components/table/table-filter.model";
import { useState } from "react";
import UiSelectMultiple from "../../select-multiple/SelectMultiple.component";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import UiButton from "../../button/Button";
import UiInputText from "../../input-text/InputText.component";
import { FilterService } from "../../../services/filter/filter.service";
import UiInputDate from "../../input-date/InputDate.component";
import { DateOnNavigation } from "../../../models/pages/participants-reminder.model";
import { classNames } from "primereact/utils";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiSelect from "../../select/Select.component";
import UiInputCheckbox from "../../input-checkbox/InputCheckbox.component";

const UiTableHeaderFilter = (props: UiTableHeaderFilterProps) => {
	const { t } = useTranslation("common");

	// Form: initialize data
	const [formData, setFormData] = useState<TableFilterFormData>(
		FilterService.setFormData(props.filters)
	);

	// Form: clear
	const clear = (): void => {
		const newValues = FilterService.resetFormData(props.filters);
		// Reset form data
		setFormData(newValues);

		// Callback
		props.fn(newValues);
	};

	// Form: submit
	const handleSubmit = (data: TableFilterFormData, form: FormApi) => props.fn(data);

	// Form: set fields columns (container)
	const setFormColumn = (filter: ITableFilter): string =>
		filter.options?.columnClass ?? "col-12 col-md-6 col-lg-2";

	// Filter: DATE_NAVIGATOR
	const dateOnPrevNext = (direction: DateOnNavigation, filter: ITableFilter): void => {
		const currentDate = formData[filter.field] as Date;
		const newDate = new Date(currentDate);
		if (direction === DateOnNavigation.PREV) {
			newDate.setDate(currentDate.getDate() - 1);
		} else {
			newDate.setDate(currentDate.getDate() + 1);
		}
		setFormData({ ...formData, [filter.field]: newDate });

		if (filter.options?.submitOnChange) {
			// Callback
			props.fn({ ...formData, [filter.field]: newDate });
		}
	};

	// Filter: templates
	const setFilterTemplate = (filter: ITableFilter): React.ReactNode => {
		let filterTemplate = <></>;
		switch (filter.type) {
			case TableFilterType.STRING:
				filterTemplate = (
					<UiInputText
						id={filter.field}
						name={filter.field}
						onChange={(e) =>
							setFormData({ ...formData, [filter.field]: e.target.value })
						}
						{...filter.options?.props}
						// disabled={submitting}
					/>
				);
				break;
			case TableFilterType.SELECT_MULTIPLE:
				filterTemplate = (
					<UiSelectMultiple
						id={filter.field}
						name={filter.field}
						onChange={(e) =>
							setFormData({ ...formData, [filter.field]: e.target.value })
						}
						appendTo="self"
						options={
							filter.order === false
								? filter.options?.props.options
								: Formatter.sortList(filter.options?.props.options, t)
						}
						// disabled={submitting}
						{...filter.options?.props}
					/>
				);
				break;
			case TableFilterType.SELECT:
				filterTemplate = (
					<UiSelect
						id={filter.field}
						name={filter.field}
						onChange={(e) =>
							setFormData({ ...formData, [filter.field]: e.target.value })
						}
						appendTo="self"
						options={
							filter.order === false
								? filter.options?.props.options
								: Formatter.sortList(filter.options?.props.options, t)
						}
						// disabled={submitting}
						{...filter.options?.props}
					/>
				);
				break;
			case TableFilterType.DATE_NAVIGATOR:
				filterTemplate = (
					<div className="table-header-filters--dates">
						{/* Previous dates */}
						<UiButton
							id="navPrevDateButton"
							type="button"
							icon="pi pi-chevron-left"
							className="p-button-text"
							onClick={() => dateOnPrevNext(DateOnNavigation.PREV, filter)}
						></UiButton>

						{/* Select other date */}
						<UiInputDate
							id={filter.field}
							name={filter.field}
							onChange={(e) =>
								setFormData({ ...formData, [filter.field]: e.target.value })
							}
							{...filter.options?.props}
							// disabled={submitting}
						/>

						{/* Next dates */}
						<UiButton
							id="navNextDateButton"
							type="button"
							icon="pi pi-chevron-right"
							className="p-button-text"
							onClick={() => dateOnPrevNext(DateOnNavigation.NEXT, filter)}
						></UiButton>
					</div>
				);
				break;
			case TableFilterType.DATE_RANGE:
				filterTemplate = (
					<div className="table-header-filters--dates">
						{/* Select other date */}
						<UiInputDate
							id={filter.field}
							name={filter.field}
							selectionMode="range"
							onChange={(e) => {
								setFormData({ ...formData, [filter.field]: e.target.value });
							}}
							showButtonBar={false}
							{...filter.options?.props}
						/>
					</div>
				);
				break;
			case TableFilterType.BOOLEAN:
				filterTemplate = (
					<div className="table-header-filters--boolean">
						<UiInputCheckbox
							id={filter.field}
							name={filter.field}
							onChange={(e) => {
								setFormData({ ...formData, [filter.field]: e.target.value });
							}}
							binary={true}
							{...filter.options?.props}
						/>
					</div>
				);
				break;
			default:
				break;
		}

		return filterTemplate;
	};

	return (
		<div className="table-filters-container">
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="filters-form-container">
							<div className="filters-form">
								{/* Filters section: icon */}
								<span className="filters-title">
									{t("UI_COMPONENTS.TABLE.ACTIONS.FILTER")}
								</span>

								{/* Filters */}
								{props.filters.map((filter) => (
									<div
										key={filter.field}
										className={classNames("filter-col", setFormColumn(filter))}
									>
										{setFilterTemplate(filter)}
									</div>
								))}
							</div>
						</div>

						{/* Action buttons */}
						<div className="filters-buttons">
							{/* Submit */}
							<UiButton
								id="filterBtnSubmit"
								label={
									submitting
										? "UI_COMPONENTS.TABLE.ACTIONS.FILTER_SUBMITTING"
										: "UI_COMPONENTS.TABLE.ACTIONS.FILTER_SUBMIT"
								}
								type="submit"
								className="p-button-rounded"
								disabled={submitting || !form.getState().valid}
							></UiButton>

							{/* Clear */}
							<UiButton
								id="filterBtnClear"
								label="UI_COMPONENTS.TABLE.ACTIONS.FILTER_CLEAR"
								className="p-button-rounded p-button-outlined"
								type="reset"
								onClick={clear}
								disabled={submitting || !form.getState().valid}
							></UiButton>
						</div>
					</form>
				)}
			/>
		</div>
	);
};

export default UiTableHeaderFilter;
