export enum LastContactOutcome {
	SUCCESSFUL = "SUCCESSFUL",
	UNSUCCESSFUL = "UNSUCCESSFUL",
}

export interface RiskUser {
	id: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	coachId: number;
	lastSuccessfulCall: string; // Date
	lastContact: string; // Date
	lastContactPurpose: string;
	lastContactOutcome: LastContactOutcome;
	dateToBecomeInactive: string; // Date
	daysPreference: string;
	timePreference: string;
	timeZone: string;
}
