import UiAutocomplete from "../../components/autocomplete/Autocomplete";
import UiButton from "../../components/button/Button";
import UiFieldMessage from "../../components/field-message/FieldMessage.component";
import UiInputDate from "../../components/input-date/InputDate.component";
import UiInputText from "../../components/input-text/InputText.component";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";
import { Source } from "../../models/entities/source.model";
import { MedicationRow } from "../../models/pages/medications/medications-list.model";
import { Formatter } from "../formatter/formatter.service";
import UiInputNumber from "../../components/input-number/InputNumber.component";
import UiSelect from "../../components/select/Select.component";
import UiInputTextarea from "../../components/input-textarea/InputTextarea.component";
import { MedicationsHistoryForm } from "../../models/pages/medications/medications-history.model";
import { ListOption } from "../../models/misc.model";
import { TestingFrequency } from "../../models/entities/participant.model";

const templateDosage = (row: MedicationRow, submitting: boolean): React.ReactNode => {
	let template: React.ReactNode;
	if (!row.editing) {
		const value = row.dosage && row.dosageUnit ? row.dosage + " " + row.dosageUnit : "-";
		template = <span className="text">{value}</span>;
	} else {
		template = (
			<div style={{ display: "flex" }}>
				<UiInputNumber
					id="dosage"
					name="dosage"
					disabled={submitting}
					placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PLACEHOLDERS.DOSAGE"
					className="frequency__input"
					maxFractionDigits={2}
				/>
				<UiInputText
					id="dosageUnit"
					name="dosageUnit"
					disabled={submitting}
					placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PLACEHOLDERS.DOSAGE_UNIT"
					className="unit__input"
				/>
			</div>
		);
	}
	return template;
};

const templateFrequency = (row: MedicationRow, submitting: boolean): React.ReactNode => {
	let template: React.ReactNode;
	if (!row.editing) {
		const value =
			row.frequency && row.period
				? row.frequency + " x " + row.periodLabel
				: row.period || Formatter.NO_DATA;
		template = <span className="text">{value}</span>;
	} else {
		template = (
			<div style={{ display: "flex" }}>
				<UiInputNumber
					id="frequency"
					name="frequency"
					disabled={submitting}
					placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PLACEHOLDERS.FREQUENCY"
					className="frequency__input"
				/>
				<UiSelect
					id="period"
					name="period"
					disabled={submitting}
					options={Object.keys(TestingFrequency).map(
						(x) =>
							new ListOption({
								id: x,
								label: `PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PERIODS.${x}`,
							})
					)}
					placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.FREQUENCY"
				/>
			</div>
		);
	}
	return template;
};

const templateDateFilledMedication = (row: MedicationRow, submitting: boolean): React.ReactNode => {
	let template: React.ReactNode;
	if (!row.editing) {
		template = <span className="text">{Formatter.date(row.dateFilled)}</span>;
	} else {
		template = (
			<UiInputDate
				id="dateFilled"
				placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.COLUMNS.DATE_FILLED"
				name="dateFilled"
				disabled={submitting}
				maxDate={new Date()}
			/>
		);
	}
	return template;
};

// Template: source
const templateSource = (sourceTranslationKey: string): React.ReactNode => (
	<span className="text">{sourceTranslationKey}</span>
);

// Template: notes
const templateNotes = (
	form: MedicationsHistoryForm,
	messageNoData: string,
	editFn: () => void,
	fieldLabel: string,
	onChangeFn: (value: string | null) => void,
	cancelFn: () => void,
	submitting: boolean
): React.ReactNode => {
	let template;

	if (!form.notes && !form.editing) {
		// There are no notes and we are not in the notes edit mode
		template = (
			<div className="empty-mode">
				<div>{messageNoData}</div>
				<UiButton
					type="button"
					key="create-note"
					className="p-button-text link"
					label="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.ACTION_CREATE_NOTE"
					onClick={editFn}
					disabled={submitting}
				/>
			</div>
		);
	} else if (form.notes && !form.editing) {
		// There are notes and we are not in the notes edit mode
		template = (
			<div
				className="update-mode"
				style={{ display: "flex" }}
			>
				<span className="label">{fieldLabel}</span>
				<UiInputTextarea
					id="notes"
					name="notes"
					onChange={(e) => onChangeFn(e.target.value)}
					disabled={submitting || !form.editing}
				></UiInputTextarea>
				<div className="actions">
					<UiButton
						type="button"
						key="edit-note"
						icon="pi pi-pencil"
						className="p-button-text neutral"
						onClick={editFn}
						disabled={submitting}
					/>
				</div>
			</div>
		);
	} else {
		// There may be notes and we are in the notes editing mode
		template = (
			<>
				<div className="update-mode">
					<span className="label">{fieldLabel}</span>
					<UiInputTextarea
						id="notes"
						name="notes"
						onChange={(e) => onChangeFn(e.target.value)}
						disabled={submitting}
						className="medications__history__notes__textarea"
					></UiInputTextarea>
					<div className="actions">
						<UiButton
							type="submit"
							key="save-note"
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.SAVING"
									: "UI_COMPONENTS.BUTTONS.SAVE"
							}
							loading={submitting}
							disabled={submitting}
						/>
						<UiButton
							type="button"
							key="cancel-note"
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							className="p-button-text danger"
							onClick={cancelFn}
							disabled={submitting}
						/>
					</div>
				</div>
			</>
		);
	}

	return template;
};

const templateNameMedication = (
	row: MedicationRow,
	submitting: boolean,
	getSuggestedMedication: (event: { query: string | null }) => Promise<void>,
	suggestedMedication: string[]
): React.ReactNode => {
	let template: React.ReactNode;
	if (!row.editing) {
		template = <span className="text">{row.name}</span>;
	} else {
		template = (
			<UiAutocomplete
				id="name"
				placeholder="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.PLACEHOLDERS.MEDICATION_NAME"
				name="name"
				suggestions={suggestedMedication}
				completeMethod={getSuggestedMedication}
				dropdown={true}
				forceSelection={false}
				disabled={submitting}
				panelClassName="medications-suggestions"
			/>
		);
	}
	return template;
};

// Template: table message
const templateEmptyMessage = (emptyMessage: UiFieldMessageProps | null): React.ReactNode => {
	let template: React.ReactNode;
	if (emptyMessage) {
		template = (
			<div
				className={
					emptyMessage.severity === FieldMessageSeverity.LOADING
						? "k-empty-message loading-message"
						: "k-empty-message"
				}
			>
				<UiFieldMessage
					severity={emptyMessage.severity}
					label={emptyMessage.label}
				></UiFieldMessage>
			</div>
		);
	} else {
		template = (
			<div className="k-empty-message">
				<UiFieldMessage
					severity={FieldMessageSeverity.INFO}
					label="UI_COMPONENTS.TABLE.EMPTY_MESSAGE"
				></UiFieldMessage>
			</div>
		);
	}

	return template;
};

// Template: Actions
const templateActions = (
	row: MedicationRow,
	editableFn: (row: MedicationRow) => void,
	updateFn: (row: MedicationRow) => Promise<void>,
	cancelFn: (row: MedicationRow) => void,
	deleteFn: (row: MedicationRow) => Promise<void>,
	submitting: boolean
): React.ReactNode => {
	let template: React.ReactNode;
	if (row.source === Source.COACH && !row.editing && !row.active) {
		template = (
			<UiButton
				type="button"
				key="edit"
				icon="pi pi-pencil"
				className="p-button-text neutral"
				onClick={() => editableFn(row)}
			/>
		);
	} else if (row.source === Source.COACH && !row.editing && row.active) {
		template = (
			<>
				<UiButton
					type="button"
					key="edit"
					icon="pi pi-pencil"
					className="p-button-text neutral"
					onClick={() => editableFn(row)}
				/>
				<UiButton
					type="button"
					key="disable"
					icon="pi pi-trash"
					className="p-button-text neutral"
					onClick={() => deleteFn(row)}
				/>
			</>
		);
	} else if (row.source === Source.COACH && row.editing) {
		template = (
			<>
				<UiButton
					type="button"
					key="cancel"
					className="p-button-outlined p-button-rounded p-button-sm"
					label="UI_COMPONENTS.BUTTONS.CANCEL"
					onClick={() => cancelFn(row)}
					disabled={submitting}
				/>
				<UiButton
					type="submit"
					key="save"
					className="p-button-rounded p-button-sm"
					label={
						submitting ? "UI_COMPONENTS.BUTTONS.SAVING" : "UI_COMPONENTS.BUTTONS.SAVE"
					}
					loading={submitting}
					disabled={submitting}
				/>
			</>
		);
	} else if ((row.source === Source.CLAIMS || row.source === Source.QHIN) && row.active) {
		template = (
			<UiButton
				type="button"
				icon="pi pi-trash"
				className="p-button-text neutral"
				onClick={() => deleteFn(row)}
			/>
		);
	}

	return template;
};

// Columns width
const columnsWidth = {
	drugCategory: "",
	pdcAmr: "100px",
	goal: "250px",
	medicationName: "", // drugCategory + pdcAmr + goal
	dateFilled: "180px",
	source: "100px",
	expandCollapse: "200px",
	actions: "200px",
};

export const MedicationsTemplate = {
	columnsWidth,
	templateDateFilledMedication,
	templateFrequency,
	templateDosage,
	templateSource,
	templateNameMedication,
	templateEmptyMessage,
	templateActions,
	templateNotes,
};
