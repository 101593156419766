export enum FieldMessageSeverity {
	INFO = "INFO",
	SUCCESS = "SUCCESS",
	WARNING = "WARNING",
	DANGER = "DANGER",
	LOADING = "LOADING",
}

export interface UiFieldMessageProps {
	severity: FieldMessageSeverity;
	label: string;
}
