import { UiFieldMessageProps } from "../../components/field-message.model";

// Component props
export type MedicationsCreateProps = {
	formData: MedicationsCreateData;
	message: UiFieldMessageProps | null;
	submitting: boolean;
	valid: boolean;
	changeFn: (values: MedicationsCreateData, valid: boolean) => void;
};

export interface IMedicationsCreateData {
	medicationName: string | null;
	dateFilled: Date | null; // Date
	dosage: string | null;
	dosageUnit: string | null;
	frequency: number | null;
	period: string | null;
}

export class MedicationsCreateData implements IMedicationsCreateData {
	medicationName: string | null;
	dateFilled: Date | null; // Date
	dosage: string | null;
	dosageUnit: string | null;
	frequency: number | null;
	period: string | null;

	constructor(params?: IMedicationsCreateData) {
		this.medicationName = params?.medicationName ?? null;
		this.dateFilled = params?.dateFilled ?? null;
		this.dosage = params?.dosage ?? null;
		this.dosageUnit = params?.dosageUnit ?? null;
		this.frequency = params?.frequency ?? null;
		this.period = params?.period ?? null;
	}
}
