const maxDigits = (value: number | null, maxDigits: number, maxDecimals: number): number | null => {
	let result: number | null = value;

	if (value || value === 0) {
		const digits = value.toString().split(".");
		if (digits[0].length > maxDigits) {
			const newDigits = digits[0].substring(0, maxDigits);
			const newDecimals = digits[0].substring(maxDigits, digits[0].length);
			const newValue: string = `${newDigits}.${newDecimals}`;
			result = Number(newValue);
		}
	}

	return result;
};

export const FormService = {
	maxDigits,
};
