import { useEffect, useState } from "react";
import { PageProps } from "../../models/routing.model";
import UiTable from "../../components/table/Table.component";
import { HelperService } from "../../services/helpers/helper.service";
import { TableColumnTemplate, TableColumn } from "../../models/components/table/table-column.model";
import { TablePagination } from "../../models/components/table/table-pagination.model";
import { TableMessages } from "../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";
import { RiskUserRow } from "../../models/pages/risk-users-row.model";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { RiskUser } from "../../models/entities/risk-users.model";
import { ITableHeaderRefresh } from "../../models/components/table/table-refresh.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableHeaderFilter,
	ITableFilter,
} from "../../models/components/table/table-filter.model";
import { FilterService } from "../../services/filter/filter.service";
import { ProviderTwilio } from "../../models/entities/provider.model";
import { List, ListOption } from "../../models/misc.model";
import { StoredCriteriaService } from "../../services/session/stored-criteria.service";

const RiskUsers = (props: PageProps) => {
	/* Table */

	// Columns
	const setColumns = (): TableColumn[] => {
		const columns: TableColumn[] = [
			{
				field: "kannactId",
				title: "RISK_USERS.TABLE.COLUMNS.id",
				template: TableColumnTemplate.KANNACT_ID,
			},
			{
				field: "fullName",
				title: "RISK_USERS.TABLE.COLUMNS.fullName",
				sortable: true,
			},
			{
				field: "phoneNumber",
				title: "RISK_USERS.TABLE.COLUMNS.phoneNumber",
				template: TableColumnTemplate.PHONE,
				sortable: true,
			},
			{
				field: "lastSuccessfulCall",
				title: "RISK_USERS.TABLE.COLUMNS.lastSuccessfulCall",
				template: TableColumnTemplate.DATE,
				sortable: true,
			},
			{
				field: "dateToBecomeInactive",
				title: "RISK_USERS.TABLE.COLUMNS.dateToBecomeInactive",
				template: TableColumnTemplate.DATE,
				sortable: true,
			},
			{
				field: "lastContact",
				title: "RISK_USERS.TABLE.COLUMNS.lastContact",
				template: TableColumnTemplate.DATE,
				sortable: true,
			},
			{
				field: "lastContactPurpose",
				title: "RISK_USERS.TABLE.COLUMNS.contactPurpose",
				template: TableColumnTemplate.TRANSLATE,
				sortable: true,
			},
			{
				field: "timePreference",
				title: "RISK_USERS.TABLE.COLUMNS.timePreference",
				template: TableColumnTemplate.TRANSLATE_PREFIX,
				templateOptions: { translationPrefix: "ENUMS.TIME_OF_DAY" },
				sortable: true,
			},
			{
				field: "daysPreference",
				title: "RISK_USERS.TABLE.COLUMNS.daysPreference",
				sortable: true,
				template: TableColumnTemplate.TRANSLATE,
			},
			{
				field: "timeZone",
				title: "RISK_USERS.TABLE.COLUMNS.timeZone",
				sortable: true,
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column = {
				field: "coachId",
				template: TableColumnTemplate.COACH,
				title: "RISK_USERS.TABLE.COLUMNS.coachId",
				sortable: true,
			};
			columns.splice(3, 0, column);
		}

		return columns;
	};

	const columns: TableColumn[] = setColumns();

	// Used by the client-side filtering
	const [originalData, setOriginalData] = useState<RiskUser[]>([]);

	const [rows, setRows] = useState<RiskUserRow[]>();

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["users-at-risk"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: undefined,
			sortOrder: undefined,
			search: undefined,
			filters: undefined,
		}
	);

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["users-at-risk"],
			newPagination
		);
	};

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Refresh
	const refresh: ITableHeaderRefresh = {
		fn: () => {
			getData();
		},
	};

	// Filters
	const [filters, setFilters] = useState<ITableHeaderFilter | undefined>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["users-at-risk"]) ?? undefined
	);

	/**
	 * DATA
	 */

	// First time load
	useEffect(() => {
		// Get data
		getData();

		// Get filters
		if (
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["users-at-risk"])
				?.filters &&
			HelperService.isAdminViewMode()
		) {
			getFilters();
		}
	}, []);

	useEffect(() => {
		if (originalData.length && rows?.length && filters?.filters) {
			filter(filters.filters);
		}
	}, [originalData]);

	// Get data: users at risk
	const getData = async (): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		// Set endpoint (based on tge view mode - coach/admin)
		const endpoint: Promise<RiskUser[]> = HelperService.isAdminViewMode()
			? EndpointsService.dataRetriever.getRiskUsers()
			: EndpointsService.dataRetriever.getRiskUsersByCoach();

		await endpoint
			.then((response: RiskUser[]) => {
				if (response?.length > 0) {
					setOriginalData(response);
					setRows(response.map((item) => new RiskUserRow(item)));
				} else {
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
				}
			})
			.catch((error: AxiosError) =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	// Filters: get options (call a backend endpoint to retreive the coaches list)
	const getFilters = async (): Promise<void> => {
		await EndpointsService.dataRetriever
			.getProvidersTwilio()
			.then((response: ProviderTwilio[]) => {
				if (response?.length > 0) {
					// If there is data --> Map it
					mapFilters(response);
				}
			})
			.catch((error: AxiosError) => {
				console.error("Risk users - Couldn't get the providers list");
			});
	};

	// Filters: submit
	const submitFilters = (value: TableFilterFormData): void => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);
		setFilters({ ...filters, filters: newFilters });

		StoredCriteriaService.set(StoredCriteriaService.KEYS["users-at-risk"], pagination, {
			...filters,
			filters: newFilters,
		});

		filter(newFilters);
	};

	const filter = (newFilters: ITableFilter[]) => {
		// Client-side filter
		setMessage({
			severity: FieldMessageSeverity.INFO,
			label: messages.filterEmpty,
		});

		if (FilterService.hasSelectedFilters(newFilters)) {
			// There are filters selected --> Filter table results
			const filtered: RiskUser[] = FilterService.filter(
				originalData,
				(filters as ITableHeaderFilter).filters
			);
			// Update filtered rows
			setRows(filtered.map((item) => new RiskUserRow(item)));
		} else {
			// No filters selected --> Reset filtered rows
			setRows(originalData.map((item) => new RiskUserRow(item)));
		}
	};

	// Filters: map
	const mapFilters = (providers: ProviderTwilio[]): void => {
		// Map backend entity
		const providersList: List = providers.map(
			(item) =>
				new ListOption({
					id: item.coachId,
					label: `${item.firstName} ${item.lastName}`,
				})
		);

		// Update filters
		const newFilters = {
			...filters,
			filters: [
				{
					field: "coachId",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						columnClass: "col-12 col-md-3 col-lg-3",
						props: {
							label: "RISK_USERS.TABLE.FILTERS.COACH_TITLE",
							options: providersList,
							filter: true,
						},
					},
					value: [],
				},
			],
		};
		setFilters(newFilters);
		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["users-at-risk"], newFilters);
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="id"
			title={
				HelperService.isAdminViewMode()
					? "RISK_USERS.TABLE.TITLE_ADMIN"
					: "RISK_USERS.TABLE.TITLE_COACH"
			}
			columns={columns}
			value={rows}
			message={message}
			pagination={pagination}
			paginationFn={onPagination}
			refresh={refresh}
			filter={filters}
			filterFn={submitFilters}
		/>
	);
};
export default RiskUsers;
