import { UiFieldMessageProps } from "../field-message.model";

export type TableMessage = UiFieldMessageProps;

export interface ITableMessages {
	loading: string;
	empty: string;
	error: string;
	filter: string;
	filterEmpty: string;
}

export class TableMessages implements ITableMessages {
	loading: string;
	empty: string;
	error: string;
	filter: string;
	filterEmpty: string;

	constructor(params?: Partial<ITableMessages>) {
		this.loading = params?.loading ?? "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_LOADING";
		this.empty = params?.empty ?? "UI_COMPONENTS.TABLE.EMPTY_MESSAGE";
		this.error = params?.error ?? "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_ERROR";
		this.filter = params?.filter ?? "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_FILTER";
		this.filterEmpty =
			params?.filterEmpty ?? "UI_COMPONENTS.TABLE.EMPTY_MESSAGE_FILTER_NO_DATA";
	}
}
