import { ParticipantStatus } from "./participant.model";

export interface Appointment {
	id: string;
	cancelled: boolean;
	coachId: string; // It's not the coachID, but the coach calendarId in Acuity
	datetime: string; // Date
	type: AppointmentType;
	participant: AppointmentParticipant;
}

export interface AppointmentParticipant {
	id?: number;
	kannactId: string;
	firstName: string;
	lastName: string;
	institution: string;
	timezone: string;
	email: string;
	dateOfBirth: string | undefined; // Date
	phone: string;
	isSpanish: boolean;
}

export interface AppointmentBlock {
	type: AppointmentBlockType;
	coachId: string; // Calendar ID
	datetime: string; // Date UTC: "2023-12-05T15:02:09.892Z"
	participants: { kannactId: string }[];
}

export const APPOINTMENT_TYPES_IDS = {
	KANNACT_WHOLE_HEALTH_INTRO_CALL: 44964344,
	KANNACT_INTRO_CALL: 28464617,
	TECH_SUPPORT_CALL: 5960381,
	COACHING_CALL: 49269063,
	COACHING_CALL_10: 49268835,
	COACHING_CALL_15: 11418323,
	COACHING_CALL_30: 38191966,
	COACHING_CALL_30_15: 70236055,
	COACHING_CALL_45: 66334838,
	KANNACT_WARM_HANDOFF: 58772120,
	FOLLOWUP_VISIT_20_10: 70575385,
};

export const APPOINTMENT_IDS_TYPES = {
	44964344: "KANNACT_WHOLE_HEALTH_INTRO_CALL",
	28464617: "KANNACT_INTRO_CALL",
	5960381: "TECH_SUPPORT_CALL",
	49269063: "COACHING_CALL",
	49268835: "COACHING_CALL_10",
	11418323: "COACHING_CALL_15",
	38191966: "COACHING_CALL_30",
	70236055: "COACHING_CALL_30_15",
	66334838: "COACHING_CALL_45",
	58772120: "KANNACT_WARM_HANDOFF",
	70575385: "FOLLOWUP_VISIT_20_10",
};

export enum AppointmentType {
	KANNACT_WHOLE_HEALTH_INTRO_CALL = "KANNACT_WHOLE_HEALTH_INTRO_CALL",
	KANNACT_INTRO_CALL = "KANNACT_INTRO_CALL",
	KANNACT_INTRO_CALL_10 = "KANNACT_INTRO_CALL_10",
	TECH_SUPPORT_CALL = "TECH_SUPPORT_CALL",
	COACHING_CALL = "COACHING_CALL",
	COACHING_CALL_10 = "COACHING_CALL_10",
	COACHING_CALL_15 = "COACHING_CALL_15",
	COACHING_CALL_30 = "COACHING_CALL_30",
	COACHING_CALL_30_15 = "COACHING_CALL_30_15",
	COACHING_CALL_45 = "COACHING_CALL_45",
	KANNACT_WARM_HANDOFF = "KANNACT_WARM_HANDOFF",
	NEW_PATIENT_CONSULTATION_20 = "NEW_PATIENT_CONSULTATION_20",
	NEW_PATIENT_CONSULTATION_45 = "NEW_PATIENT_CONSULTATION_45",
	FOLLOWUP_VISIT_20_10 = "FOLLOWUP_VISIT_20_10",
}

export enum AppointmentBlockType {
	// intro call blocks
	RAYANNA_INTRO_CALL_BLOCK = "RAYANNA_INTRO_CALL_BLOCK",
	CELESTE_INTRO_CALL_BLOCK = "CELESTE_INTRO_CALL_BLOCK",
	HEATHER_INTRO_CALL_BLOCK = "HEATHER_INTRO_CALL_BLOCK",
	KATIE_INTRO_CALL_BLOCK = "KATIE_INTRO_CALL_BLOCK",
	LUIS_INTRO_CALL_BLOCK = "LUIS_INTRO_CALL_BLOCK",
	RICHARD_INTRO_CALL_BLOCK = "RICHARD_INTRO_CALL_BLOCK",
	CORY_INTRO_CALL_BLOCK = "CORY_INTRO_CALL_BLOCK",
	STEPHANIE_INTRO_CALL_BLOCK = "STEPHANIE_INTRO_CALL_BLOCK",
	NANDAN_INTRO_CALL_BLOCK = "NANDAN_INTRO_CALL_BLOCK",
	QA_INTRO_CALL_BLOCK = "QA_INTRO_CALL_BLOCK",
	ERIC_INTRO_CALL_BLOCK = "ERIC_INTRO_CALL_BLOCK",
	KIRSTEN_INTRO_CALL_BLOCK = "KIRSTEN_INTRO_CALL_BLOCK",
	JAMES_INTRO_CALL_BLOCK = "JAMES_INTRO_CALL_BLOCK",
	CHAD_INTRO_CALL_BLOCK = "CHAD_INTRO_CALL_BLOCK",
	KRISTINA_INTRO_CALL_BLOCK = "KRISTINA_INTRO_CALL_BLOCK",
	// ro blocks
	RAYANNA_RO_CALL_BLOCK = "RAYANNA_RO_CALL_BLOCK",
	CELESTE_RO_CALL_BLOCK = "CELESTE_RO_CALL_BLOCK",
	HEATHER_RO_CALL_BLOCK = "HEATHER_RO_CALL_BLOCK",
	KATIE_RO_CALL_BLOCK = "KATIE_RO_CALL_BLOCK",
	LUIS_RO_CALL_BLOCK = "LUIS_RO_CALL_BLOCK",
	RICHARD_RO_CALL_BLOCK = "RICHARD_RO_CALL_BLOCK",
	CORY_RO_CALL_BLOCK = "CORY_RO_CALL_BLOCK",
	STEPHANIE_RO_CALL_BLOCK = "STEPHANIE_RO_CALL_BLOCK",
	NANDAN_RO_CALL_BLOCK = "NANDAN_RO_CALL_BLOCK",
	QA_RO_CALL_BLOCK = "QA_RO_CALL_BLOCK",
	ERIC_RO_CALL_BLOCK = "ERIC_RO_CALL_BLOCK",
	KIRSTEN_RO_CALL_BLOCK = "KIRSTEN_RO_CALL_BLOCK",
	JAMES_RO_CALL_BLOCK = "JAMES_RO_CALL_BLOCK",
	CHAD_RO_CALL_BLOCK = "CHAD_RO_CALL_BLOCK",
	KRISTINA_RO_CALL_BLOCK = "KRISTINA_RO_CALL_BLOCK",
	// reengage blocks
	RAYANNA_REENGAGE_BLOCK = "RAYANNA_REENGAGE_BLOCK",
	CELESTE_REENGAGE_BLOCK = "CELESTE_REENGAGE_BLOCK",
	HEATHER_REENGAGE_BLOCK = "HEATHER_REENGAGE_BLOCK",
	KATIE_REENGAGE_BLOCK = "KATIE_REENGAGE_BLOCK",
	LUIS_REENGAGE_BLOCK = "LUIS_REENGAGE_BLOCK",
	RICHARD_REENGAGE_BLOCK = "RICHARD_REENGAGE_BLOCK",
	CORY_REENGAGE_BLOCK = "CORY_REENGAGE_BLOCK",
	STEPHANIE_REENGAGE_BLOCK = "STEPHANIE_REENGAGE_BLOCK",
	NANDAN_REENGAGE_BLOCK = "NANDAN_REENGAGE_BLOCK",
	QA_REENGAGE_BLOCK = "QA_REENGAGE_BLOCK",
	ERIC_REENGAGE_BLOCK = "ERIC_REENGAGE_BLOCK",
	KIRSTEN_REENGAGE_BLOCK = "KIRSTEN_REENGAGE_BLOCK",
	JAMES_REENGAGE_BLOCK = "JAMES_REENGAGE_BLOCK",
	CHAD_REENGAGE_BLOCK = "CHAD_REENGAGE_BLOCK",
	KRISTINA_REENGAGE_BLOCK = "KRISTINA_REENGAGE_BLOCK",
}

export interface AppointmentCoach {
	id: string; // Calendar ID
	gladstoneId: string; // Coach ID in GS2
	name: string;
	bio: string;
	phone: string;
	photoUrl: string;
}

export interface AppointmentTypes {
	type: AppointmentType;
	name: string;
	durationInMinutes: number;
	paddingAfterInMinutes: number;
	paddingBeforeInMinutes: number;
	totalPaddingInMinutes: number;
}

export interface AppointmentBlocksTypes {
	type: AppointmentBlockType;
	name: string;
	durationInMinutes: number;
	paddingAfterInMinutes: number;
	paddingBeforeInMinutes: number;
	totalPaddingInMinutes: number;
}

export interface AppointmentBlockInfo {
	id: number;
	kannactId: string;
	firstName: string;
	lastName: string;
	status: ParticipantStatus;
	phoneNumber: string;
}
