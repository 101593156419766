import { useEffect } from "react";
import { NoteFormData } from "../../../../../../models/notes/note-form";
import { NoteHelper } from "../../../../../../services/helpers/note-helper";

const NoteDraft = ({
	participantId,
	formData,
}: {
	participantId: number;
	formData: NoteFormData;
}) => {
	useEffect(() => {
		if (formData) {
			NoteHelper.saveDraft(participantId, formData);
		}
	}, [formData]);

	return null;
};

export default NoteDraft;
