import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useParticipantPhysicians = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-physicians", { participantId }];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getPhysicians({
			participantId,
		});

		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
