import { Formatter } from "./../../../services/formatter/formatter.service";
import { ProviderDetail } from "../../entities/provider.model";
import { TableTemplateButtons } from "../../components/table/table-template.model";
import { AuthRoles } from "../../roles.model";
import { Gender, PreferredLanguage, eParticipantStatus } from "../../entities/participant.model";

export interface SettingsUsersCrudModal {
	isVisible: boolean;
	mode: "CREATE" | "UPDATE";
	entity: ProviderDetail | null;
}

export interface SettingsUsersForm {
	id: number | null;
	firstName: string | null;
	lastName: string | null;
	dob: Date | null;
	gender: Gender | null;
	isActive: eParticipantStatus | null;
	isActivelyCoaching: boolean | null;
	role: AuthRoles | null;
	institutionId: number | null;
	languagePref: PreferredLanguage | null;
	email1: string | null;
	email2: string | null;
	phone1: string | null;
}

export class SettingsUserRow {
	name: string;
	isActive: eParticipantStatus;
	isActivelyCoaching: string;
	role: number;
	entity: ProviderDetail;
	actionsTemplate: TableTemplateButtons;

	constructor(params: ProviderDetail, updateModalFn: () => void) {
		this.name = Formatter.fullName(params.firstName, params.lastName);
		this.isActive = params.isActive;
		this.isActivelyCoaching = params.isActivelyCoaching ? "YES" : "NO";
		this.role = params.role;
		this.entity = params;
		this.actionsTemplate = [
			{
				id: "btnUpdate",
				icon: "pi pi-pencil",
				className: "p-button-text neutral",
				disabled: false,
				onClick: updateModalFn,
			},
		];
	}
}
