import { useContext } from "react";
import { PageProps } from "../../../models/routing.model";
import ParticipantDashboardBiometrics from "./biometrics/ParticipantDashboardBiometrics";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import MedicationsQuickView from "./medications/MedicationsQuickView";
import ParticipantOverview from "./ParticipantOverview";
import ConditionsQuickView from "./conditions/ConditionsQuickView";
import ParticipantNotes from "./notes/ParticipantNotes";

const ParticipantDashboard = (props: PageProps) => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const participantProfile = participantContext.participant!;

	return (
		<div className="pt-dashboard">
			<div className="pt-dashboard__main">
				{/* Participant overview */}
				<ParticipantOverview />

				{/* Participant notes */}
				<ParticipantNotes />
			</div>

			<div className="pt-dashboard__sidebar">
				<ul>
					{/* Conditions */}
					<li>
						<ConditionsQuickView participantId={participantProfile.id} />
					</li>

					{/* Biometrics */}
					<li>
						<ParticipantDashboardBiometrics participantId={participantProfile.id} />
					</li>

					{/* Medications */}
					<li>
						<MedicationsQuickView participantId={participantProfile.id} />
					</li>
				</ul>
			</div>
		</div>
	);
};
export default ParticipantDashboard;
