import { AppointmentsService } from "../../../services/appointments/appointments.service";
import { Formatter } from "../../../services/formatter/formatter.service";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateTranslate,
} from "../../components/table/table-template.model";
import {
	Appointment,
	AppointmentBlock,
	AppointmentBlockType,
	AppointmentBlocksTypes,
	AppointmentCoach,
	AppointmentType,
	AppointmentTypes,
} from "../../entities/appointments.model";
import { ProviderTwilio } from "../../entities/provider.model";

export class AppointmentRow {
	// Appointment: appointment ID | Block: coachId_datetime
	id: string;
	// Appointment: firstName+lastName | Block: null
	name: string | null;
	// Appointment: pt kannactId / null | Block: null
	kannactId: string | null;
	kannactIdTemplate: string | null;
	type: string;
	typeTemplate: TableTemplateTranslate; // Translated type
	coachId: number | undefined;
	datetime: string; // Date
	duration: string | null;
	phone: string | null;
	phoneTemplate: string | null;
	actionsTemplate: TableTemplateButtons;
	entity: Appointment | AppointmentBlock;

	constructor(
		params: Appointment | AppointmentBlock,
		kannactIdTemplate: string,
		appointmentTypes: AppointmentTypes[],
		appointmentCoaches: AppointmentCoach[],
		providers: ProviderTwilio[],
		blockTypes?: AppointmentBlocksTypes[],
		rescheduleFn?: (e: TableTemplateButtonsEvent) => any,
		cancelFn?: (e: TableTemplateButtonsEvent) => any
	) {
		if (params.type.includes("_BLOCK")) {
			// It's block/class
			const block = params as AppointmentBlock;
			this.id = `${params.coachId}_${params.datetime}`;
			this.name = Formatter.NO_DATA;
			this.kannactId = null;
			this.kannactIdTemplate = Formatter.NO_DATA;
			this.type = block.type;
			this.typeTemplate = {
				key: `ENUMS.APPOINTMENTS_BLOCKS.${block.type}`,
			};
			this.coachId = this.setcoachId(block.coachId, appointmentCoaches, providers);
			this.datetime = block.datetime;
			this.duration = this.templateDuration(block.type, blockTypes!);
			this.phone = null;
			this.phoneTemplate = null;
			this.actionsTemplate = [];
		} else {
			// It's a normal appointment
			const appt = params as Appointment;
			this.id = appt.id;
			this.name = Formatter.fullName(appt.participant.firstName, appt.participant.lastName);
			this.kannactId = appt.participant.kannactId;
			this.kannactIdTemplate = appt.participant.kannactId ?? kannactIdTemplate;
			this.type = appt.type;
			this.typeTemplate = { key: `ENUMS.APPOINTMENTS_TYPE.${appt.type}` };
			this.coachId = this.setcoachId(appt.coachId, appointmentCoaches, providers);
			this.datetime = appt.datetime;
			this.duration = this.templateDuration(appt.type, appointmentTypes);
			this.phone = appt.participant.phone;
			this.phoneTemplate = appt.participant.phone;
			this.actionsTemplate =
				rescheduleFn && cancelFn
					? AppointmentsService.templateActions(rescheduleFn, cancelFn)
					: [];
		}
		this.entity = params;
	}

	private setcoachId = (
		coachCalendarId: string,
		appointmentCoaches: AppointmentCoach[],
		providers: ProviderTwilio[]
	) => {
		const coachId = appointmentCoaches.find((x) => x.id === coachCalendarId)?.gladstoneId;
		if (coachId) return providers.find((x) => x.coachId.toString() === coachId)?.coachId;
	};

	private templateDuration = (
		type: AppointmentType | AppointmentBlockType,
		appointmentTypes: (AppointmentTypes | AppointmentBlocksTypes)[]
	) => {
		const appointment = appointmentTypes.find((x) => x.type === type);
		let result = "";
		if (type.includes("_BLOCKS")) {
			// It's a block/class
			result = `${appointment?.durationInMinutes ?? Formatter.NO_DATA} min`;
		} else {
			// It's an appointment
			result = `${appointment?.durationInMinutes ?? Formatter.NO_DATA} min + ${
				appointment?.totalPaddingInMinutes ?? Formatter.NO_DATA
			} buffer`;
		}
		return result;
	};
}
