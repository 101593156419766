import { useTranslation } from "react-i18next";
import UiButton from "../../../../../../components/button/Button";
import UiFieldMessage from "../../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../../models/components/field-message.model";

const NotesHistoryState = ({
	isLoading,
	isError,
	isEmpty,
	onRefresh,
}: {
	isLoading: boolean;
	isError: boolean;
	isEmpty: boolean;
	onRefresh: () => Promise<void>;
}) => {
	const { t } = useTranslation("common");

	return (
		<div>
			<div className="participant-notes-history__title">
				{t(`PARTICIPANT.DASHBOARD.NOTES.HISTORY_TABLE_TITLE`)}
			</div>

			<UiFieldMessage
				severity={
					isLoading
						? FieldMessageSeverity.LOADING
						: isError
							? FieldMessageSeverity.DANGER
							: FieldMessageSeverity.INFO
				}
				label={
					isLoading
						? "UI_COMPONENTS.PANEL_LIST.MESSAGES.LOADING"
						: isError
							? "UI_COMPONENTS.PANEL_LIST.MESSAGES.ERROR"
							: "UI_COMPONENTS.PANEL_LIST.MESSAGES.NO_DATA"
				}
			/>

			{isError && (
				<div className="action-buttons">
					<UiButton
						label="UI_COMPONENTS.BUTTONS.TRY_AGAIN"
						className="p-button-sm mt-2"
						onClick={onRefresh}
					/>
				</div>
			)}
		</div>
	);
};
export default NotesHistoryState;
