import { useState } from "react";
import { Form } from "react-final-form";
import {
	CustomGoalsFormData,
	CustomGoalsFormMode,
	CustomGoalsFormProps,
} from "../../../../../models/custom-goals/custom-goals-misc";
import UiInputRadio from "../../../../../components/input-radio/InputRadio.component";
import SmartGoalForm from "./SmartGoalForm";
import LongTermGoalForm from "./LongTermGoalForm";
import { LongTermGoal, SmartGoal } from "../../../../../models/custom-goals/custom-goals";
import UiButton from "../../../../../components/button/Button";

const CustomGoalsForm = (props: CustomGoalsFormProps) => {
	const [formData, setFormData] = useState<CustomGoalsFormData>({
		type:
			props.mode === CustomGoalsFormMode.CREATE
				? null
				: props.mode === CustomGoalsFormMode.UPDATE_LONG_TERM_GOAL ||
					  props.mode === CustomGoalsFormMode.DUPLICATE_LONG_TERM_GOAL
					? "LONG_TERM_GOAL"
					: "SMART_GOAL",
	});

	return (
		<>
			<Form
				onSubmit={() => {}}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<UiInputRadio
							id="type"
							name="type"
							label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.FIELDS.mode"
							onChange={(e) => setFormData({ ...formData, type: e.value })}
							options={[
								{
									id: "SMART_GOAL",
									label: `PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.SMART_GOAL`,
								},
								{
									id: "LONG_TERM_GOAL",
									label: `PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.CREATE_FORM.LONG_TERM_GOAL`,
								},
							]}
							disabled={props.mode !== CustomGoalsFormMode.CREATE}
						/>
					</form>
				)}
			/>

			{formData.type === "SMART_GOAL" && (
				<SmartGoalForm
					mode={props.mode}
					entity={props.entity as SmartGoal | null}
					participantId={props.participantId}
					longTermGoalsQuery={props.longTermGoalsQuery}
					onDone={props.onDone}
				/>
			)}

			{formData.type === "LONG_TERM_GOAL" && (
				<LongTermGoalForm
					mode={props.mode}
					entity={props.entity as LongTermGoal | null}
					participantId={props.participantId}
					onDone={props.onDone}
				/>
			)}

			{!formData.type && (
				<div className="action-buttons">
					<UiButton
						id="modalBtnCancel"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
						className="p-button-outlined p-button-rounded"
						type="button"
						onClick={props.onDone}
					></UiButton>
				</div>
			)}
		</>
	);
};
export default CustomGoalsForm;
