import { useEffect } from "react";
import { BsFillChatLeftFill } from "react-icons/bs";
import { Badge } from "primereact/badge";
import { useParams } from "react-router-dom";
import TwilioConversationService from "../../../../services/twilio/twilio-conversations.service";
import { useUnreadMessagesCounts } from "./useUnreadMessagesCounts";

const ConversationMenu = () => {
	const urlParams = useParams<{ participantId?: string }>();
	const unreadMessagesCounts = useUnreadMessagesCounts({
		participantId: Number(urlParams.participantId),
		enabled: !!urlParams.participantId,
	});

	const numUnreadCareMessages = unreadMessagesCounts.data?.careMessageUnreadCount ?? 0;

	const numUnreadSMS = unreadMessagesCounts.data?.smsUnreadCount ?? 0;

	const numUnreadMessages = numUnreadCareMessages + numUnreadSMS;

	useEffect(() => {
		// Listen to the unread count changes
		const unreadCountSubscription = TwilioConversationService.unreadCountSubject.subscribe(
			(event) => unreadMessagesCounts.invalidate()
		);

		// Remove listeners
		return () => {
			if (unreadCountSubscription) unreadCountSubscription.unsubscribe();
		};
	}, []);

	return (
		<span className="p-overlay-badge conversation-menu">
			{numUnreadMessages > 0 && (
				<Badge
					value={TwilioConversationService.beautifyUnreadCount(numUnreadMessages)}
					severity="danger"
					className="conversation-menu-badge"
				/>
			)}
			<BsFillChatLeftFill />
		</span>
	);
};
export default ConversationMenu;
