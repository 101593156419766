import { Formatter } from "../../../../services/formatter/formatter.service";
import { classNames } from "primereact/utils";
import { getSessionStorageUser } from "../../../../services/session/auth.service";
import { Sms } from "../../../../models/entities/sms.model";

const SmsMessage = ({
	message,
	members,
}: {
	message: Sms;
	members?: { [x: string]: { phone: string; fullName: string } };
}) => {
	/**
	 * Messages: check message author
	 * @description Check if a message was sent by the logged user, or by someone else
	 */
	const isOwnMessage = (message: Sms): boolean =>
		message.from.endpoint === getSessionStorageUser()?.phone ||
		message.from.endpoint ===
			`${getSessionStorageUser()?.countryCode}${getSessionStorageUser()?.phone}`;

	return (
		<div
			key={message.sid}
			className={classNames(
				"message-container",
				isOwnMessage(message) ? "own-message" : "other-message"
			)}
		>
			<div className="message-wrapper">
				<div className="message-info">
					{/* Message: author */}
					{message.from.endpoint && (
						<span className="message-author">
							{members && members[message.from.endpoint]?.fullName
								? members[message.from.endpoint].fullName
								: message.from.endpoint}
						</span>
					)}

					{/* Message: creation date */}
					{message.dateCreated && (
						<span className="message-date">
							{Formatter.date(message.dateCreated, {
								day: "numeric",
								month: "short",
								year: "numeric",
								hour: "2-digit",
								minute: "2-digit",
							})}
						</span>
					)}
				</div>

				{/* Message: content/body */}
				<div className="message-body">{message.body}</div>
			</div>
		</div>
	);
};
export default SmsMessage;
