import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TwilioEndpointsService } from "../../../../services/endpoints/twilio-endpoints.service";

export const useUnreadMessagesCounts = ({
	participantId,
	enabled = true,
}: {
	participantId: number;
	enabled?: boolean;
}) => {
	const queryClient = useQueryClient();

	const queryKey = getUnreadMessagesCountsQueryKey(participantId);

	const query = useQuery(
		queryKey,
		async () => {
			const response = await TwilioEndpointsService.getUnreadMessagesCounts(
				participantId.toString()
			);
			return response;
		},
		{
			enabled,
		}
	);

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};

export const getUnreadMessagesCountsQueryKey = (participantId: number) => [
	"participant-unread-messages-counts",
	{ participantId },
];
