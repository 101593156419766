import { useTranslation } from "react-i18next";
import { UiPhoneCallerProps } from "../../models/components/phone-caller.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { TableService } from "../../services/table/table.service";
import { BsTelephoneFill } from "react-icons/bs";
import UiButton from "../button/Button";
import { classNames } from "primereact/utils";

const UiPhoneCaller = (props: UiPhoneCallerProps) => {
	const { t } = useTranslation("common");

	return (
		<>
			{props.phoneNumber && (
				<span className="k-phone-caller-container">
					{/* Phone number (formatted & non-clickable) */}
					<span className="phone-number">{Formatter.phoneNumber(props.phoneNumber)}</span>

					{/* Phone icon (clickable) */}
					<UiButton
						type="button"
						className={classNames(
							"p-button-rounded phone-icon",
							props.size || "p-button-sm"
						)}
						icon={<BsTelephoneFill />}
						onClick={() => TableService.phoneCall(t, props.phoneNumber)}
					/>
				</span>
			)}
		</>
	);
};

export default UiPhoneCaller;
