import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "primereact/badge";
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from "primereact/tabview";
import UiCard from "../../../components/card/Card";
import { PageProps } from "../../../models/routing.model";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import UiButton from "../../../components/button/Button";
import { ParticipantSUGData } from "../../../models/pages/service-utilization-goals/participant-sug.model";
import { SuCategories } from "../../../models/entities/service-utilization-goals.model";
import ServiceUtilizationGoals from "./ServiceUtilizationGoal";
import { SUGModalHistoryProps } from "../../../models/pages/service-utilization-goals/sug-modal-history.model";
import ServiceUtilizationGoalsModalHistory from "./ServiceUtilizationGoalsModalHistory";
import { useCategoriesGoals } from "./useCategoriesGoals";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { CreateServiceUtilizationEntryRequest } from "../../../models/endpoints/service-utilization-goals-endpoint.model";
import GoalApplicableStatusModal, {
	GoalApplicableStatusModalState,
	useGoalApplicableStatusModal,
} from "./GoalApplicableStatusModal";
import MarkAsNeverReceivedModal, {
	MarkAsNeverReceivedModalState,
	useMarkAsNeverReceivedModal,
} from "./MarkAsNeverReceivedModal";
import MentalHeatlhSurveyLinks from "./MentalHealthSurveyLinks";

type Categories = { [x: string]: ParticipantSUGData };

const ParticipantServiceUtilizationGoals = (props: PageProps) => {
	const { t } = useTranslation("common");
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	/* Tabs */

	const [activeTab, setActiveTab] = useState<number>(0);

	const categoriesGoals = useCategoriesGoals({
		participantId: Number(participantContext.participant?.id),
	});

	const handleSubmitDataEntry = async (
		createSUEntryRequest: CreateServiceUtilizationEntryRequest
	) => {
		await EndpointsService.dataRetriever.createServiceUtilizationEntry(createSUEntryRequest);

		await categoriesGoals.invalidate();
	};

	const goalApplicableStatusModal = useGoalApplicableStatusModal();

	const handleGoalApplicableStatusChange = async (reason: string) => {
		await EndpointsService.dataRetriever.createServiceUtilizationEntry({
			participantId: Number(participantContext.participant?.id),
			body: {
				category: goalApplicableStatusModal.state.goalCategory,
				dateOn: new Date().toISOString(),
				estimated: false,
				examType:
					goalApplicableStatusModal.state.status === "APPLICABLE_MODAL_OPEN"
						? "MARK_AS_APPLICABLE"
						: "MARK_AS_NON_APPLICABLE",
				goalName: goalApplicableStatusModal.state.goalName,
				notes: reason,
				source: "coach",
			},
		});

		await categoriesGoals.invalidate();

		goalApplicableStatusModal.closeModal();
	};

	const markAsNeverReceivedModal = useMarkAsNeverReceivedModal();

	const handleMarkAsNeverReceived = async (notes?: string) => {
		await EndpointsService.dataRetriever.createServiceUtilizationEntry({
			participantId: Number(participantContext.participant?.id),
			body: {
				category: markAsNeverReceivedModal.state.goalCategory,
				dateOn: new Date().toISOString(),
				estimated: false,
				examType: "MARK_AS_NEVER_RECEIVED",
				goalName: markAsNeverReceivedModal.state.goalName,
				notes: notes ?? null,
				source: "coach",
			},
		});

		await categoriesGoals.invalidate();

		markAsNeverReceivedModal.closeModal();
	};

	/* Modal: Full history */

	// Modal: close
	const closeModal = (isVisible: boolean): void =>
		setModalSUGProps({ ...modalSUGProps, isVisible });

	// Modal: open
	const openModalSUG = (): void =>
		setModalSUGProps({
			...modalSUGProps,
			isVisible: true,
			categories: categoriesGoals.data!,
			activeTab,
		});

	// Modal: props
	const [modalSUGProps, setModalSUGProps] = useState<SUGModalHistoryProps>({
		isVisible: false,
		activeTab,
		categories: categoriesGoals.data!,
		closeModal: closeModal.bind(this),
	});

	if (categoriesGoals.isLoading)
		return (
			<UiCard className="service-utilization-goals-container">
				<i className="pi pi-spin pi-spinner message-icon"></i>
			</UiCard>
		);

	if (categoriesGoals.isError)
		return (
			<UiCard className="service-utilization-goals-container">
				<UiFieldMessage
					severity={FieldMessageSeverity.DANGER}
					label="PARTICIPANT.PROFILE.EMPTY_MESSAGE_ERROR"
				/>
				<div style={{ marginTop: "0.5rem" }}>
					<UiButton
						label={t("UI_COMPONENTS.BUTTONS.TRY_AGAIN")}
						className="p-button-rounded p-button-sm"
						onClick={categoriesGoals.invalidate}
					/>
				</div>
			</UiCard>
		);

	return (
		<UiCard
			title="PARTICIPANT.SERVICE_UTILIZATION_GOALS.TITLE"
			titleUnderline={false}
			className="service-utilization-goals-container"
			customHeader={
				<UiButton
					label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.BUTTON_FULL_HISTORY"
					className="p-button-rounded p-button-sm"
					onClick={openModalSUG}
				/>
			}
		>
			{/* Tabs */}
			<TabView
				activeIndex={activeTab}
				onTabChange={(e) => setActiveTab(e.index)}
			>
				{tabTemplate({
					categories: categoriesGoals.data,
					onSubmitDataEntry: handleSubmitDataEntry,
					onClickMarkApplicabilityButton: goalApplicableStatusModal.openModal,
					onClickMarkAsNeverReceived: markAsNeverReceivedModal.openModal,
				})}
			</TabView>

			{/* Modal: full history */}
			{modalSUGProps.isVisible && (
				<ServiceUtilizationGoalsModalHistory props={modalSUGProps} />
			)}

			{goalApplicableStatusModal.state.status !== "MODAL_CLOSED" && (
				<GoalApplicableStatusModal
					{...goalApplicableStatusModal.state}
					onSubmitForm={handleGoalApplicableStatusChange}
					onCloseModal={() => goalApplicableStatusModal.closeModal()}
				/>
			)}

			{markAsNeverReceivedModal.state.isOpen && (
				<MarkAsNeverReceivedModal
					{...markAsNeverReceivedModal.state}
					onSubmitForm={handleMarkAsNeverReceived}
					onCloseModal={() => markAsNeverReceivedModal.closeModal()}
				/>
			)}
		</UiCard>
	);
};

const tabTemplate = ({
	categories,
	onSubmitDataEntry,
	onClickMarkApplicabilityButton,
	onClickMarkAsNeverReceived,
}: {
	categories: Categories;
	onSubmitDataEntry: (
		createSUEntryRequest: CreateServiceUtilizationEntryRequest
	) => Promise<void>;
	onClickMarkApplicabilityButton: (state: GoalApplicableStatusModalState) => void;
	onClickMarkAsNeverReceived: (state: MarkAsNeverReceivedModalState) => void;
}): React.ReactNode[] => {
	const categoryNames = Object.keys(categories);

	return categoryNames.map((category) => (
		<TabPanel
			key={category}
			headerTemplate={(e) => (
				<TabHeaderTemplate
					options={e}
					category={category}
					categories={categories}
				/>
			)}
			disabled={categories[category].disabled}
		>
			{category === SuCategories.MENTAL_HEALTH && <MentalHeatlhSurveyLinks />}

			{/* Tab content */}
			<TabContent
				data={categories[category]}
				onSubmitDataEntry={onSubmitDataEntry}
				onClickMarkApplicabilityButton={onClickMarkApplicabilityButton}
				onClickMarkAsNeverReceived={onClickMarkAsNeverReceived}
			/>
		</TabPanel>
	));
};

const TabHeaderTemplate = ({
	options,
	category,
	categories,
}: {
	options: TabPanelHeaderTemplateOptions;
	category: string;
	categories: Categories;
}) => {
	const { t } = useTranslation("common");

	return (
		<div
			className="p-tabview-nav-link"
			onClick={options.onClick}
			role="button"
			tabIndex={0}
		>
			<span className="tab-title">{t(categories[category].title)}</span>
			{categories[category].warningsCount > 0 && category !== SuCategories.NON_APPLICABLE && (
				<Badge
					value={categories[category].warningsCount}
					severity="danger"
					className="tab-count"
				/>
			)}
		</div>
	);
};

const TabContent = ({
	data,
	onSubmitDataEntry,
	onClickMarkAsNeverReceived,
	onClickMarkApplicabilityButton,
}: {
	data: ParticipantSUGData;
	onSubmitDataEntry: (
		createSUEntryRequest: CreateServiceUtilizationEntryRequest
	) => Promise<void>;
	onClickMarkAsNeverReceived: (state: MarkAsNeverReceivedModalState) => void;
	onClickMarkApplicabilityButton: (state: GoalApplicableStatusModalState) => void;
}) => {
	const tabContentTemplate = [];
	const { t } = useTranslation("common");

	if (data.services.length > 0) {
		data.services.forEach((service) => {
			const tabServiceTemplate: React.ReactNode = (
				<ServiceUtilizationGoals
					key={service.id}
					props={{
						...service,
						onSubmitDataEntry,
						onClickMarkAsNeverReceived: () =>
							onClickMarkAsNeverReceived({
								goalName: service.id,
								goalCategory: service.category,
								goalId: service.id,
								goalTitle: service.title,
								isOpen: true,
							}),
						onClickMarkApplicabilityButton: () =>
							onClickMarkApplicabilityButton({
								goalName: service.id,
								goalCategory: service.category,
								goalId: service.id,
								goalTitle: service.title,
								status: service.applicable
									? "NON_APPLICABLE_MODAL_OPEN"
									: "APPLICABLE_MODAL_OPEN",
							}),
					}}
				/>
			);
			tabContentTemplate.push(tabServiceTemplate);
		});
	} else {
		const tabEmptyTemplate: React.ReactNode = (
			<p className="empty-category">{t("PARTICIPANT.SERVICE_UTILIZATION_GOALS.EMPTY_TAB")}</p>
		);
		tabContentTemplate.push(tabEmptyTemplate);
	}

	return <>{tabContentTemplate}</>;
};

export default ParticipantServiceUtilizationGoals;
