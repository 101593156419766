import { useEffect, useState } from "react";
import {
	IParticipantSUGService,
	SuHistoryRow,
} from "../../../models/pages/service-utilization-goals/participant-sug.model";
import {
	TableColumnTemplate,
	TableDateMode,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import UiTable from "../../../components/table/Table.component";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import TableEmptyMessage from "../../../components/table/empty-message/TableEmptyMessage.component";
import { useTranslation } from "react-i18next";
import UiButton from "../../../components/button/Button";
import { SuCategories } from "../../../models/entities/service-utilization-goals.model";

const ServiceUtilizationGoalsRecentHistory = ({
	props,
}: {
	props: IParticipantSUGService & {
		onClickMarkAsNeverReceived: () => void;
	};
}) => {
	// Table: columns
	const columns: TableColumn[] = [
		{
			field: "examType",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.examType",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: {
				translationPrefix: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.EXAMS_TYPE",
			},
		},
		{
			field: "dateOn",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.dateOn",
			template: TableColumnTemplate.ESTIMATED_DATE,
			sortable: true,
			templateOptions: {
				dateMode: TableDateMode.UTC,
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					timeZone: "UTC",
				},
			},
		},
		{
			field: "source",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.source",
			sortable: true,
			template: TableColumnTemplate.TRANSLATE_PREFIX,
			templateOptions: { translationPrefix: "ENUMS.SOURCE" },
		},
		{
			field: "notes",
			title: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.COLUMNS.notes",
			sortable: true,
		},
	];

	// Table: rows
	const [rows, setRows] = useState<SuHistoryRow[]>([]);

	// Table: pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "dateOn",
		sortOrder: -1,
	});

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
	};

	// Table: messages
	const messages = new TableMessages();
	const [message] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// First time load
	useEffect(() => {
		mapData();
	}, [props]);

	// Rows: mapping
	const mapData = (): void => {
		let result: SuHistoryRow[] = [];

		if (props.recentHistory.length > 0) {
			result = props.recentHistory.map((item) => {
				const row: SuHistoryRow = {
					id: item.id,
					service: props.id,
					examType: item.examType,
					dateOn: item.dateOn,
					source: item.source,
					notes: item.notes ?? Formatter.NO_DATA,
					estimated: item.estimated,
				};
				return row;
			});
		}

		setRows(result);
	};

	return (
		<div className="sug-history-content">
			<UiTable
				dataKey="id"
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
				paginationFn={onPagination}
				paginator={false}
				alwaysShowPaginator={false}
				emptyMessage={
					props.category !== SuCategories.MENTAL_HEALTH ? (
						<CustomNoResultsLayout
							onClickMarkAsNeverReceived={props.onClickMarkAsNeverReceived}
						/>
					) : null
				}
			/>
		</div>
	);
};

const CustomNoResultsLayout = (props: { onClickMarkAsNeverReceived: () => void }) => {
	const { t } = useTranslation("common");

	return (
		<div className="sug-history__no-content-container">
			<TableEmptyMessage
				severity={FieldMessageSeverity.INFO}
				label="UI_COMPONENTS.TABLE.EMPTY_MESSAGE"
			/>
			<div className="sug-history__no-content-cta-wrapper">
				<UiButton
					label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.HISTORY.BUTTON_NEVER_RECEIVED"
					className="p-button-rounded"
					onClick={props.onClickMarkAsNeverReceived}
				/>
			</div>
		</div>
	);
};

export default ServiceUtilizationGoalsRecentHistory;
