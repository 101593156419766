import { useTranslation } from "react-i18next";
import {
	TableColumnTemplate,
	TableDateMode,
} from "../../models/components/table/table-column.model";
import { Formatter } from "../../services/formatter/formatter.service";
import UiTag from "../tag/Tag.component";
import UiLink from "../link/Link.component";
import { UiTableCellProps } from "../../models/components/table/table-cell.model";
import { SplitButton } from "primereact/splitbutton";
import UiTableTwilioAudioTemplate from "./templates/TableTwilioAudioTemplate.component";
import UiTableButtonsTemplate from "./templates/TableButtonsTemplate.component";
import UiPhoneCaller from "../phone-caller/PhoneCaller.component";
import { MenuItem } from "primereact/menuitem";
import { HelperService } from "../../services/helpers/helper.service";
import {
	TableTemplateKannactId,
	TableTemplateLink,
	TableTemplateText,
} from "../../models/components/table/table-template.model";
import UiButton from "../button/Button";
import UiTableTextTemplate from "./templates/TableTextTemplate.component";
import { CoachFormatter } from "../../services/coach-formatter/coach-formatter.service";

const UiTableCell = (props: UiTableCellProps): JSX.Element => {
	const { t } = useTranslation("common");

	const setRowCellTemplate = (): JSX.Element => {
		const cellData = props.rowData[String(props.column.field)];
		// Cell template
		let value;

		switch (props.column.template) {
			case TableColumnTemplate.DATE:
				if (props.column.templateOptions?.dateMode === TableDateMode.UTC) {
					value = Formatter.dateISOToString(
						cellData,
						props.column.templateOptions?.dateFormat
					);
				} else {
					value = Formatter.date(cellData, props.column.templateOptions?.dateFormat);
				}
				break;
			case TableColumnTemplate.PHONE:
				value = Formatter.phoneNumber(cellData);
				break;
			case TableColumnTemplate.COACH:
				value = CoachFormatter.getCoachName(cellData);
				break;
			case TableColumnTemplate.PHONE_CALL:
				if (cellData)
					value = (
						<UiPhoneCaller
							phoneNumber={cellData}
							size="p-button-xs"
						/>
					);
				break;
			case TableColumnTemplate.CURRENCY:
				value = Formatter.currency(cellData, props.column.templateOptions?.currency);
				break;
			case TableColumnTemplate.DURATION:
				value = Formatter.duration(cellData);
				break;
			case TableColumnTemplate.TAG:
				if (cellData) value = <UiTag {...cellData} />;
				break;
			case TableColumnTemplate.TRANSLATE_PREFIX:
				value = cellData
					? props.column.templateOptions?.translationPrefix
						? t(`${props.column.templateOptions.translationPrefix}.${cellData}`)
						: t(cellData)
					: undefined;
				break;
			case TableColumnTemplate.TRANSLATE:
				value = cellData?.key ? t(cellData.key, cellData?.params) : cellData;
				break;
			case TableColumnTemplate.LINK:
				const tmpVal: TableTemplateLink = cellData;
				if (tmpVal.to || tmpVal.onClick) {
					value = <UiLink {...cellData} />;
				} else if (tmpVal.label) {
					value = t(tmpVal.label);
				}
				break;
			case TableColumnTemplate.TWILIO_AUDIO:
				if (cellData?.callId) {
					value = <UiTableTwilioAudioTemplate {...cellData} />;
				}
				break;
			case TableColumnTemplate.AUDIO:
				if (cellData) {
					value = (
						<audio
							controls
							draggable={false}
							src={cellData}
						></audio>
					);
				}
				break;
			case TableColumnTemplate.ACTIONS:
				if (cellData?.length > 0) {
					const items: MenuItem[] = cellData.map((action: MenuItem) => {
						if (action.label) action.label = t(action.label);
						return action;
					});
					value = (
						<>
							<SplitButton
								className="actions-template-button"
								dropdownIcon="pi pi-ellipsis-v"
								model={items}
							/>
						</>
					);
				}
				break;
			case TableColumnTemplate.CUSTOM_ACTIONS:
				if (cellData?.length > 0) {
					value = <>{cellData}</>;
				}
				break;
			case TableColumnTemplate.CUSTOM:
				value = props.column.templateOptions?.customTemplate?.(
					props.rowData,
					props.colBody
				);
				break;
			case TableColumnTemplate.BUTTONS:
				value = <UiTableButtonsTemplate {...props} />;
				break;
			case TableColumnTemplate.KANNACT_ID:
				value = HelperService.isKannactId(cellData) ? (
					<UiLink {...new TableTemplateKannactId(cellData).props} />
				) : (
					cellData
				);
				break;
			case TableColumnTemplate.ESTIMATED_DATE:
				value = (
					<>
						<span>
							{Formatter.date(cellData, props.column.templateOptions?.dateFormat)}
						</span>
						{props.rowData["estimated"] && <span> (Est.)</span>}
					</>
				);
				break;
			case TableColumnTemplate.EXPANDER:
				value = props.column.templateOptions?.expanderTemplate ? (
					props.column.templateOptions?.expanderTemplate?.(
						props.rowData,
						props.toggleExpand
					)
				) : (
					<UiButton
						label={
							props.isExpanded
								? props.column.templateOptions?.expandedLabel ??
									"UI_COMPONENTS.TABLE.TEMPLATES.EXPANDER.expanded"
								: props.column.templateOptions?.collapsedLabel ??
									"UI_COMPONENTS.TABLE.TEMPLATES.EXPANDER.collapsed"
						}
						type="button"
						className="p-button-text link p-0"
						onClick={() => props.toggleExpand(props.rowData, props.colBody)}
					/>
				);
				break;
			case TableColumnTemplate.TEXT:
				const data = cellData as TableTemplateText;
				value = (
					<UiTableTextTemplate
						templateOptions={props.column.templateOptions}
						className={data.className}
						value={data.value}
					/>
				);
				break;
			default:
				value = cellData ?? undefined;
				break;
		}

		return value;
	};

	return <>{setRowCellTemplate()}</>;
};

export default UiTableCell;
