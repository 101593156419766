import React, { useState } from "react";
import { Form } from "react-final-form";
import { UiExtensionPadProps } from "../../models/components/extensionpad.model";
import UiButton from "../button/Button";
import UiInputText from "../input-text/InputText.component";

const UiExtensionPad = (props: UiExtensionPadProps) => {
	/**
	 * Keypad
	 */
	const keypadKeys: { [x: string]: { value: string; subValue?: string }[] } = {
		row1: [
			{ value: "1", subValue: "-" },
			{ value: "2", subValue: "ABC" },
			{ value: "3", subValue: "DEF" },
		],
		row2: [
			{ value: "4", subValue: "GHI" },
			{ value: "5", subValue: "JKL" },
			{ value: "6", subValue: "MNO" },
		],
		row3: [
			{ value: "7", subValue: "PQRS" },
			{ value: "8", subValue: "TUV" },
			{ value: "9", subValue: "WXYZ" },
		],
		row4: [{ value: "*" }, { value: "0", subValue: "+" }, { value: "#" }],
	};
	const setKeypadKeyTemplate = (
		key: string,
		keyRow: { value: string; subValue?: string }[]
	): React.ReactNode => {
		return (
			<div
				key={key}
				className="dialkey-row"
			>
				{keyRow.map((key) => (
					<div
						className="key-item"
						key={key.value}
						onClick={(e) => updateExtension(true, key.value)}
						role="button"
						tabIndex={0}
					>
						<span className="key-value">{key.value}</span>
						{key.subValue && <span className="key-subvalue">{key.subValue}</span>}
					</div>
				))}
			</div>
		);
	};
	const setKeypadRowTemplate = (): React.ReactNode[] => {
		const rows: React.ReactNode[] = [];
		Object.entries(keypadKeys).forEach(([key, val]) => {
			const row: React.ReactNode = setKeypadKeyTemplate(key, val);
			rows.push(row);
		});
		return rows;
	};

	/**
	 * Extension
	 */
	const [formData, setFormData] = useState<{ extension: string }>({ extension: "" });
	const updateExtension = (keypad: boolean, value: string) => {
		if (keypad) setFormData({ extension: formData.extension + value });
		else setFormData({ extension: value });
	};
	const sendExtension = (): void => props.sendExtension(formData.extension);

	return (
		<div className="k-extensionpad">
			{/* Keyboard input */}
			<Form
				onSubmit={sendExtension}
				initialValues={formData}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="input-container">
							{/* Extension number (input) */}
							<UiInputText
								id="extension"
								placeholder="UI_COMPONENTS.EXTENSIONPAD.PLACEHOLDER"
								label="UI_COMPONENTS.EXTENSIONPAD.LABEL"
								name="extension"
								onChange={(e) => updateExtension(false, e.target.value)}
							></UiInputText>

							<UiButton
								type="submit"
								className="p-button-rounded"
								icon="pi pi-send"
								onClick={sendExtension}
							></UiButton>
						</div>
					</form>
				)}
			/>

			{/* Extensionpad input */}
			<div>{setKeypadRowTemplate()}</div>
		</div>
	);
};
export default UiExtensionPad;
