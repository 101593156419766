import {
	ITableFilter,
	TableFilterFormData,
	TableFilterType,
} from "../../models/components/table/table-filter.model";

/**
 * Filter: value
 * @description Filter by an exact value
 */
const filterByValues = (selectedFilters: string[], rowValue: any, matches: boolean[]): void => {
	matches.push(selectedFilters.some((sel) => sel.toString() === rowValue.toString()));
};
const filterByValue = (
	selectedFilter: string | boolean,
	rowValue: any,
	matches: boolean[]
): void => {
	matches.push(selectedFilter.toString() === rowValue.toString());
};

const matches = (selectedFilters: ITableFilter[], row: any): boolean[] => {
	const matches: boolean[] = [];

	selectedFilters.forEach((selectedFilter) => {
		switch (selectedFilter.type) {
			case TableFilterType.SELECT:
			case TableFilterType.STRING:
				filterByValue(selectedFilter.value, row[selectedFilter.field], matches);
				break;
			case TableFilterType.BOOLEAN:
			case TableFilterType.DATE:
			case TableFilterType.NUMBER:
			case TableFilterType.SELECT_MULTIPLE:
				filterByValues(selectedFilter.value, row[selectedFilter.field], matches);
				break;
			case TableFilterType.STRING_RANGE:
			case TableFilterType.NUMBER_RANGE:
			default:
				break;
		}
	});

	return matches;
};

// Filter: client-side
const filter = (tableRows: any[], filters: ITableFilter[]): any[] => {
	// Filters that have checked options
	const selectedFilters: ITableFilter[] = filters.filter(
		(filter) => filter.value?.length > 0 || filter.value === 0 || filter.value === false
	);
	let filtered: any[] = tableRows;
	// Only show rows that matched all the filters (combined)
	if (selectedFilters?.length > 0)
		filtered = tableRows.filter((row) => matches(selectedFilters, row).indexOf(false) === -1);

	return filtered;
};

// Search: client-side
const search = (tableRows: any[], keys: string[], search: string | null | undefined) => {
	let filtered: any[] = tableRows;

	if (search) {
		filtered = tableRows.filter((x) =>
			keys.some((k) => x[k]?.toString()?.toLowerCase().includes(search.toLowerCase()))
		);
	}

	return filtered;
};

const hasSelectedFilters = (tableFilter?: ITableFilter[]): boolean => {
	let result = false;
	if (tableFilter) {
		tableFilter.forEach((filter) => {
			if (
				(filter.value instanceof Array && filter.value.length > 0) ||
				(!(filter.value instanceof Array) && filter.value) ||
				filter.value === 0 ||
				filter.value === false
			)
				result = true;
		});
	}
	return result;
};

// When the "APPLY FILTERS" button is clicked -> update the filters state
const updateFiltersValues = (
	tableFilters: ITableFilter[],
	formData: TableFilterFormData
): ITableFilter[] => {
	return tableFilters.map((filter) => {
		filter.value = formData[filter.field];
		return filter;
	});
};

const getFilter = (filters: ITableFilter[], field: string): ITableFilter | null => {
	const result = filters.filter((item) => item.field === field);
	return result ? result[Number(0)] || null : null;
};

const resetFormData = (filters: ITableFilter[]): TableFilterFormData => {
	const data: TableFilterFormData = {};
	if (filters.length > 0) {
		filters.forEach((filter) => {
			data[String(filter.field)] = null;
		});
	}
	return data;
};

const setFormData = (filters: ITableFilter[]): TableFilterFormData => {
	const data: TableFilterFormData = {};
	if (filters.length > 0) {
		filters.forEach((filter) => {
			data[String(filter.field)] = filter.value || null;
		});
	}
	return data;
};

export const FilterService = {
	filter,
	setFormData,
	resetFormData,
	updateFiltersValues,
	hasSelectedFilters,
	getFilter,
	search,
};
