import { useTranslation } from "react-i18next";
import {
	CHART_DATA,
	CHART_INFO,
	CHART_TYPE,
} from "../../../../models/pages/biometrics/biometrics-charts.model";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { Biometric, BiometricType } from "../../../../models/entities/biometrics.model";

const BiometricsChartsChartSummary = ({
	type,
	chartData,
	biometrics,
}: {
	type: CHART_TYPE;
	chartData: CHART_DATA;
	biometrics: Map<string, Biometric[]>;
}) => {
	const { t } = useTranslation("common");

	let result: CHART_INFO = { count: null, min: null, avg: null, max: null };

	switch (type) {
		case CHART_TYPE.BLOOD_PRESSURE:
			// Calculate average: Systolic
			let avgSystolic = null;
			if (chartData.datasets[0]?.data.length) {
				avgSystolic = chartData.datasets[0].data.reduce((acc, num) => acc + num.y, 0);
				avgSystolic = Math.round(avgSystolic / chartData.datasets[0].data.length);
			}

			// Calculate average: Diastolic
			let avgDiastolic = null;
			if (chartData.datasets[1]?.data.length) {
				avgDiastolic = chartData.datasets[1].data.reduce((acc, num) => acc + num.y, 0);
				avgDiastolic = Math.round(avgDiastolic / chartData.datasets[1].data.length);
			}

			/* Calculate minimum */

			// Get the minimum Systolic reading
			const minSystolicReading =
				chartData.datasets[0].data.length > 0
					? chartData.datasets[0].data.reduce(
							(prev, curr) => (prev.y < curr.y ? prev : curr),
							chartData.datasets[0].data[0] // Initial value
						)
					: null;

			// Get the associated Diastolic value for that reading
			const minDiastolic = minSystolicReading
				? biometrics
						.get(BiometricType.blood_pressure)
						?.find(
							(x) =>
								new Date(x.vitalTime).getTime() ===
								(minSystolicReading.x as Date).getTime()
						)
						?.entries.find((x) => x.subType === BiometricType.blood_pressure_diastolic)
						?.value
				: null;

			/* Calculate maximum */

			// Get the maximum Systolic reading
			const maxSystolicReading =
				chartData.datasets[0].data.length > 0
					? chartData.datasets[0].data.reduce(
							(prev, curr) => (prev.y > curr.y ? prev : curr),
							chartData.datasets[0].data[0] // Initial value
						)
					: null;

			// Get the associated Diastolic value for that reading
			const maxDiastolic = maxSystolicReading
				? biometrics
						.get(BiometricType.blood_pressure)
						?.find(
							(x) =>
								new Date(x.vitalTime).getTime() ===
								(maxSystolicReading.x as Date).getTime()
						)
						?.entries.find((x) => x.subType === BiometricType.blood_pressure_diastolic)
						?.value
				: null;

			// Update state
			result = {
				count:
					chartData.datasets[0] &&
					chartData.datasets[1] &&
					chartData.datasets[0].data.length > chartData.datasets[1].data.length
						? chartData.datasets[0].data.length
						: chartData.datasets[1]
							? chartData.datasets[1].data.length
							: null,
				min: minSystolicReading
					? `${minSystolicReading.y ?? Formatter.NO_DATA}/${minDiastolic ?? Formatter.NO_DATA}`
					: null,
				avg: `${avgSystolic ?? Formatter.NO_DATA}/${avgDiastolic ?? Formatter.NO_DATA}`,
				max: maxSystolicReading
					? `${maxSystolicReading.y ?? Formatter.NO_DATA}/${maxDiastolic ?? Formatter.NO_DATA}`
					: null,
			};
			break;
		case CHART_TYPE.GLUCOSE:
			// Calculate values (min, max, average)
			let minGlucose = chartData.datasets[0].data[0].y;
			let maxGlucose = chartData.datasets[0].data[0].y;
			let avgGlucose = 0;
			chartData.datasets[0].data.forEach((val) => {
				if (val.y < minGlucose) minGlucose = val.y;
				if (val.y > maxGlucose) maxGlucose = val.y;
				avgGlucose = avgGlucose + val.y;
			});
			avgGlucose = Math.round(avgGlucose / chartData.datasets[0].data.length);

			// Update state
			result = {
				count: chartData.datasets[0].data.length,
				min: minGlucose,
				avg: avgGlucose,
				max: maxGlucose,
			};
			break;
		case CHART_TYPE.WEIGHT:
			// Calculate values (min, max, average)
			let min = chartData.datasets[0].data[0].y;
			let max = chartData.datasets[0].data[0].y;
			let avg = 0;
			chartData.datasets[0].data.forEach((val) => {
				if (val.y < min) min = val.y;
				if (val.y > max) max = val.y;
				avg = avg + val.y;
			});
			avg = Math.round((avg / chartData.datasets[0].data.length) * 10) / 10;

			// Update state
			result = { count: chartData.datasets[0].data.length, min, avg, max };
			break;
		default:
			break;
	}
	return (
		<div className="d-flex align-items-baseline">
			<span className="text-bold fs-5 me-3">
				{t("PARTICIPANT.BIOMETRICS.CHARTS.TITLE_SUMMARY")}
			</span>
			<div className="chart-info-container">
				<span className="chart-info-container">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.INFO_COUNT", {
						value: result.count ?? Formatter.NO_DATA,
					})}
				</span>
				<span className="chart-info-container">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.INFO_MIN", {
						value: result.min ?? Formatter.NO_DATA,
					})}
				</span>
				<span className="chart-info-container">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.INFO_AVG", {
						value: result.avg ?? Formatter.NO_DATA,
					})}
				</span>
				<span className="chart-info-container">
					{t("PARTICIPANT.BIOMETRICS.CHARTS.INFO_MAX", {
						value: result.max ?? Formatter.NO_DATA,
					})}
				</span>
			</div>
		</div>
	);
};

export default BiometricsChartsChartSummary;
