import { Outlet, useNavigate } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import { PageProps } from "../../../models/routing.model";
import { useTranslation } from "react-i18next";

const TableShowcase = (props: PageProps) => {
	const { t } = useTranslation("common");
	const navigate = useNavigate();

	const items = [
		{
			label: t("SHOWCASES.TABLE_SHOWCASES.SIMPLE_TABLE_SHOWCASE.TITLE"),
			command: () => {
				navigate("/showcases/table/simple", { replace: true });
			},
		},
		{
			label: t("SHOWCASES.TABLE_SHOWCASES.TEMPLATES_TABLE_SHOWCASE.TITLE"),
			command: () => {
				navigate("/showcases/table/templates", { replace: true });
			},
		},
		{
			label: t("SHOWCASES.TABLE_SHOWCASES.FULL_TABLE_SHOWCASE.TITLE"),
			command: () => {
				navigate("/showcases/table/full", { replace: true });
			},
		},
	];

	return (
		<>
			<TabMenu model={items} />
			<Outlet />
		</>
	);
};

export default TableShowcase;
