import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";

const useNumUnreadConversations = ({
	coachId,
	isAdminView,
}: {
	coachId?: number;
	isAdminView: boolean;
}) => {
	const queryClient = useQueryClient();
	const queryKey = ["num-unread-conversations", { coachId, isAdminView }];

	const query = useQuery(
		queryKey,
		async () => {
			const numUnreadConversations = await EndpointsService.twilio.getNumUnreadConversations(
				isAdminView
					? {}
					: {
							config: {
								params: {
									filters: `coachId==${coachId}`,
								},
							},
						}
			);

			return numUnreadConversations;
		},
		{
			refetchInterval: 5000,
		}
	);

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};

export default useNumUnreadConversations;
