import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useAddressStates = () => {
	const queryClient = useQueryClient();
	const queryKey = ["address-states"];

	const query = useQuery(queryKey, async () => {
		const response = await EndpointsService.dataRetriever.getAddressStates();
		return response;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
