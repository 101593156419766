import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomGoalsModal from "./CustomGoalsModal";
import CustomGoalsActive from "./CustomGoalsActive";
import UiButton from "../../../../../components/button/Button";
import { ParticipantContextProps } from "../../../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../../../contexts/Participant.context";
import { TableMessages } from "../../../../../models/components/table/table-message.model";
import { useSmartGoals } from "../../../../../hooks/custom-goals/useSmartGoals";
import { useLongTermGoals } from "../../../../../hooks/custom-goals/useLongTermGoals";
import UiFieldMessage from "../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../models/components/field-message.model";
import { NoteHelper } from "../../../../../services/helpers/note-helper";
import { CustomGoalsActiveForm } from "../../../../../models/custom-goals/custom-goals-misc";

const CustomGoals = ({
	noteId,
	noteProgress,
	smartGoalsQuery,
	longTermGoalsQuery,
	onChange,
}: {
	noteId: number | null;
	noteProgress: CustomGoalsActiveForm[];
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onChange: (e: CustomGoalsActiveForm[]) => void;
}) => {
	const { t } = useTranslation("common");
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const messages = new TableMessages();

	const [isVisible, setIsVisible] = useState<boolean>(false);

	return (
		<div className="">
			<h5 className="text-bold">{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.TITLE")}</h5>
			<p>{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.SUBTITLE")}</p>

			{smartGoalsQuery.isLoading && (
				<UiFieldMessage
					severity={FieldMessageSeverity.LOADING}
					label={messages.loading}
				/>
			)}
			{smartGoalsQuery.isError && (
				<UiFieldMessage
					severity={FieldMessageSeverity.DANGER}
					label={messages.error}
				/>
			)}
			{(!smartGoalsQuery.data ||
				smartGoalsQuery.data.length === 0 ||
				NoteHelper.activeSmartGoals(smartGoalsQuery.data, noteId).length === 0) && (
				<UiFieldMessage
					severity={FieldMessageSeverity.INFO}
					label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.EMPTY"
				/>
			)}

			{/* SMART goals: current/active */}
			{smartGoalsQuery.data &&
				smartGoalsQuery.data.length > 0 &&
				NoteHelper.activeSmartGoals(smartGoalsQuery.data, noteId).length > 0 && (
					<CustomGoalsActive
						noteId={noteId}
						smartGoals={NoteHelper.activeSmartGoals(smartGoalsQuery.data, noteId)}
						noteProgress={noteProgress}
						onChange={onChange}
					/>
				)}

			<div className="d-flex justify-content-center mt-2">
				<UiButton
					id="modalCustomGoals"
					className="p-button-sm p-button-rounded"
					label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.BUTTON_MODAL"
					onClick={() => setIsVisible(true)}
				/>

				{isVisible && (
					<CustomGoalsModal
						isVisible={isVisible}
						participantId={participantContext.participant?.id!}
						smartGoalsQuery={smartGoalsQuery}
						longTermGoalsQuery={longTermGoalsQuery}
						onClose={() => setIsVisible(false)}
					/>
				)}
			</div>
		</div>
	);
};

export default CustomGoals;
