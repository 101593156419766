import React from "react";
import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import {
	PanelListMessage,
	PanelListMessageSeverity,
	UiPanelListProps,
} from "../../models/components/panel-list.model";
import UiButton from "../button/Button";
import { classNames } from "primereact/utils";
import GoalIndicator from "../goal-indicator/GoalIndicator";

const UiPanelList = ({ props }: { props: UiPanelListProps }) => {
	const { t } = useTranslation("common");

	// Panel header
	const setHeaderTemplate = (options: PanelHeaderTemplateOptions): React.ReactNode => {
		return (
			<div className="panel-list-header">
				{/* Title */}
				<span className="header-title">{t(props.title)}</span>

				{/* Action */}
				{props.hasAction && (
					<UiButton
						type="button"
						className="p-button-xs p-button-rounded"
						label={props.actionLabel}
						onClick={props.actionFn}
						disabled={
							props.message.severity === PanelListMessageSeverity.LOADING ||
							props.message.severity === PanelListMessageSeverity.ERROR
						}
					/>
				)}
			</div>
		);
	};

	// Message
	const setMessage = (): React.ReactNode | undefined => {
		const currentMessage: PanelListMessage = {
			severity: props.message.severity,
			message: props.message.message,
		};

		const template: React.ReactNode = (
			<p
				className={classNames(
					"panel-no-data",
					props.message.severity === PanelListMessageSeverity.ERROR && "has-error",
					props.message.severity === PanelListMessageSeverity.LOADING && "loading"
				)}
			>
				{currentMessage.severity === PanelListMessageSeverity.LOADING && (
					<i className="pi pi-spin pi-spinner message-icon"></i>
				)}
				{t(currentMessage.message)}
			</p>
		);

		return props.list.length === 0 ? template : undefined;
	};

	return (
		<Panel
			headerTemplate={setHeaderTemplate}
			toggleable={false}
			className="k-panel-list"
		>
			{/* Message: no data / loading / error */}
			{setMessage()}

			{/* Data list */}
			{props.list.length > 0 && (
				<ul className="panel-list">
					{props.list.map((item, index) => (
						<li
							key={`${item.label}`}
							className="panel-list-item"
						>
							{item.label && <span className="item-label">{t(item.label)}:</span>}
							{item.value && (
								<span className="item-value">
									{item.value}{" "}
									{item.goal && (
										<GoalIndicator
											goal={item.goal}
											detailed={false}
										/>
									)}
								</span>
							)}
						</li>
					))}
				</ul>
			)}
		</Panel>
	);
};

export default UiPanelList;
