import { TagSeverityType } from "primereact/tag";
import {
	TableTemplateLink,
	TableTemplateTwilioAudio,
	TableTemplateTag,
} from "../components/table/table-template.model";
import { CallHistoryDirection, CallHistory } from "../entities/call-history.model";
import { CallStatus } from "../entities/call.model";
import { engagementType } from "../entities/engagement.model";

export interface ICallLogRow {
	sid: string;
	parentCallSid: string;
	direction: TableTemplateTag;
	status: TableTemplateTag;
	createdOn: string; // Date
	coachId: number;
	participant: string; // Kannact ID
	phone: string;
	duration: number; // Seconds
	twilioAudioTemplate: TableTemplateTwilioAudio;
	engagementTemplate: TableTemplateLink;
	entity: CallHistory;
}

export class CallLogRow implements ICallLogRow {
	sid: string;
	parentCallSid: string;
	direction: TableTemplateTag;
	status: TableTemplateTag;
	createdOn: string; // Date
	coachId: number;
	participant: string; // Kannact ID
	phone: string;
	duration: number; // Seconds
	twilioAudioTemplate: TableTemplateTwilioAudio;
	engagementTemplate: TableTemplateLink;
	entity: CallHistory;

	constructor(params: CallHistory, modalEngagementFn?: (entity: CallHistory) => Promise<void>) {
		this.sid = params.callSid;
		this.parentCallSid = params.parentCallSid;
		this.direction = this.setDirection(params);
		this.status = this.setStatus(params.status);
		this.createdOn = params.createdOn;
		this.coachId = params.coachId;
		this.participant = params.participant;
		this.phone =
			params.callDirection === CallHistoryDirection.INBOUND
				? params.fromNumber
				: params.toNumber;
		this.duration = params.duration;
		this.twilioAudioTemplate = this.setRecording(params);
		this.engagementTemplate = this.setEngagementDetails(params, modalEngagementFn);
		this.entity = params;
	}

	private setDirectionSeverity = (direction: CallHistoryDirection) => {
		let result: TagSeverityType = "warning";
		if (direction === CallHistoryDirection.INBOUND) {
			result = "info";
		} else if (direction === CallHistoryDirection.OUTBOUND) {
			result = "success";
		}
		return result;
	};

	private setDirection = (params: CallHistory): TableTemplateTag => {
		const value: TableTemplateTag = {
			value: `ENUMS.CALL_DIRECTION.${params.callDirection}`,
			uppercase: true,
			rounded: true,
			severity: this.setDirectionSeverity(params.callDirection),
		};
		return value;
	};

	private setStatus = (paramValue: CallStatus): TableTemplateTag => {
		const value: TableTemplateTag = {
			value: `ENUMS.CALL_STATUS.${paramValue}`,
			uppercase: true,
			rounded: true,
			severity: "warning",
		};

		switch (paramValue) {
			case CallStatus.COMPLETED:
				value.severity = "success";
				break;
			case CallStatus.FAILED:
			case CallStatus.CANCELED:
				value.severity = "danger";
				break;
			default:
				break;
		}

		return value;
	};

	private setRecording = (params: CallHistory): TableTemplateTwilioAudio => {
		return {
			callId: params.parentCallSid,
			callDate: params.createdOn,
			kannactId: params.participant,
			mediaUrl: null,
		};
	};

	private setEngagementDetails = (
		params: CallHistory,
		modalEngagementFn?: (entity: CallHistory) => void
	): TableTemplateLink => {
		let template: TableTemplateLink;
		if (params.engagementDetails) {
			template = {
				to: `/participant/${params.participant.slice(
					3,
					params.participant.length
				)}/appointments/engagement-history`,
				label: `ENUMS.ENGAGEMENT_DETAILS.${params.engagementDetails}`,
			};
		} else if (params.engagementType === engagementType.NONE) {
			template = {
				to: "",
				label: "CALL_CENTER.HISTORY.TABLE.NON_ENGAGEMENT_MSG",
			};
		} else {
			template = {
				to: "",
				onClick: modalEngagementFn,
				label: "CALL_CENTER.HISTORY.TABLE.ENGAGEMENT_MSG",
				className: "text-danger",
				icon: "pi pi-exclamation-triangle",
			};
		}

		return template;
	};
}
