import { useContext, useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import UiTable from "../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableDateMode,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { ParticipantReminderRow } from "../../../models/pages/participants-reminder.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { BackendPagination } from "../../../models/pagination.model";
import { Reminder, IReminder } from "../../../models/entities/reminder.model";
import { AxiosError } from "axios";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import UiModalReminder from "../../../components/modal-reminder/ModalReminder.component";
import {
	ModalReminderMode,
	UiModalReminderProps,
} from "../../../models/components/modal-reminder.model";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import { TableFilterType } from "../../../models/components/table/table-filter.model";
import UiButton from "../../../components/button/Button";

const ParticipantReminder = (props: PageProps) => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "dateOn",
			title: "PARTICIPANT.REMINDERS.TABLE.COLUMNS.reminderDate",
			sortable: true,
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateMode: TableDateMode.UTC,
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					timeZone: "UTC",
				},
			},
			style: {
				width: "300px",
				paddingRight: "64px",
			},
		},
		{
			field: "reason",
			title: "PARTICIPANT.REMINDERS.TABLE.COLUMNS.reason",
			sortable: true,
		},
		{
			field: "createdBy",
			title: "PARTICIPANT.REMINDERS.TABLE.COLUMNS.createdBy",
			sortable: true,
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			alignment: "right",
		},
	];

	// Rows
	const [rows, setRows] = useState<ParticipantReminderRow[]>([]);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "dateOn",
		sortOrder: 1,
		search: undefined,
		filters: [
			{
				field: "patientId",
				type: TableFilterType.STRING,
				value: participantContext.participant?.id?.toString(),
			},
		],
	});

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	/**
	 * MODAL: Reminders
	 * - Create
	 * - Update
	 * - Delete
	 * - Resolve
	 * - Snooze
	 */

	const resolve = async (event: TableTemplateButtonsEvent): Promise<void> => {
		// Update entity
		const updatedReminder: Reminder = (event.row as ParticipantReminderRow).entity;
		updatedReminder.resolved = true;
		updatedReminder.updatedOn = new Date().toISOString();

		// Update row (on the frontend, while the endpoint updates the real data)
		setRows(
			rows.filter(
				(row: ParticipantReminderRow) =>
					row.entity.id !== (event.row as ParticipantReminderRow).entity.id
			)
		);

		// Call endpoint
		await EndpointsService.dataRetriever
			.updateReminder({
				body: {
					id: updatedReminder.id as number,
					kannactId: updatedReminder.kannactId,
					patientId: updatedReminder.patientId,
					reason: updatedReminder.reason,
					dateOn: updatedReminder.dateOn,
					resolved: updatedReminder.resolved,
					createdOn: updatedReminder.createdOn,
					updatedOn: updatedReminder.updatedOn,
				},
			})
			.finally(() => {
				getData(pagination);
				getCount(pagination);
			});
	};

	const snoozeModal = (event: TableTemplateButtonsEvent): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.SNOOZE,
			entity: (event.row as ParticipantReminderRow).entity,
			isVisible: true,
		});

	const createModal = (): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.CREATE,
			entity: {
				kannactId: participantContext.participant?.kannactId,
			},
			isVisible: true,
		});

	const updateModal = (reminder: IReminder): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.DETAILS,
			entity: reminder,
			isVisible: true,
		});

	const deleteModal = (reminder: IReminder): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.DELETE,
			entity: reminder,
			isVisible: true,
		});

	const cancelModal = () => {
		setModalProps({ ...modalProps, isVisible: false });
	};

	const completeModal = (refresh: boolean, reminder: IReminder | null): void => {
		// Update modal state
		setModalProps({ ...modalProps, isVisible: false });

		// Update row template
		if (refresh && reminder) {
			setRows(
				rows.map((row: ParticipantReminderRow) => {
					if (row.entity.id === reminder.id) {
						// Update value
						const originalEntity: IReminder = { ...row.entity, ...reminder };

						// Create new row
						row = new ParticipantReminderRow(
							originalEntity,
							resolve.bind(this),
							snoozeModal.bind(this),
							updateModal.bind(this, originalEntity),
							deleteModal.bind(this, originalEntity)
						);
					}
					return row;
				})
			);
		}

		// Refresh to get the updated data (also down the tree)
		if (refresh) {
			getData(pagination);
			getCount(pagination);
		}
	};

	const [modalProps, setModalProps] = useState<UiModalReminderProps>({
		mode: ModalReminderMode.DETAILS,
		entity: null,
		isVisible: false,
		onCancel: cancelModal.bind(this),
		onComplete: completeModal.bind(this),
	});

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);
	}, []);

	// Get data: participants
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		await EndpointsService.dataRetriever
			.getReminders({
				config: {
					params: new BackendPagination(event),
				},
			})
			.then((response: IReminder[]) => {
				if (response?.length > 0) {
					setRows(
						response.map(
							(item) =>
								new ParticipantReminderRow(
									item,
									resolve.bind(this),
									snoozeModal.bind(this),
									updateModal.bind(this, item),
									deleteModal.bind(this, item)
								)
						)
					);
				} else {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		await EndpointsService.dataRetriever
			.getRemindersCount({
				config: { params: new BackendPagination(event) },
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participant Reminders: Error getting the count");
			});
	};

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		getData(newPagination);
	};

	return (
		<>
			<UiTable
				dataKey="id"
				title={props.title}
				useAsCard={true}
				customHeader={
					<UiButton
						type="button"
						className="p-button-rounded p-button-sm"
						icon={"pi pi-plus"}
						label="PARTICIPANTS.REMINDERS.BUTTON_CRUD_CREATE"
						onClick={createModal}
					/>
				}
				columns={columns}
				value={rows}
				message={message}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={onPagination}
				totalRecords={count}
			/>

			{/* Reminder modal */}
			{modalProps.isVisible && <UiModalReminder {...modalProps}></UiModalReminder>}
		</>
	);
};

export default ParticipantReminder;
