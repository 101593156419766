import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiButton from "../../../../../components/button/Button";
import { useState } from "react";
import UiInputText from "../../../../../components/input-text/InputText.component";
import UiFieldMessage from "../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../models/components/field-message.model";
import { Validations } from "../../../../../services/form/validations.service";
import {
	CustomGoalsDeleteFormProps,
	CustomGoalType,
} from "../../../../../models/custom-goals/custom-goals-misc";
import { EndpointsService } from "../../../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import FormSection from "../../../../../components/form-section/FormSection.component";

const CustomGoalsDelete = (props: CustomGoalsDeleteFormProps) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<boolean>(false);
	const [formData, setFormData] = useState<{ reason: string | null }>({
		reason: null,
	});

	const handleSubmit = async (data: { reason: string }) => {
		if (props.mode === CustomGoalType.SMART_GOAL) {
			await deleteSmartGoal();
		} else {
			await deleteLongTermGoal();
		}
	};

	const deleteSmartGoal = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.deleteSmartGoal({
				body: {
					id: props.entity!.id,
					reason: formData.reason!,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	const deleteLongTermGoal = async () => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.deleteLongTermGoal({
				body: {
					id: props.entity!.id,
					reason: formData.reason!,
				},
			})
			.then((response) => props.onDone())
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={{ reason: null }}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					{/* Message */}
					<div className="mb-4">
						<span>
							{t(
								props.mode === CustomGoalType.SMART_GOAL
									? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.DELETE_FORM.MSG_SMART_GOAL"
									: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.DELETE_FORM.MSG_LONG_TERM_GOAL"
							)}
						</span>
						<span className="ms-1 text-bold">{props.entity.name}</span>
					</div>

					<FormSection>
						{/* Reason */}
						<UiInputText
							id="reason"
							placeholder="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.DELETE_FORM.reasonPlaceholder"
							label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.DELETE_FORM.reason"
							name="reason"
							onChange={(e) => setFormData({ reason: e.target.value })}
							validations={[Validations.required]}
							disabled={submitting}
						/>
					</FormSection>

					{/* FORM MESSAGE */}
					{message && (
						<div className="form-message">
							<UiFieldMessage
								severity={FieldMessageSeverity.DANGER}
								label="PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.DELETE_FORM.SUBMIT_ERROR"
							/>
						</div>
					)}

					{/* Actions: cancel | confirm */}
					<div className="action-buttons">
						<UiButton
							label={"UI_COMPONENTS.BUTTONS.NEVERMIND"}
							className="p-button-outlined p-button-rounded"
							type="button"
							onClick={props.onDone}
							disabled={submitting}
						/>
						<UiButton
							label={
								submitting
									? "UI_COMPONENTS.BUTTONS.DELETING"
									: "UI_COMPONENTS.BUTTONS.DELETE"
							}
							className="p-button-rounded"
							loading={submitting}
							type="submit"
							disabled={submitting}
						/>
					</div>
				</form>
			)}
		/>
	);
};
export default CustomGoalsDelete;
