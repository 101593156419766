import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export const useMatomoTrackParticipant = () => {
	const location = useLocation();
	const matomo = useMatomo();

	useEffect(() => {
		if (location.pathname.includes("/participant/")) {
			// The participant ID is saved automatically by Matomo > Custom Directions > Action Dimensions
			matomo.trackPageView();
		}
	}, [location]);
};
