import { UiFieldMessageProps } from "../../components/field-message.model";
import { BiometricsLatest } from "../../endpoints/biometrics-endpoint.model";
import { BiometricType, Biometric } from "../../entities/biometrics.model";
import { Goal } from "../../entities/goal.model";
import { Source } from "../../entities/source.model";
import { BiometricsModalMode, IBiometricData } from "./biometrics-modal.model";
import {
	BiometricValueTemplateBloodPressure,
	BiometricValueTemplateCholesterol,
	BiometricValueTemplateGlucose,
	BiometricValueTemplateHeight,
	BiometricValueTemplateHemoglobinA1c,
	BiometricValueTemplateKidneyGfr,
	BiometricValueTemplateWeight,
	BiometricValueTemplatetriglycerides,
} from "./biometrics-templates.model";

// Component props
export type BiometricsEditProps = {
	mode: BiometricsModalMode;
	participantId: number;
	formData: IBiometricData;
	biometrics: Map<string, Biometric[]>;
	latestBiometric: BiometricsLatest | null;
	message: UiFieldMessageProps | null;
	goals: Goal[];
	values: number[] | null;
	submitting: boolean;
	changeFn: () => void;
};

// Form: update
export interface BiometricUpdateForm {
	ids: number[] | null;
	type: BiometricType | null;
	subTypes: BiometricType[];
	value:
		| BiometricValueTemplateHemoglobinA1c
		| BiometricValueTemplateBloodPressure
		| BiometricValueTemplateGlucose
		| BiometricValueTemplateWeight
		| BiometricValueTemplateHeight
		| BiometricValueTemplateCholesterol
		| BiometricValueTemplatetriglycerides
		| BiometricValueTemplateKidneyGfr;
	dateOn: Date | null;
	submitting: boolean;
}

export class BiometricUpdateBody implements Partial<Biometric> {
	ids?: number[];
	deviceId?: number;
	participantId?: number;
	isManual?: boolean;
	source?: Source;
  mealState?: "PRE-MEAL" | "POST-MEAL" | "FASTING" | "CONTROL" | "UNKNOWN" | null;
	value?: any | null;
	unit?: string;
	type?: BiometricType;
	vitalTime?: string; // Date
	deleted?: boolean;
}

export type BiometricsInlineUpdateForm = {
	value: BiometricUpdateForm["value"] | null;
	dateOn: BiometricUpdateForm["dateOn"] | null;
};
