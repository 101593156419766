import { useContext, useState } from "react";
import UiCard from "../../../components/card/Card";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import ParticipantDetailsGeneralInfo from "./GeneralInformation";
import ParticipantDetailsKannactInfo from "./KannactInformation";
import ParticipantDetailsSubscriptions from "./SubscriptionsPreferences";
import ParticipantDetailsContact from "./ContactPreferences";
import { useAddressStates } from "../../../hooks/useAddressStates";
import { eParticipantStatus } from "../../../models/entities/participant.model";
import UiButton from "../../../components/button/Button";
import { PageProps } from "../../../models/routing.model";
import ModalProfileEvents, { ModalProfileEventsProps } from "./ModalProfileEvents";
import { useInstitutionsDetailed } from "../../../hooks/useInstitutionsDetailed";
import ParticipantDetailsPhysicians from "./Physicians";
import { useParticipantPhysicians } from "../../../hooks/useParticipantPhysicians";

const ParticipantDetails = (props: PageProps) => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	const [modalProps, setModalProps] = useState<
		Omit<ModalProfileEventsProps, "onClose" | "onComplete" | "participantId">
	>({
		isVisible: false,
		mode: "OPT_OUT",
	});

	// Kannact information
	const institutions = useInstitutionsDetailed();

	// Contact preferences
	const addressStatesQuery = useAddressStates();

	// Physicians information
	const physiciansQuery = useParticipantPhysicians({
		participantId: participantContext.participant!.id,
	});

	return (
		<UiCard
			title="PARTICIPANT.DETAILS.TAB_TITLE"
			className="participant-details-container"
			titleUnderline={false}
			customHeader={
				<>
					<UiButton
						label={
							participantContext.participant!.status === eParticipantStatus.DNC
								? "PARTICIPANT.DETAILS.HEADER_ACTIONS.OPT_IN"
								: "PARTICIPANT.DETAILS.HEADER_ACTIONS.OPT_OUT"
						}
						className="p-button-rounded p-button-sm p-button-outlined"
						onClick={() =>
							setModalProps({
								isVisible: true,
								mode:
									participantContext.participant!.status ===
									eParticipantStatus.DNC
										? "OPT_IN"
										: "OPT_OUT",
							})
						}
					/>
					{!participantContext.participant!.enrollmentDate &&
						participantContext.participant!.status !== eParticipantStatus.DNC && (
							<UiButton
								label="PARTICIPANT.DETAILS.HEADER_ACTIONS.ENROLLED"
								className="p-button-rounded p-button-sm ms-2"
								onClick={() =>
									setModalProps({
										isVisible: true,
										mode: "ENROLLED",
									})
								}
							/>
						)}
				</>
			}
		>
			<div className="row">
				<div className="col-12 col-lg-6">
					{/* General information */}
					<ParticipantDetailsGeneralInfo
						participant={participantContext.participant!}
						onUpdate={participantContext.onRefresh}
					/>

					{/* Contact preferences */}
					<ParticipantDetailsContact
						participant={participantContext.participant!}
						addressStatesQuery={addressStatesQuery}
						onUpdate={participantContext.onRefresh}
					/>

					{/* Physicians information */}
					<ParticipantDetailsPhysicians
						participant={participantContext.participant!}
						query={physiciansQuery}
					/>
				</div>

				<div className="col-12 col-lg-6">
					{/* Kannact information */}
					<ParticipantDetailsKannactInfo
						participant={participantContext.participant!}
						institutions={institutions.data || []}
						onUpdate={participantContext.onRefresh}
					/>

					{/* Subscriptions preferences */}
					<ParticipantDetailsSubscriptions
						participant={participantContext.participant!}
						onUpdate={participantContext.onRefresh}
					/>
				</div>
			</div>

			{modalProps.isVisible && (
				<ModalProfileEvents
					isVisible={modalProps.isVisible}
					participantId={participantContext.participant!.id}
					mode={modalProps.mode}
					onClose={() => setModalProps({ ...modalProps, isVisible: false })}
					onComplete={() => {
						setModalProps({ ...modalProps, isVisible: false });
						participantContext.onRefresh();
					}}
				/>
			)}
		</UiCard>
	);
};

export default ParticipantDetails;
