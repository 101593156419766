import {
	SuCategories,
	SuServices,
	SuStatus,
	SuEvent,
	ServiceUtilizationExamGoal,
} from "../../models/entities/service-utilization-goals.model";
import { Source } from "../../models/entities/source.model";
import { List } from "../../models/misc.model";
import {
	IParticipantSUGService,
	SuHistory,
} from "../../models/pages/service-utilization-goals/participant-sug.model";

const serviceNeedsAttention = (status: SuStatus): boolean =>
	status === SuStatus.no_data ||
	status === SuStatus.due_soon ||
	status === SuStatus.survey_due_soon ||
	status === SuStatus.survey_off_track ||
	status === SuStatus.therapy_due_soon ||
	status === SuStatus.therapy_off_track ||
	status === SuStatus.off_track;

const updateCount = (data: IParticipantSUGService[]): number => {
	let result: number = 0;
	data.forEach((item) => {
		if (item.status && serviceNeedsAttention(item.status)) result += 1;
	});
	return result;
};

const getCategory = (data: SuServices): SuCategories => {
	let result: SuCategories;

	switch (data) {
		case SuServices.PREVENTIVE_CARE:
		case SuServices.BREAST_CANCER:
		case SuServices.CERVICAL_CANCER:
		case SuServices.COLORECTAL_CANCER:
			result = SuCategories.SCREENINGS;
			break;
		case SuServices.INFLUENZA:
		case SuServices.ZOSTER:
		case SuServices.PNEUMOCOCCAL:
		case SuServices.TD_DAP:
			result = SuCategories.VACCINES;
			break;
		case SuServices.DEPRESSION:
		case SuServices.UNHEALTHY_ALCOHOL_USE:
			result = SuCategories.MENTAL_HEALTH;
			break;
		default:
			result = SuCategories.NON_APPLICABLE;
			break;
	}

	return result;
};

const getExamType = (data: SuServices, examTypes: ServiceUtilizationExamGoal[]): List => {
	const result: List = [];

	examTypes.forEach((item) => {
		if (item.name === data)
			item.events.forEach((event) =>
				result.push({
					id: event,
					label: `PARTICIPANT.SERVICE_UTILIZATION_GOALS.EXAMS_TYPE.${event}`,
				})
			);
	});

	return result;
};

const getHistory = (data: SuEvent[], max_elements: number | null = null): SuHistory[] => {
	let result: SuHistory[] = [];

	if (data.length > 0) {
		// Sort
		result = data
			.sort((a, b) => {
				// Sort by: last event date (first)
				const dateA = new Date(a.date);
				const dateB = new Date(b.date);
				return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
			})
			.map((event, index) => {
				const row: SuHistory = {
					id: index, // TODO: map (not coming from the backend yet)
					examType: event.description,
					dateOn: event.date,
					estimated: event.estimated,
					source: event.source.toUpperCase() as Source,
					notes: event.notes, // TODO: map (not coming from the backend yet)
				};
				return row;
			});
		result = max_elements !== null ? result.slice(0, max_elements) : result; // Filter: get the 5 most recent events
	}

	return result;
};

export const SU_Service = {
	serviceNeedsAttention,
	updateCount,
	getCategory,
	getExamType,
	getHistory,
};
