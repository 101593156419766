import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import UiButton from "../../../components/button/Button";
import {
	SettingsUsersCrudModal,
	SettingsUsersForm,
} from "../../../models/pages/settings/users.model";
import { Form } from "react-final-form";
import UiInputText from "../../../components/input-text/InputText.component";
import { useState } from "react";
import { FormApi } from "final-form";
import { Validations } from "../../../services/form/validations.service";
import UiSelect from "../../../components/select/Select.component";
import { AuthRoles } from "../../../models/roles.model";
import UiInputDate from "../../../components/input-date/InputDate.component";
import {
	Gender,
	PreferredLanguage,
	eParticipantStatus,
} from "../../../models/entities/participant.model";
import UiInputCheckbox from "../../../components/input-checkbox/InputCheckbox.component";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { ProviderDetail } from "../../../models/entities/provider.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { Phone } from "../../../models/entities/phone.model";
import { Email } from "../../../models/entities/email.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import { Institution } from "../../../models/entities/institution.model";
import UiInputNumber from "../../../components/input-number/InputNumber.component";
import FormSection from "../../../components/form-section/FormSection.component";
import { ListOption } from "../../../models/misc.model";

const SettingsUsersModal = ({
	isVisible,
	mode,
	entity,
	institutions,
	onSubmit,
	closeModal,
}: {
	institutions: Institution[];
	onSubmit: () => void;
	closeModal: () => void;
} & SettingsUsersCrudModal) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<boolean>(false);

	const [formData, setFormData] = useState<SettingsUsersForm>({
		id: entity?.id || null,
		firstName: entity?.firstName || null,
		lastName: entity?.lastName || null,
		dob: entity?.dob ? new Date(entity.dob) : null,
		gender: entity?.gender || null,
		isActive: entity?.isActive || null,
		isActivelyCoaching: entity?.isActivelyCoaching || false,
		role: entity?.role || null,
		institutionId: entity?.institutionId || 0,
		languagePref: entity?.languagePref || null,
		email1: entity?.emails?.find((x) => x.isPrimary)?.emailAddress || null,
		email2: entity?.emails?.find((x) => !x.isPrimary && !x.deleted)?.emailAddress || null,
		phone1: entity?.phones?.find((x) => x.isPrimary)?.phoneNumber || null,
	});

	const formHasChanged = () =>
		mode === "UPDATE" ? !!Object.keys(setUpdateRequest()).length : true;

	const setUpdateRequest = () => {
		const result: { [x: string]: any } = {};
		const emails: Partial<Email>[] = [];

		for (const key in formData) {
			if (Object.hasOwn(formData, key)) {
				switch (key) {
					case "dob":
						if (
							!entity?.dob ||
							formData.dob!.toISOString() !== new Date(entity.dob).toISOString()
						) {
							result.dob = Formatter.dateUTCWithoutTime(formData.dob)?.toISOString();
						}
						break;
					case "email1":
						const email1 = entity?.emails?.find((x) => x.isPrimary);
						if (email1 && formData.email1 && email1.emailAddress !== formData.email1) {
							// The email has to be updated
							emails.push({
								emailId: email1.emailId,
								emailAddress: formData.email1,
								isPrimary: true,
							});
						} else if (email1 && !formData.email1) {
							// The email has to be deleted
							emails.push({
								emailId: email1.emailId,
								deleted: true,
							});
						} else if (!email1 && formData.email1) {
							// The email has to be created
							emails.push({
								emailId: null,
								emailAddress: formData.email1,
								isPrimary: true,
							});
						}
						break;
					case "email2":
						// Email 2
						const email2 = entity?.emails?.find((x) => !x.isPrimary && !x.deleted);
						if (email2 && formData.email2 && email2.emailAddress !== formData.email2) {
							// The email has to be updated
							emails.push({
								emailId: email2.emailId,
								emailAddress: formData.email2,
								isPrimary: false,
							});
						} else if (email2 && !formData.email2) {
							// The email has to be deleted
							emails.push({
								emailId: email2.emailId,
								deleted: true,
							});
						} else if (!email2 && formData.email2) {
							// The email has to be created
							emails.push({
								emailId: null,
								emailAddress: formData.email2,
								isPrimary: false,
							});
						}

						break;
					case "phone1":
						const phone1 = entity?.phones?.find((x) => x.isPrimary);
						if (formData.phone1 && phone1?.phoneNumber !== formData.phone1) {
							const phones: Partial<Phone>[] = [
								{
									phoneId: phone1?.phoneId || null,
									countryCode: "+1",
									phoneNumber: formData.phone1,
									isPrimary: true,
								},
							];
							// result.contactId = entity?..contactId;
							result.phones = phones;
						}
						break;
					case "institutionId":
						if (entity && formData.institutionId !== entity.institutionId) {
							result.institutionName = institutions.find(
								(x) => x.institutionId === formData.institutionId
							)?.institutionName;
						}
						break;
					default:
						if (
							formData[key as keyof SettingsUsersForm] &&
							formData[key as keyof SettingsUsersForm] !==
								entity![key as keyof ProviderDetail]
						) {
							result[key as keyof SettingsUsersForm] =
								formData[key as keyof SettingsUsersForm];
						}
						break;
				}
			}
		}

		if (emails.length > 0) {
			result.emails = emails;
		}

		return result;
	};

	// Submit
	const handleSubmit = async (
		data: SettingsUsersForm,
		form: FormApi<SettingsUsersForm, SettingsUsersForm>
	) => {
		setMessage(false);
		if (mode === "CREATE") {
			await create();
		} else {
			await update();
		}
	};

	// Create
	const create = async () => {
		await EndpointsService.dataRetriever
			.createProvider({
				body: [
					{
						institutionId: 0,
						status: formData.isActive!,
						firstName: formData.firstName!,
						lastName: formData.lastName!,
						dateOfBirth: Formatter.formatDob(formData.dob)!, // Date without timezone
						gender: formData.gender || null,
						email: formData.email1!,
						email2: formData.email2 || null,
						phoneNumber: formData.phone1 || null,
						roleId: formData.role!,
						isActivelyCoaching: formData.isActivelyCoaching!,
						languagePref: formData.languagePref || null,
					},
				],
			})
			.then((response) => onSubmit())
			.catch((error) => setMessage(true));
	};

	// Update
	const update = async () => {
		await EndpointsService.dataRetriever
			.updateProvider({
				body: {
					coachId: entity?.id!,
					...setUpdateRequest(),
				},
			})
			.then((response) => onSubmit())
			.catch((error) => setMessage(true));
	};

	return (
		<Dialog
			header={t(`SETTINGS.USERS.MODAL_CRUD.TITLE_${mode}`)}
			visible={isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={closeModal}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<FormSection>
							<div className="row">
								{mode === "UPDATE" && (
									<div className="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
										<UiInputNumber
											id="id"
											placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.id"
											label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.id"
											name="id"
											useGrouping={false}
											disabled={true}
										/>
									</div>
								)}

								<div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
									<UiInputText
										id="firstName"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.firstName"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.firstName"
										name="firstName"
										onChange={(e) =>
											setFormData({ ...formData, firstName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
									<UiInputText
										id="lastName"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.lastName"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.lastName"
										name="lastName"
										onChange={(e) =>
											setFormData({ ...formData, lastName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
									<UiInputDate
										id="dob"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.dob"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.dob"
										name="dob"
										onChange={(e) =>
											setFormData({
												...formData,
												dob: e.target.value as Date,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
									<UiSelect
										id="gender"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.gender"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.gender"
										name="gender"
										onChange={(e) =>
											setFormData({ ...formData, gender: e.target.value })
										}
										options={[
											{ id: Gender.F, label: `ENUMS.GENDER.${Gender.F}` },
											{ id: Gender.M, label: `ENUMS.GENDER.${Gender.M}` },
											{
												id: Gender.OTHER,
												label: `ENUMS.GENDER.${Gender.OTHER}`,
											},
										]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
									<UiSelect
										id="languagePref"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.languagePref"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.languagePref"
										name="languagePref"
										onChange={(e) =>
											setFormData({
												...formData,
												languagePref: e.target.value,
											})
										}
										disabled={submitting}
										options={Object.keys(PreferredLanguage).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.LANGUAGE.${x}`,
												})
										)}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
									<UiSelect
										id="isActive"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.isActive"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.isActive"
										name="isActive"
										onChange={(e) =>
											setFormData({ ...formData, isActive: e.target.value })
										}
										options={[
											{
												id: eParticipantStatus.ACTIVE,
												label: `ENUMS.PARTICIPANT_STATUS.${eParticipantStatus.ACTIVE}`,
											},
											{
												id: eParticipantStatus.INACTIVE,
												label: `ENUMS.PARTICIPANT_STATUS.${eParticipantStatus.INACTIVE}`,
											},
										]}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
									<UiInputCheckbox
										id="isActivelyCoaching"
										name="isActivelyCoaching"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.isActivelyCoaching"
										onChange={(e) =>
											setFormData({
												...formData,
												isActivelyCoaching: e.target.value,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
										binary={true}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
									<UiSelect
										id="role"
										placeholder="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.role"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.role"
										name="role"
										onChange={(e) =>
											setFormData({ ...formData, role: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
										options={[
											{
												id: AuthRoles.PROVIDER,
												label: `ENUMS.ROLES.${AuthRoles.PROVIDER}`,
											},
											{
												id: AuthRoles.COACH,
												label: `ENUMS.ROLES.${AuthRoles.COACH}`,
											},
											{
												id: AuthRoles.COACH_COORDINATOR,
												label: `ENUMS.ROLES.${AuthRoles.COACH_COORDINATOR}`,
											},
											{
												id: AuthRoles.ADMIN,
												label: `ENUMS.ROLES.${AuthRoles.ADMIN}`,
											},
										]}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
									<UiInputText
										id="phone1"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.phone1"
										name="phone1"
										prefix="+1"
										onChange={(e) =>
											setFormData({ ...formData, phone1: e.target.value })
										}
										validations={formData.phone1 ? [Validations.numbers] : []}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<UiInputText
										id="email1"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.email1"
										name="email1"
										onChange={(e) =>
											setFormData({ ...formData, email1: e.target.value })
										}
										validations={[Validations.required, Validations.email]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<UiInputText
										id="email2"
										label="SETTINGS.USERS.MODAL_CRUD.FORM_FIELDS.email2"
										name="email2"
										onChange={(e) =>
											setFormData({ ...formData, email2: e.target.value })
										}
										validations={formData.email2 ? [Validations.email] : []}
										disabled={submitting}
									/>
								</div>
							</div>
						</FormSection>

						{message && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label="SETTINGS.USERS.MODAL_CRUD.ERROR_SUBMIT"
								/>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								id="modalBtnCancel"
								label="UI_COMPONENTS.BUTTONS.CANCEL"
								className="p-button-outlined p-button-rounded"
								type="button"
								onClick={closeModal}
								disabled={submitting}
							></UiButton>
							<UiButton
								id="modalBtnSubmit"
								label={
									submitting
										? "UI_COMPONENTS.BUTTONS.SAVING"
										: "UI_COMPONENTS.BUTTONS.SAVE"
								}
								className="p-button-rounded"
								loading={submitting}
								type="submit"
								onClick={handleSubmit}
								disabled={
									submitting || form.getState().invalid || !formHasChanged()
								}
							></UiButton>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};

export default SettingsUsersModal;
