import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import UiTable from "../../../components/table/Table.component";
import { HelperService } from "../../../services/helpers/helper.service";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import {
	TablePagination,
	ITablePagination,
} from "../../../models/components/table/table-pagination.model";
import { ITableHeaderSearch } from "../../../models/components/table/table-search.model";
import { ITableHeaderRefresh } from "../../../models/components/table/table-refresh.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import { CallLogRow } from "../../../models/pages/call-history-row.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { BackendPagination } from "../../../models/pagination.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { CallHistory } from "../../../models/entities/call-history.model";
import { ProviderTwilio } from "../../../models/entities/provider.model";
import { List, ListOption } from "../../../models/misc.model";
import { FilterService } from "../../../services/filter/filter.service";
import {
	ModalEngagementMode,
	UiModalEngagementProps,
} from "../../../models/components/modal-engagement.model";
import UiModalEngagement from "../../../components/modal-engagement/ModalEngagement.component";
import { EngagementDetails, IEngagement } from "../../../models/entities/engagement.model";
import { CallStatus, CallerInfo } from "../../../models/entities/call.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const CallCenterHistory = (props: PageProps) => {
	/* Table */

	// Columns
	const setColumns = (): TableColumn[] => {
		const columns: TableColumn[] = [
			{
				field: "direction",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.direction",
				template: TableColumnTemplate.TAG,
			},
			{
				field: "status",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.status",
				template: TableColumnTemplate.TAG,
			},
			{
				field: "createdOn",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.dateCreated",
				template: TableColumnTemplate.DATE,
				templateOptions: {
					dateFormat: {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
					},
				},
				sortable: true,
				style: {
					width: "170px",
				},
			},
			{
				field: "participant",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.participant",
				sortable: true,
				template: TableColumnTemplate.KANNACT_ID,
				style: {
					width: "150px",
				},
			},
			{
				field: "phone",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.phone",
				template: TableColumnTemplate.PHONE_CALL,
				style: {
					minWidth: "220px",
				},
			},
			{
				field: "engagementTemplate",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.engagementDetails",
				template: TableColumnTemplate.LINK,
			},
			{
				field: "duration",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.duration",
				template: TableColumnTemplate.DURATION,
				sortable: true,
				style: {
					width: "150px",
				},
			},
			{
				field: "twilioAudioTemplate",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.recording",
				template: TableColumnTemplate.TWILIO_AUDIO,
				style: {
					width: "100px",
				},
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column: TableColumn = {
				field: "coachId",
				title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.coach",
				template: TableColumnTemplate.COACH,
				sortable: true,
			};
			columns.splice(5, 0, column);
		}

		return columns;
	};
	const columns: TableColumn[] = setColumns();

	const [rows, setRows] = useState<CallLogRow[]>([]);

	// Filters
	const callDirectionFilter: ITableFilter = {
		field: "callDirection",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "CALL_CENTER.HISTORY.FILTERS.DIRECTION_TITLE",
				options: [
					{ id: "inbound", label: "ENUMS.CALL_DIRECTION.inbound" },
					{ id: "outbound", label: "ENUMS.CALL_DIRECTION.outbound" },
				],
			},
		},
		value: [],
	};
	const statusFilter: ITableFilter = {
		field: "status",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "CALL_CENTER.HISTORY.FILTERS.OUTCOME_TITLE",
				options: [
					{ id: "QUEUED", label: "ENUMS.CALL_STATUS.QUEUED" },
					{ id: "RINGING", label: "ENUMS.CALL_STATUS.RINGING" },
					{ id: "IN_PROGRESS", label: "ENUMS.CALL_STATUS.IN_PROGRESS" },
					{ id: "CANCELED", label: "ENUMS.CALL_STATUS.CANCELED" },
					{ id: "COMPLETED", label: "ENUMS.CALL_STATUS.COMPLETED" },
					{ id: "FAILED", label: "ENUMS.CALL_STATUS.FAILED" },
					{ id: "BUSY", label: "ENUMS.CALL_STATUS.BUSY" },
					{ id: "NO_ANSWER", label: "ENUMS.CALL_STATUS.NO_ANSWER" },
				],
			},
		},
		value: [],
	};
	const engagementFilter: ITableFilter = {
		field: "engagementFilter",
		type: TableFilterType.SELECT_MULTIPLE,
		order: false,
		options: {
			props: {
				label: "CALL_CENTER.HISTORY.FILTERS.ENGAGEMENT_TITLE",
				options: [
					{ id: "NULL", label: "CALL_CENTER.HISTORY.TABLE.ENGAGEMENT_MSG" },
					{ id: "COACHING_SESSION", label: "ENUMS.ENGAGEMENT_DETAILS.COACHING_SESSION" },
					{ id: "TECH_SUPPORT", label: "ENUMS.ENGAGEMENT_DETAILS.TECH_SUPPORT" },
					{ id: "RESCHEDULED", label: "ENUMS.ENGAGEMENT_DETAILS.RESCHEDULED" },
					{ id: "PT_UNENROLLED", label: "ENUMS.ENGAGEMENT_DETAILS.PT_UNENROLLED" },
					{
						id: "CONNECTION_ISSUES",
						label: "ENUMS.ENGAGEMENT_DETAILS.CONNECTION_ISSUES",
					},
					{ id: "INTRO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INTRO_CALL" },
					{ id: "INFO_CALL", label: "ENUMS.ENGAGEMENT_DETAILS.INFO_CALL" },
					{ id: "LEFT_VOICEMAIL", label: "ENUMS.ENGAGEMENT_DETAILS.LEFT_VOICEMAIL" },
					{
						id: "VOICEMAIL_PROBLEM",
						label: "ENUMS.ENGAGEMENT_DETAILS.VOICEMAIL_PROBLEM",
					},
					{ id: "INCORRECT_PHONE", label: "ENUMS.ENGAGEMENT_DETAILS.INCORRECT_PHONE" },
					{ id: "OTHER", label: "ENUMS.ENGAGEMENT_DETAILS.OTHER" },
					{ id: "NONE", label: "CALL_CENTER.HISTORY.TABLE.NON_ENGAGEMENT_MSG" },
				],
			},
		},
		value: [],
	};
	const coachFilter: ITableFilter = {
		field: "coachId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: [`${getSessionStorageUser()?.id}`],
	};
	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["call-center-calls"]) ?? {
			filters: [callDirectionFilter, statusFilter, engagementFilter],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["call-center-calls"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "createdOn",
			sortOrder: -1,
			search: undefined,
			filters: filters?.filters,
		}
	);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Refresh
	const refresh: ITableHeaderRefresh = {
		fn: () => {
			getData(pagination);
		},
	};

	// Search
	const tableSearch: ITableHeaderSearch = {
		title: "CALL_CENTER.HISTORY.SEARCH_PLACEHOLDER",
		fn: (value: string | null | undefined) => {
			// Update pagination state
			const newPagination: TablePagination = {
				...pagination,
				search: value,
				page: 0,
				first: 0,
			};
			setPagination(newPagination);

			StoredCriteriaService.setPagination(
				StoredCriteriaService.KEYS["call-center-calls"],
				newPagination
			);

			// Update data
			getData(newPagination);
			getCount(newPagination);
		},
	};

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["call-center-calls"],
			newPagination
		);

		getData(newPagination);
	};

	/**
	 * MODAL: ENGAGEMENT
	 */

	/**
	 * Participant information
	 * @description When receiving an incoming call, map the FROM phone number with our participant's DB.
	 * When making an outbound call, map the TO phone number with our participant's DB.
	 */
	const getCallinfo = async (parentCallSid: string): Promise<List> => {
		let participantsList: List = [];

		await EndpointsService.dataRetriever
			.getCallInfo({
				config: {
					params: { callSid: parentCallSid },
				},
			})
			.then((response: CallerInfo[]) => {
				if (response?.length) {
					/* We found the phone number in the participants DB */

					// Update the participants list
					const participants: List = response.map((pt) => {
						const item: ListOption = {
							id: pt.id,
							label: `${Formatter.fullName(pt.firstName, pt.lastName)} / ${pt.kannactId}`,
						};
						return item;
					});

					participantsList = participants;
				}
			})
			.catch((error: AxiosError) => {
				console.log("Call: error getting the participants information");
			});

		return participantsList;
	};

	const openModal = async (entity: CallHistory): Promise<void> => {
		let ptList: List = [];
		if (entity.parentCallSid) ptList = await getCallinfo(entity.parentCallSid);

		setModalEngagementProps({
			...modalEngagementsProps,
			patientId: null,
			participantList: ptList,
			entity: {
				callSid: entity.parentCallSid,
				engagementDate: new Date(entity.createdOn),
				outcome: entity.status === CallStatus.COMPLETED,
			},
			isVisible: true,
		});
	};

	const closeModalEngagement = (refresh: boolean, engagement: IEngagement | null): void => {
		setModalEngagementProps({ ...modalEngagementsProps, isVisible: false });

		// Update row template
		if (refresh && engagement) {
			setRows(
				rows.map((row: CallLogRow) => {
					if (row.entity.callSid === engagement.callSid) {
						// Update read value
						const originalEntity = { ...row.entity };
						originalEntity.engagementDetails = engagement.details as EngagementDetails;

						// Create new row
						row = new CallLogRow(originalEntity, openModal.bind(this, originalEntity));
					}
					return row;
				})
			);
		}

		// Refresh to get the updated data (also down the tree)
		if (refresh) {
			getData(pagination);
			getCount(pagination);
		}
	};

	// Modal engagement props
	const [modalEngagementsProps, setModalEngagementProps] = useState<UiModalEngagementProps>({
		edit: false,
		mode: ModalEngagementMode.CALL,
		patientId: null,
		participantList: null,
		entity: {
			callSid: null,
			engagementDate: new Date(),
			outcome: false,
			coachId: getSessionStorageUser().id!,
		},
		isVisible: false,
		closeModal: closeModalEngagement.bind(this),
	});

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);

		// Get filters
		if (
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["call-center-calls"])
				?.filters &&
			HelperService.isAdminViewMode()
		) {
			getFilters();
		}
	}, []);

	// Get data: participants
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };
		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode())
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) || []), coachFilter];

		// Set endpoint (based on tge view mode - coach/admin)
		const endpoint: Promise<CallHistory[]> = HelperService.isAdminViewMode()
			? EndpointsService.dataRetriever.getHistoryAdmin({
					config: { params: new BackendPagination(updatedEvent) },
				})
			: EndpointsService.dataRetriever.getHistory({
					config: { params: new BackendPagination(updatedEvent) },
				});

		await endpoint
			.then((response: CallHistory[]) => {
				if (response?.length > 0)
					setRows(
						response.map((item) => new CallLogRow(item, openModal.bind(this, item)))
					);
				else setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode())
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) || []), coachFilter];

		await EndpointsService.dataRetriever
			.getHistoryCount({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participants: Error getting the count");
			});
	};

	/**
	 * FILTERS
	 */

	// Filters: get options (call a backend endpoint to retreive the coaches list)
	const getFilters = async (): Promise<void> => {
		await EndpointsService.dataRetriever
			.getProvidersTwilio()
			.then((response: ProviderTwilio[]) => {
				// If there is data --> Map it
				if (response?.length > 0) mapFilters(response);
			})
			.catch((error: AxiosError) => {
				console.error("Participants - Couldn't get the providers list");
			});
	};

	// Filters: map
	const mapFilters = (providers: ProviderTwilio[]): void => {
		// Map backend entity
		const providersList: List = providers.map(
			(item) =>
				new ListOption({
					id: item.coachId,
					label: `${item.firstName} ${item.lastName}`,
				})
		);

		// Update filters
		const updatedFilters: ITableFilter[] = [
			{
				field: "coachId",
				type: TableFilterType.SELECT_MULTIPLE,
				options: {
					columnClass: "col-12 col-md-3 col-lg-3",
					props: {
						label: "CALL_CENTER.HISTORY.FILTERS.FROM_TITLE",
						options: providersList,
						filter: true,
					},
				},
				value: [],
			},
			...(filters?.filters as ITableFilter[]),
		];

		setFilters({
			...filters,
			filters: updatedFilters,
		});
		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["call-center-calls"], {
			...filters,
			filters: updatedFilters,
		});
	};

	// Filters: submit
	const submitFilters = (value: TableFilterFormData): void => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);
		setFilters({ ...filters, filters: newFilters });

		// Update table message
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.filter });

		// Update pagination
		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};
		setPagination(newPagination);

		StoredCriteriaService.set(StoredCriteriaService.KEYS["call-center-calls"], newPagination, {
			...filters,
			filters: newFilters,
		});

		// Get data
		getData(newPagination);
		getCount(newPagination);
	};

	return (
		<>
			<UiTable
				useAsCard={true}
				dataKey="sid"
				title={
					HelperService.isAdminViewMode()
						? "CALL_CENTER.HISTORY.TABLE.TITLE_ADMIN"
						: "CALL_CENTER.HISTORY.TABLE.TITLE_COACH"
				}
				columns={columns}
				value={rows}
				message={message}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={onPagination}
				totalRecords={count}
				refresh={refresh}
				search={tableSearch}
				filter={filters}
				filterFn={submitFilters}
			/>

			{/* Modal: engagement */}
			{modalEngagementsProps.isVisible && <UiModalEngagement {...modalEngagementsProps} />}
		</>
	);
};
export default CallCenterHistory;
