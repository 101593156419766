import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "../../models/routing.model";
import UiButton from "../button/Button";
import { NavLink } from "react-router-dom";
import icon from "../../assets/img/kannact-icon.svg";
import { FiLogOut } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import { classNames } from "primereact/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { Roles } from "../../models/roles.model";
import { getSessionStorageUser, setSessionStorageUser } from "../../services/session/auth.service";
import UiDialPad from "../dialpad/Dialpad.component";
import { InputSwitch } from "primereact/inputswitch";
import SidebarResponsive from "../sidebar/sidebar-responsive/SidebarResponsive";
import { SidebarResponsiveProps } from "../../models/components/sidebar-responsive.model";
import { StoredCriteriaService } from "../../services/session/stored-criteria.service";

const HeaderMobile = ({ props }: { props: Route[] }) => {
	const { t } = useTranslation("common");

	/* Quick actions */

	// Toggler
	const [toggleQuickView, setToggleQuickView] = useState<boolean>(false);

	// Swicth view: admin/coach
	const [adminView, setAdminView] = useState<boolean>(
		getSessionStorageUser()?.adminView ?? false
	);

	const { logout } = useAuth0();

	// Switch view: toggler
	const updateViewMode = (event: boolean): void => {
		// Update stored user
		setSessionStorageUser({ ...getSessionStorageUser(), adminView: event });

		// Reload the page
		window.location.reload();
	};

	/* Menu */

	// Props
	const toggleMenu = (visible: boolean): void =>
		setSidebarProps({ ...sidebarProps, isVisible: visible });
	const [sidebarProps, setSidebarProps] = useState<SidebarResponsiveProps>({
		isVisible: false,
		closeModal: toggleMenu.bind(this),
	});

	return (
		<header
			data-testid="header-mobile"
			className="k-header-responsive"
		>
			{/* Header */}
			<div className="header-container">
				{/* Menu: toggler */}
				<UiButton
					className="p-button-text header-menu"
					icon={<FaBars />}
					onClick={() => toggleMenu(true)}
				/>

				{/* Company logo (redirects to /home) */}
				<NavLink
					to="/"
					className="header-logo"
				>
					<img
						src={icon}
						alt={"icon"}
					/>
				</NavLink>

				{/* Quick actions: toggler */}
				<UiButton
					className="p-button-text"
					icon="pi pi-ellipsis-v"
					onClick={() => setToggleQuickView(!toggleQuickView)}
				/>

				{/* Quick actions: panel */}
				<div className={classNames("quick-actions-panel", toggleQuickView && "active")}>
					{/* Switch: admin/coach view */}
					{Roles.isAdmin() && (
						<div className="switch-view quick-action">
							<InputSwitch
								checked={adminView}
								onChange={(e) => {
									setAdminView(e.value);
									updateViewMode(e.value);
								}}
							/>
							<span className="view-mode">
								{t(
									adminView
										? "UI_COMPONENTS.HEADER.ADMIN_VIEW"
										: "UI_COMPONENTS.HEADER.COACH_VIEW"
								)}
							</span>
						</div>
					)}

					{/* Dialpad */}
					{getSessionStorageUser()?.phone && (
						<div className="quick-action">
							<UiDialPad />
						</div>
					)}

					{/* Logout */}
					<div className="quick-action">
						<NavLink
							to="#"
							className="header-logout"
							onClick={() => {
								StoredCriteriaService.deleteAll();
								logout({
									logoutParams: { returnTo: window.location.origin + "/login" },
								});
							}}
						>
							<>
								<FiLogOut className="logout-icon" />
								{t("LOGOUT.TITLE")}
							</>
						</NavLink>
					</div>
				</div>
			</div>

			{/* Sidebar menu */}
			{sidebarProps.isVisible && <SidebarResponsive props={sidebarProps} />}
		</header>
	);
};

export default HeaderMobile;
