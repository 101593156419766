import { Formatter } from "../../services/formatter/formatter.service";
import { HelperService } from "../../services/helpers/helper.service";
import { TableTemplateTag, TableTemplateTranslate } from "../components/table/table-template.model";
import {
	Gender,
	Participant,
	PreferredLanguage,
	eParticipantStatus,
} from "../entities/participant.model";

export interface IParticipantRow {
	id: number;
	coachId: number;
	participantEmail: string;
	kannactId: string;
	name: string;
	status: TableTemplateTag;
	contactPurpose: TableTemplateTranslate | undefined;
	timezone: string | undefined;
	phone: string;
}

export class ParticipantRow implements IParticipantRow {
	id: number;
	coachId: number;
	participantEmail: string;
	kannactId: string;
	name: string;
	status: TableTemplateTag;
	contactPurpose: TableTemplateTranslate | undefined;
	timezone: string | undefined;
	phone: string;

	constructor(params: Participant) {
		this.id = params.id;
		this.coachId = params.coachId;
		this.participantEmail = params.participantEmail;
		this.contactPurpose = HelperService.formatLastContactPurpose(params.contactPurpose);
		this.kannactId = params.kannactId;
		this.name = Formatter.fullName(params.firstName, params.lastName);
		this.status = Formatter.statusTag(params.status);
		this.timezone = params.timezone;
		this.phone = params.phone;
	}
}

export interface ParticipantCreateForm {
	firstName: string | null;
	lastName: string | null;
	institutionId: number | null;
	status: eParticipantStatus | null;
	dateOfBirth: Date | null; // Date
	gender: Gender | null;
	languagePref: PreferredLanguage | null;
	email: string | null;
	phoneNumber: string | null;
}
