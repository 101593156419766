import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import UiTable from "../../../components/table/Table.component";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import { HelperService } from "../../../services/helpers/helper.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { Formatter } from "../../../services/formatter/formatter.service";
import { useTranslation } from "react-i18next";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { BackendPagination } from "../../../models/pagination.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { ListOption } from "../../../models/misc.model";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";
import { FilterService } from "../../../services/filter/filter.service";
import { useNavigate } from "react-router-dom";
import { ParticipantsMessagesRow } from "../../../models/pages/participants-messages.model";

// TODO: refactor to use react-query and add a 5s polling to refresh the data
const ParticipantsMessages = (props: PageProps) => {
	const { t } = useTranslation("common");
	const navigate = useNavigate();

	const [rows, setRows] = useState<ParticipantsMessagesRow[]>([]);

	const [count, setCount] = useState(0);

	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-messages"]) ?? {
			filters: [
				{
					field: "type",
					type: TableFilterType.SELECT_MULTIPLE,
					options: {
						props: {
							label: t("PARTICIPANTS.MESSAGES.FILTERS.TYPE_TITLE"),
							options: [
								{
									id: "CARE_MESSAGE",
									label: t("PARTICIPANTS.MESSAGES.TABLE.VALUES.CARE_MESSAGE"),
								},
								{ id: "SMS", label: t("PARTICIPANTS.MESSAGES.TABLE.VALUES.SMS") },
							],
						},
					},
					value: [],
				},
			],
		}
	);

	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(
			StoredCriteriaService.KEYS["participants-messages"]
		) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "updatedOn",
			sortOrder: -1,
			search: undefined,
			filters: filters.filters,
		}
	);

	useEffect(() => {
		if (
			!StoredCriteriaService.getFilters(
				StoredCriteriaService.KEYS["participants-messages"]
			)?.filters.find((x) => x.field === "coachId") &&
			HelperService.isAdminViewMode()
		) {
			setupCoachFilter();
		}
	}, []);

	useEffect(() => {
		getData(pagination);
	}, [pagination]);

	const setupCoachFilter = async () => {
		const coaches = await EndpointsService.dataRetriever.getProvidersTwilio();

		if (coaches?.length > 0) {
			const coachesListOptions = coaches.map(
				(item) =>
					new ListOption({
						id: item.coachId,
						label: `${item.firstName} ${item.lastName}`,
					})
			);

			const newFilters = {
				filters: [
					{
						field: "coachId",
						type: TableFilterType.SELECT_MULTIPLE,
						options: {
							columnClass: "col-12 col-md-3 col-lg-3",
							props: {
								label: "PARTICIPANTS.MESSAGES.FILTERS.COACH_TITLE",
								options: coachesListOptions,
								filter: true,
							},
						},
						value: [],
					},
					...filters.filters,
				],
			};
			setFilters(newFilters);
			StoredCriteriaService.setFilters(
				StoredCriteriaService.KEYS["participants-messages"],
				newFilters
			);
		}
	};

	const getData = async (event: TablePagination) => {
		const isAdminView = HelperService.isAdminViewMode();
		const coachId = getSessionStorageUser().id;

		setRows([]);
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		const newPagination = {
			...pagination,
			filters: pagination.filters ? [...pagination.filters] : [],
			sortField: HelperService.compoundSortField(getTableColumns(), pagination),
		};

		if (!isAdminView) {
			// if we're not in the admin view, we want to filter the results to only show the coach's participants
			newPagination.filters.push({
				field: "coachId",
				type: TableFilterType.SELECT_MULTIPLE,
				value: [coachId],
			});
		}

		try {
			const queryParams = new BackendPagination(newPagination);

			const [unreadConversations, numUnreadConversations] = await Promise.all([
				EndpointsService.twilio.getUnreadConversations({
					config: {
						params: queryParams,
					},
				}),
				EndpointsService.twilio.getNumUnreadConversations({
					config: {
						params: queryParams,
					},
				}),
			]);

			if (!unreadConversations.length) {
				setMessage({
					severity: FieldMessageSeverity.INFO,
					label: messages.empty,
				});
				return;
			}

			const rows = unreadConversations.map((conversation) => {
				const row: ParticipantsMessagesRow = {
					kannactId: conversation.kannactId,
					name: Formatter.fullName(conversation.firstName, conversation.lastName),
					coachId: conversation.coachId,
					updatedOn: conversation.latestMessageTime,
					type: t(`PARTICIPANTS.MESSAGES.TABLE.VALUES.${conversation.type}`),
					coachUnreadCount: conversation.count,
					actionsTemplate: [
						{
							id: "viewAction",
							className: "p-button-sm p-button-rounded",
							label: "PARTICIPANTS.MESSAGES.TABLE.VALUES.VIEW_ACTION",
							onClick: (e: TableTemplateButtonsEvent) => {
								const kananctId = conversation.kannactId;
								const participantId = HelperService.getParticipantId(kananctId);

								const path = `/participant/${participantId}/messages?type=${conversation.type}`;

								navigate(path);
							},
						},
					],
				};
				return row;
			});

			setRows(rows);
			setCount(numUnreadConversations);
		} catch (err) {
			setMessage({
				severity: FieldMessageSeverity.DANGER,
				label: messages.error,
			});
		}
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="id"
			title={
				HelperService.isAdminViewMode()
					? "PARTICIPANTS.MESSAGES.TABLE.TITLE_ADMIN"
					: "PARTICIPANTS.MESSAGES.TABLE.TITLE_COACH"
			}
			columns={getTableColumns()}
			value={rows}
			message={message}
			isServerPaginated={true}
			pagination={pagination}
			paginationFn={(newPagination: TablePagination) => {
				setPagination({ ...pagination, ...newPagination });
				StoredCriteriaService.setPagination(
					StoredCriteriaService.KEYS["participants-messages"],
					{ ...pagination, ...newPagination }
				);
			}}
			totalRecords={count}
			refresh={{
				fn: () => {
					getData(pagination);
				},
			}}
			search={{
				fn: (value: string | null | undefined) => {
					const newPagination = {
						...pagination,
						search: value,
						page: 0,
						first: 0,
					};

					setPagination(newPagination);
					StoredCriteriaService.setPagination(
						StoredCriteriaService.KEYS["participants-messages"],
						newPagination
					);
				},
			}}
			filter={filters}
			filterFn={(value: TableFilterFormData) => {
				// Update filters state
				const newFilters = FilterService.updateFiltersValues(
					(filters as ITableHeaderFilter).filters,
					value
				);
				setFilters({ ...filters, filters: newFilters });

				// Update pagination
				const newPagination: TablePagination = {
					...pagination,
					filters: newFilters,
					page: 0,
					first: 0,
				};
				setPagination(newPagination);

				// Update stored criteria
				StoredCriteriaService.set(
					StoredCriteriaService.KEYS["participants-messages"],
					newPagination,
					{
						...filters,
						filters: newFilters,
					}
				);

				getData(newPagination);
			}}
		/>
	);
};

const getTableColumns = () => {
	const columns: TableColumn[] = [
		{
			field: "kannactId",
			title: "PARTICIPANTS.MESSAGES.TABLE.COLUMNS.KANNACT_ID",
			template: TableColumnTemplate.KANNACT_ID,
		},
		{
			field: "name",
			title: "PARTICIPANTS.MESSAGES.TABLE.COLUMNS.NAME",
			sortable: true,
			sortingField: "firstName,lastName",
		},
		{
			field: "updatedOn",
			title: "PARTICIPANTS.MESSAGES.TABLE.COLUMNS.LATEST_MESSAGE_TIME",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "type",
			title: "PARTICIPANTS.MESSAGES.TABLE.COLUMNS.TYPE",
		},
		{
			field: "coachUnreadCount",
			title: "PARTICIPANTS.MESSAGES.TABLE.COLUMNS.COUNT",
			sortable: true,
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			alignment: "right",
		},
	];

	if (HelperService.isAdminViewMode()) {
		const coachColumn: TableColumn = {
			field: "coachId",
			template: TableColumnTemplate.COACH,
			title: "CALL_CENTER.HISTORY.TABLE.COLUMNS.coach",
			sortable: true,
		};

		columns.splice(2, 0, coachColumn);
	}

	return columns;
};

export default ParticipantsMessages;
