import { UiTableProps } from "./table.model";

export interface ITableHeaderSearch {
	title?: string;
	tooltip?: string;
	placeholder?: string;
	value?: string | null;
	fn?: (value: string | null | undefined) => void;
}

export class TableHeaderSearch implements ITableHeaderSearch {
	title: string;
	tooltip?: string;
	placeholder: string;
	value?: string | null;
	fn?: (value: string | null | undefined) => void;

	constructor(params: UiTableProps) {
		this.title = params.search?.title ?? "UI_COMPONENTS.TABLE.ACTIONS.SEARCH";
		this.tooltip = params.search?.tooltip;
		this.placeholder =
			params.search?.placeholder ?? "UI_COMPONENTS.TABLE.ACTIONS.SEARCH_PLACEHOLDER";
		this.value = params.search?.value;
		this.fn = params.search?.fn;
	}
}

/**
 * Table search component
 */

export interface UiTableHeaderSearchProps {
	value: string | null;
	placeholder: string;
	searchFn: (value: string | null) => void;
}
