import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateTag,
	TableTemplateTwilioAudio,
} from "../components/table/table-template.model";
import { RecordingEntity } from "../entities/call-voicemail.model";

export interface IRecordingRow {
	id: number;
	startTime: Date;
	duration: number; // Number in seconds
	participant: string; // Kannact ID
	phone: string;
	coachId: number;
	alreadySeen: boolean;
	alreadySeenTemplate: TableTemplateTag;
	recording: string;
	twilioAudioTemplate: TableTemplateTwilioAudio;
	actionsTemplate: TableTemplateButtons;
	entity: RecordingEntity;
}

export class RecordingRow implements IRecordingRow {
	id: number;
	startTime: Date;
	duration: number; // Number in seconds
	participant: string; // Kannact ID
	phone: string;
	coachId: number;
	alreadySeen: boolean;
	alreadySeenTemplate: TableTemplateTag;
	recording: string;
	twilioAudioTemplate: TableTemplateTwilioAudio;
	actionsTemplate: TableTemplateButtons;
	entity: RecordingEntity;

	constructor(params: RecordingEntity, actionFn: any) {
		this.id = params.id;
		this.startTime = params.startTime;
		this.duration = params.duration;
		this.participant = params.participant;
		this.phone = params.fromnumber;
		this.coachId = params.coachId;
		this.alreadySeen = params.alreadySeen;
		this.alreadySeenTemplate = this.setMarkedAsReadTemplate(params.alreadySeen);
		this.recording = params.mediaUrl;
		this.twilioAudioTemplate = this.setRecording(params);
		this.actionsTemplate = this.setActionsTemplate(params, actionFn);
		this.entity = params;
	}

	private setMarkedAsReadTemplate = (paramValue: boolean): TableTemplateTag => {
		const value: TableTemplateTag = {
			severity: paramValue ? "success" : "danger",
			value: paramValue
				? "CALL_CENTER.VOICEMAIL.STATUS.true"
				: "CALL_CENTER.VOICEMAIL.STATUS.false",
			uppercase: true,
			rounded: true,
		};
		return value;
	};

	private setActionsTemplate = (params: RecordingEntity, actionFn: any): TableTemplateButtons => [
		{
			id: "readAction",
			className: !params.alreadySeen
				? "p-button-xs p-button-rounded"
				: "p-button-outlined p-button-xs p-button-rounded",
			label: !params.alreadySeen
				? "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.actionsList.resolve"
				: "CALL_CENTER.VOICEMAIL.TABLE.COLUMNS.actionsList.unresolve",
			disabled: false,
			onClick: (e: TableTemplateButtonsEvent) => {
				actionFn(e);
			},
		},
	];

	private setRecording = (params: RecordingEntity): TableTemplateTwilioAudio => {
		return {
			callId: params.callSid,
			callDate: params.createdOn,
			kannactId: params.participant,
			mediaUrl: params.mediaUrl,
		};
	};
}
