import { TableColumn } from "../../models/components/table/table-column.model";
import { ITablePagination } from "../../models/components/table/table-pagination.model";
import { TableTemplateTranslate } from "../../models/components/table/table-template.model";
import { ParticipantProfile, PrincipalMethod } from "../../models/entities/participant.model";
import { Roles } from "../../models/roles.model";
import { getSessionStorageUser } from "../session/auth.service";

/**
 * View mode: admin/coach
 * @description Returns true if the user is in admin view, and false if it's coach view
 */
const isAdminViewMode = (): boolean =>
	(Roles.isAdmin() || Roles.isProvider()) && getSessionStorageUser()?.adminView;

/**
 * Sort: compound field
 * @description When a table column value is the result of multiple fields,
 * we have to get the multiple fields that compose this column.
 * Ex:
 * Name: first name + last name
 */
const compoundSortField = (
	columns: TableColumn[],
	pagination: ITablePagination
): string | undefined => {
	let compoundSortField: string | undefined = undefined;

	// Find the column:
	const column = columns.find((column) => column.field === pagination.sortField);
	compoundSortField = column?.sortingField ?? pagination.sortField;
	return compoundSortField;
};

const isKannactId = (value: string): boolean => value?.length >= 6 && value?.length <= 9;

/**
 * Participant ID from Kannact ID
 * @description Given a Kannact ID, get it's ID
 */
const getParticipantId = (kannactId: string) => kannactId.substring(3);

/**
 * Participant: Last Contact Purpose
 */
const formatLastContactPurpose = (value: string): TableTemplateTranslate | undefined => {
	let result: TableTemplateTranslate | undefined;

	// Map value to the only know descriptions
	if (
		value === "NONE" ||
		value === "CALL_LOG_COACHING_PHONE_CALL" ||
		value === "ENGAGE" ||
		value === "ENGAGED"
	) {
		result = { key: `ENUMS.CONTACT_PURPOSE.${value}` };
	} else if (value.startsWith("RO_")) {
		result = {
			key: "ENUMS.CONTACT_PURPOSE.RO_",
			params: { attempt: value.slice(3) },
		};
	} else if (value) {
		result = { key: value };
	}

	return result;
};

// Participant: principal phone
const getPrincipalPhone = (participant: ParticipantProfile, prefix: boolean) => {
	let result: string | null =
		participant.principal?.find((x) => x.method === PrincipalMethod.PHONE)?.username ?? null;
	if (result && !prefix) {
		// Remove prefix
		result = result.replace("+1", "");
	}

	return result;
};

// Participant: principal email
const getPrincipalEmail = (participant: ParticipantProfile) =>
	participant.principal?.find((x) => x.method === PrincipalMethod.EMAIL)?.username ?? null;

export const HelperService = {
	compoundSortField,
	isKannactId,
	getParticipantId,
	formatLastContactPurpose,
	isAdminViewMode,
	getPrincipalPhone,
	getPrincipalEmail,
};
