import { getSessionStorageUser } from "./auth.service";

export const hasValidRoles = (deniedRoles?: number[], allowedRoles?: number[]): boolean => {
	let result: boolean = false;
	const userRole = getSessionStorageUser()?.role;

	if (!deniedRoles && !allowedRoles) {
		/**
		 * CASE: There are no restrictions
		 *
		 * ALLOWED IF: Everybody is allowed
		 */
		result = true;
	} else if (userRole && deniedRoles && !allowedRoles && !deniedRoles.includes(userRole)) {
		/**
		 * CASE: There is a denied list, but there is not an allowed list. Everybody is allowed except
		 * the ones in the denied list
		 *
		 * ALLOWED IF:
		 * - You're not in the denied list
		 */
		result = true;
	} else if (userRole && !deniedRoles && allowedRoles && allowedRoles.includes(userRole)) {
		/**
		 * CASE: There is an allowed list, but there is not a denied list. Only the allowed ones
		 * can access
		 *
		 * ALLOWED IF:
		 * - You're in the allowed list
		 */
		result = true;
	} else if (
		userRole &&
		deniedRoles &&
		allowedRoles &&
		!deniedRoles.includes(userRole) &&
		allowedRoles.includes(userRole)
	) {
		/**
		 * CASE: There is an allowed and a denied list. You're only allowed if you are in the allowed list
		 * but not in the denied list
		 *
		 * ALLOWED IF:
		 * - You're in the allowed list
		 * AND
		 * - You're not in the denied list
		 */
		result = true;
	}

	return result;
};

export const RolesService = {
	hasValidRoles,
};
