import { useTranslation } from "react-i18next";
import { ObjectUtils, classNames } from "primereact/utils";
import UiFieldErrors from "../field-errors/FieldErrors.component";
import { Field, FieldInputProps } from "react-final-form";
import UiFieldLabel from "../field-label/FieldLabel.component";
import { Validations } from "../../services/form/validations.service";
import { UiInputCheckboxProps } from "../../models/components/input-checkbox.model";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { List, ListOptionValue } from "../../models/misc.model";

const UiInputCheckbox = (props: UiInputCheckboxProps) => {
	const { t } = useTranslation("common");

	const optionsList: List =
		props.options?.map((item) => {
			item.label = t(item.label.toString());
			return item;
		}) ?? [];

	/**
	 * Remove from the props the ones that need special treatment
	 * (like the placholder that needs to be translated, etc.).
	 */
	const otherProps = ObjectUtils.findDiffKeys(props, {
		placeholder: props?.placeholder,
		onChange: props.onChange,
		onBlur: props.onBlur,
		name: props.name,
		value: props.value,
		className: props.className,
		binary: props.binary?.toString(),
		removeBottomSpacer: props.removeBottomSpacer,
	});

	// Use a custom onChange function passed as a prop
	const handleChange = (
		event: CheckboxChangeParams,
		input: FieldInputProps<any, HTMLElement>,
		selectedItem: ListOptionValue
	): void => {
		if (props.binary) {
			// Value is boolean
			event.value = event.checked;
			event.target.value = event.checked;
		} else {
			// Value is an array
			let tmpValue: ListOptionValue[] = event.value;
			if (event.checked) {
				tmpValue.push(selectedItem);
			} else {
				tmpValue = tmpValue.filter((item) => item !== selectedItem);
			}

			event.value = tmpValue;
			event.target.value = tmpValue;
		}

		/**
		 * Force onBlur propagation (final-form library):
		 * meta.touched is only updated with the onBlur event, but we need it to be updated also with the onChange event
		 */
		// if (input) input.onChange(event);
		// input.onBlur();

		// Execute onChange callback function
		if (props.onChange) props.onChange(event);
	};

	const isChecked = (
		formControlValue: boolean | string[] | undefined | null,
		id: ListOptionValue
	): boolean => {
		let result = false;

		if (props.binary) {
			// Binary
			result = (formControlValue as boolean) || false;
		} else if (formControlValue instanceof Array) {
			result = formControlValue.includes(id.toString());
		}

		return result;
	};

	return (
		<Field
			name={props.name}
			validate={Validations.composeValidators(props.validations)}
			render={({ input, meta }) => (
				<div
					className={classNames("k-input-container", props.removeBottomSpacer && "mb-0")}
				>
					<div
						className={classNames(
							"k-inputcheckbox-container",
							props.binary ? "chbx-binary" : "chbx-group",
							props.label && "has-label"
						)}
					>
						<UiFieldLabel
							id={props.id}
							label={props.label}
							disabled={props.disabled}
							meta={meta}
							validations={props.validations}
						>
							{/* Checkbox: binary */}
							{props.binary && (
								<Checkbox
									inputId={props.id}
									placeholder={
										props?.placeholder ? t(props.placeholder) : undefined
									}
									className={classNames(
										{ "p-invalid": !!(meta.touched && meta.error) },
										props.className
									)}
									name={input.name}
									value={input.value}
									onChange={(event) => handleChange(event, input, props.id)}
									checked={input.value}
									disabled={props.disabled}
									{...otherProps}
								/>
							)}

							{/* Checkbox: group */}
							{!props.binary &&
								optionsList.map((item) => (
									<div
										key={item.id.toString()}
										className="checkbox-item"
									>
										<Checkbox
											inputId={item.id.toString()}
											type="checkbox"
											placeholder={
												props?.placeholder
													? t(props.placeholder)
													: undefined
											}
											className={classNames(
												{ "p-invalid": !!(meta.touched && meta.error) },
												props.className
											)}
											name={input.name}
											value={input.value}
											onChange={(event) =>
												handleChange(event, input, item.id)
											}
											checked={isChecked(input.value, item.id)}
											disabled={props.disabled || item.disabled}
											{...otherProps}
										/>
										<label
											htmlFor={item.id.toString()}
											className={classNames(
												"p-checkbox-label",
												item.className
											)}
										>
											{t(item.label.toString())}
										</label>
									</div>
								))}
						</UiFieldLabel>
					</div>

					{/* Validation messages */}
					<UiFieldErrors {...meta}></UiFieldErrors>
				</div>
			)}
		/>
	);
};

export default UiInputCheckbox;
