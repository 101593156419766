import { PageProps } from "../../../models/routing.model";
import UiCard from "../../../components/card/Card";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import BiometricsCreate from "./BiometricsCreate";
import UiButton from "../../../components/button/Button";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

const ParticipantBiometrics = (props: PageProps) => {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();

	// Navigation / URL params
	const navigate = useNavigate();
	const urlParams = useParams<{ participantId: string }>();

	// Create
	const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

	return (
		<UiCard
			title="PARTICIPANT.BIOMETRICS.TITLE"
			titleUnderline={false}
			customHeader={
				<UiButton
					type="button"
					className="p-button-rounded p-button-sm"
					icon={"pi pi-plus"}
					label="PARTICIPANT.BIOMETRICS.CREATE.CREATE"
					onClick={() => setModalIsVisible(true)}
				/>
			}
		>
			<TabMenu
				activeIndex={
					window.location.pathname.includes("report")
						? 2
						: window.location.pathname.includes("charts")
							? 1
							: 0
				}
				model={[
					{
						id: "list",
						label: t("PARTICIPANT.BIOMETRICS.TAB_TITLE_LIST"),
						command: (e) =>
							navigate(
								`/participant/${urlParams.participantId}/biometrics/${e.item.id}`,
								{
									replace: true,
								}
							),
					},
					{
						id: "charts",
						label: t("PARTICIPANT.BIOMETRICS.TAB_TITLE_CHARTS"),
						command: (e) =>
							navigate(
								`/participant/${urlParams.participantId}/biometrics/${e.item.id}`,
								{
									replace: true,
								}
							),
					},
					{
						id: "report",
						label: t("PARTICIPANT.BIOMETRICS.TAB_TITLE_REPORT"),
						command: (e) =>
							navigate(
								`/participant/${urlParams.participantId}/biometrics/${e.item.id}`,
								{
									replace: true,
								}
							),
					},
				]}
			/>

			{modalIsVisible && (
				<BiometricsCreate
					participantId={Number(urlParams.participantId)}
					isVisible={modalIsVisible}
					onComplete={() => {
						queryClient.invalidateQueries([
							"biometrics",
							{ participantId: Number(urlParams.participantId) },
						]);
						queryClient.invalidateQueries([
							"biometric-goals",
							{ participantId: Number(urlParams.participantId) },
						]);
						setModalIsVisible(false);
					}}
					onClose={() => setModalIsVisible(false)}
				/>
			)}

			<Outlet />
		</UiCard>
	);
};
export default ParticipantBiometrics;
