import { useEffect, useState } from "react";
import { CareMessageClientStatus } from "../../../../models/pages/care-messages.model";
import TwilioConversationService from "../../../../services/twilio/twilio-conversations.service";
import { ParticipantProfile } from "../../../../models/entities/participant.model";
import { Client } from "@twilio/conversations";
import MessagesClientState from "../MessagesClientState";
import ParticipantCareMessages from "./CareMessages";

const ParticipantCareMessagesClient = ({ participant }: { participant: ParticipantProfile }) => {
	const [clientState, setClientState] = useState<CareMessageClientStatus | null>(
		CareMessageClientStatus.LOADING
	);

	useEffect(() => {
		// Listen to the SDK client changes
		const clientSubscription = TwilioConversationService.clientSubject.subscribe(
			(event: Client | null) => {
				console.log("clientSubscription: ", event, event?.connectionState);
				if (event && event.connectionState === "connected") {
					setClientState(CareMessageClientStatus.SUCCESS);
				} else if (
					event?.connectionState === "denied" ||
					event?.connectionState === "disconnected" ||
					event?.connectionState === "disconnecting" ||
					event?.connectionState === "error"
				) {
					setClientState(CareMessageClientStatus.ERROR);
				}
			}
		);

		// Remove listeners
		return () => {
			if (clientSubscription) clientSubscription.unsubscribe();
		};
	}, []);

	return (
		<>
			{/* Twilio client */}
			{clientState === CareMessageClientStatus.LOADING ||
			clientState === CareMessageClientStatus.ERROR ? (
				<MessagesClientState clientState={clientState} />
			) : (
				<ParticipantCareMessages participant={participant} />
			)}
		</>
	);
};
export default ParticipantCareMessagesClient;
