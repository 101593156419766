import { useTranslation } from "react-i18next";
import { Formatter } from "../../../../../services/formatter/formatter.service";
import { LongTermGoal } from "../../../../../models/custom-goals/custom-goals";

const LongTermGoalDetails = ({ entity }: { entity: LongTermGoal }) => {
	const { t } = useTranslation("common");

	return (
		<ul className="custom-goals-modal__details">
			<li>
				<label className="field">
					{t(
						"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.LONG_TERM_GOALS_DETAILS.motivations"
					)}
				</label>
				<span className="value">{entity.motivations || Formatter.NO_DATA}</span>
			</li>
			<li>
				<label className="field">
					{t("PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.LONG_TERM_GOALS_DETAILS.barriers")}
				</label>
				<span className="value">{entity.barriers || Formatter.NO_DATA}</span>
			</li>
			{entity.reason && (
				<li>
					<label className="field">
						{t(
							"PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.LONG_TERM_GOALS_DETAILS.reason"
						)}
					</label>
					<span className="value">{entity.reason || Formatter.NO_DATA}</span>
				</li>
			)}
		</ul>
	);
};
export default LongTermGoalDetails;
