import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// App
import "./index.sass";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Translations
import en from "./assets/i18n/en-US.json";
import es from "./assets/i18n/es-ES.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { getBrowserLang } from "./services/i18n/i18n.service";
import { locale, addLocale } from "primereact/api";

// i18n: Internationalization
i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: getBrowserLang(), // language to use
	fallbackLng: "en",
	supportedLngs: ["en", "es"],
	resources: {
		en: {
			common: en,
		},
		es: {
			common: es,
		},
	},
});

// Primereact: add translations used internally by some components (calendar, table, etc.)
addLocale("es", es.UI_COMPONENTS.PRIME_REACT);

// Use the browser language. Otherwise use the fallback language ('en')
locale(!i18next.languages.includes(i18next.language) ? "en" : i18next.language);

// Render primary component
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	//<React.StrictMode> {/* Makes every component instantiate twice */}
	<I18nextProvider i18n={i18next}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</I18nextProvider>
	// </React.StrictMode>
);

reportWebVitals();
