import { useContext, useState } from "react";
import NotesHistory from "./notes/history/NotesHistory";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { useNextAppointment } from "../../../../hooks/useNextAppointment";
import UiCard from "../../../../components/card/Card";
import { useParticipantNotes } from "../../../../hooks/useParticipantNotes";
import NoteForm from "./notes/form/NoteForm";
import { Note, NoteTopics } from "../../../../models/notes/note";
import { useSmartGoals } from "../../../../hooks/custom-goals/useSmartGoals";
import { useLongTermGoals } from "../../../../hooks/custom-goals/useLongTermGoals";

const ParticipantNotes = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const participantId = Number(participantContext.participant!.id);

	// Notes history: filter
	const [topicsFilter, setTopicsFilter] = useState<{ filter: NoteTopics[] }>({ filter: [] });

	// Participant notes
	const notesQuery = useParticipantNotes({ participantId });

	// Participant goals
	const smartGoalsQuery = useSmartGoals(participantId);
	const longTermGoalsQuery = useLongTermGoals(participantId);

	// Editing note
	const [updatingNote, setUpdatingNote] = useState<Note | null>(null);

	// Coaching note: Next appointment
	const ptEmail =
		participantContext.participant!.emails?.filter((x) => x.isPrimary)[0]?.emailAddress ||
		undefined;
	const nextAppointment = useNextAppointment({
		kannactId: participantContext.participant!.kannactId,
		email: ptEmail,
	});

	return (
		<UiCard className="participant-notes">
			<div className="row">
				{/* Note: CREATE/UPDATE */}
				<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<NoteForm
						participant={participantContext.participant!}
						note={updatingNote}
						nextAppointmentQuery={nextAppointment}
						smartGoalsQuery={smartGoalsQuery}
						longTermGoalsQuery={longTermGoalsQuery}
						onSubmit={() => {
							setUpdatingNote(null);
							notesQuery.invalidate();
							smartGoalsQuery.invalidate();
							longTermGoalsQuery.invalidate();
						}}
						onCancel={() => setUpdatingNote(null)}
					/>
				</div>

				{/* Notes: history */}
				<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 participant-notes-history">
					<NotesHistory
						topicsFilter={topicsFilter}
						updatingNote={updatingNote}
						notesQuery={notesQuery}
						smartGoalsQuery={smartGoalsQuery}
						onUpdate={(e) => setUpdatingNote(e)}
						onTopicsFilter={(e: NoteTopics[]) => setTopicsFilter({ filter: e })}
					/>
				</div>
			</div>
		</UiCard>
	);
};

export default ParticipantNotes;
