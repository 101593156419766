import { DataTableProps } from "primereact/datatable";
import { TableColumn } from "./table-column.model";
import { TablePagination } from "./table-pagination.model";
import { ITableHeaderRefresh } from "./table-refresh.model";
import { ITableHeaderSearch } from "./table-search.model";
import { ITableHeaderCrud } from "./table-crud.model";
import { TableFilterFormData, ITableHeaderFilter } from "./table-filter.model";
import { TableMessage } from "./table-message.model";

export enum TableStyle {
	DEFAULT = "DEFAULT",
	GREEN = "GREEN",
	NESTED = "NESTED",
}

export interface UiTableProps extends DataTableProps {
	useAsCard?: boolean;
	columns: TableColumn[];
	tableTitle?: string;
	customHeader?: React.ReactNode;
	type?: TableStyle;

	emptyMessage?: React.ReactNode;

	// Message to show (when there's no data, or there was an error, etc.)
	message?: TableMessage;

	// Pagination state (client-side and server-side)
	pagination: TablePagination;

	// Server side pagination
	isServerPaginated?: boolean;
	paginationFn?: (event: TablePagination) => void;

	// Refresh
	refresh?: ITableHeaderRefresh;

	// Search (client-side and server-side)
	search?: ITableHeaderSearch;

	// CRUD
	crud?: ITableHeaderCrud;

	// Filter
	filter?: ITableHeaderFilter;
	filterFn?: (value: TableFilterFormData) => void;

	// Expandable rows
	expandedTemplate?: (rowData: any) => React.ReactNode;
}
