import { eEngagementDetails, engagementType } from "./engagement.model";
import { CallStatus } from "./call.model";

export enum CallHistoryDirection {
	OUTBOUND = "outbound",
	INBOUND = "inbound",
}

export interface CallHistory {
	id: number;
	callSid: string;
	parentCallSid: string;
	callDirection: CallHistoryDirection;
	status: CallStatus;
	createdOn: string; // Date
	coachId: number;
	participant: string; // kannactId
	duration: number; // Seconds
	engagementDetails: eEngagementDetails;
	engagementType: engagementType;
	fromNumber: string;
	toNumber: string;
}
