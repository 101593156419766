import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { MedicationsFormatter } from "../../../../services/medications/medications-formatter.service";

export const useLatestActiveMedications = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["latest-active-medications", { participantId }];

	const query = useQuery(queryKey, async () => {
		const latestActiveMedications = await EndpointsService.dataRetriever
			.getMedicationsLatest({
				config: {
					params: {
						participantId,
						onlyActive: true,
					},
				},
			})
			.then(MedicationsFormatter.sortMedications);

		return latestActiveMedications;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
