import { Call, Device } from "@twilio/voice-sdk";
import { TwilioError } from "@twilio/voice-sdk/es5/twilio/errors";
import { CallerInfo } from "../entities/call.model";
import { EngagementDetails } from "../entities/engagement.model";
import { UiFieldMessageProps } from "./field-message.model";

export interface CallPresenterProps {
	callPresenter: CallPresenter;
}

export type CallPresenterEngagementForm = {
	participant: number | null;
	outcome: boolean | null;
	details: EngagementDetails | null;
	other: string | null;
	formMessage?: UiFieldMessageProps;
};

export interface CallData {
	id: string;
	fromNumber: string;
	toNumber: string;
	timer?: number;
	device?: Device;
	call?: Call;
	callerInfo?: CallerInfo[];
	durationInterval?: any;
	updateState: (id: string) => void;
	hangupOtherCalls: (activeCallSid: string) => void;
}

export default class CallPresenter {
	// State
	state: CallData;

	constructor(params: CallData) {
		this.state = params;

		/* Bind functions */

		// Call actions
		this.startDuration = this.startDuration.bind(this);
		this.stopDuration = this.stopDuration.bind(this);
		this.reject = this.reject.bind(this);
		this.mute = this.mute.bind(this);
		this.hangUp = this.hangUp.bind(this);
		this.extension = this.extension.bind(this);
		this.done = this.done.bind(this);
		this.answer = this.answer.bind(this);

		// Call listeners
		this.setCallListeners = this.setCallListeners.bind(this);
		this.removeListener = this.removeListener.bind(this);
		this.removeAllListeners = this.removeAllListeners.bind(this);
		this.callEventRinging = this.callEventRinging.bind(this);
		this.callEventConnect = this.callEventConnect.bind(this);
		this.callEventReconnecting = this.callEventReconnecting.bind(this);
		this.callEventAccept = this.callEventAccept.bind(this);
		this.callEventDisconnect = this.callEventDisconnect.bind(this);
		this.callEventReject = this.callEventReject.bind(this);
		this.callEventCancel = this.callEventCancel.bind(this);
		this.callEventError = this.callEventError.bind(this);
	}

	// Duration: When a call starts, initialize the call duration. When a call ends, stop the duration count.
	startDuration = () => {
		let duration: number = 0;
		this.state.durationInterval = setInterval(() => {
			duration++;
			this.state.timer = duration;
			this.state.updateState(this.state.id);
		}, 1000);
		this.state.updateState(this.state.id);
	};

	stopDuration = () => {
		clearInterval(this.state.durationInterval);
		this.state.durationInterval = undefined;
		this.state.updateState(this.state.id);
	};

	// Hang up:Hang up an active call
	hangUp = (): void => {
		this.state.call?.disconnect();
		this.state.updateState(this.state.id);
	};

	// Mute: Mute an active call
	mute = () => {
		if (this.state.call) {
			this.state.call?.mute(!this.state.call.isMuted());
			this.state.updateState(this.state.id);
		}
	};

	// Send digits: An an extension on an active call
	extension = (call: Call, digits: string) => {
		if (call && digits) call.sendDigits(digits);
	};

	// Answer: Answer an incoming call
	answer = (): void => {
		this.state.call?.accept();
		this.state.hangupOtherCalls(this.state.id);
		this.state.updateState(this.state.id);
	};

	// Reject: Don't answer an incoming call and reject it
	reject = (): void => {
		this.state.call?.reject();
		this.state.updateState(this.state.id);
	};

	// Done: After a call has ended, close and hide the call management bar
	done = () => {
		this.state.call = undefined;
		this.state.updateState(this.state.id);
	};

	// Call events: Manage the different call events
	setCallListeners = (): void => {
		if (this.state.call) {
			this.state.call.on("ringing", this.callEventRinging);
			this.state.call.on("connect", this.callEventConnect);
			this.state.call.on("reconnecting", this.callEventReconnecting);
			this.state.call.on("accept", this.callEventAccept);
			this.state.call.on("disconnect", this.callEventDisconnect);
			this.state.call.on("cancel", this.callEventCancel);
			this.state.call.on("reject", this.callEventReject);
			this.state.call.on("error", this.callEventError);
		}
	};

	// Event: ringing. This event is triggered when there the user is making an outgoing call
	callEventRinging = (event: boolean): void => console.log("Call event: ringing: ", event);

	// Event: connect. This event is triggered when there's an outgoing call, the has been placed, but it hasn't been answered yet
	callEventConnect = (event: Call): void => {
		console.log("Call event: connect: ", event);
		// Update the state
		this.state.call = event;
		this.state.updateState(this.state.id);
	};

	// Event: reconnecting. This event is triggered when the Call has lost media connectivity and is reconnecting.
	callEventReconnecting = (event: any): void => console.log("Call event: reconnecting: ", event);

	// Event: accept. This event is triggered when the call (incoming or outgoing) has been answered
	callEventAccept = (event: Call): void => {
		console.log("Call event: accept: ", event);
		// Start the duration timer
		this.startDuration();
		// Update the state
		this.state.call = event;
		this.state.updateState(this.state.id);
	};

	// Event: disconnect. This event is triggered when a call has been ansered, and someone hangs up
	callEventDisconnect = (event: Call): void => {
		console.log("Call event: disconnect: ", event);
		// Stop the duration timer
		this.stopDuration();
		// Call ended --> Remove listeners
		this.removeAllListeners();
		// Update state
		this.state.call = event;
		this.state.updateState(this.state.id);
	};

	// Event: cancel. This event is triggered when there's an incoming call, and the caller hangs up (before the call was answered)
	callEventCancel = (): void => {
		console.log("Call event: cancel");
		// Call ended --> Remove listeners
		this.removeAllListeners();
		// Update state
		this.state.call = undefined;
		this.state.updateState(this.state.id);
	};

	// Event: reject. This event is triggered when there's an incoming call, and the coach rejects it (without answering)
	callEventReject = (): void => {
		console.log("Call event: reject");
		// Call ended --> Remove listeners
		this.removeAllListeners();
		// Update state
		this.state.call = undefined;
		this.state.updateState(this.state.id);
	};

	// Event: error. This event is triggered when the call recieves an error
	callEventError = (event: TwilioError): void => console.log("Call event: error: ", event);

	/**
	 * Remove call listeners:
	 * We don't use the method ".removeAllListeners()" because some events listeners are settled internally by the Twilio SDK:
	 * The listeners settled internally are:
	 * cancel --> x1 listener
	 * accept --> x2 listeners
	 * disconnect --> x2 listeners
	 * error --> x2 listeners
	 */
	removeListener = (listenerName: string, listenerFn: any): void => {
		this.state.call?.removeListener(listenerName, listenerFn);
	};

	// Remove all call listeners
	removeAllListeners = (): void => {
		if (this.state.call) {
			// Remove all the call listeners
			this.removeListener("ringing", this.callEventRinging);
			this.removeListener("connect", this.callEventConnect);
			this.removeListener("reconnecting", this.callEventReconnecting);
			this.removeListener("accept", this.callEventAccept);
			this.removeListener("disconnect", this.callEventDisconnect);
			this.removeListener("cancel", this.callEventCancel);
			this.removeListener("reject", this.callEventReject);
			this.removeListener("error", this.callEventError);
		}
	};
}
