import {
	BiometricsCreateEntry,
	BiometricsLatest,
} from "../../models/endpoints/biometrics-endpoint.model";
import { Biometric, BiometricType } from "../../models/entities/biometrics.model";
import { Goal } from "../../models/entities/goal.model";
import { Source } from "../../models/entities/source.model";
import { CHART_DATA, CHART_FILTER } from "../../models/pages/biometrics/biometrics-charts.model";
import { BiometricRow, IBiometricData } from "../../models/pages/biometrics/biometrics-modal.model";
import {
	BiometricValueTemplateBloodPressure,
	BiometricValueTemplateCholesterol,
	BiometricValueTemplateGlucose,
	BiometricValueTemplateHeight,
} from "../../models/pages/biometrics/biometrics-templates.model";
import {
	BiometricsEditProps,
	BiometricUpdateBody,
	BiometricUpdateForm,
} from "../../models/pages/biometrics/biometrics-update.model";
import { Formatter } from "../formatter/formatter.service";
import { BiometricsFormatter } from "./biometrics-formatter.service";
import { BiometricsTemplate } from "./biometrics-template.service";

const getLatestValues = (biometricsResponse: Map<string, Biometric[]>): BiometricsLatest => {
	return {
		hemoglobinA1c: biometricsResponse?.get("A1C")?.[0] ?? null,
		bloodPressure: biometricsResponse?.get("BLOOD_PRESSURE")?.[0] ?? null,
		glucose: biometricsResponse?.get("GLUCOSE")?.[0] ?? null,
		weight: biometricsResponse?.get("WEIGHT")?.[0] ?? null,
		height: biometricsResponse?.get("HEIGHT")?.[0] ?? null,
		cholesterol: biometricsResponse?.get("CHOLESTEROL")?.[0] ?? null,
		triglycerides: biometricsResponse?.get("TRIGLYCERIDES")?.[0] ?? null,
		kidneyGfr: biometricsResponse?.get("KIDNEY_GFR")?.[0] ?? null,
	};
};

const getListRows = (
	latestBiometric: BiometricsEditProps["latestBiometric"],
	goals: BiometricsEditProps["goals"],
	editingRow: BiometricRow | null
) => {
	const rows: BiometricRow[] = [
		// Hemoglobin A1C,
		BiometricsTemplate.hemoglobinA1c(latestBiometric?.hemoglobinA1c ?? null, goals),
		// Blood pressure
		BiometricsTemplate.bloodPressure(latestBiometric?.bloodPressure ?? null, goals),
		// Blood glucose
		BiometricsTemplate.glucose(latestBiometric?.glucose ?? null),
		// Height
		BiometricsTemplate.height(latestBiometric?.height ?? null),
		// Weight
		BiometricsTemplate.weight(latestBiometric?.weight ?? null),
		// Cholesterol
		BiometricsTemplate.cholesterol(latestBiometric?.cholesterol ?? null),
		// Triglycerides
		BiometricsTemplate.triglycerides(latestBiometric?.triglycerides ?? null),
		// Kidney GFR
		BiometricsTemplate.kidneyGfr(latestBiometric?.kidneyGfr ?? null),
	];

	return rows.map((row) => {
		if (!editingRow) {
			return row;
		}

		const editing = editingRow.ids?.join(",") === row.ids?.join(",");

		return {
			...row,
			editing,
		};
	});
};

const getNestedListRows = (
	biometrics: Map<string, Biometric[]>,
	type: BiometricType,
	goals: Goal[],
	editingRow: BiometricRow | null
) => {
	const historyBiometrics = biometrics.get(type) ? biometrics.get(type)!.slice(1) : [];
	const list = historyBiometrics.map((item, index) => {
		let tmpRow: BiometricRow;
		switch (item.type) {
			case BiometricType.hemoglobin_a1c:
				tmpRow = BiometricsTemplate.hemoglobinA1c(item || null, goals);
				break;
			case BiometricType.blood_pressure:
				tmpRow = BiometricsTemplate.bloodPressure(item || null, goals);
				break;
			case BiometricType.glucose:
				tmpRow = BiometricsTemplate.glucose(item || null);
				break;
			case BiometricType.height:
				tmpRow = BiometricsTemplate.height(item || null);
				break;
			case BiometricType.weight:
				tmpRow = BiometricsTemplate.weight(item || null);
				break;
			case BiometricType.cholesterol:
				tmpRow = BiometricsTemplate.cholesterol(item || null);
				break;
			case BiometricType.triglycerides:
				tmpRow = BiometricsTemplate.triglycerides(item || null);
				break;
			case BiometricType.kidney_gfr:
				tmpRow = BiometricsTemplate.kidneyGfr(item || null);
				break;
			default:
				tmpRow = BiometricsTemplate.hemoglobinA1c(null, []);
				break;
		}
		return tmpRow;
	});

	return list.map((row) => {
		if (!editingRow) {
			return row;
		}

		const editing = editingRow.ids?.join(",") === row.ids?.join(",");

		return {
			...row,
			editing,
		};
	});
};

const setUpdateBody = (data: BiometricUpdateForm, subType: BiometricType) => {
	const body = new BiometricUpdateBody();
	if (data.dateOn) {
		const date = new Date(data.dateOn);
		body.vitalTime = date.toISOString();
	}

	body.source = Source.COACH;
	// Get value depending on the subtype
	switch (subType) {
		case BiometricType.blood_pressure_diastolic:
			body.value = (data.value as BiometricValueTemplateBloodPressure).bloodPressureDiastolic;
			break;
		case BiometricType.blood_pressure_systolic:
			body.value = (data.value as BiometricValueTemplateBloodPressure).bloodPressureSystolic;
			break;
		case BiometricType.heart_rate:
			body.value = (data.value as BiometricValueTemplateBloodPressure).heartRate;
			break;
		case BiometricType.glucose: {
			body.value = (data.value as BiometricValueTemplateGlucose).glucoseValue;
			body.mealState =
				(data.value as BiometricValueTemplateGlucose).glucoseContext ?? "UNKNOWN";
			break;
		}

		case BiometricType.cholesterol_hdl:
			body.value = (data.value as BiometricValueTemplateCholesterol).cholesterolHdl;
			break;
		case BiometricType.cholesterol_ldl:
			body.value = (data.value as BiometricValueTemplateCholesterol).cholesterolLdl;
			break;
		case BiometricType.cholesterol_total:
			body.value = (data.value as BiometricValueTemplateCholesterol).cholesterolTotal;
			break;
		case BiometricType.height:
			const heightFeet = data.value as BiometricValueTemplateHeight;
			body.value = BiometricsFormatter.convertHeight(
				heightFeet.heightFeet,
				heightFeet.heightInches
			);
			break;
		default:
			body.value = data.value;
	}
	return body;
};

const setCreateBody = (
	biometricData: IBiometricData,
	participantId: number
): Partial<BiometricsCreateEntry>[] => {
	const requestBody: Partial<BiometricsCreateEntry>[] = [];

	if (biometricData.hemoglobinA1c) {
		requestBody.push({
			type: "A1C",
			subType: "A1C",
			value: biometricData.hemoglobinA1c,
		});
	}

	if (biometricData.bloodPressureSystolic) {
		requestBody.push({
			type: "BLOOD_PRESSURE",
			subType: "BLOOD_PRESSURE_SYSTOLIC",
			value: biometricData.bloodPressureSystolic,
		});
	}
	if (biometricData.bloodPressureDiastolic) {
		requestBody.push({
			type: "BLOOD_PRESSURE",
			subType: "BLOOD_PRESSURE_DIASTOLIC",
			value: biometricData.bloodPressureDiastolic,
		});
	}
	if (biometricData.heartRate) {
		requestBody.push({
			type: "BLOOD_PRESSURE",
			subType: "HEART_RATE",
			value: biometricData.heartRate,
		});
	}
	if (biometricData.cholesterolHdl) {
		requestBody.push({
			type: "CHOLESTEROL",
			subType: "CHOLESTEROL_HDL",
			value: biometricData.cholesterolHdl,
		});
	}

	if (biometricData.cholesterolLdl) {
		requestBody.push({
			type: "CHOLESTEROL",
			subType: "CHOLESTEROL_LDL",
			value: biometricData.cholesterolLdl,
		});
	}

	if (biometricData.cholesterolTotal) {
		requestBody.push({
			type: "CHOLESTEROL",
			subType: "CHOLESTEROL_TOTAL",
			value: biometricData.cholesterolTotal,
		});
	}

	if (biometricData.triglycerides) {
		requestBody.push({
			type: "TRIGLYCERIDES",
			subType: "TRIGLYCERIDES",
			value: biometricData.triglycerides,
		});
	}

	if (biometricData.weight) {
		requestBody.push({
			type: "WEIGHT",
			subType: "WEIGHT",
			value: biometricData.weight,
		});
	}

	if (biometricData.heightFeet || biometricData.heightInches) {
		requestBody.push({
			type: "HEIGHT",
			subType: "HEIGHT",
			value: BiometricsFormatter.convertHeight(
				biometricData.heightFeet,
				biometricData.heightInches
			)!,
		});
	}

	if (biometricData.kidneyGfr) {
		requestBody.push({
			type: "KIDNEY_GFR",
			subType: "KIDNEY_GFR",
			value: biometricData.kidneyGfr,
		});
	}

	if (biometricData.glucoseValue) {
		requestBody.push({
			type: "GLUCOSE",
			subType: "GLUCOSE",
			value: biometricData.glucoseValue,
		});
	}

	return requestBody.map((item) => {
		item.participantId = participantId;
		item.source = Source.COACH;
		item.isManual = true;
		item.vitalTime = biometricData.dateOn?.toISOString();
		item.deleted = false;
		item.estimated = biometricData.estimated ?? false;

		if (item.type === "GLUCOSE" && biometricData.glucoseContext) {
			const mealInfo = biometricData.glucoseContext;
			item.mealState = mealInfo;
		}

		return item;
	});
};

const setStartDate = (range: CHART_FILTER) => {
	let result = "";
	const today = new Date();

	switch (range) {
		case CHART_FILTER.WEEK:
			today.setDate(today.getDate() - 7);
			result = Formatter.dateUTCWithoutTime(today)!.toISOString();
			break;
		case CHART_FILTER.MONTH:
			today.setDate(today.getDate() - 30);
			result = Formatter.dateUTCWithoutTime(today)!.toISOString();
			break;
		case CHART_FILTER.TRIMESTER:
			today.setDate(today.getDate() - 90);
			result = Formatter.dateUTCWithoutTime(today)!.toISOString();
			break;
		case CHART_FILTER.ALL:
			today.setDate(1);
			today.setMonth(0);
			today.setFullYear(1900);
			result = Formatter.dateUTCWithoutTime(today)!.toISOString();
			break;
	}
	return result;
};

const emptyDataset = (data?: CHART_DATA): boolean => {
	let value = true;

	if (
		(data?.datasets.length === 1 && data?.datasets[0].data.length > 0) ||
		(data?.datasets.length === 2 &&
			(data?.datasets[0].data.length > 0 || data?.datasets[1].data.length > 0))
	) {
		value = false;
	}

	return value;
};

export const BiometricsHelper = {
	getLatestValues,
	getListRows,
	getNestedListRows,
	setUpdateBody,
	setCreateBody,
	setStartDate,
	emptyDataset,
};
