import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import UiButton from "../../../components/button/Button";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { BiometricRow } from "../../../models/pages/biometrics/biometrics-modal.model";

const BiometricsDelete = ({
	isVisible,
	row,
	onComplete,
	onClose,
}: {
	isVisible: boolean;
	row: BiometricRow;
	onComplete: () => void;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");

	const [message, setMessage] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const submit = async () => {
		setMessage(false);
		setSubmitting(true);

		await EndpointsService.dataRetriever
			.deleteBiometrics({
				body: row.ids!,
			})
			.then(() => onComplete())
			.catch(() => setMessage(true))
			.finally(() => setSubmitting(false));
	};

	return (
		<Dialog
			header={t("PARTICIPANT.BIOMETRICS.TITLE")}
			visible={isVisible}
			className="biometrics-modal"
			breakpoints={{ "960px": "80vw", "641px": "100vw" }}
			style={{ width: "80vw" }}
			modal={true}
			focusOnShow={false}
			onHide={onClose}
		>
			<div className="">
				<Trans
					i18nKey="PARTICIPANT.BIOMETRICS.DELETE.MSG"
					defaults={t("PARTICIPANT.BIOMETRICS.DELETE.MSG")}
					values={{
						measurement: t(row.measurement),
						value: row.value.formatted,
						dateTaken: row.dateOn.formatted,
					}}
					components={{ bold: <span className="text-bold" /> }}
				/>

				{message && (
					<div className="form-message">
						<UiFieldMessage
							severity={FieldMessageSeverity.DANGER}
							label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
						/>
					</div>
				)}

				<div className="action-buttons">
					<UiButton
						id="modalBtnCancel"
						label="UI_COMPONENTS.BUTTONS.CANCEL"
						className="p-button-outlined p-button-rounded"
						onClick={onClose}
						disabled={submitting}
					/>
					<UiButton
						id="modalBtnSubmit"
						label={
							submitting
								? "UI_COMPONENTS.BUTTONS.SAVING"
								: "UI_COMPONENTS.BUTTONS.CONFIRM"
						}
						className="p-button-rounded"
						onClick={submit}
						disabled={submitting}
					/>
				</div>
			</div>
		</Dialog>
	);
};
export default BiometricsDelete;
