import { BiometricUnit } from "../../models/entities/biometrics.model";
import { Formatter } from "../formatter/formatter.service";

const isValidNumber = (value?: string | number | null): boolean => !!value;

// Hemoglobin A1C (%)
const formatHemoglobinA1c = (value?: number | null): string =>
	isValidNumber(value) ? `${value} ${BiometricUnit.hemoglobinA1c}` : Formatter.NO_DATA;

// Blood pressure (millimeters of mercury - mmHg)
const formatBloodPressure = (
	bloodPressureSystolic?: number | null,
	bloodPressureDiastolic?: number | null,
	heartRate?: number | null
): string => {
	let bps = isValidNumber(bloodPressureSystolic) ? bloodPressureSystolic : Formatter.NO_DATA;
	let bpd = isValidNumber(bloodPressureDiastolic) ? bloodPressureDiastolic : Formatter.NO_DATA;
	let hr = isValidNumber(heartRate) ? heartRate : Formatter.NO_DATA;
	if (bps !== Formatter.NO_DATA || bpd !== Formatter.NO_DATA || hr !== Formatter.NO_DATA)
		return `${bps}/${bpd} ${BiometricUnit.bloodPressure} ${hr} ${BiometricUnit.heartRate}`;
	return Formatter.NO_DATA;
};

const formatGlucose = (value: number | null): string => {
	return isValidNumber(value) ? `${value} ${BiometricUnit.glucose}` : Formatter.NO_DATA;
};

/**
 * Height (inches)
 * @description Given a height value in inches, convert it into feet+inches (UI). ex: 70.86 (inches) --> 5'11"
 */
const formatHeight = (inches?: number | null): string => {
	let result: string = Formatter.NO_DATA;
	if (isValidNumber(inches)) {
		const feetVal = Math.floor((inches as number) / 12);
		const inchesVal = Math.round((inches as number) - feetVal * 12);
		result = `${feetVal}'${inchesVal}"`;
	}
	return result;
};

/**
 * Height (feet + inches)
 * @description Given a height value in inches, convert it into feet+inches (UI). ex: 70.86 (inches) --> 5'11"
 */
const formatHeightValues = (
	inches?: number | null
): { heightFeet: number | null; heightInches: number | null } => {
	const result: { heightFeet: number | null; heightInches: number | null } = {
		heightFeet: null,
		heightInches: null,
	};

	if (isValidNumber(inches)) {
		const feetVal = Math.floor((inches as number) / 12);
		const inchesVal = Math.round((inches as number) - feetVal * 12);
		result.heightFeet = feetVal;
		result.heightInches = inchesVal;
	}

	return result;
};

/**
 * Height(feet + inches)
 * @description Given a height value in inches, convert it into feet+inches (UI). ex: 70.86 (inches) --> 5'11"
 */
const convertHeight = (heightFeet: number | null, heightInches: number | null): number | null => {
	let result: number | null = null;

	let feetAsInches: number | null = null;
	if (isValidNumber(heightFeet)) feetAsInches = Math.round(Number(heightFeet) * 12);

	if (isValidNumber(feetAsInches) && isValidNumber(heightInches)) {
		result = Number(feetAsInches) + Number(heightInches);
	} else if (!isValidNumber(feetAsInches) && isValidNumber(heightInches)) {
		result = Number(heightInches);
	} else if (isValidNumber(feetAsInches) && !isValidNumber(heightInches)) {
		result = Number(feetAsInches);
	}

	return result;
};

// Weight (pounds - lbs)
const formatWeight = (pounds?: number | null): string =>
	isValidNumber(pounds) ? `${pounds} ${BiometricUnit.weight}` : Formatter.NO_DATA;

// Cholesterol (mg/dL)
const formatCholesterol = (
	cholesterolHdl?: number | null,
	cholesterolLdl?: number | null,
	cholesterolTotal?: number | null
): string => {
	let result: string = Formatter.NO_DATA;
	if (
		isValidNumber(cholesterolHdl) &&
		isValidNumber(cholesterolLdl) &&
		isValidNumber(cholesterolTotal)
	) {
		// HDL + LDL + Total
		result = `HDL ${cholesterolHdl} ${BiometricUnit.cholesterol}, LDL ${cholesterolLdl} ${BiometricUnit.cholesterol}, Total ${cholesterolTotal} ${BiometricUnit.cholesterol}`;
	} else if (
		!isValidNumber(cholesterolHdl) &&
		isValidNumber(cholesterolLdl) &&
		isValidNumber(cholesterolTotal)
	) {
		// LDL + Total
		result = `HDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, LDL ${cholesterolLdl} ${BiometricUnit.cholesterol}, Total ${cholesterolTotal} ${BiometricUnit.cholesterol}`;
	} else if (
		isValidNumber(cholesterolHdl) &&
		!isValidNumber(cholesterolLdl) &&
		isValidNumber(cholesterolTotal)
	) {
		// HDL + Total
		result = `HDL ${cholesterolHdl} ${BiometricUnit.cholesterol}, LDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, Total ${cholesterolTotal} ${BiometricUnit.cholesterol}`;
	} else if (
		isValidNumber(cholesterolHdl) &&
		isValidNumber(cholesterolLdl) &&
		!isValidNumber(cholesterolTotal)
	) {
		// HDL + LDL
		result = `HDL ${cholesterolHdl} ${BiometricUnit.cholesterol}, LDL ${cholesterolLdl} ${BiometricUnit.cholesterol}, Total ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	} else if (
		isValidNumber(cholesterolHdl) &&
		!isValidNumber(cholesterolLdl) &&
		!isValidNumber(cholesterolTotal)
	) {
		// HDL
		result = `HDL ${cholesterolHdl} ${BiometricUnit.cholesterol}, LDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, Total ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	} else if (
		!isValidNumber(cholesterolHdl) &&
		isValidNumber(cholesterolLdl) &&
		!isValidNumber(cholesterolTotal)
	) {
		// LDL
		result = `HDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, LDL ${cholesterolLdl} ${BiometricUnit.cholesterol}, Total ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	} else if (
		!isValidNumber(cholesterolHdl) &&
		!isValidNumber(cholesterolLdl) &&
		isValidNumber(cholesterolTotal)
	) {
		// Total
		result = `HDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, LDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}, Total ${cholesterolTotal} ${BiometricUnit.cholesterol}`;
	}
	return result;
};

// Cholesterol HDL (mg/dL)
const formatCholesterolHdl = (cholesterolHdl?: number | null): string => {
	let result: string;
	if (isValidNumber(cholesterolHdl)) {
		result = `HDL ${cholesterolHdl} ${BiometricUnit.cholesterol}`;
	} else {
		result = `HDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	}
	return result;
};

// Cholesterol LDL (mg/dL)
const formatCholesterolLdl = (cholesterolLdl?: number | null): string => {
	let result: string;
	if (isValidNumber(cholesterolLdl)) {
		result = `LDL ${cholesterolLdl} ${BiometricUnit.cholesterol}`;
	} else {
		result = `LDL ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	}
	return result;
};

// Cholesterol Total (mg/dL)
const formatCholesterolTotal = (cholesterolTotal?: number | null): string => {
	let result: string;
	if (isValidNumber(cholesterolTotal)) {
		result = `Total ${cholesterolTotal} ${BiometricUnit.cholesterol}`;
	} else {
		result = `Total ${Formatter.NO_DATA} ${BiometricUnit.cholesterol}`;
	}
	return result;
};

// Triglycerides
const formatTriglycerides = (value?: number | null): string =>
	isValidNumber(value) ? `${value} ${BiometricUnit.triglycerides}` : Formatter.NO_DATA;

// Kidney GFR
const formatKidneyGfr = (value?: number | null): string =>
	isValidNumber(value) ? `${value} ${BiometricUnit.kidneyGfr}` : Formatter.NO_DATA;

export const BiometricsFormatter = {
	formatHemoglobinA1c,
	formatBloodPressure,
	formatGlucose,
	formatHeight,
	formatHeightValues,
	convertHeight,
	formatWeight,
	formatCholesterol,
	formatCholesterolHdl,
	formatCholesterolLdl,
	formatCholesterolTotal,
	formatTriglycerides,
	formatKidneyGfr,
};
