import { Formatter } from "../../services/formatter/formatter.service";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateText,
} from "../components/table/table-template.model";
import { LongTermGoal } from "./custom-goals";

export class LongTermGoalCurrentRow {
	id: number;
	name: TableTemplateText;
	startDate: string; // Date
	endDate: string; // Date
	complete: TableTemplateButtons;
	actions: TableTemplateButtons;
	entity: LongTermGoal;

	constructor(
		params: LongTermGoal,
		expired: string,
		completeFn: (e: TableTemplateButtonsEvent) => void,
		updateFn: (e: TableTemplateButtonsEvent) => void,
		deleteFn: (e: TableTemplateButtonsEvent) => void,
		duplicateFn: (e: TableTemplateButtonsEvent) => void
	) {
		this.id = params.id;
		this.name = {
			className: "text-bold",
			value: params.name,
		};
		this.startDate = params.startDate;
		this.endDate = this.setEndDate(params.endDate, expired);
		this.complete = this.setComplete(completeFn);
		this.actions = this.setActions(updateFn, deleteFn, duplicateFn);
		this.entity = params;
	}

	private setEndDate = (endDate: string, expired: string) => {
		let result: string = Formatter.date(endDate, {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
			timeZone: "UTC",
		})!;
		if (new Date(endDate).getTime() < new Date().getTime()) {
			result += ` ${expired}`;
		}
		return result;
	};

	private setComplete = (completeFn: (e: TableTemplateButtonsEvent) => void) => [
		{
			id: "actionComplete",
			label: "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.ACTION_COMPLETE",
			className: "p-button-sm p-button-rounded p-button-success-darker",
			disabled: false,
			onClick: completeFn,
		},
	];

	private setActions = (
		updateFn: (e: TableTemplateButtonsEvent) => void,
		deleteFn: (e: TableTemplateButtonsEvent) => void,
		duplicateFn: (e: TableTemplateButtonsEvent) => void
	) => [
		{
			id: "actionUpdate",
			icon: "pi pi-pencil",
			className: "p-button-text neutral",
			disabled: false,
			onClick: updateFn,
		},
		{
			id: "actionDelete",
			icon: "pi pi-trash",
			className: "p-button-text neutral",
			disabled: false,
			onClick: deleteFn,
		},
		{
			id: "actionDuplicate",
			icon: "pi pi-copy",
			className: "p-button-text neutral",
			disabled: false,
			onClick: duplicateFn,
		},
	];
}

export class LongTermGoalPastRow {
	id: number;
	name: TableTemplateText;
	startDate: string; // Date
	endDate: string; // Date
	status: TableTemplateText;
	updatedOn: string; // Date
	actions: TableTemplateButtons;
	entity: LongTermGoal;

	constructor(params: LongTermGoal, duplicateFn: (e: TableTemplateButtonsEvent) => void) {
		this.id = params.id;
		this.name = {
			className: "text-bold",
			value: params.name,
		};
		this.startDate = params.startDate;
		this.endDate = params.endDate;
		this.status = this.setStatus(params.deleted, params.completed);
		this.updatedOn = params.updatedOn;
		this.actions = this.setActions(duplicateFn);
		this.entity = params;
	}

	private setStatus = (deleted: boolean, completed: boolean): TableTemplateText => {
		const result: TableTemplateText = {
			className: deleted ? "text-danger" : completed ? "text-success" : "",
			value: deleted
				? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.statusDeleted"
				: completed
					? "PARTICIPANT.DASHBOARD.CUSTOM_GOALS.MODAL.COLUMNS.statusCompleted"
					: "UNDEFINED",
		};
		return result;
	};

	private setActions = (duplicateFn: (e: TableTemplateButtonsEvent) => void) => [
		{
			id: "actionDuplicate",
			icon: "pi pi-copy",
			className: "p-button-text neutral",
			disabled: false,
			onClick: duplicateFn,
		},
	];
}
