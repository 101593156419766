import { FormApi } from "final-form";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../components/button/Button";
import UiFormInfo from "../../../components/form-info/FormInfo.component";
import UiInputDate from "../../../components/input-date/InputDate.component";
import { DateFormData } from "../../../models/pages/showcases.model";
import { PageProps } from "../../../models/routing.model";
import { Validations } from "../../../services/form/validations.service";

const InputDateShowcase = (props: PageProps) => {
	const minDate = new Date();
	minDate.setDate(minDate.getDate() - 2);

	const maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + 3);

	const [formData, setFormData] = useState<DateFormData>({
		default: null,
		default_value: new Date(),
		disabled: null,
		disabled_value: new Date("2022-12-31T00:00:00.000+00:00"),
		required: null,
		disabled_days: null,
		multiple_selection: null,
		multiple_selection_value: [minDate, maxDate, new Date()],
		min_max: null,
		range_selection: null,
		range_selection_value: [minDate, maxDate],
		date_time: null,
		inline: null,
		inline_time: new Date(),
	});
	useEffect(() => {
		console.log("Input date showcase > Form changed! ", formData);
	}, [formData]);

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	// Submit
	const handleSubmit = async (data: DateFormData, form: FormApi<DateFormData, DateFormData>) => {
		console.log("Submitting... ", data, form, formData);
		await sleep(3000);
		// Update state onSubmit
		setFormData({ ...data });
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="default"
									placeholder="SHOWCASES.COMMON_FIELDS.default"
									label="SHOWCASES.COMMON_FIELDS.default"
									name="default"
									onChange={(e) =>
										setFormData({ ...formData, default: e.target.value })
									}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="default_value"
									placeholder="SHOWCASES.COMMON_FIELDS.default_value"
									label="SHOWCASES.COMMON_FIELDS.default_value"
									name="default_value"
									onChange={(e) =>
										setFormData({ ...formData, default_value: e.target.value })
									}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="disabled"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled"
									label="SHOWCASES.COMMON_FIELDS.disabled"
									name="disabled"
									onChange={(e) =>
										setFormData({ ...formData, disabled: e.target.value })
									}
									disabled={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="disabled_value"
									placeholder="SHOWCASES.COMMON_FIELDS.disabled_value"
									label="SHOWCASES.COMMON_FIELDS.disabled_value"
									name="disabled_value"
									onChange={(e) =>
										setFormData({ ...formData, disabled_value: e.target.value })
									}
									disabled={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="required"
									placeholder="SHOWCASES.COMMON_FIELDS.required"
									label="SHOWCASES.COMMON_FIELDS.required"
									name="required"
									onChange={(e) =>
										setFormData({ ...formData, required: e.target.value })
									}
									validations={[Validations.required]}
									disabled={submitting}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="disabled_days"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.disabled_days"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.disabled_days"
									name="disabled_days"
									onChange={(e) =>
										setFormData({ ...formData, disabled_days: e.target.value })
									}
									disabled={submitting}
									disabledDays={[1, 4]}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="multiple_selection"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.multiple_selection"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.multiple_selection"
									name="multiple_selection"
									onChange={(e) =>
										setFormData({
											...formData,
											multiple_selection: e.target.value,
										})
									}
									disabled={submitting}
									selectionMode="multiple"
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="multiple_selection_value"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.multiple_selection_value"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.multiple_selection_value"
									name="multiple_selection_value"
									onChange={(e) =>
										setFormData({
											...formData,
											multiple_selection_value: e.target.value,
										})
									}
									disabled={submitting}
									selectionMode="multiple"
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="min_max"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.min_max"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.min_max"
									name="min_max"
									onChange={(e) =>
										setFormData({ ...formData, min_max: e.target.value })
									}
									disabled={submitting}
									minDate={minDate}
									maxDate={maxDate}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="range_selection"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.range_selection"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.range_selection"
									name="range_selection"
									onChange={(e) =>
										setFormData({
											...formData,
											range_selection: e.target.value,
										})
									}
									disabled={submitting}
									selectionMode="range"
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="range_selection_value"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.range_selection_value"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.range_selection_value"
									name="range_selection_value"
									onChange={(e) =>
										setFormData({
											...formData,
											range_selection_value: e.target.value,
										})
									}
									disabled={submitting}
									selectionMode="range"
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="date_time"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.date_time"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.date_time"
									name="date_time"
									onChange={(e) =>
										setFormData({ ...formData, date_time: e.target.value })
									}
									disabled={submitting}
									showTime={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="inline"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.inline"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.inline"
									name="inline"
									onChange={(e) =>
										setFormData({ ...formData, inline: e.target.value })
									}
									disabled={submitting}
									inline={true}
								/>
							</div>

							<div className="col-12 col-sm-6 col-md-3 col-lg-3">
								<UiInputDate
									id="inline_time"
									placeholder="SHOWCASES.INPUT_DATE_SHOWCASE.inline_time"
									label="SHOWCASES.INPUT_DATE_SHOWCASE.inline_time"
									name="inline_time"
									onChange={(e) =>
										setFormData({ ...formData, inline_time: e.target.value })
									}
									disabled={submitting}
									inline={true}
									showTime={true}
								/>
							</div>
						</div>

						{/* SUBMIT */}
						<div className="text-end">
							<UiButton
								id="btnSubmit"
								type="submit"
								label={
									submitting
										? "SHOWCASES.COMMON_ACTIONS.BTN_SUBMITTING"
										: "SHOWCASES.COMMON_ACTIONS.BTN_SUBMIT"
								}
								disabled={submitting}
								loading={submitting}
							/>
						</div>

						{/* FORM INFORMATION */}
						<UiFormInfo {...(form as any)}></UiFormInfo>
					</form>
				)}
			/>
		</>
	);
};

export default InputDateShowcase;
