import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "primereact/tag";
import { UiTagProps } from "../../models/components/tag.model";
import { ObjectUtils } from "primereact/utils";

const UiTag = (props: UiTagProps) => {
	const { t } = useTranslation("common");

	const otherProps = ObjectUtils.findDiffKeys(props, {
		value: props.value,
		uppercase: props.uppercase?.toString(),
	});

	return (
		<>
			{props.value && (
				<Tag
					value={props.uppercase ? t(props.value).toUpperCase() : t(props.value)}
					{...otherProps}
				></Tag>
			)}
		</>
	);
};

export default UiTag;
