import { AppointmentRow } from "../../../models/pages/appointments/appointments.model";
import { useEffect, useState } from "react";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { AxiosError } from "axios";
import {
	AppointmentBlock,
	AppointmentBlockInfo,
} from "../../../models/entities/appointments.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import UiLink from "../../../components/link/Link.component";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiTag from "../../../components/tag/Tag.component";
import UiPhoneCaller from "../../../components/phone-caller/PhoneCaller.component";
import { TableMessages } from "../../../models/components/table/table-message.model";

const AppointmentBlockContainer = ({ row }: { row: AppointmentRow }) => {
	const entity = row.entity as AppointmentBlock;
	const [participants, setParticipants] = useState<AppointmentBlockInfo[]>([]);
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		label: messages.loading,
		severity: FieldMessageSeverity.LOADING,
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const ids = entity.participants.map((x) => x.kannactId.slice(3));
		await EndpointsService.dataRetriever
			.getParticipantMinimal({
				config: {
					params: { ids },
				},
			})
			.then((response) => {
				if (response?.length > 0) {
					setMessage({ label: "", severity: FieldMessageSeverity.SUCCESS });
					setParticipants(response);
				} else {
					setMessage({ label: messages.empty, severity: FieldMessageSeverity.INFO });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ label: messages.error, severity: FieldMessageSeverity.DANGER })
			);
	};

	return (
		<div className="appointments-block-info">
			{message.severity === FieldMessageSeverity.SUCCESS ? (
				<ul className="block-info-list">
					{participants.map((x) => (
						<li
							key={x.id}
							className="block-info"
						>
							{/* Kannact ID */}
							<UiLink
								id={x.kannactId}
								to={`/participant/${x.kannactId.slice(3)}/dashboard`}
								label={x.kannactId}
							/>

							{/* Full name */}
							<span className="full-name">
								{Formatter.fullName(x.firstName, x.lastName)}
							</span>

							{/* Status */}
							<UiTag {...Formatter.statusTag(x.status)} />

							{/* Phone number */}
							<UiPhoneCaller phoneNumber={x.phoneNumber} />
						</li>
					))}
				</ul>
			) : (
				<div className="d-flex justify-content-center">
					<UiFieldMessage
						severity={message.severity}
						label={message.label}
					/>
				</div>
			)}
		</div>
	);
};
export default AppointmentBlockContainer;
