import {
	BiometricGoal,
	Goal,
	GoalCategory,
	GoalState,
	MedicationGoal,
} from "../../models/entities/goal.model";

const biometricsGoalsPrefixes = Object.values(BiometricGoal);
const medicationsGoalsPrefixes = Object.values(MedicationGoal);

const getElegibles = (goals: Goal[]) => {
	let result: Goal[] = [];

	if (goals.length) {
		result = goals.filter(
			(goal) =>
				goal.eligible &&
				(goal.category === GoalCategory.BIOMETRIC
					? biometricsGoalsPrefixes.find((prefix) =>
							(goal.goalName as BiometricGoal).startsWith(prefix)
						)
					: medicationsGoalsPrefixes.find((prefix) =>
							(goal.goalName as MedicationGoal).startsWith(prefix)
						))
		);
	}

	return result;
};

const showGoal = (goal?: Goal) => {
	let result = true;

	if (
		!goal ||
		(goal.eligible === false &&
			(goal.state === GoalState.expired || goal.state === GoalState.no_data))
	) {
		result = false;
	}

	return result;
};

const getGoalsByCategory = (goals: Goal[]) => {
	let result: Goal[] = [];

	if (goals.length) {
		result = goals.filter((goal) =>
			goal.category === GoalCategory.BIOMETRIC
				? biometricsGoalsPrefixes.find((prefix) =>
						(goal.goalName as BiometricGoal).startsWith(prefix)
					)
				: medicationsGoalsPrefixes.find((prefix) =>
						(goal.goalName as MedicationGoal).startsWith(prefix)
					)
		);
	}

	return result;
};

const getDescription = (goal: Goal): string => {
	let description = "";

	switch (goal.state) {
		case GoalState.on_track:
			description =
				goal.category === GoalCategory.BIOMETRIC
					? `ENUMS.GOALS_STATUS.HEALTHY`
					: `ENUMS.GOALS_STATUS.GOOD`;
			break;
		case GoalState.off_track:
			description =
				goal.category === GoalCategory.BIOMETRIC
					? "ENUMS.GOALS_STATUS.TOO_HIGH"
					: "ENUMS.GOALS_STATUS.TOO_LOW";
			break;
		case GoalState.no_data:
			description = "ENUMS.GOALS_STATUS.DATA_NEEDED";
			break;
		case GoalState.expired:
			description = `ENUMS.GOALS_STATUS.OUT_OF_DATE`;
			break;
		case GoalState.fallback:
			description = "ENUMS.GOALS_STATUS.PROCESSING";
			break;
		case GoalState.due_soon:
			description = "ENUMS.GOALS_STATUS.DUE_SOON";
			break;
		default:
			break;
	}

	return description;
};

const getDescriptionByStateCategory = (state: GoalState, category: GoalCategory): string => {
	let description = `${state}`;

	switch (state) {
		case GoalState.on_track:
			description =
				category === GoalCategory.BIOMETRIC
					? `ENUMS.GOALS_STATUS.HEALTHY`
					: `ENUMS.GOALS_STATUS.GOOD`;
			break;
		case GoalState.off_track:
			description =
				category === GoalCategory.BIOMETRIC
					? "ENUMS.GOALS_STATUS.TOO_HIGH"
					: "ENUMS.GOALS_STATUS.TOO_LOW";
			break;
		case GoalState.no_data:
			description = "ENUMS.GOALS_STATUS.DATA_NEEDED";
			break;
		case GoalState.expired:
			description = `ENUMS.GOALS_STATUS.OUT_OF_DATE`;
			break;
		case GoalState.fallback:
			description = "ENUMS.GOALS_STATUS.PROCESSING";
			break;
		case GoalState.due_soon:
			description = "ENUMS.GOALS_STATUS.DUE_SOON";
			break;
		default:
			break;
	}

	return description;
};

const getTooltip = (goal: Goal) => {
	let tooltip = "";

	switch (goal.state) {
		case GoalState.on_track:
			if (goal.category === GoalCategory.BIOMETRIC) {
				tooltip = goal.goalName.startsWith(BiometricGoal.A1C)
					? "ENUMS.GOALS_DESCRIPTION.A1C_ON_TRACK"
					: "ENUMS.GOALS_DESCRIPTION.BPS_ON_TRACK";
			} else {
				tooltip = goal.goalName.startsWith(MedicationGoal.STATIN_PDC)
					? "ENUMS.GOALS_DESCRIPTION.STATIN_PDC_ON_TRACK"
					: "ENUMS.GOALS_DESCRIPTION.AMR_ON_TRACK";
			}
			break;
		case GoalState.off_track:
			if (goal.category === GoalCategory.BIOMETRIC) {
				tooltip = goal.goalName.startsWith(BiometricGoal.A1C)
					? "ENUMS.GOALS_DESCRIPTION.A1C_OFF_TRACK"
					: "ENUMS.GOALS_DESCRIPTION.BPS_OFF_TRACK";
			} else {
				tooltip = goal.goalName.startsWith(MedicationGoal.STATIN_PDC)
					? "ENUMS.GOALS_DESCRIPTION.STATIN_PDC_OFF_TRACK"
					: "ENUMS.GOALS_DESCRIPTION.AMR_OFF_TRACK";
			}
			break;
		case GoalState.no_data:
			if (goal.category === GoalCategory.BIOMETRIC) {
				tooltip = goal.goalName.startsWith(BiometricGoal.A1C)
					? "ENUMS.GOALS_DESCRIPTION.A1C_DATA_NEEDED"
					: "ENUMS.GOALS_DESCRIPTION.BPS_DATA_NEEDED";
			} else {
				tooltip = "ENUMS.GOALS_DESCRIPTION.DATA_NEEDED";
			}
			break;
		case GoalState.expired:
			if (goal.category === GoalCategory.BIOMETRIC) {
				tooltip = goal.goalName.startsWith(BiometricGoal.A1C)
					? "ENUMS.GOALS_DESCRIPTION.A1C_OUT_OF_DATE"
					: "ENUMS.GOALS_DESCRIPTION.BPS_OUT_OF_DATE";
			} else {
				tooltip = `ENUMS.GOALS_DESCRIPTION.OUT_OF_DATE`;
			}
			break;
		case GoalState.due_soon:
			if (goal.category === GoalCategory.BIOMETRIC) {
				tooltip = goal.goalName.startsWith(BiometricGoal.A1C)
					? "ENUMS.GOALS_DESCRIPTION.A1C_DUE_SOON"
					: "ENUMS.GOALS_DESCRIPTION.BPS_DUE_SOON";
			} else {
				tooltip = `ENUMS.GOALS_DESCRIPTION.DUE_SOON`;
			}
			break;
		case GoalState.fallback:
			// No tooltip needed
			break;
		default:
			break;
	}

	return tooltip;
};

const getMedicationGoalValue = (goal: Goal) => {
	const goalValue: number | undefined = goal.metadata.events?.at(0)?.value;
	const isDecimalValue = goalValue && goalValue !== 0 && goalValue !== 1;
	return isDecimalValue ? goalValue.toFixed(2) : goalValue;
};

const getMedicationGoalCategory = (goal: Goal) =>
	goal.goalName.startsWith("AMR") ? "AMR" : "Statin PDC";

const getMedicationGoalCategoryNames = (goal: Goal): string[] => {
	if (goal.goalName.startsWith("AMR")) {
		return [
			"copd rescue drug",
			"asthma rescue inhaler",
			"copd maintance",
			"asthma maintenance",
		];
	}

	return ["statin"];
};

const getMedicationGoalByCategory = (goals: Goal[], category: string) =>
	goals?.filter((goal) => getMedicationGoalCategoryNames(goal).includes(category)).at(0)!;

export const GoalsService = {
	biometricsGoalsPrefixes,
	medicationsGoalsPrefixes,
	getElegibles,
	showGoal,
	getGoalsByCategory,
	getDescription,
	getDescriptionByStateCategory,
	getTooltip,
	getMedicationGoalValue,
	getMedicationGoalCategory,
	getMedicationGoalByCategory,
};
