import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { GoalsService } from "../../../../services/goals/goals.service";

export const useMedicationGoals = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["medication-goals", { participantId }];

	const query = useQuery(queryKey, async () => {
		const medicationGoals = await EndpointsService.dataRetriever.getMedicationGoals({
			participantId,
		});
		return GoalsService.getElegibles(medicationGoals?.goals ?? []);
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
