import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Dialog } from "primereact/dialog";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import UiButton from "../../../components/button/Button";
import { ModalSurveyProps } from "../../../models/pages/participant-surveys.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { Formatter } from "../../../services/formatter/formatter.service";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";

const formatName = (x: string) => {
	let result: string = x;
	// Replace "_" for a space
	result = result.replace("_", " ");
	// Capitalize the first letter
	result = result.charAt(0).toUpperCase() + result.slice(1);
	return result;
};

const isFile = (value: any): boolean => value.name && value.type && value.content;

const ModalSurveyResults = ({
	isVisible,
	surveyName,
	surveyResult,
	closeModal,
}: ModalSurveyProps) => {
	const { t } = useTranslation("common");

	return (
		<Dialog
			header={t("PARTICIPANT.SURVEYS.MODAL_RESULTS.TITLE")}
			visible={isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={closeModal}
		>
			<h4>{surveyName}</h4>

			{Object.keys(surveyResult)?.length > 0 ? (
				<div className="survey-results">
					{Object.keys(surveyResult).map((x, index) => (
						<SurveyResult
							key={`${x}_${index}`}
							name={x}
							value={surveyResult[x]}
						/>
					))}
				</div>
			) : (
				<UiFieldMessage
					severity={FieldMessageSeverity.INFO}
					label={new TableMessages().empty}
				/>
			)}

			<div className="action-buttons">
				<UiButton
					id="modalBtnClose"
					label="UI_COMPONENTS.BUTTONS.CLOSE"
					className="p-button-outlined p-button-rounded"
					type="button"
					onClick={closeModal}
				></UiButton>
			</div>
		</Dialog>
	);
};

const SurveyResult = ({ name, value }: { name: string; value: any }) => {
	const hasNestedValues = () =>
		typeof value === "object" && value !== null && !Array.isArray(value) && !isFile(value);

	const formatValue = (value: any, options: PanelHeaderTemplateOptions) => {
		let result: React.ReactNode;

		if (value && Array.isArray(value) && isFile(value[0])) {
			// An array of files
			result = value.map((x) => (
				<UiButton
					key={x.name}
					className="p-button-text link p-0 mx-2"
					label={x.name ? x.name : t("PARTICIPANT.SURVEYS.MODAL_RESULTS.FILE_IMG_LINK")}
					onClick={() => {
						Formatter.openFile(Formatter.base64MimeToBlob(x.content, x.type), x.type);
					}}
				/>
			));
		} else if (value && Array.isArray(value)) {
			// Array
			result = value.join(", ");
		} else if (typeof value === "object" && value !== null && isFile(value)) {
			// File
			result = (
				<UiButton
					className="p-button-text link p-0"
					label={
						value.name
							? value.name
							: t("PARTICIPANT.SURVEYS.MODAL_RESULTS.FILE_IMG_LINK")
					}
					onClick={() => {
						Formatter.openFile(
							Formatter.base64ToBlob(value.content, value.type),
							value.type
						);
					}}
				/>
			);
		} else if (typeof value === "object" && value !== null) {
			// Nested object
			result = (
				<UiButton
					className="p-button-text neutral p-0 m-0"
					icon={
						options.collapsed ? (
							<BsChevronDown style={{ fontSize: "1.25rem" }} />
						) : (
							<BsChevronUp style={{ fontSize: "1.25rem" }} />
						)
					}
					onClick={options.onTogglerClick}
				/>
			);
		} else if (value) {
			// string | number | boolean
			result = value.toString();
		}

		return result;
	};

	const flatten = (obj: any) => {
		let res: { [x: string]: any } = {};
		for (const [key, value] of Object.entries(obj)) {
			if (
				typeof value === "object" &&
				value !== null &&
				!Array.isArray(value) &&
				!isFile(value)
			) {
				res = { ...res, ...flatten(value) };
			} else {
				res[key] = value;
			}
		}
		return res;
	};

	return (
		<Panel
			toggleable={hasNestedValues()}
			headerTemplate={(options: PanelHeaderTemplateOptions) => (
				<div className="result">
					<span className="result-name">{formatName(name)}</span>
					<span className="result-value">{formatValue(value, options)}</span>
				</div>
			)}
		>
			{/* Nested values */}
			{hasNestedValues() && <SurveyNestedResult value={flatten(value)} />}
		</Panel>
	);
};

const SurveyNestedResult = ({ value }: any) => {
	const formatValue = (value: any) => {
		let result: React.ReactNode;

		if (value && isFile(value)) {
			// File
			result = (
				<UiButton
					className="p-button-text link p-0"
					label={
						value.name
							? value.name
							: t("PARTICIPANT.SURVEYS.MODAL_RESULTS.FILE_IMG_LINK")
					}
					onClick={() => {
						Formatter.openFile(
							Formatter.base64ToBlob(value.content, value.type),
							value.type
						);
					}}
				/>
			);
		} else if (value && Array.isArray(value) && isFile(value[0])) {
			// Array of files
			result = value.map((x) => (
				<UiButton
					className="p-button-text link p-0 mx-2"
					label={x.name ? x.name : t("PARTICIPANT.SURVEYS.MODAL_RESULTS.FILE_IMG_LINK")}
					onClick={() => {
						Formatter.openFile(Formatter.base64MimeToBlob(x.content, x.type), x.type);
					}}
				/>
			));
		} else if (Array.isArray(value)) {
			result = value.join(", ");
		} else {
			// string | number | boolean
			result = value?.toString();
		}

		return result;
	};

	return (
		<div className="row result-expanded">
			{Object.keys(value).map((x) => (
				<div
					key={x}
					className="col-12 col-sm-6 col-md-3 col-lg-3"
				>
					<div className="result-name">{formatName(x)}</div>
					<div className="result-value">{formatValue(value[x])}</div>
				</div>
			))}
		</div>
	);
};

export default ModalSurveyResults;
