import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { Formatter } from "../../../../../../services/formatter/formatter.service";
import UiButton from "../../../../../../components/button/Button";

const NotesHistoryPanelEdit = (props: { lastEditionDate?: string; onClickEdit: () => void }) => {
	const { t } = useTranslation("common");

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				alignItems: "center",
			}}
		>
			<div>
				{props.lastEditionDate &&
					t(`PARTICIPANT.DASHBOARD.NOTES.EDITED`) +
						" " +
						Formatter.dateISOToString(new Date(props.lastEditionDate).toISOString(), {
							day: "2-digit",
							month: "2-digit",
							year: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
						})}
			</div>

			<UiButton
				className="p-button-text neutral ml-2"
				icon="pi pi-pencil"
				onClick={props.onClickEdit}
			/>
		</div>
	);
};

export default NotesHistoryPanelEdit;
