import { useState } from "react";
import ConditionsModal from "./ConditionsModal";
import { useConditions } from "./useConditions";
import ConditionsQuickViewPanel from "./ConditionsQuickViewPanel";
import { ConditionsModalMode } from "../../../../models/pages/conditions/conditions-modal.model";

const ConditionsQuickView = ({ participantId }: { participantId: number }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const conditions = useConditions({ participantId });

	return (
		<>
			<ConditionsQuickViewPanel
				isLoading={conditions.isLoading}
				isError={conditions.isError}
				conditions={conditions.data}
				onClickAction={() => setIsModalOpen(true)}
			/>

			{isModalOpen && (
				<ConditionsModal
					modalMode={ConditionsModalMode.READ}
					participantId={participantId}
					closeModal={() => {
						setIsModalOpen(false);
						conditions.invalidate();
					}}
					isVisible={isModalOpen}
				/>
			)}
		</>
	);
};
export default ConditionsQuickView;
