import { useState } from "react";
import MedicationsModal from "./MedicationsModal";
import MedicationsQuickViewPanel from "./MedicationsQuickViewPanel";
import { useMedicationGoals } from "./useMedicationGoals";
import { useLatestActiveMedications } from "./useLatestActiveMedications";
import { MedicationsModalMode } from "../../../../models/pages/medications/medications-modal";

const MedicationsQuickView = ({ participantId }: { participantId: number }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const latestActiveMedications = useLatestActiveMedications({ participantId });
	const medicationsGoals = useMedicationGoals({ participantId });

	const isLoading = latestActiveMedications.isLoading;
	const isError = latestActiveMedications.isError;

	return (
		<>
			<MedicationsQuickViewPanel
				isLoading={isLoading}
				isError={isError}
				latestActiveMedications={latestActiveMedications.data}
				goals={medicationsGoals.data}
				onClickAction={() => setIsModalOpen(true)}
			/>
			{isModalOpen && (
				<MedicationsModal
					mode={MedicationsModalMode.READ}
					participantId={participantId}
					isVisible={isModalOpen}
					closeModal={() => {
						setIsModalOpen(false);
						medicationsGoals.invalidate();
						latestActiveMedications.invalidate();
					}}
					goals={medicationsGoals.data}
				/>
			)}
		</>
	);
};

export default MedicationsQuickView;
