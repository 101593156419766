import { TableDateFormat } from "../../models/components/table/table-column.model";
import {
	eParticipantStatus,
	Gender,
	ParticipantStatus,
	PreferredCoachingMethod,
} from "../../models/entities/participant.model";
import { getBrowserLang } from "../i18n/i18n.service";
import { UiTagProps } from "../../models/components/tag.model";
import { TFunction } from "react-i18next";
import { List } from "../../models/misc.model";
import { es, enUS } from "date-fns/locale";
import formatDistance from "date-fns/formatDistance";

const NO_DATA = "-";

const getLocalForDateFns = () => {
	let result: Locale | undefined = undefined;
	const locale = getBrowserLang().split("-")[0];
	switch (locale) {
		case "es":
			result = es;
			break;
		case "en":
			result = enUS;
			break;
		default:
			result = undefined;
			break;
	}
	return result;
};

const getDateDistance = (endDate: Date, startDate: Date) => {
	return formatDistance(endDate, startDate, {
		addSuffix: true,
		locale: getLocalForDateFns(),
	});
};

/**
 * Format: date
 * @description Formats a date into a more readable format (local date with timezone)
 */
const date = (data?: string | Date, format?: TableDateFormat): string | undefined => {
	let value: string | undefined;
	let date: Date | undefined;
	if (data) date = new Date(data);
	if (date)
		value = date.toLocaleDateString(
			getBrowserLang(),
			format ?? { day: "2-digit", month: "2-digit", year: "2-digit" }
		);
	return value;
};

/**
 * Format date: "1990-04-24T00:00:00.000+00:00" -> "Apr 24, 1990"
 * @description The function receives an ISOString date (UTC), and transforms it to a string.
 */
const dateISOToString = (data?: string, format?: TableDateFormat): string | undefined => {
	let value: string | undefined;
	if (data) {
		const formatter = new Intl.DateTimeFormat(
			getBrowserLang(),
			format ?? {
				day: "numeric",
				month: "short",
				year: "numeric",
				timeZone: "UTC",
			}
		);
		const date = new Date(data);
		if (date.getTime()) value = formatter.format(date);
	}
	return value;
};

/**
 * Format date: Date (ISOString) -> Date UTC (Date object)
 */
const dateISOToUTC = (date?: string): Date | null => {
	let result = null;
	if (date) {
		const dateLocal = new Date(date);
		result = new Date(
			dateLocal.getUTCFullYear(),
			dateLocal.getUTCMonth(),
			dateLocal.getUTCDate()
		);
	}
	return result;
};

/**
 * Format date: "1990-04-24T14:35:12.000+00:00" -> "1990-04-24T00:00:00.000+00:00"
 * @desacription Formats a date (UTC) removing the time (hours, minutes, etc.)
 */
const dateUTCWithoutTime = (date: Date | undefined | null): Date | null =>
	date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null;

/**
 * Format date: Date (local with timezone) -> Date (UTC without timezone)
 * @description
 */
const dateUTC = (date?: Date | null): Date | null =>
	date ? new Date(date.toISOString().slice(0, -1)) : null;

/**
 * Format date: Date (UTC) -> Date (Local)
 * @description
 */
const dateLocal = (date?: Date): Date | null => (date ? new Date(date) : null);

/**
 * Format dob (date of birth)
 * @description
 */
const formatDob = (dob?: Date | null) => {
	let result = !dob ? dob : "";
	if (dob) {
		// Format date of birth
		const year = `${dob!.getFullYear()}`;
		const month =
			dob!.getMonth() + 1 < 10 ? `0${dob!.getMonth() + 1}` : `${dob!.getMonth() + 1}`;
		const day = dob!.getDate() < 10 ? `0${dob!.getDate()}` : `${dob!.getDate()}`;
		result = `${year}-${month}-${day}`;
	}
	return result;
};

/**
 * Format: phone number
 * @descriptions Formats a phone number into a more readable format: "9016535184" to "(901) 653-5184".
 * Or unformats the phone number into a plain string (removing special characters)
 */
const phoneNumber = (data?: string, unformat?: boolean): string | undefined => {
	let value: string | undefined = data;
	if (value && unformat) {
		value = value.trim().replace(/\D/g, "");
		// Remove country code
		if (value.length === 11) value = value.slice(1, value.length);
	} else if (value && !unformat) {
		const currentValue = value.trim().replace(/\D/g, "");
		if (currentValue.length < 4) value = currentValue;
		if (currentValue.length < 7)
			value = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
		if (currentValue.length === 10)
			value = `(${currentValue.slice(0, 3)}) ${currentValue.slice(
				3,
				6
			)}-${currentValue.slice(6, 10)}`;
		// Has USA country code if length === 11
		if (currentValue.length === 11)
			value = `+${currentValue.slice(0, 1)} (${currentValue.slice(
				1,
				4
			)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`;
	}
	return value;
};

/**
 * Format: currency
 * @description Formats a currency into a more readable format: "35.2" to "$ 35.2"
 */
const currency = (data?: number, currency?: string): string | number | undefined => {
	let value: string | number | undefined = data;
	if (value)
		value = value.toLocaleString(getBrowserLang(), {
			style: "currency",
			currency: currency ?? "USD",
		});
	return value;
};

/**
 * Format: duration
 * @description Formats a duration time into a more readable format: "15" to "15 sec"
 */
const duration = (data?: string | number): string | undefined => {
	let value: string | undefined;

	if (data || data === 0) {
		// Cast to number
		const tmpValue = Number(data);

		// Get values: hours, minutes and seconds
		const h = Math.floor(tmpValue / 3600);
		const m = Math.floor((tmpValue % 3600) / 60);
		const s = Math.floor((tmpValue % 3600) % 60);

		// If the value is inferior to 10, add a 0 as a prefix. Ex: "5" -> "05"
		const hDisplay = h < 10 ? `0${h}` : h;
		const mDisplay = m < 10 ? `0${m}` : m;
		const sDisplay = s < 10 ? `0${s}` : s;

		// Format display
		value = `${hDisplay}:${mDisplay}:${sDisplay}`;
	}

	return value;
};

const fullName = (firstName?: string, lastName?: string, preferredName?: string | null) => {
	let value = "";
	if (
		firstName &&
		lastName &&
		preferredName &&
		preferredName.toLowerCase() !== firstName.toLowerCase()
	) {
		value = `${firstName} (${preferredName}) ${lastName}`;
	} else if (firstName && lastName) {
		value = `${firstName} ${lastName}`;
	} else if (firstName && !lastName) {
		value = firstName;
	} else if (!firstName && lastName) {
		value = lastName;
	}
	return value;
};

const statusTag = (paramValue: ParticipantStatus): UiTagProps => {
	const value: UiTagProps = {
		value:
			paramValue === null
				? "ENUMS.PARTICIPANT_STATUS.NOT_COMPUTED"
				: `ENUMS.PARTICIPANT_STATUS.${paramValue}`,
		severity: "warning",
		uppercase: true,
		rounded: true,
	};

	switch (paramValue) {
		case eParticipantStatus.ACTIVE:
			value.severity = "success";
			break;
		case eParticipantStatus.PROSPECTIVE:
			value.severity = "info";
			break;
		case eParticipantStatus.DNC:
			value.severity = "danger";
			break;
		case eParticipantStatus.INACTIVE:
			value.severity = "warning";
			break;
		default:
			break;
	}

	return value;
};

const gender = (value: Gender): string => `ENUMS.GENDER.${value}`;

const sortList = (list: List, t: TFunction<"common", undefined>): List =>
	list.sort((a, b) =>
		t(a.label.toString()) > t(b.label.toString())
			? 1
			: t(b.label.toString()) > t(a.label.toString())
				? -1
				: 0
	);

const preferredCoachingMethod = (coachingMethod: PreferredCoachingMethod) =>
	`ENUMS.COACHING_METHOD.${coachingMethod}`;

const feetInchesToInches = (feet: number | null, inches: number | null): number | null => {
	let result: number | null = null;
	if (feet && inches) {
		result = feet * 12 + inches;
	} else if (!feet && inches) {
		result = inches;
	} else if (feet && !inches) {
		result = feet * 12;
	}
	return result;
};

const numberDecimals = (value: number, digits: number): number => {
	let result: number = value;
	if (value) {
		const formatter = new Intl.NumberFormat(getBrowserLang(), {
			maximumFractionDigits: digits,
		});
		result = parseFloat(formatter.format(value));
	}
	return result;
};

const calculateAge = (birth: string): number => {
	const today = new Date();
	const birthDate = new Date(birth);
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

const datesDaysDiffUTC = (date1: Date, date2: Date): number => {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const datesDaysDiffLocal = (date1: Date, date2: Date): number => {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	const local1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const local2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
	return Math.floor((local2.getTime() - local1.getTime()) / _MS_PER_DAY);
};

const beautifyBoolean = (value?: boolean) => {
	let result = Formatter.NO_DATA;
	if (value === true) {
		result = `ENUMS.YES`;
	} else if (value === false) {
		result = `ENUMS.NO`;
	}
	return result;
};

const base64ToBlob = (base64: string, mimeType: string): Blob => {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);

	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: mimeType });
};

const base64MimeToBlob = (base64: string, mimeType: string): Blob => {
	const byteString = atob(base64.split(",")[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeType });
};

const openFile = (blob: Blob, blobMimeType: string) => {
	/**
	 * Opening a blob file with any mimetype is not safe. The opened tab will inherit the same origin as the opener (eg. https://gladstone.com)
	 *  and thus if the file opened is able to execute javascript, like a html file, then it would be able to gain complete access over the DOM
	 * 	and windows object of the opener tab, which can lead to very bad outcomes.
	 *
	 * 	Browsers are working on extending the Blob objects api, to be able to open them as URLS witouth inheriting the same origin of the opener:
	 * 		https://github.com/w3c/FileAPI/issues/192. Meanwhile since we only allow a limited number of mime types, is it better to have
	 * 		a whitelist in place. If we ever need to allow html files we could use a sandboxed domain but that's not needed now.
	 */

	const SAFE_ALLOWED_MIME_TYPES = ["image/png", "image/jpeg", "image/heic", "application/pdf"];

	if (!SAFE_ALLOWED_MIME_TYPES.includes(blobMimeType)) {
		alert(`File type not supported: ${blobMimeType}. Contact developers for more information.`);
		return;
	}

	const url = URL.createObjectURL(blob);
	window.open(url, "_blank"); // Open the file in a new tab
	URL.revokeObjectURL(url); // Clean up the URL after opening
};

export const Formatter = {
	NO_DATA,
	phoneNumber,
	currency,
	date,
	duration,
	fullName,
	statusTag,
	gender,
	sortList,
	preferredCoachingMethod,
	dateUTCWithoutTime,
	dateISOToString,
	dateUTC,
	dateLocal,
	dateISOToUTC,
	formatDob,
	feetInchesToInches,
	numberDecimals,
	calculateAge,
	datesDaysDiffUTC,
	datesDaysDiffLocal,
	getDateDistance,
	beautifyBoolean,
	base64ToBlob,
	base64MimeToBlob,
	openFile,
};
