import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Route } from "../../../models/routing.model";
import { getSidebarMenu } from "../../../services/sidebar/sidebar.service";

const SidebarResponsiveSubmodule = ({
	module,
	onClose,
}: {
	module: Route;
	onClose: (isVisible: boolean) => void;
}) => {
	const { t } = useTranslation("common");

	const basePath = getSidebarMenu("/" + module.route.path).basePath;
	const submodules = getSidebarMenu("/" + module.route.path).menu;

	const getClassName = (item: any, isActive: boolean): string => {
		let css = "menu-item";
		if (item.icon && item.title) css += " icon-label";
		if (isActive) css += " active";
		return css;
	};

	return (
		<>
			{submodules.map((item) => (
				<NavLink
					key={`${basePath}/${item.route.path}`}
					to={`${basePath}/${item.route.path}`}
					className={({ isActive }) =>
						isActive ? getClassName(item, true) : getClassName(item, false)
					}
					onClick={() => onClose(false)}
				>
					{item.routeProps?.icon && (
						<span className="menu-icon">{item.routeProps?.icon}</span>
					)}

					{item.routeProps?.title && (
						<span className="menu-title">{t(`${item.routeProps?.title}`)}</span>
					)}
				</NavLink>
			))}
		</>
	);
};
export default SidebarResponsiveSubmodule;
