import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useParticipantNotes = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["participant-notes", { participantId }];

	const query = useQuery(queryKey, async () => {
		const participantNotes = await EndpointsService.dataRetriever.getParticipantNotes({
			config: {
				params: { participantId },
			},
		});

		return participantNotes;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
