import UiFieldMessage from "../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../models/components/field-message.model";

const ParticipantState = ({ isLoading, isError }: { isLoading: boolean; isError: boolean }) => {
	return (
		<div className="participant-profile-message">
			{isLoading || isError ? (
				<UiFieldMessage
					severity={
						isLoading ? FieldMessageSeverity.LOADING : FieldMessageSeverity.DANGER
					}
					label={
						isLoading
							? "PARTICIPANT.PROFILE.EMPTY_MESSAGE_LOADING"
							: "PARTICIPANT.PROFILE.EMPTY_MESSAGE_ERROR"
					}
				/>
			) : (
				<></>
			)}
		</div>
	);
};
export default ParticipantState;
