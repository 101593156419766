import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { FormSectionProps } from "../../models/components/form-section.model";

const FormSection = (props: FormSectionProps) => {
	const { t } = useTranslation("common");

	return (
		<div className={classNames("form-section", props.className)}>
			{props.title && <div className="form-section__header">{t(props.title)}</div>}

			<div className={classNames("form-section__content", props.contentClassName)}>
				{props.children}
			</div>
		</div>
	);
};
export default FormSection;
