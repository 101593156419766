import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ModalReminderData,
	ModalReminderFormProps,
	ModalReminderMode,
} from "../../models/components/modal-reminder.model";
import UiButton from "../button/Button";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { Form } from "react-final-form";
import FormSection from "../form-section/FormSection.component";
import UiInputDate from "../input-date/InputDate.component";
import UiInputText from "../input-text/InputText.component";
import { Validations } from "../../services/form/validations.service";
import { Formatter } from "../../services/formatter/formatter.service";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { IReminder } from "../../models/entities/reminder.model";
import { AxiosError } from "axios";
import { mappedAxiosError } from "../../services/http/http.service";
import { getSessionStorageUser } from "../../services/session/auth.service";
import { FormApi } from "final-form";

const ModalReminderForm = (props: ModalReminderFormProps) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState<ModalReminderData>({
		kannactId: props.entity?.kannactId ?? null,
		patientId: props.entity?.patientId ?? null,
		reason: props.entity?.reason ?? null,
		dateOn: props.entity?.dateOn ? Formatter.dateISOToUTC(props.entity?.dateOn) : null,
		formMessage: undefined,
		mode: props.mode,
	});

	// Submit
	const handleSubmit = async (
		data: ModalReminderData,
		form: FormApi<ModalReminderData, ModalReminderData>
	) => {
		// Reset login error
		setFormData({ ...data, formMessage: undefined });

		if (formData.mode === ModalReminderMode.CREATE) {
			// Create
			create(data);
		} else {
			// Update / Snooze
			update(data);
		}
	};

	/* CREATE */

	const create = async (data: ModalReminderData): Promise<void> => {
		const now = new Date();

		await EndpointsService.dataRetriever
			.createReminder({
				body: {
					id: null,
					kannactId: data.kannactId as string,
					patientId: Number(data.kannactId?.substring(3)),
					reason: data.reason as string,
					dateOn: Formatter.dateUTCWithoutTime(data.dateOn)?.toISOString() as string,
					resolved: false,
					createdOn: now.toISOString(),
					updatedOn: now.toISOString(),
					createdBy: getSessionStorageUser().id!,
				},
			})
			.then((response: IReminder) => props.onComplete(true, response))
			.catch((error: AxiosError) => {
				// Error message
				data.formMessage = mappedAxiosError(
					undefined,
					"UI_COMPONENTS.MODAL_REMINDER.MESSAGE_ERROR"
				);
				setFormData(data);
			});
	};

	/* UPDATE / SNOOZE */

	const update = async (data: ModalReminderData): Promise<void> => {
		await EndpointsService.dataRetriever
			.updateReminder({
				body: {
					id: props.entity?.id as number,
					kannactId: data.kannactId as string,
					patientId: Number(data.kannactId?.substring(3)),
					reason: data.reason as string,
					dateOn: Formatter.dateUTCWithoutTime(data.dateOn)?.toISOString() as string,
					resolved: false,
					createdOn: props.entity?.createdOn as string,
					updatedOn: new Date().toISOString(),
				},
			})
			.then((response: IReminder) => props.onComplete(true, response))
			.catch((error: AxiosError) => {
				// Error message
				data.formMessage = mappedAxiosError(
					undefined,
					"UI_COMPONENTS.MODAL_REMINDER.MESSAGE_ERROR"
				);
				setFormData(data);
			});
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					<p className="mb-4">{t("UI_COMPONENTS.MODAL_REMINDER.MSG_INFO")}</p>

					<FormSection className="row">
						{/* Kannact ID */}
						<div className="col-12 col-sm-6 col-md-6 col-lg-6">
							<UiInputText
								id="kannactId"
								label="UI_COMPONENTS.MODAL_REMINDER.FORM_FIELDS.kannactId"
								name="kannactId"
								onChange={(e) =>
									setFormData({ ...formData, kannactId: e.target.value })
								}
								validations={[Validations.required]}
								disabled={submitting || formData.mode === ModalReminderMode.SNOOZE}
							/>
						</div>

						{/* Reason */}
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
							<UiInputText
								id="reason"
								label="UI_COMPONENTS.MODAL_REMINDER.FORM_FIELDS.reason"
								name="reason"
								onChange={(e) =>
									setFormData({ ...formData, reason: e.target.value })
								}
								validations={[Validations.required]}
								disabled={submitting || formData.mode === ModalReminderMode.SNOOZE}
							/>
						</div>

						{/* Date */}
						<div className="col-12 col-sm-12 col-md-12 col-lg-8">
							<UiInputDate
								id="dateOn"
								name="dateOn"
								label="UI_COMPONENTS.MODAL_REMINDER.FORM_FIELDS.dateOn"
								onChange={(e) =>
									setFormData({ ...formData, dateOn: e.target.value as Date })
								}
								minDate={new Date()}
								validations={[Validations.required]}
								disabled={submitting}
								inline={true}
							/>
						</div>
					</FormSection>

					{formData.formMessage && (
						<div className="form-message">
							<UiFieldMessage {...formData.formMessage}></UiFieldMessage>
						</div>
					)}

					<div className="action-buttons">
						<UiButton
							label="UI_COMPONENTS.BUTTONS.CANCEL"
							className="p-button-outlined p-button-rounded"
							type="button"
							onClick={props.onCancel}
							disabled={submitting}
						/>
						<UiButton
							label="UI_COMPONENTS.BUTTONS.CONFIRM"
							className="p-button-rounded"
							type="submit"
							loading={submitting}
							disabled={submitting}
						/>
					</div>
				</form>
			)}
		/>
	);
};
export default ModalReminderForm;
