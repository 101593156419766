import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import UiButton from "../../../../components/button/Button";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import { MedicationRow } from "../../../../models/pages/medications/medications-list.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";

const MedicationsDelete = (props: {
	medication: MedicationRow;
	onCancel: () => void;
	onComplete: () => void;
}) => {
	const { t } = useTranslation("common");
	const [message, setMessage] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const submit = async () => {
		setMessage(false);
		setSubmitting(true);

		await EndpointsService.dataRetriever
			.deleteMedication({
				id: props.medication.id,
			})
			.then((response) => props.onComplete())
			.catch((error) => setMessage(true))
			.finally(() => setSubmitting(false));
	};

	return (
		<div>
			<Trans
				i18nKey="PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.MESSAGE_DELETE"
				defaults={t("PARTICIPANT.DASHBOARD.MEDICATIONS.MODAL.MESSAGE_DELETE")}
				values={{ name: props.medication.name }}
				components={{ bold: <span className="text-bold" /> }}
			/>

			{message && (
				<div className="form-message">
					<UiFieldMessage
						severity={FieldMessageSeverity.DANGER}
						label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
					/>
				</div>
			)}

			<div className="action-buttons">
				<UiButton
					id="modalBtnCancel"
					label="UI_COMPONENTS.BUTTONS.CANCEL"
					className="p-button-outlined p-button-rounded"
					onClick={props.onCancel}
					disabled={submitting}
				/>
				<UiButton
					id="modalBtnSubmit"
					label={
						submitting
							? "UI_COMPONENTS.BUTTONS.SAVING"
							: "UI_COMPONENTS.BUTTONS.CONFIRM"
					}
					className="p-button-rounded"
					onClick={submit}
					disabled={submitting}
				/>
			</div>
		</div>
	);
};
export default MedicationsDelete;
