import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { UiCardProps } from "../../models/components/card.model";

const UiCard = (props: UiCardProps) => {
	const { t } = useTranslation("common");

	return (
		<div className={classNames("k-card", props.className, props.theme)}>
			{/* Card title */}
			{props.title && (
				<div className="k-card-header">
					<span
						className={classNames(
							"k-card-title",
							props.titleUnderline === false && "title-simple"
						)}
					>
						{t(`${props.title}`)}
					</span>

					{props.customHeader ? props.customHeader : <></>}
				</div>
			)}

			<div className="k-card-content">
				{/* Transclude content */}
				{props.children}
			</div>
		</div>
	);
};

export default UiCard;
