import { useTranslation } from "react-i18next";
import { Formatter } from "../../../services/formatter/formatter.service";

const ParticipantDetailsField = ({
	title,
	value,
}: {
	title: string;
	value: string | number | React.ReactNode | null | boolean | undefined;
}) => {
	const { t } = useTranslation("common");
	let valueToDisplay = value || Formatter.NO_DATA;
	if (typeof value === "boolean") {
		valueToDisplay = t(value ? "ENUMS.YES" : "ENUMS.NO");
	}
	return (
		<div className="pt-details-field">
			<div className="field-title">{t(title)}</div>
			<span className="field-value">{valueToDisplay}</span>
		</div>
	);
};

export default ParticipantDetailsField;
