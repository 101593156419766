import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { SidebarMenu } from "../../models/routing.model";

const SidebarDesktop = ({ props }: { props: SidebarMenu }) => {
	const { t } = useTranslation("common");

	const [expanded, setExpanded] = useState(false);

	const getClassName = (item: any, isActive: boolean): string => {
		let css = "menu-item";
		if (item.icon && item.title) css += " icon-label";
		if (isActive) css += " active";
		return css;
	};

	return (
		<aside className={expanded ? "k-sidebar-container active" : "k-sidebar-container"}>
			{expanded ? (
				<FaChevronLeft
					className="sidebar-toggle"
					onClick={() => setExpanded(false)}
				/>
			) : (
				<FaChevronRight
					className="sidebar-toggle"
					onClick={() => setExpanded(true)}
				/>
			)}

			<ul className="menu-list">
				{/* Iterate the menu options */}
				{props.menu
					.filter((item) => item.routeProps.isVisible !== false)
					.map((item, index) => (
						<li key={`${item.routeProps?.id}`}>
							<NavLink
								to={`${props.basePath}/${item.route.path}`}
								className={({ isActive }) =>
									isActive ? getClassName(item, true) : getClassName(item, false)
								}
							>
								{/* Icon */}
								{item.routeProps?.icon && (
									<span className="menu-icon">{item.routeProps?.icon}</span>
								)}

								{/* Label */}
								{item.routeProps?.title && (
									<span className="menu-title">
										{t(`${item.routeProps?.title}`)}
									</span>
								)}
							</NavLink>
						</li>
					))}
			</ul>
		</aside>
	);
};

export default SidebarDesktop;
