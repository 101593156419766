import React from "react";
import { useTranslation } from "react-i18next";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";

const UiFieldMessage = (props: UiFieldMessageProps | undefined) => {
	const { t } = useTranslation("common");

	const setIcon = (severity: FieldMessageSeverity): string => {
		let icon: string = "k-field-icon ";
		switch (severity) {
			case FieldMessageSeverity.INFO:
				icon += "pi pi-info-circle";
				break;
			case FieldMessageSeverity.SUCCESS:
				icon += "pi pi-check-circle";
				break;
			case FieldMessageSeverity.WARNING:
				icon += "pi pi-exclamation-triangle";
				break;
			case FieldMessageSeverity.DANGER:
				icon += "pi pi-times-circle";
				break;
			case FieldMessageSeverity.LOADING:
				icon += "pi pi-spin pi-spinner";
				break;
			default:
				console.warn("Severity not found");
				break;
		}
		return icon;
	};

	return (
		<>
			{props && (props.severity || props.label) && (
				<small className={`k-field-message-container ${props.severity}`}>
					<i className={setIcon(props.severity)}></i>
					<span className="k-field-message">{t(props.label)}</span>
				</small>
			)}
		</>
	);
};

export default UiFieldMessage;
