import { useTranslation } from "react-i18next";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { useHedisGoals } from "./useHedisGoals";
import { SU_Service } from "../../../services/service-utilization-goals/service-utilization-goals.service";
import {
	BiometricGoal,
	GoalCategory,
	GoalState,
	MedicationGoal,
} from "../../../models/entities/goal.model";
import {
	SuCategories,
	SuServices,
	SuStatus,
} from "../../../models/entities/service-utilization-goals.model";
import { GoalsService } from "../../../services/goals/goals.service";
import { HedisGoal, HedisGoalCategory } from "../../../models/endpoints/goals_endpoint.model";

const ParticipantHedisGoals = ({
	participantContext,
}: {
	participantContext: ParticipantContextProps;
}) => {
	const { t } = useTranslation("common");

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const op = useRef<OverlayPanel>(null);

	const goals = useHedisGoals({ participantId: participantContext.participant!.id });

	const serviceUtilizationGoals =
		goals.data
			?.find((x) => x.name === "SERVICE_UTILIZATION")
			?.goals.filter(
				(goal) => SU_Service.getCategory(goal.name as SuServices) !== SuCategories.VACCINES
			) || [];
	const biometricGoals = goals.data?.find((x) => x.name === "BIOMETRICS")?.goals || [];
	const medicationGoals = goals.data?.find((x) => x.name === "MEDICATIONS")?.goals || [];

	const goalsCount =
		serviceUtilizationGoals.length + biometricGoals.length + medicationGoals.length;

	return (
		<div className="d-flex">
			<span className="hedis-goals-title">{t("PARTICIPANT.PROFILE.FIELDS.HEDIS_GOALS")}</span>
			{goalsCount && goalsCount > 0 ? (
				<span className="p-badge p-component p-badge-no-gutter p-badge-danger tab-count hedis-goals__circle hedis-goals__badge-content">
					{goalsCount}
				</span>
			) : undefined}
			<button
				className="btn bg-transparent p-0 hedis-goals_button-no-focus"
				onClick={(e) => {
					op.current?.toggle(e);
				}}
			>
				<span className={isCollapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"}></span>
			</button>

			<OverlayPanel
				ref={op}
				appendTo={this}
				onShow={() => setIsCollapsed(false)}
				onHide={() => setIsCollapsed(true)}
				className="hedis-goals_overlaypanel"
			>
				{goalsCount && goalsCount > 0 ? (
					<div className="hedis-goals__container">
						{/* Goals: Biometrics */}
						{biometricGoals.length > 0 && (
							<HedisDropdownSection
								title={"PARTICIPANT.PROFILE.HEDIS_GOALS.BIOMETRICS"}
								category={HedisGoalCategory.BIOMETRICS}
								goals={biometricGoals}
							/>
						)}

						{/* Goals: Medications */}
						{medicationGoals.length > 0 && (
							<HedisDropdownSection
								title={"PARTICIPANT.PROFILE.HEDIS_GOALS.MEDICATION_ADHERENCE"}
								category={HedisGoalCategory.MEDICATIONS}
								goals={medicationGoals}
							/>
						)}

						{/* Goals: Service Utilization */}
						{serviceUtilizationGoals.length > 0 && (
							<HedisDropdownSection
								title={"PARTICIPANT.PROFILE.HEDIS_GOALS.SERVICE_UTILIZATION"}
								category={HedisGoalCategory.SERVICE_UTILIZATION}
								goals={serviceUtilizationGoals}
							/>
						)}
					</div>
				) : (
					<span>{t("PARTICIPANT.PROFILE.HEDIS_GOALS.EMPTY")}</span>
				)}
			</OverlayPanel>
		</div>
	);
};

const HedisDropdownSection = ({
	title,
	category,
	goals,
}: {
	title: string;
	category: HedisGoalCategory;
	goals: HedisGoal[];
}) => {
	const { t } = useTranslation("common");

	const mapData = (goal: HedisGoal) => {
		const result = {
			desc: "",
			value: "",
		};

		switch (category) {
			case HedisGoalCategory.BIOMETRICS:
				result.desc = t(
					`PARTICIPANT.PROFILE.HEDIS_GOALS.${goal.name.startsWith(BiometricGoal.BPS) ? "BPS" : "A1C"}`
				);
				result.value = t(
					GoalsService.getDescriptionByStateCategory(
						goal.state as GoalState,
						GoalCategory.BIOMETRIC
					)
				);
				break;
			case HedisGoalCategory.MEDICATIONS:
				result.desc = t(
					`PARTICIPANT.PROFILE.HEDIS_GOALS.${goal.name.startsWith(MedicationGoal.AMR) ? "AMR" : "STATIN_PDC"}`
				);
				result.value = t(
					GoalsService.getDescriptionByStateCategory(
						goal.state as GoalState,
						GoalCategory.DRUGS
					)
				);
				break;
			case HedisGoalCategory.SERVICE_UTILIZATION:
				result.desc = `${t(`PARTICIPANT.SERVICE_UTILIZATION_GOALS.SERVICES.${goal.name}`)}:`;
				result.value = `${t(`PARTICIPANT.SERVICE_UTILIZATION_GOALS.STATUS.${goal.state as SuStatus}`)}`;
				break;
			default:
				break;
		}

		return result;
	};

	return (
		<div className="hedis-goals__section">
			<span className="hedis-goals__title">{t(title)}</span>
			<ul className="hedis-goals__list">
				{goals.map((goal) => {
					const row = mapData(goal);
					return (
						<li key={goal.name}>
							<span className="mx-1">{row.desc}</span>
							<strong>{row.value}</strong>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ParticipantHedisGoals;
