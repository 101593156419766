import { useContext, useState } from "react";
import { Form } from "react-final-form";
import {
	IParticipantSUGService,
	SuForm,
} from "../../../models/pages/service-utilization-goals/participant-sug.model";
import UiInputDate from "../../../components/input-date/InputDate.component";
import { Validations } from "../../../services/form/validations.service";
import UiInputTextarea from "../../../components/input-textarea/InputTextarea.component";
import UiButton from "../../../components/button/Button";
import UiSelect from "../../../components/select/Select.component";
import { SuCategories, SuExam } from "../../../models/entities/service-utilization-goals.model";
import UiInputCheckbox from "../../../components/input-checkbox/InputCheckbox.component";
import { ParticipantContext } from "../../../contexts/Participant.context";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { CreateServiceUtilizationEntryRequest } from "../../../models/endpoints/service-utilization-goals-endpoint.model";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { Formatter } from "../../../services/formatter/formatter.service";

const ServiceUtilizationGoalsDataEntry = ({
	props,
}: {
	props: IParticipantSUGService & {
		onSubmitDataEntry: (
			createSUEntryRequest: CreateServiceUtilizationEntryRequest
		) => Promise<void>;
	};
}) => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	// Form data
	const getInitialFormData = () => ({
		examType: props.examTypes.length === 1 ? (props.examTypes[0].id as SuExam) : null,
		dateOn: null,
		estimated: false,
		notes: null,
	});
	const [formData, setFormData] = useState<SuForm>(getInitialFormData());
	const [errorOnSubmitForm, setErrorOnSubmitForm] = useState(false);

	const clear = () => {
		setFormData(getInitialFormData());
	};

	// Submit
	const submit = async (): Promise<void> => {
		try {
			setErrorOnSubmitForm(false);

			await props.onSubmitDataEntry({
				participantId: Number(participantContext.participant?.id),
				body: {
					category: props.category,
					dateOn: Formatter.dateUTCWithoutTime(formData.dateOn)?.toISOString()!,
					estimated: formData.estimated,
					examType: formData.examType!,
					goalName: props.id,
					notes: formData.notes!,
					source: "coach",
				},
			});
			clear();
		} catch (err) {
			setErrorOnSubmitForm(true);
		}
	};

	return (
		<div className="sug-form-content">
			<Form
				onSubmit={submit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting, ...rest }) => {
					return (
						<form onSubmit={(event) => handleSubmit(event)?.then(() => form.restart())}>
							<div className="row">
								{/* Date of service */}
								<div className="col-12 col-lg-4">
									<UiInputDate
										id="dateOn"
										placeholder={
											props.category === SuCategories.MENTAL_HEALTH
												? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.dateOnMentalHealth"
												: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.dateOn"
										}
										label={
											props.category === SuCategories.MENTAL_HEALTH
												? "PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.dateOnMentalHealth"
												: "PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.dateOn"
										}
										name="dateOn"
										onChange={(e) =>
											setFormData({
												...formData,
												dateOn: (e.target.value as Date) || null,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
										maxDate={new Date()}
									/>
								</div>

								{/* Estimated date */}
								<div className="col-12 col-lg-8">
									<UiInputCheckbox
										id="estimated"
										name="estimated"
										label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.estimated"
										onChange={(e) =>
											setFormData({ ...formData, estimated: e.target.value })
										}
										disabled={submitting}
										binary={true}
									/>
								</div>

								{/* Exam type */}
								{props.examTypes.length > 1 && (
									<div className="col-12 col-lg-6">
										<UiSelect
											id="examType"
											placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.examType"
											label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.examType"
											name="examType"
											onChange={(e) =>
												setFormData({
													...formData,
													examType: e.target.value,
												})
											}
											validations={[Validations.required]}
											disabled={submitting}
											options={props.examTypes}
										/>
									</div>
								)}

								{/* Notes */}
								<div className="col-12 col-lg-12">
									<UiInputTextarea
										id="notes"
										placeholder="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.notes"
										label="PARTICIPANT.SERVICE_UTILIZATION_GOALS.DATA_ENTRY.notes"
										name="notes"
										onChange={(e) =>
											setFormData({ ...formData, notes: e.target.value })
										}
										disabled={submitting}
									/>
								</div>
							</div>

							{/* FORM MESSAGE */}
							{errorOnSubmitForm && (
								<div className="form-message">
									<UiFieldMessage
										severity={FieldMessageSeverity.DANGER}
										label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
									></UiFieldMessage>
								</div>
							)}

							{/* Form actions */}
							<div className="action-buttons">
								<UiButton
									label="UI_COMPONENTS.BUTTONS.CLEAR"
									className="p-button-outlined p-button-rounded"
									type="reset"
									onClick={() => {
										clear();
										form.restart();
									}}
									disabled={submitting}
								/>
								<UiButton
									label={
										submitting
											? "UI_COMPONENTS.BUTTONS.SAVING"
											: "UI_COMPONENTS.BUTTONS.SAVE"
									}
									className="p-button-rounded"
									type="submit"
									disabled={submitting}
								/>
							</div>
						</form>
					);
				}}
			/>
		</div>
	);
};

export default ServiceUtilizationGoalsDataEntry;
